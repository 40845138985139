// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { StyledStatementsModalContainer } from "./styled";

export const TurnOffStatementsModalMessage = () => {
  return (
    <StyledStatementsModalContainer>
      <p>Are you sure you want to turn off automatic statement delivery?</p>
      <p>If so, you can restart delivery at any time.</p>
    </StyledStatementsModalContainer>
  );
};

export default TurnOffStatementsModalMessage;
