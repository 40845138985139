import React from "react";

import { Accordion } from "@billfire/toybox";
import AddPaymentMethodLink from "../AddPaymentMethodLink/index";
import PaymentMethodSelect from "../PaymentMethodSelect";
import PaymentMethodTitle from "../PaymentMethodSelect/PaymentMethodTitle";
import { getPaymentMethods } from "../../state/entities/paymentMethods/selectors";

import { useSelector } from "react-redux";
import { TitleWrapper } from "./styled";

const PPPaymentMethodSelect: React.FC = () => {
  const hasPaymentMethods = !!useSelector(getPaymentMethods).length;

  return (
    <>
      {hasPaymentMethods ? (
        <Accordion
          title="Payment Method"
          headingPadding={"15px 0"}
          titleColor={"#777777"}
          titleWeight="100"
          secondaryTitle={
            <TitleWrapper>
              <PaymentMethodTitle styleFont={false} />
            </TitleWrapper>
          }
          content={<PaymentMethodSelect />}
          fontSize="15px"
          hideBoxShadow={true}
          fused
          accordionWidth="100%"
        />
      ) : (
        <AddPaymentMethodLink {...{ hasPaymentMethods }} />
      )}
    </>
  );
};

export default PPPaymentMethodSelect;
