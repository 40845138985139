import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, filter, startWith } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadVendorsSubmit,
  loadVendorsStart,
  loadVendorsSuccess,
  loadVendorsFailed
} from "./slice";
import { ApiResponseVendors, ApiResponseVendor, Vendor } from "./types";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";

export const mapResponse = (
  responseVendors: ApiResponseVendors
): Record<string, Vendor> => {
  return responseVendors.vendors.reduce(
    (byId: Record<string, Vendor>, responseVendor: ApiResponseVendor) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { uuid, _pk, _sk, ...rest } = responseVendor;
      // eslint-disable-next-line security/detect-object-injection
      byId[uuid] = {
        ...rest,
        uuid,
        id: uuid,
        paymentMethodConfig: {
          ...rest.paymentMethodConfig,
          options: rest.paymentMethodConfig?.options || []
        }
      };
      return byId;
    },
    {}
  );
};

export const fetchVendorsEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadVendorsStart.type),
    filter(() => {
      return !state$.value.vendors.hasEverLoadedVendors;
    }),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      return ajax.get(api.API_URL("/vendors"), api.getHeaders({ token })).pipe(
        map(response => {
          const resp = api.handleAJAXResponse(response, token);
          const mapped = mapResponse(resp);
          return loadVendorsSuccess(mapped);
        }),
        startWith(loadVendorsSubmit()),
        catchError(error => {
          if (error instanceof AjaxError) api.handleAJAXResponse(error, token);
          return of(loadVendorsFailed());
        })
      );
    })
  );
