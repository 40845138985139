import styled from "styled-components";

export const CancelMessage = styled.div`
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 40px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  justify-content: space-between;
`;

export const LeftColumn = styled.div`
  display: flex;
`;

export const RightColumn = styled.div`
  display: flex;
`;
