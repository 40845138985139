import styles, { Accordion, DataTable } from "@billfire/toybox";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../breakpoints";
import TotalsAndFees from "../TotalsAndFees";

const { colors } = styles;
const { baseGray2, baseGray3, baseGray7 } = colors;

export const StyledTotalsAndFees = styled(TotalsAndFees)`
  padding-top: 15px;
  border-bottom: 2px solid rgb(246, 246, 246);
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 2px solid rgb(246, 246, 246);

  :last-child {
    border-bottom: none;
  }
`;

export const RowTitle = styled.div`
  color: ${baseGray7};
`;

export const RowContent = styled.div`
  color: ${baseGray3};
`;

export const StyledDataTable = styled(DataTable)`
  width: 100%;

  th {
    color: ${baseGray7};
    padding: 15px 0px 0px 0px;
    font-weight: normal;
    :last-child {
      text-align: right;
    }
  }
  td {
    color: ${baseGray2};
    padding: 15px 15px 0px 0px;
    @media only screen and (${device.phone}) {
      padding: 15px 10px 0 0;
    }
    :last-child {
      text-align: right;
      padding: 15px 0 0 0;
    }
  }

  @media only screen and (${device.tablet}) {
    margin-bottom: 15px;
  }
`;

export const ErrorContainer = styled.div`
  padding: 40px 26px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
`;

export const StyledLink = styled(Link)`
  color: ${colors.baseBlue0};
`;

export const StyledInvoiceLink = styled.a`
  color: ${colors.baseBlue0};
  white-space: nowrap;
  text-align: left;
  max-width: 90%;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const PMDetailContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const PMDisplayNameContainer = styled.div`
  display: flex;
  max-width: 50%;
`;

export const EllipsisPMName = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${baseGray3};
  padding-right: 5px;
`;

export const PrintExportLinkWrapper = styled.div`
  padding-bottom: 20px;
`;

export const Header = styled.div`
  text-align: center;
  h2 {
    margin-top: 20px;
    margin-bottom: 50px;
    color: rgb(80, 80, 80);
    font-weight: 400;
  }
`;

export const ThankYouHeader = styled.div`
  font-size: 24px;
  padding: 25px 0px;
`;

export const StyledAccordion = styled(Accordion)`
  &.accordion {
    .accordion-header {
      color: #777;
      font-weight: 300;
      font-size: 16px;
      border-bottom: 2px solid rgb(246, 246, 246);
    }
    .accordion-content {
      padding-top: 20px;
    }
  }
`;
