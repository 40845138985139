/* eslint-disable-next-linemax-len */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React from "react";

import { Icon, IconProps } from "./index";

export const Messages = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"7 7 66 66"}
    onClick={props.onClick}
    label="messages"
  >
    <g
      id="Group_9435_00000101782013380761544940000002294743386343729025_"
      transform="translate(-304.389 -51.554)"
    >
      <path
        fill={props.fill}
        id="Path_1145_00000039122540049192221240000010536196630347625913_"
        d="M339.8,116.1c-1.3,2.7-0.2,6,2.5,7.3
        c0.8,0.4,1.7,0.6,2.6,0.6c3.8,0,6.3-3.6,4.4-7.9H339.8z"
      />
      <path
        fill={props.fill}
        id="Path_1146_00000018202126646797728350000015029828445910592153_"
        d="M367,100.1c-0.5-0.9-1.7-4.2-1.7-15.1
		c0-9.6-4.8-15.9-14.2-18.7c-0.1-4-3.5-7.2-7.6-7.1c-3.9,0.1-6.9,3.2-7.1,7.1c-8.5,2.7-12.9,9-12.9,18.7c0,10.9-1.2,14.2-1.7,15.1
		c-3.6,0.5-6.1,3.7-5.6,7.3c0.4,3.2,3.1,5.6,6.3,5.6h43.7c3.6-0.1,6.4-3.1,6.3-6.7C372.5,103.1,370.1,100.5,367,100.1L367,100.1z
		 M366.3,107.7h-43.7c-0.6,0-1-0.4-1-1.2s0.4-1.2,1-1.2c4.4,0,6.3-6.1,6.3-20.4c0-8.4,3.3-12.5,11.4-14.2l1.5-0.3v-4.3h0.1
		c0-0.2,0.1-0.3,0.2-0.5c0.5-1,1.7-1.4,2.7-1c0.8,0.4,1.3,1.2,1.1,2.1v3.7l1.5,0.3C356,72.6,360,77,360,85c0,14.3,1.9,20.4,6.3,20.4
		c0.6,0,1,0.4,1,1.2S366.9,107.7,366.3,107.7z"
      />
    </g>
  </Icon>
);

export const Click2Pay = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"7 7 66 66"}
    onClick={props.onClick}
    label="click2Pay"
  >
    <g
      id="Group_9433_00000109721054811928393620000017460594384395670939_"
      transform="translate(-93.475 -53.695)"
    >
      <g id="Group_9431_00000140701381308188841210000011639399930651981221_">
        <path
          id="Path_1140_00000115480252292778243540000006158639937238987166_"
          d="M136.9,108.2c1.6-0.4,3.1-1.1,4.5-2
			c3.3-2.6,4.2-7.2,2.1-10.9c-0.1-0.2-0.3-0.5-0.4-0.7c-1.5-1.6-3.4-2.8-5.6-3.3l-1.6-0.5c-1.5-0.5-3.1-1-4.7-1.7
			c-0.9-0.3-1.5-1.2-1.4-2.2c0.1-0.9,0.5-1.8,1.1-2.4c1.4-0.9,3.2-1.1,4.8-0.4h0c1,0.4,1.8,1.2,2.1,2.2c0.1,0.8,0.9,1.4,1.7,1.2
			c0,0,0,0,0,0h0c0.3,0,0.6,0,0.8,0c0.2,0,0.3,0,0.4,0h1.2c0.7,0,1.2-0.1,1.5-0.5c0.2-0.6,0.2-1.3,0-1.9c-0.8-3.2-3.4-5.7-6.6-6.4
			c-0.6-0.2-0.7-0.3-0.7-0.9c-0.1-3.1-0.2-3.3-2.9-3.2H133c-0.4-0.1-0.8,0-1.1,0.3c-0.3,0.4-0.5,0.9-0.4,1.4v0.3c0,0.3,0,0.5,0,0.8
			c0.1,0.9-0.1,1.2-1,1.3c-4,0.7-6.9,4.3-6.7,8.4c0.1,4.6,2.3,6.4,5.9,7.8c1.2,0.5,2.5,0.9,3.7,1.3c1.1,0.4,2.3,0.8,3.4,1.2
			c1.1,0.4,1.8,1.4,1.8,2.5c0.1,1-0.5,2-1.4,2.5l0,0c-1.8,1.1-4.1,1.2-6,0.3c-0.7-0.2-1.3-0.7-1.6-1.3c-0.4-1.7-2.2-2.7-3.8-2.2
			l-0.1,0c-0.1,0-0.2,0-0.3,0h-0.2c-0.6-0.1-1.3,0.1-1.7,0.6c-0.3,0.7-0.3,1.4,0,2.1c0.7,3.1,3.1,5.5,6.1,6.2l0.3,0.1
			c1.7,0.5,1.7,0.5,1.7,2.5c0,1.2,0.5,1.8,1.5,1.8h0.1c0.3,0,0.6,0,0.8,0c0.9,0,1.5,0,1.9-0.4s0.4-1.2,0.3-2.8
			C136.2,108.5,136.3,108.4,136.9,108.2z"
        />
      </g>
      <g id="Group_9432_00000104684813329745183560000003906325281624280752_">
        <path
          id="Path_1141_00000149381890830513297760000017790048411835514245_"
          d="M133.5,126.6c-18.2,0-33-14.8-33-33
			c0-18.2,14.8-33,33-33c18.2,0,33,14.8,33,33C166.4,111.9,151.7,126.6,133.5,126.6z M133.5,66.2c-15.2,0-27.5,12.3-27.5,27.5
			c0,15.2,12.3,27.5,27.5,27.5s27.5-12.3,27.5-27.5l0,0C160.9,78.5,148.6,66.3,133.5,66.2L133.5,66.2z"
        />
      </g>
    </g>
  </Icon>
);

export const AutoPay = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"7 7 66 66"}
    onClick={props.onClick}
    label="autoPay"
  >
    <g
      id="Group_9434_00000090263243192425498190000009156929875523146655_"
      transform="translate(-188.291 -54.224)"
    >
      <path
        id="Path_1142_00000039846302693186929460000000556916568072911272_"
        d="M231.8,108.9c1.6-0.4,3.1-1.1,4.5-2
		c3.3-2.6,4.2-7.2,2.1-10.9c-0.1-0.2-0.3-0.5-0.4-0.7c-1.5-1.6-3.4-2.8-5.6-3.3l-1.6-0.5c-1.5-0.5-3.1-1-4.7-1.7
		c-0.9-0.3-1.5-1.2-1.4-2.2c0.1-0.9,0.5-1.8,1.1-2.4c1.4-0.9,3.2-1.1,4.8-0.4h0c1,0.4,1.8,1.2,2.1,2.2c0.1,0.8,0.9,1.4,1.7,1.2
		c0,0,0,0,0,0h0c0.3,0,0.6,0,0.8,0c0.2,0,0.3,0,0.4,0h1.2c0.7,0,1.2-0.1,1.5-0.5c0.2-0.6,0.2-1.3,0-1.9c-0.8-3.2-3.4-5.7-6.7-6.4
		c-0.6-0.2-0.7-0.3-0.7-0.9c-0.1-3.1-0.2-3.3-2.9-3.2H228c-0.4-0.1-0.8,0-1.1,0.3c-0.3,0.4-0.5,0.9-0.4,1.4v0.3c0,0.3,0,0.5,0,0.8
		c0.1,0.9-0.1,1.2-1,1.3c-4,0.7-6.9,4.3-6.7,8.4c0.1,4.6,2.3,6.4,5.9,7.8c1.2,0.5,2.5,0.9,3.7,1.3c1.1,0.4,2.3,0.8,3.4,1.2
		c1.1,0.4,1.8,1.4,1.8,2.5c0.1,1-0.5,2-1.4,2.5l0,0c-1.8,1.1-4.1,1.2-6,0.3c-0.7-0.2-1.3-0.7-1.6-1.3c-0.4-1.7-2.2-2.7-3.8-2.2
		l-0.1,0c-0.1,0-0.2,0-0.3,0h-0.2c-0.6-0.1-1.3,0.1-1.7,0.6c-0.3,0.7-0.3,1.4,0,2.1c0.7,3.1,3.1,5.5,6.1,6.2l0.3,0.1
		c1.7,0.5,1.7,0.5,1.7,2.5c0,1.2,0.5,1.8,1.5,1.8h0.1c0.3,0,0.6,0,0.8,0c0.9,0,1.5,0,1.9-0.4s0.4-1.2,0.3-2.8
		C231.1,109.2,231.2,109.1,231.8,108.9z"
      />
      <path
        id="Path_1143_00000023257153525081042940000014975431460820629182_"
        d="M210.7,79.3l-3.7-1.8c9.2-11.8,26.2-13.9,38-4.7
		c3.9,3,6.9,7.1,8.7,11.7l0.5,1.4l5.8-2l-0.6-1.4c-6.5-17.1-25.6-25.7-42.8-19.2c-6.1,2.3-11.4,6.4-15.2,11.7l-4.1-2l0.9,15.2
		L210.7,79.3z"
      />
      <path
        id="Path_1144_00000044866881546123247690000017614244186518489520_"
        d="M246.4,108.7l3.8,1.8c-9,12-25.9,14.5-37.9,5.5
		c-4.2-3.2-7.4-7.5-9.2-12.4l-0.5-1.4l-5.8,2l0.5,1.4c6.3,17.2,25.3,26.1,42.5,19.9c6.5-2.3,12-6.6,16-12.3l4.1,2l-0.9-15.2
		L246.4,108.7z"
      />
    </g>
  </Icon>
);

export const Invoices = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"2 6 78 68"}
    onClick={props.onClick}
    label="invoices"
  >
    <g
      id="Group_9439_00000120547397355139563040000012571082671193056928_"
      transform="translate(-53.73 -77.152)"
    >
      <path
        id="Path_1154_00000047776649806355175290000015147315437836028347_"
        d="M100.7,139.7H61.2V87.5h39.5v20.6h3.7V83.8H57.5v59.6
		h46.9v-3.8L100.7,139.7L100.7,139.7z"
      />
      <path
        id="Path_1155_00000134247843788036450590000000351884816199047354_"
        d="M132.5,144.4L132.5,144.4l-11.7-11.7
		c-0.5-0.5-1.4-0.5-1.9,0c0,0,0,0,0,0l0,0l-0.6,0.6l-1.5-1.5c4.1-5.7,2.9-13.6-2.7-17.7s-13.6-2.9-17.7,2.7s-2.9,13.6,2.7,17.7
		c4.5,3.3,10.7,3.3,15.1-0.1l1.5,1.5l-0.6,0.6c-0.5,0.5-0.5,1.4,0,1.9l0,0l11.6,11.6c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4
		l3.8-3.8C133,145.8,133,144.9,132.5,144.4C132.5,144.4,132.5,144.4,132.5,144.4z M106.6,134.3c-5.5,0-10-4.5-10-10
		c0-5.5,4.5-10,10-10s10,4.5,10,10C116.6,129.8,112.1,134.2,106.6,134.3z M127.8,147.2l-9.7-9.7l1.9-1.9l9.7,9.7L127.8,147.2z"
      />
      <path
        id="Path_1156_00000010294546344953603160000010324424102814002056_"
        d="M104.5,126.5l-3-3l-1.6,1.6l4.6,4.6l9.2-9.2l-1.6-1.6
		L104.5,126.5z"
      />
      <rect
        id="Rectangle_6415_00000004511375817063119180000014903968699278387102_"
        x="64.7"
        y="90.2"
        width="15.5"
        height="2.6"
      />
      <rect
        id="Rectangle_6416_00000075147371541632983530000001374944193945818778_"
        x="64.7"
        y="95.4"
        width="15.5"
        height="2.6"
      />
      <rect
        id="Rectangle_6417_00000169537894230795414280000001867461165255185314_"
        x="64.7"
        y="104.7"
        width="33"
        height="2.6"
      />
      <path
        id="Path_1157_00000109014115935110606270000017141846421264852630_"
        d="M64.7,132.9v2.6H94v-2.6h-4v-20h4v-2.6H64.7v2.6h0
		L64.7,132.9L64.7,132.9z M87.4,132.9h-3.9v-20h3.9V132.9z M67.4,112.9h13.4v20H67.4V112.9z"
      />
    </g>
  </Icon>
);

export const Payments = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"10 6 60 66"}
    onClick={props.onClick}
    label="payments"
  >
    <g
      id="Group_9440_00000140710128249927429400000018341862950072580483_"
      transform="translate(-187.725 -83.636)"
    >
      <path
        id="Path_1158_00000032633775008910666900000005933026829024988588_"
        d="M253.3,95.9v26h-52.5v4.4h56.9V95.9L253.3,95.9z"
      />
      <path
        id="Path_1159_00000170984202388404203220000018237999759499810720_"
        d="M250.6,119.2v-5l0,0V95.6l0,0v-5h-52.9v28.6
		L250.6,119.2z M202.8,109.1c-0.5,0-1,0.1-1.5,0.3v-9c2.6,0.9,5.4-0.5,6.3-3.1c0.4-1,0.4-2.2,0-3.2h33.2c-0.9,2.6,0.5,5.4,3.1,6.3
		c1,0.4,2.2,0.4,3.2,0v9c-2.6-0.9-5.4,0.5-6.3,3.1c-0.4,1-0.4,2.2,0,3.2h-33.2c0.2-0.5,0.3-1,0.3-1.5
		C207.9,111.3,205.6,109.1,202.8,109.1L202.8,109.1z"
      />
      <ellipse
        id="Ellipse_114_00000127726201230008486500000001483073589114334138_"
        cx="224.2"
        cy="105"
        rx="6.3"
        ry="7.8"
      />
      <path
        id="Path_1160_00000004530865462628006770000011494030088927379356_"
        d="M234.9,131.4h-10.7v11.4h-6.6l12.3,13.9l11.7-13.9
		h-6.6L234.9,131.4z M236.2,145.3l-6.4,7.6l-6.7-7.6h3.6v-11.4h5.7v11.4H236.2z"
      />
    </g>
  </Icon>
);

export const Statement = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"7 7 66 66"}
    onClick={props.onClick}
    label="statement"
  >
    <g
      id="Group_9438_00000055683985001631063900000015406050549262157190_"
      transform="translate(-94.135 -65.697)"
    >
      <path
        id="Path_1152_00000099654818077865980350000005654221488384683926_"
        d="M155.1,87.7v44.9h-32.2v4.3h36.5V87.7H155.1z"
      />
      <path
        id="Path_1153_00000153700377257750529110000000923100710648300206_"
        d="M152.4,74.6h-43.5v55.2h43.5V74.6z M148.7,126.1
		h-36.1V78.3h36.1L148.7,126.1z"
      />
      <rect
        id="Rectangle_6403_00000046327846638428908790000000827751164541802659_"
        x="116.2"
        y="117.2"
        width="4.3"
        height="5.3"
      />
      <rect
        id="Rectangle_6404_00000081611043148658755670000011063368535830947517_"
        x="124.7"
        y="110.8"
        width="4.3"
        height="11.7"
      />
      <rect
        id="Rectangle_6405_00000109713215799904883890000011024846203141229954_"
        x="131.9"
        y="112.9"
        width="4.3"
        height="9.6"
      />
      <rect
        id="Rectangle_6406_00000026126396767613473320000009151810047118781088_"
        x="140.4"
        y="106.5"
        width="4.3"
        height="16"
      />
      <rect
        id="Rectangle_6407_00000006688211929097928830000012506517672421703087_"
        x="115.2"
        y="82.6"
        width="5.2"
        height="2.7"
      />
      <rect
        id="Rectangle_6408_00000163031660953204320730000005448028497930919091_"
        x="123.3"
        y="82.6"
        width="22.3"
        height="2.7"
      />
      <rect
        id="Rectangle_6409_00000145045833302498439100000011119262751878928828_"
        x="115.2"
        y="88"
        width="5.2"
        height="2.7"
      />
      <rect
        id="Rectangle_6410_00000134231261246336433570000007259041731692865209_"
        x="123.3"
        y="88"
        width="22.3"
        height="2.7"
      />
      <rect
        id="Rectangle_6411_00000048483652824979604250000002258660875224969651_"
        x="115.2"
        y="93.4"
        width="5.2"
        height="2.7"
      />
      <rect
        id="Rectangle_6412_00000101787993376056543990000000097712793829836441_"
        x="123.3"
        y="93.4"
        width="22.3"
        height="2.7"
      />
      <rect
        id="Rectangle_6413_00000045610398052533411760000018051913398305469591_"
        x="115.2"
        y="98.8"
        width="5.2"
        height="2.7"
      />
      <rect
        id="Rectangle_6414_00000029011177200855943010000003321385328619561105_"
        x="123.3"
        y="98.8"
        width="22.3"
        height="2.7"
      />
    </g>
  </Icon>
);

export const PaymentPlan = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"4 10 72 66"}
    onClick={props.onClick}
    label="paymentPlan"
  >
    <g
      id="Group_9441_00000057866819363785114020000015160206861075500700_"
      transform="translate(-307.525 -84.579)"
    >
      <path
        id="Path_1161_00000103258578271320083170000011231774572484218044_"
        d="M337.5,129.8v24.4h45.1v-24.4H337.5z M378.3,145.6
		c-2.4,0-4.3,1.9-4.3,4.3c0,0.3,0,0.6,0.1,0.9H346c0.5-2.3-1-4.6-3.4-5c-0.6-0.1-1.1-0.1-1.7,0v-7.3c2.3,0.5,4.6-1.1,5-3.4
		c0.1-0.5,0.1-1.1,0-1.7h28.1c-0.5,2.3,1.1,4.6,3.4,5c0.5,0.1,1.1,0.1,1.7,0v7.3C378.8,145.7,378.6,145.6,378.3,145.6z"
      />
      <ellipse
        id="Ellipse_115_00000036941987468521336900000008878114688072931764_"
        cx="360"
        cy="142.1"
        rx="5.3"
        ry="6.7"
      />
      <path
        id="Path_1162_00000005979607948053948510000014732756415897418400_"
        d="M317.9,123c0-12.5,10.2-22.6,22.6-22.6
		c12.5,0,22.6,10.1,22.6,22.6c0,0.4,0,0.9,0,1.3h5.5c0-0.4,0-0.9,0-1.3c0-15.5-12.6-28.1-28.2-28.1c-15.5,0-28.1,12.6-28.1,28.1
		c0,0.4,0,0.9,0,1.3h5.5C318,123.9,317.9,123.4,317.9,123z"
      />
      <path
        id="Path_1163_00000080189126510263062970000003431503624491533186_"
        d="M340.8,121l-8.2-3.8l-1.9,4.2l10.1,4.6l0,0.1l0,0
		l0.1,0l0.1-0.1l16.2-7.9l-2-4.1L340.8,121z"
      />
      <path
        id="Path_1164_00000105416948146461640630000006876248530546381702_"
        d="M328.2,137.5l-12.5-8.7l-0.9,15.2l4.8-2.3
		c3.2,3.6,7.4,6.4,12,7.9v-5.9c-2.5-1.1-4.8-2.6-6.8-4.6L328.2,137.5z"
      />
    </g>
  </Icon>
);

export const Remit = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"15 10 49 60"}
    onClick={props.onClick}
    label="remit"
  >
    <g
      id="Group_9436_00000114775499794499744240000017393154647467581096_"
      transform="translate(-191.326 -60.113)"
    >
      <path
        id="Path_1148_00000163050448111908019150000007127847670807291053_"
        d="M207.8,70.3V130h47V70.3H207.8z M251.1,126.3h-39.6
		V74h39.6V126.3z"
      />
      <rect
        id="Rectangle_6386_00000171696968280785193200000005604726120159103920_"
        x="214.1"
        y="93"
        width="4.1"
        height="2.7"
      />
      <rect
        id="Rectangle_6387_00000098921430699278867050000007409299618906370732_"
        x="220.7"
        y="93"
        width="27.8"
        height="2.7"
      />
      <rect
        id="Rectangle_6388_00000030454616149432462200000003772474551734721954_"
        x="214.1"
        y="99"
        width="4.1"
        height="2.7"
      />
      <rect
        id="Rectangle_6389_00000112590672213433431670000000571886479256476086_"
        x="220.7"
        y="99"
        width="27.8"
        height="2.7"
      />
      <rect
        id="Rectangle_6390_00000173853985233435014610000003472220142626085290_"
        x="214.1"
        y="104.9"
        width="4.1"
        height="2.7"
      />
      <rect
        id="Rectangle_6391_00000056415568871834513990000008329190429097671555_"
        x="220.7"
        y="104.9"
        width="27.8"
        height="2.7"
      />
      <rect
        id="Rectangle_6392_00000064324239182157661490000015287502700523202189_"
        x="214.1"
        y="110.8"
        width="4.1"
        height="2.7"
      />
      <rect
        id="Rectangle_6393_00000039126488105458455660000000676636225229346185_"
        x="220.7"
        y="110.8"
        width="27.8"
        height="2.7"
      />
      <rect
        id="Rectangle_6394_00000134219796611294368490000014640114963166571448_"
        x="236"
        y="118.8"
        width="12.5"
        height="2.7"
      />
      <rect
        id="Rectangle_6395_00000053503383761006111100000014739524779851453071_"
        x="236"
        y="78.2"
        width="12.5"
        height="2.7"
      />
      <rect
        id="Rectangle_6396_00000033330407307257063660000011329783548936745654_"
        x="214.1"
        y="84.1"
        width="12"
        height="2.7"
      />
      <rect
        id="Rectangle_6397_00000032639008117361833040000013703129120908076200_"
        x="214.1"
        y="78.2"
        width="12"
        height="2.7"
      />
    </g>
  </Icon>
);

export const Reminders = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"8 8 66 66"}
    onClick={props.onClick}
    label="reminders"
  >
    <g
      id="Group_9437_00000125568682383461743410000005021449288897584768_"
      transform="translate(-296.473 -64.632)"
    >
      <rect
        id="Rectangle_6398_00000011741915846767313730000016967665264327608743_"
        x="313.4"
        y="95.3"
        width="8.6"
        height="8.6"
      />
      <rect
        id="Rectangle_6399_00000137120131814942998990000010771609215988352702_"
        x="327.1"
        y="95.3"
        width="8.6"
        height="8.6"
      />
      <rect
        id="Rectangle_6400_00000093888957108021967060000009863076878356964773_"
        x="340.9"
        y="95.3"
        width="8.6"
        height="8.6"
      />
      <rect
        id="Rectangle_6401_00000001654903242723935320000003661062683650908058_"
        x="313.4"
        y="108.8"
        width="8.6"
        height="8.6"
      />
      <rect
        id="Rectangle_6402_00000012457466933222944940000008155529243415605139_"
        x="327.1"
        y="108.8"
        width="8.6"
        height="8.6"
      />
      <path
        id="Path_1149_00000124878698830261927620000000488898284208521628_"
        d="M308.1,89.2h47v15.5h3.3V79.8h-6v-4.1
		c0.1-1.5-1.1-2.8-2.5-2.9c-1.5-0.1-2.8,1.1-2.9,2.5c0,0.1,0,0.2,0,0.3v4.1h-12.7v-4.1c0.1-1.5-1.1-2.8-2.5-2.9
		c-1.5-0.1-2.8,1.1-2.9,2.5c0,0.1,0,0.2,0,0.3v4.1h-12.7v-4.1c0.1-1.5-1-2.8-2.5-2.9s-2.8,1-2.9,2.5c0,0.1,0,0.2,0,0.3v4.1h-5.9
		v47.8h30.9v-3.3h-27.6L308.1,89.2z"
      />
      <path
        id="Path_1150_00000098220607499042635390000002344749654460501695_"
        d="M353.7,107.4L353.7,107.4c-8,0-14.5,6.5-14.5,14.5
		s6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5C368.2,113.9,361.7,107.4,353.7,107.4C353.7,107.4,353.7,107.4,353.7,107.4L353.7,107.4
		z M353.7,133.4L353.7,133.4c-6.3,0-11.5-5.1-11.5-11.5s5.1-11.5,11.5-11.5c6.3,0,11.5,5.1,11.5,11.5
		C365.1,128.2,360,133.4,353.7,133.4C353.7,133.4,353.7,133.4,353.7,133.4L353.7,133.4z"
      />
      <path
        id="Path_1151_00000179633669836573487240000000816102818924339844_"
        d="M354.6,113.3h-3.1v11.5h8.9v-3.1h-5.8V113.3z"
      />
    </g>
  </Icon>
);
