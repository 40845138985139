import * as React from "react";
import CommonWrapper from "../../components/CommonWrapper";
import {
  ContentPanel,
  MessageLine,
  StyledButton,
  ButtonContainer
} from "./styled";
import { useHistoryPush } from "../../hooks/useHistoryPush";

const NotFoundPage: React.FC = () => {
  const redirect = useHistoryPush();
  return (
    <>
      <CommonWrapper>
        <ContentPanel>
          <MessageLine>404</MessageLine>
          <MessageLine>We couldn’t find this page</MessageLine>
          <ButtonContainer>
            <StyledButton
              loading={false}
              hasIcon={true}
              handleClick={() => redirect(`/`)}
            >
              Go Home
            </StyledButton>
          </ButtonContainer>
        </ContentPanel>
      </CommonWrapper>
    </>
  );
};

export default NotFoundPage;
