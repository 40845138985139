import { formatShortDate } from "@billfire/toybox";
import * as React from "react";
import Address from "../Address";
import CheckNumber from "../CheckNumber/CheckNumber";
import Print from "../Print";
import InvoicesList from "../InvoicesList";
import {
  PrintContainer,
  PrintedOn,
  PrintTitle,
  VendorTitleContainer,
  FixedHeightContainer,
  RemitAndPrintContainer
} from "./styled";
import Totals from "../InvoiceTotals/Totals";
import BlankTotals from "../BlankTotals/Totals";
import { getPrintClassName } from "../utils";
import { useRemitForm } from "..";
import { isRemitInvoice } from "../types";
import FormComments from "../FormComments";
import VendorTitle from "../../VendorTitle";
import FoldLine from "../FoldLine";

interface PrintViewProps {
  printRef: React.RefObject<HTMLDivElement>;
}

const PrintView: React.FC<PrintViewProps> = ({ printRef }) => {
  const remitForm = useRemitForm();

  const printClassName = getPrintClassName(remitForm.sortedInvoices);

  const hasInvoices = remitForm.sortedInvoices.some(inv => isRemitInvoice(inv));

  const UseTotals = hasInvoices ? Totals : BlankTotals;

  return (
    <PrintContainer ref={printRef} className={printClassName}>
      <FixedHeightContainer>
        <VendorTitleContainer>
          <VendorTitle hidePanel={true} logoPadding="0px" />
        </VendorTitleContainer>
        <RemitAndPrintContainer>
          <Address />
          <PrintTitle>REMIT FORM</PrintTitle>
        </RemitAndPrintContainer>
        <PrintedOn>
          Printed on: {formatShortDate(new Date(Date.now()))}
        </PrintedOn>
        <CheckNumber />
        <Print printRef={printRef} hideButton={true} />
        <Address remit />
      </FixedHeightContainer>
      <FoldLine />
      <InvoicesList printing={true} />
      <UseTotals sortedInvoices={remitForm.sortedInvoices} printing={true} />
      <FormComments />
    </PrintContainer>
  );
};

export default PrintView;
