import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useDeepLinker } from "../../hooks/useDeepLinker";

const RequestDeepLink: React.FC = () => {
  const { redirect, redirectReady } = useDeepLinker();

  React.useEffect(() => {
    if (redirectReady) {
      redirect();
    }
  }, [redirectReady, redirect]);

  return <SkeletonLoader />;
};

export default RequestDeepLink;
