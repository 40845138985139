import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getSortedAds } from "../../../state/entities/ads/selectors";
import { Ad, getEmptyAd } from "../../../state/entities/ads/types";
import { AdTickParams } from "./types";
import { calculateAdFlags, sendAdTick, sendImpressions } from "./utils";
import { useParamHook } from "../../../hooks/useParams";

export interface AdSlide extends Ad {
  props: {
    duration: number;
    offScreen: boolean;
  };
}

interface UseAdsReturn {
  ad1: AdSlide;
  ad2: AdSlide;
  animate: boolean;
  displayAds: boolean;
  adClick: (ad: Ad) => boolean;
}

const setupAds = (currentAd: Ad, nextAd: Ad, onAd1: boolean) => {
  const currentProps = {
    duration: 0,
    offScreen: false
  };

  const nextProps = {
    duration: currentAd.duration,
    offScreen: true
  };

  let ad1, ad2;

  if (onAd1) {
    ad1 = { ...currentAd, props: currentProps };
    ad2 = { ...nextAd, props: nextProps };
  } else {
    ad2 = { ...currentAd, props: currentProps };
    ad1 = { ...nextAd, props: nextProps };
  }

  return { ad1, ad2 };
};

interface AdTrackState {
  currentAdIndex: number;
  onAd1: boolean;
  currentAdStart: number;
}

const useAdsState = () => {
  const ads = useSelector(getSortedAds);

  const [adTrack, setAdTrack] = useState<AdTrackState>({
    currentAdIndex: 0,
    onAd1: true,
    currentAdStart: Date.now()
  });
  const { currentAdIndex, onAd1 } = adTrack;

  // eslint-disable-next-line security/detect-object-injection
  const currentAd = ads.length > 0 ? ads[currentAdIndex] : getEmptyAd();
  const nextAd =
    ads.length > 1 ? ads[(currentAdIndex + 1) % ads.length] : getEmptyAd();

  return { ads, currentAd, nextAd, onAd1, setAdTrack, adTrack };
};

const useAdTicks = (ads: Ad[]) => {
  const [viewsSent, setViewsSent] = useState<boolean>(false);
  const adParams = useParams<AdTickParams>();

  useEffect(() => {
    if (!viewsSent && ads && ads.length && adParams.locationId) {
      setViewsSent(true);
      sendImpressions(ads, adParams);
    }
  }, [adParams, ads, viewsSent]);

  const adClick = (ad: Ad) => {
    sendAdTick(ad, "click", adParams);
    return true;
  };

  return { adClick };
};

export const useAds = (): UseAdsReturn => {
  const { ads, currentAd, nextAd, onAd1, setAdTrack, adTrack } = useAdsState();
  const { adClick } = useAdTicks(ads);

  const adFlags = calculateAdFlags(currentAd, ads);

  useEffect(() => {
    const interval = setInterval(() => {
      const targetTime = adTrack.currentAdStart + currentAd.duration * 1000;

      if (adFlags.animate && targetTime < Date.now()) {
        setAdTrack((state: AdTrackState) => ({
          currentAdIndex: (state.currentAdIndex + 1) % ads.length,
          onAd1: !state.onAd1,
          currentAdStart: Date.now()
        }));
      }
    }, 500);

    return () => clearInterval(interval);
  });

  return { ...setupAds(currentAd, nextAd, onAd1), ...adFlags, adClick };
};

export const useHandleClickUrl = (ad: AdSlide) => {
  const { token, vendorId, locationId } = useParamHook();
  const { clickUrl } = ad;
  const unlinkedImage = clickUrl === undefined;
  const isInternalLink = !(
    clickUrl?.includes("http") || clickUrl === undefined
  );
  const internalLinkPath = clickUrl === "dashboard" ? "" : `/${clickUrl}`;
  const formattedInternalUrl = `/token/${token}/vendors/${vendorId}/locations/${locationId}${internalLinkPath}`;

  return isInternalLink
    ? formattedInternalUrl
    : unlinkedImage
    ? undefined
    : clickUrl;
};
