import * as React from "react";
import { columns, PaymentPlanRow } from "./utils";
import { StyledDataTable } from "./styled";
import { useParams } from "react-router-dom";
import { TableTitle } from "./styled";
import {
  SortBys,
  SortBy,
  Status,
  SetSortBys,
  SetSortDirection,
  SetSortBy
} from "./types";
export interface PaymentPlanTableProps {
  title: string;
  paymentPlans: PaymentPlanRow[];
  status: Status;
  sortBys: SortBys;
  setSortBys: SetSortBys;
}

const PaymentPlanTable: React.FC<PaymentPlanTableProps> = ({
  title,
  paymentPlans,
  status,
  sortBys,
  setSortBys
}) => {
  const { token, vendorId, locationId } = useParams<{
    token: string;
    vendorId: string;
    locationId: string;
  }>();

  // eslint-disable-next-line security/detect-object-injection
  const thisSortBy: SortBy = sortBys[status];

  const setSortDirection: SetSortDirection = sortDirection => {
    setSortBys({ ...sortBys, [status]: { ...thisSortBy, sortDirection } });
  };

  const setSortBy: SetSortBy = sortBy => {
    setSortBys({ ...sortBys, [status]: { ...thisSortBy, sortBy } });
  };

  return paymentPlans.length ? (
    <>
      <TableTitle>{title}</TableTitle>
      <StyledDataTable
        sortable
        initialData={paymentPlans}
        columns={columns(token, vendorId, locationId)}
        externalSortBy={thisSortBy.sortBy}
        externalSetSortBy={setSortBy}
        externalSortDirection={thisSortBy.sortDirection}
        setExternalSortDirection={setSortDirection}
      />
    </>
  ) : null;
};

export default PaymentPlanTable;
