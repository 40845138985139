import { CustomRowTable } from "@billfire/toybox";
import Pencil from "../../../../assets/pencil.svg";
import styled from "styled-components";

export const InvoiceTable = styled(CustomRowTable).attrs({
  "data-testid": "invoiceTable"
})<{ mobile: boolean }>`
  width: 100%;
  border-collapse: separate;
  tr > th:first-child {
    width: ${props => (props.mobile ? "10%" : "7%")};
    text-align: left;
  }
  tr.spaceUnder > td {
    padding-bottom: 10px;
    border-spacing: 5px;
  }
  th,
  td {
    padding: ${props => (props.mobile ? "0" : "0 5px")};
  }
`;

export interface CustomTableColumnProps {
  isPastDue: boolean;
}

export const CustomTableColumn = styled.div<CustomTableColumnProps>`
  color: ${props => (props.isPastDue ? "#cb000b" : "")};
`;

export const PencilDisplay = styled(Pencil).attrs({
  "data-testid": "pencil-svg"
})`
  cursor: pointer;
`;

export const EmptyMessage = styled.div`
  font-size: 15px;
  letter-spacing: -0.09px;
  text-align: center;
  color: #777;
  padding: 20px 0;
`;

export const DropDownContainer = styled.div<{ mobile: boolean }>`
  height: 25px;
  padding-top: 60px;
  width: fit-content;
  min-width: ${props => (props.mobile ? "100%" : "412px")};
  margin: 0 auto;
`;
