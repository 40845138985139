import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { ofType } from "redux-observable";
import {
  loadAutopay,
  loadAutopaySuccess,
  loadAutopayFailed,
  deleteAutopay,
  deleteAutopaySuccess,
  deleteAutopayFailed,
  updateActiveAutopayExcludedInvoices,
  excludeAutopayInvoices
} from "./slice";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import {
  ApiResponseAutoPay,
  getEmptyAutopay,
  isActiveAutopayType,
  ActiveAutopay
} from "./types";
import history from "../../../history";
import { isUserAction } from "../persisted/selectors";
import { createSaveAction } from "../persisted/utils";
import { saveAction } from "../persisted/slice";
import { ReplayType } from "../persisted/types";
import {
  getFieldOrDefault,
  getTypeFromProvider
} from "../paymentMethods/utils";

const mapAutopay = (autopay: ActiveAutopay): ActiveAutopay => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paymentMethodFields: any = {};

  if (autopay.paymentMethod) {
    paymentMethodFields.paymentMethod = {
      ...autopay.paymentMethod,
      type: getFieldOrDefault(
        autopay.paymentMethod?.type,
        getTypeFromProvider(autopay?.paymentMethod?.provider)
      )
    };
  }

  return {
    ...autopay,
    ...paymentMethodFields,
    paymentMethodLastFour: getFieldOrDefault(
      autopay.paymentMethodLastFour,
      autopay?.paymentMethod?.displayExtra?.lastFour
    ),
    paymentMethodName: getFieldOrDefault(
      autopay.paymentMethodName,
      autopay?.paymentMethod?.displayExtra?.name
    ),
    paymentMethodType: getFieldOrDefault(
      autopay.paymentMethodType,
      getTypeFromProvider(autopay?.paymentMethod?.provider)
    )
  };
};

export const fetchAutopayEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<unknown> =>
  action$.pipe(
    ofType(loadAutopay.type),
    switchMap(() => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/autopay`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp: ApiResponseAutoPay = api.handleAJAXResponse(
              response,
              token
            );
            const autopay = isActiveAutopayType(resp.autoPay)
              ? mapAutopay(resp.autoPay)
              : getEmptyAutopay();
            return loadAutopaySuccess(autopay);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadAutopayFailed());
          })
        );
    })
  );

export const deleteAutopayEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(deleteAutopay.type),
    switchMap(() => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      const autopayId = state$.value.autopay.activeAutopay.id;

      return ajax
        .delete(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/autopays/${autopayId}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            api.handleAJAXResponse(response, token);
            history.push("autopaydelete/confirmation", { deleted: true });
            return deleteAutopaySuccess();
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(deleteAutopayFailed());
          })
        );
    })
  );

export const persistAutopayInvoiceSelectionEpic = (
  action$: Observable<PayloadAction<string[]>>,
  state$: { value: StoreType }
) =>
  action$.pipe(
    ofType(
      excludeAutopayInvoices.type,
      updateActiveAutopayExcludedInvoices.type
    ),
    filter(() => isUserAction(state$.value)),
    map(action => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      return saveAction(
        createSaveAction(
          { ...action, replayType: ReplayType.autopay },
          selectedVendorId,
          selectedLocationId,
          token
        )
      );
    })
  );
