import * as types from "../../entities/campaigns/types";
import { StoreType } from "../../types";

export const selectCampaigns = (state: StoreType): Array<types.Campaign> =>
  Object.values(state.campaigns.byId);

export const selectDeliverTo = (state: StoreType): string =>
  state.campaigns.deliverTo;

export const selectCampaignContactId = (state: StoreType): string =>
  state.campaigns.contactId;

export const selectCampaignsLoadingFlags = (
  state: StoreType
): types.LoadingCampaignsFlags => {
  return {
    loadingCampaigns: state.campaigns.loadingCampaigns,
    loadedCampaigns: state.campaigns.loadedCampaigns,
    loadCampaignsFailed: state.campaigns.loadCampaignsFailed
  };
};
export const selectCampaignCreatingFlags = (
  state: StoreType
): types.CreatingCampaignFlags => {
  return {
    creatingCampaign: state.campaigns.creatingCampaign,
    createdCampaign: state.campaigns.createdCampaign,
    createCampaignFailed: state.campaigns.createCampaignFailed
  };
};

export const selectCampaignDeletingFlags = (
  state: StoreType
): types.DeletingCampaignFlags => {
  return {
    deletingCampaign: state.campaigns.deletingCampaign,
    deletedCampaign: state.campaigns.deletedCampaign,
    deleteCampaignFailed: state.campaigns.deleteCampaignFailed
  };
};
