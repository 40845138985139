import styled from "styled-components";

export const PrintContainer = styled.div`
  @media screen {
    display: none;
  }

  padding: 0px 20px 0px 13px;
  background-color: #fff;

  tr > td.select-box {
    display: none;
  }

  tr > th.select-box {
    display: none;
  }

  thead > tr > th {
    border-bottom: 1pt solid black;
    font-size: 9.5px;
    line-height: 1.47;

    padding-bottom: 11px;
  }

  th.spacer {
    border-bottom: none;
  }

  tr > td {
    font-size: 9.5px;
    font-weight: normal;
    line-height: 1.47;
  }

  tr.tr-selected > td {
    background-color: inherit;
  }

  td.spacer {
    border-bottom: none;
  }

  tr.comments {
    border-bottom: 1pt solid black;
  }

  tr.blank-row {
    border-bottom: none;
  }

  tr.blank-row > td {
    border-bottom: 1pt solid black;
    height: 40px;
  }

  tr.blank-row > td.spacer {
    border-bottom: none;
  }

  tr > td > input {
    background-color: inherit;
  }

  .filled-in-form tr > td > input {
    border: none;
  }

  tr.hidden-comments {
    display: table-row;
  }

  div.blank-label {
    display: none;
  }

  input.blank-payment-amount {
    border: none;
  }

  thead tr.no-invoices > th > div {
    margin-bottom: 0px;
  }

  tr.blank-row > td > div {
    font-size: 9.5px;
    font-weight: 300;
    line-height: 1.47;
    color: #4a4a4a;
    padding-top: 7px;
  }

  .empty-message {
    font-size: 19px;
    line-height: 1.56;
    padding: 20px 0px 35px 0px;
  }
`;

export const PrintTitle = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.79;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  padding-bottom: 10px;
  margin-top: 25px;
`;

export const VendorTitleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PrintedOn = styled.div`
  font-size: 9.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  text-align: right;
  color: #000;

  padding-bottom: 10px;
`;

export const FixedHeightContainer = styled.div`
  min-height: 350px;
  max-height: 350px;
`;

export const RemitAndPrintContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
