import * as React from "react";
import { useAutopaySelectors } from "../hooks";
import DetailRow from "../../DetailRow";
import { formatShortDate } from "@billfire/toybox";
import PaymentMethodDetailRow from "../../PaymentDetail/PaymentMethodDetailRow";

const Details: React.FC = () => {
  const { selectedPaymentDate, selectedPaymentMethod } = useAutopaySelectors();

  if (!selectedPaymentDate || !selectedPaymentMethod) {
    return null;
  }

  return (
    <>
      <PaymentMethodDetailRow
        name={selectedPaymentMethod.displayExtra.name}
        lastFour={selectedPaymentMethod.displayExtra.lastFour}
      />
      <DetailRow
        titleText="Start Date"
        detailText={formatShortDate(selectedPaymentDate)}
      />
    </>
  );
};

export default Details;
