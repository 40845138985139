import React from "react";
import {
  Modal,
  ModalSize,
  ModalType,
  Button,
  formatCurrency
} from "@billfire/toybox";
import { ButtonContainer, MessageContainer } from "../modals.styled";
import { invoiceTypes } from "../../state/entities/invoices";
export interface UndoDisputedInvoiceModalProps {
  onClose: () => void;
  onSubmit: () => void;
  invoice: invoiceTypes.DisputedInvoice;
}
const UndoDisputedInvoiceModal: React.FC<UndoDisputedInvoiceModalProps> = ({
  onClose,
  onSubmit,
  invoice
}) => {
  let title,
    topMessage,
    bottomMessage = "";

  if (invoice.newAmount > 0.0) {
    title = "Undo partial payment?";
    topMessage = `Do you want to undo the partial payment of invoice ${invoice.displayInvNum}?`;
    bottomMessage = `This will reset it to the original amount of ${formatCurrency(
      invoice.openAmount
    )} and undo the ${formatCurrency(invoice.newAmount)} partial payment.`;
  } else {
    title = "Undo dispute?";
    topMessage = `Do you want to undo the dispute of invoice ${invoice.displayInvNum}?`;
    bottomMessage = `This will reset it to the original amount of ${formatCurrency(
      invoice.openAmount
    )}.`;
  }

  return (
    <Modal
      title={title}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={onClose}
      loading={false}
      errored={false}
      hideActions={true}
    >
      <MessageContainer>{topMessage}</MessageContainer>
      <MessageContainer>{bottomMessage}</MessageContainer>
      <ButtonContainer>
        <Button
          color="secondary"
          loading={false}
          handleClick={() => {
            onClose();
          }}
          padding="10px 0px 0px 0px"
        >
          Keep
        </Button>
        <Button
          color="primary"
          loading={false}
          handleClick={() => {
            onSubmit();
          }}
          padding="11px 52.7px 10px 54.5px"
        >
          Undo
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
export default UndoDisputedInvoiceModal;
