import styled from "styled-components";
import { Button } from "@billfire/toybox";

export const PrintText = styled.div`
  margin: 0 0 0 7px;
  font-size: 14px;
  align-self: center;
  color: #4990e2;
`;

export const StyledPrintLink = styled(Button)`
  width: 134px;
  height: 45px;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;
