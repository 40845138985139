// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import {
  RowContainer,
  TimeContainer,
  RowOne,
  DetailContainer,
  RowTwo
} from "./styled";
import { DataTableJourneyRecord, isJourneyColumnName } from "./types";
import { sortDate, SortDirection, sortString } from "@billfire/toybox";
import { getSimpleTime, getShortTime } from "../../utils/index";
import api from "../../state/api";
import { Invoice } from "../../state/entities/invoices/types";

export const invoiceJourneyTableColumns = () => [
  {
    name: "updatedAt",
    label: "Date & Time",
    sortFn: sortDate,
    width: "95%",
    render: (_: unknown, journey: DataTableJourneyRecord) => (
      <RowContainer>
        <RowOne>
          <TimeContainer>
            <div>{getShortTime(journey.updatedAt)}</div>
            <div>{getSimpleTime(journey.updatedAt)}</div>
          </TimeContainer>
          <DetailContainer>{journey.action}</DetailContainer>
        </RowOne>
        <RowTwo>{journey.detail}</RowTwo>
      </RowContainer>
    )
  },
  {
    name: "action",
    label: "Action",
    width: "45px",
    sortFn: sortString,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render: (_: unknown, journey: DataTableJourneyRecord) => <></>
  }
];

export const createInvoiceComment = (
  token: string,
  vendorId: string,
  locationId: string,
  invoiceId: string,
  message: string
) => {
  return api.postWithToken(
    `/vendors/${vendorId}/locations/${locationId}/messages`,
    { invoiceId, message },
    token
  );
};

export const updateContactReadAt = (
  path: string,
  body: object,
  token: string
) => {
  return api.patchWithToken(path, body, token);
};

const headers = [
  { label: "Invoice", key: "displayInvNum" },
  { label: "Date & Time", key: "updatedAt" },
  { label: "Action", key: "action" },
  { label: "Details", key: "detail" }
];

export const mapAndSortInvoiceJourneyCSVData = (
  invoice: Invoice,
  journeys: DataTableJourneyRecord[],
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const columns = invoiceJourneyTableColumns();

  const foundColumn = columns.find(({ name }) => name === sortBy);
  const sortFn = foundColumn && foundColumn.sortFn;

  const csvData = journeys
    .sort(
      (a, b) =>
        sortFn &&
        isJourneyColumnName(sortBy) &&
        // eslint-disable-next-line security/detect-object-injection
        sortFn(a[sortBy], b[sortBy], sortDirection || "ASC")
    )
    .map(journey => ({
      displayInvNum: invoice.displayInvNum,
      updatedAt: `${getShortTime(journey.updatedAt)} ${getSimpleTime(
        journey.updatedAt
      )}`,
      action: journey.action,
      detail: journey.detail
    }));
  return { csvData, headers };
};
