import * as React from "react";
import { useSelector } from "react-redux";
import { useNavbarDisplay } from "../../hooks/navigation";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";
import { StyledPanel } from "./VendorTitle.styled";
import { useVendorTitleInfo } from "./hooks";

interface VendorTitleProps {
  hidePanel?: boolean;
  logoPadding?: string;
  small?: boolean;
}

const VendorTitle: React.FC<VendorTitleProps> = ({
  hidePanel = false,
  small = false,
  logoPadding
}) => {
  const { shouldDisplayNavbar, routeInfo } = useNavbarDisplay();
  const vendor = useSelector(getSelectedVendor);

  const VendorTitleInfo = useVendorTitleInfo(vendor, logoPadding, small);

  if (!vendor) return null;

  return hidePanel ? (
    <div data-testid="vendor-title-div">{VendorTitleInfo}</div>
  ) : (
    <StyledPanel
      data-testid="vendor-title-panel"
      noHeader={!shouldDisplayNavbar && !routeInfo.billfireLogo}
    >
      {VendorTitleInfo}
    </StyledPanel>
  );
};

export default VendorTitle;
