import styled from "styled-components";
import { Panel, Button } from "@billfire/toybox";
import { device } from "../../breakpoints";

export const ContentPanel = styled(Panel)`
  margin: auto;
  margin-top: 52px;
  padding: 30px 15px 30px 15px;
`;

export const MessageLine = styled.div`
  margin-bottom: 20px;
  opacity: 0.4;
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #272a43;

  @media only screen and (${device.tablet}) {
    font-size: 55px;
  }
`;

export const StyledButton = styled(Button)`
  width: 330px;
  height: 50px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: center;
`;
