import styles, { Accordion } from "@billfire/toybox";
import * as React from "react";
import { useTotal } from "../../../hooks/useTotal";
import InvoiceList from "./InvoiceList";
import MoreInvoices from "./MoreInvoices";
import InvoiceTotalsAndFees from "../../InvoiceTotalsAndFees";

const {
  colors: { baseGray7 }
} = styles;

const Amount: React.FC = () => {
  const [total] = useTotal();

  return (
    <Accordion
      title="Amount"
      fused
      startingContentHeight={"1px"}
      secondaryTitle={
        <span
          style={{ fontSize: "15px", color: baseGray7 }}
          data-testid={"c2p-total"}
        >
          {total}
        </span>
      }
      fontSize="15px"
      contentPadding="0px"
    >
      <InvoiceList />
      <MoreInvoices />
      <InvoiceTotalsAndFees />
    </Accordion>
  );
};

export default Amount;
