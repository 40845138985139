import { Invoice, getEmptyInvoice } from "../../state/entities/invoices/types";

export interface InvoiceJourneyApiResponse {
  journeys: Array<JourneyRecord>;
  invoice: Invoice;
}

export interface JourneyRecord {
  action: string;
  detail: string;
  id: string;
  updatedAt: string;
}

export interface DataTableJourneyRecord extends JourneyRecord {
  rowId: string;
}

export interface UpdatedInvoiceJourney {
  invoice: Invoice;
  journeys: Array<DataTableJourneyRecord>;
}

export const getEmptyUpdatedInvoiceJourney = () => ({
  invoice: getEmptyInvoice(),
  journeys: [{ rowId: "", updatedAt: "", action: "", detail: "", id: "" }]
});

enum JourneyColumnName {
  updatedAt = "updatedAt",
  action = "action"
}

export const isJourneyColumnName = (
  colName?: string | null
): colName is JourneyColumnName => {
  return Object.values(JourneyColumnName).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    colName as unknown as JourneyColumnName
  );
};
