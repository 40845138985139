import {
  StyledButton,
  StyledButtonLink,
  StyledH3,
  StyledP,
  StyledPanel
} from "./styled";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

const ReferralCTA = () => {
  return (
    <StyledPanel padding="20px 13px" margin={"11px 0 0 0 "}>
      <StyledH3>Wish you could pay all your vendors with VALET?</StyledH3>
      <StyledP>
        Let us know whom to contact and we'll get in touch with them.
      </StyledP>
      <StyledButtonLink
        to={{ pathname: "https://billfire.com/referrals" }}
        target="_blank"
      >
        <StyledButton children={"Refer Us"} loading={false}></StyledButton>
      </StyledButtonLink>
    </StyledPanel>
  );
};

export default ReferralCTA;
