// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { StyledStatementsModalTitleContainer } from "./styled";

export const TurnOffStatementsModalTitle = () => {
  return (
    <StyledStatementsModalTitleContainer>
      Turn Off Statements
    </StyledStatementsModalTitleContainer>
  );
};

export default TurnOffStatementsModalTitle;
