import { formatCurrency, formatShortDate } from "@billfire/toybox";
import { DateTime } from "luxon";
import Api from "../../../state/api";
import { InvoiceForSelected, InvoiceForSelectedDataRow } from "../types";
import { PaymentPayload, PaymentDetailsParams } from "./useFetchPaymentDetails";
export const dataTableColumns = [
  {
    name: "displayInvNum",
    label: "Invoice",
    width: "400px"
  },
  {
    name: "paymentDate",
    label: "Scheduled",
    width: "400px"
  },
  {
    name: "paidAmount",
    label: "Amount",
    width: "10px"
  }
];

export const fetchPaymentDetails = async (
  token: string,
  paymentMethodId: string | undefined,
  vendorId: string | null,
  locationId: string | null
) => {
  const res = await Api.getWithToken(
    `/vendors/${vendorId}/locations/${locationId}/payments?paymentMethodId=${paymentMethodId}`,
    token
  );

  return res;
};

export const setErrorMessage = (
  deleteFailed: boolean,
  selectedPaymentMethod: { name: string; lastFour: string }
) => {
  if (deleteFailed)
    return `Could not delete ${selectedPaymentMethod.name} ${selectedPaymentMethod.lastFour}.`;
  return "";
};

export const setModalTitle = (
  hasPaymentPlan: boolean,
  hasAutoPay: boolean,
  selectedPaymentMethod: { name: string; lastFour: string }
) => {
  if (hasPaymentPlan || hasAutoPay) {
    return `Could not delete ${selectedPaymentMethod.name} ${selectedPaymentMethod.lastFour}.`;
  }
  return `Delete ${selectedPaymentMethod.name} ${selectedPaymentMethod.lastFour}?`;
};

export const sortByPaymentDate = (
  a: InvoiceForSelected,
  b: InvoiceForSelected
) => {
  const aDate = DateTime.fromISO(a.paymentDate);
  const bDate = DateTime.fromISO(b.paymentDate);

  if (aDate < bDate) return -1;
  else if (aDate > bDate) return 1;
  return 0;
};

export const formatInvoices = (
  invoices: InvoiceForSelected[]
): InvoiceForSelectedDataRow[] => {
  return invoices.map(invoice => ({
    ...invoice,
    rowId: invoice.id,
    paymentDate: formatShortDate(invoice.paymentDate),
    paidAmount: formatCurrency(invoice.paidAmount)
  }));
};

export const setStateFromPayload = (
  payload: PaymentPayload,
  setInvoicesForSelected: (val: InvoiceForSelectedDataRow[]) => void,
  setHasPaymentPlan: (val: boolean) => void,
  setHasAutopay: (val: boolean) => void,
  setCheckPaymentsLoading: (val: boolean) => void,
  setPaymentsLoaded: (val: boolean) => void
) => {
  const sortedPayments = payload.payments.sort(sortByPaymentDate);
  setInvoicesForSelected(formatInvoices(sortedPayments));
  setHasPaymentPlan(payload.hasPaymentPlan);
  setHasAutopay(payload.hasAutopay);
  setCheckPaymentsLoading(false);
  setPaymentsLoaded(true);
};

export const getPaymentMethodPaymentDetails = async (
  paymentDetailsParams: PaymentDetailsParams,
  setInvoicesForSelected: (val: InvoiceForSelectedDataRow[]) => void,
  setHasPaymentPlan: (val: boolean) => void,
  setHasAutopay: (val: boolean) => void,
  setCheckPaymentsLoading: (val: boolean) => void,
  setPaymentsLoaded: (val: boolean) => void
) => {
  const params = paymentDetailsParams;
  params.selectedPaymentMethod !== undefined
    ? await fetchPaymentDetails(
        params.authToken,
        params.selectedPaymentMethod.id,
        params.selectedVendorId,
        params.selectedLocationId
      )
        .then((payload: PaymentPayload) => {
          setStateFromPayload(
            payload,
            setInvoicesForSelected,
            setHasPaymentPlan,
            setHasAutopay,
            setCheckPaymentsLoading,
            setPaymentsLoaded
          );
        })
        .catch(() => {
          setCheckPaymentsLoading(false);
          setPaymentsLoaded(true);
        })
    : null;
};
