import styled from "styled-components";

export const Container = styled.div`
  background-color: #f9f9f9;
  max-width: 800px;
  margin: auto;
  margin-top: 10px;
`;

export interface HeaderProps {
  clickable: boolean;
}

export const Header = styled.div.attrs({
  "data-testid": "common-wrapper-header"
})<HeaderProps>`
  background-color: #62656a;
  display: flex;
  align-items: center;
  padding: 13px 0px;
  width: 100%;
  cursor: ${props => (props.clickable ? "pointer" : "default")};
`;

export const PageContent = styled.div`
  margin: 0 10px;
`;

export const LogoContainer = styled.div`
  margin: auto;
`;

export const FooterContainer = styled.div`
  text-align: center;
  margin: 25px;
`;
export const FooterText = styled.div.attrs({ "data-testid": "footer" })`
  font-size: 14px;
  line-height: 1.14;
  color: #a4a4a4;
  margin-bottom: 5px;
`;

export const StyledSup = styled.sup`
  font-size: 65%;
`;
