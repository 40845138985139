import styled from "styled-components";
import { device } from "../../breakpoints";

export interface StyledPanelProps {
  bordered?: boolean;
}

export const StyledHeader = styled.div`
  margin: auto;
  padding: 15px 12.3px 0px 12px;
  font-size: 31px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #272a43;
  @media only screen and (${device.tablet}) {
    font-size: 27px;
    padding: 15px 0 0px 0px;
  }
`;

export const StyledText = styled.div`
  padding: 5px;
  margin: auto;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #272a43;
`;

export const StyledAddress = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: rgba(100, 102, 120, 0.76);
`;

export const StyledPanel = styled.div<StyledPanelProps>`
  padding: 0px 0px 25px 0px;
  width: 100%;
  background-color: white;
  border: ${props => (props.bordered ? "solid 1px #eaf0f7" : "none")};
  border-radius: ${props => (props.bordered ? "4px" : "0px")};
  margin-bottom: ${props => (props.bordered ? "15px" : "0px")};
`;
