import { CampaignsState } from "./entities/campaigns/types";
import { SharedState } from "./entities/shared/types";
import { RequestState } from "./entities/requests/types";
import { VendorsState } from "./entities/vendors/types";
import { InvoicesState } from "./entities/invoices/types";
import { PaymentMethodsState } from "./entities/paymentMethods/types";
import { LocationsState } from "./entities/locations/types";
import { PaymentGroupsState } from "./entities/paymentGroups/types";
import { AutopayState } from "./entities/autopay/types";
import { PaymentPlanState } from "./entities/paymentPlans/types";
import { PersistedState } from "./entities/persisted/types";
import { MessageState } from "./entities/messages/types";
import { StatementDeliveryState } from "./entities/statementDelivery/types";
import { CombinedState } from "redux";
import { AdsState } from "./entities/ads/types";

export type StoreType = CombinedState<{
  shared: SharedState;
  requests: RequestState;
  vendors: VendorsState;
  invoices: InvoicesState;
  paymentMethods: PaymentMethodsState;
  locations: LocationsState;
  paymentGroups: PaymentGroupsState;
  autopay: AutopayState;
  paymentPlans: PaymentPlanState;
  messages: MessageState;
  statementDelivery: StatementDeliveryState;
  campaigns: CampaignsState;
  persisted: PersistedState;
  ads: AdsState;
}>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Selector<T> = (state: StoreType, ...args: any[]) => T;

export enum TokenType {
  ContactAccess = "ContactAccess",
  PaymentMethod = "PMAuth"
}
