import * as React from "react";
import { useCustomerManageAutopay } from "../hooks";
import { StyledHR, RedButton } from "../styled";
import { useDeleteAutopayModal } from "./hooks";

const TurnOffButton: React.FC = () => {
  const customerManageAutopay = useCustomerManageAutopay();

  const openModal = useDeleteAutopayModal();

  if (!customerManageAutopay) {
    return null;
  }

  return (
    <>
      <StyledHR />
      <RedButton loading={false} handleClick={openModal}>
        Turn Off AutoPay Plus
      </RedButton>
    </>
  );
};

export default TurnOffButton;
