import { Frequency } from "../../state/entities/shared/types";

export const isFrequency = (freq: string): freq is Frequency => {
  return Object.values(Frequency).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    freq as unknown as Frequency
  );
};

export interface StatementScheduleProps {
  scheduleUnit: Frequency;
  scheduleValue: string;
  sendEmptyStatements: boolean;
}
