import { Invoice } from "../../state/entities/invoices/types";

export const printViewTruncate = (string: string) => {
  if (string.length > 9) {
    const slice = string.slice(string.length - 10);
    return `...${slice}`;
  } else {
    return string;
  }
};

export const createPath = (
  token: string,
  vendorId: string,
  locationId: string,
  invoiceId: string
) =>
  `/token/${token}/vendors/${vendorId}/locations/${locationId}/invoices/${invoiceId}/pdfloader`;

export const decideInvoice = (
  invoice: Invoice | undefined,
  storeInvoice: Invoice
) => (invoice ? invoice : storeInvoice);

export const openPdf = (
  token: string,
  vendorId: string,
  locationId: string,
  invoiceId: string
) => {
  const path = createPath(token, vendorId, locationId, invoiceId);
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(path, "_blank", "noopener,noreferrer");
};
