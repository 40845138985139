import * as React from "react";
import { usePaymentMethodTitle } from "./hooks";
import {
  PaymentMethodBodyContainer,
  PaymentMethodText,
  PaymentMethodTitleContainer,
  StyledToolTip
} from "./styled.components";
import { formatPaymentMethod, visibility, paymentMethodTitle } from "./utils";
import { DisplayedPaymentMethod } from "../../../state/entities/paymentMethods/types";
import PendingVerification from "./PendingVerification";
import PaymentMethodIcon from "./PaymentMethodIcon";
export interface PaymentMethodTitleProps {
  onlyUseSelectedPmFromRedux?: boolean;
  paymentMethodOverride?: DisplayedPaymentMethod;
  styleFont?: boolean;
}
const PaymentMethodTitle: React.FC<PaymentMethodTitleProps> = ({
  onlyUseSelectedPmFromRedux = false,
  styleFont = true,
  paymentMethodOverride
}) => {
  const { paymentMethods, selectedPaymentMethod, verificationRequired } =
    usePaymentMethodTitle(onlyUseSelectedPmFromRedux);

  const title = paymentMethodTitle(
    selectedPaymentMethod,
    onlyUseSelectedPmFromRedux,
    paymentMethodOverride,
    paymentMethods
  );

  if (paymentMethods.length === 0 && !title) {
    return <>Add New Method</>;
  }

  return title ? (
    <PaymentMethodTitleContainer>
      <StyledToolTip
        tailDirection="right"
        message={`${title.displayExtra.name} ${title.displayExtra.lastFour}`}
        visibility={visibility(title)}
      >
        <PaymentMethodBodyContainer>
          {verificationRequired && <PendingVerification />}
          <PaymentMethodIcon paymentMethod={title} />
          <PaymentMethodText
            styleFont={styleFont}
            verificationRequired={!!verificationRequired}
          >
            {formatPaymentMethod(title)}
          </PaymentMethodText>
        </PaymentMethodBodyContainer>
      </StyledToolTip>
    </PaymentMethodTitleContainer>
  ) : null;
};

export default PaymentMethodTitle;
