import { useSelector } from "react-redux";
import { invoiceSelectors, invoiceUtils } from "../state/entities/invoices";
import { paymentMethodSelectors } from "../state/entities/paymentMethods";
import { StoreType } from "../state/types";

import { formatCurrency } from "@billfire/toybox";
import currency from "currency.js";
import { getDisputedInvoicesById } from "../state/entities/invoices/selectors";
import { PaymentMethod } from "../state/entities/paymentMethods/types";

export const calculateServiceFee = (
  serviceFeePercent: string,
  subtotal: number
): currency => {
  const convertedFee = parseFloat(serviceFeePercent.toString()) / 100.0;
  return currency(subtotal).multiply(convertedFee);
};

export const useTotal = (): string[] => {
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    paymentMethodSelectors.getSelectedPaymentMethod(state)
  );

  const invoiceTotal = useSelector((state: StoreType) =>
    invoiceUtils.getInvoiceTotal(
      invoiceSelectors.getSelectedInvoices(state),
      getDisputedInvoicesById(state)
    )
  );

  const serviceFee = selectedPaymentMethod?.displayExtra.serviceFee;
  if (serviceFee) {
    const fee = calculateServiceFee(serviceFee, invoiceTotal);
    const total = fee.add(invoiceTotal);
    return [
      formatCurrency(invoiceTotal),
      formatCurrency(fee.value),
      formatCurrency(total.value),
      serviceFee
    ];
  } else {
    return [formatCurrency(invoiceTotal)];
  }
};

export const useFee = (paymentMethod: PaymentMethod | undefined): string[] => {
  const selectedPM = useSelector((state: StoreType) =>
    paymentMethodSelectors.getSelectedPaymentMethod(state)
  );
  const paymentMethodToUse = paymentMethod ? paymentMethod : selectedPM;

  const serviceFee = paymentMethodToUse?.displayExtra.serviceFee;
  return serviceFee ? [serviceFee] : [""];
};
