import * as React from "react";
import { DisplayContainer, GroupPanel } from "./styled";
import ResolutionCenterPanel from "./ResolutionCenterPanel";
import Click2PayPanel from "./Click2PayPanel";
import AutoPayPanel from "./AutoPayPanel";
import GroupHeader from "./GroupHeader";
import StatementPanel from "./StatementPanel";
import RemitPanel from "./RemitPanel";
import RemindersPanel from "./RemindersPanel";

const ToolsPanels: React.FC = () => {
  return (
    <DisplayContainer>
      <GroupHeader title="TOOLS" color="#ccba8f" />
      <GroupPanel>
        <Click2PayPanel />
        <AutoPayPanel />
        <ResolutionCenterPanel />
        <RemitPanel />
        <RemindersPanel />
        <StatementPanel />
      </GroupPanel>
    </DisplayContainer>
  );
};

export default ToolsPanels;
