import * as React from "react";
import { useSelector } from "react-redux";
import PaymentsReport from "../../components/PaymentsReport";
import { getPaymentGroupsLoading } from "../../state/entities/paymentGroups/selectors";
import { StoreType } from "../../state/types";
import { usePaymentGroupsLoader } from "../../hooks/usePaymentGroupsLoader";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";

const PaymentsReportPage: React.FC = () => {
  usePaymentGroupsLoader();
  const paymentGroupsLoading = useSelector((state: StoreType) =>
    getPaymentGroupsLoading(state)
  );

  const { locationsLoading } = useLocationsLoader();
  const { loadingVendors } = useVendorsLoader();

  return paymentGroupsLoading || locationsLoading || loadingVendors ? (
    <SkeletonLoader />
  ) : (
    <PaymentsReport />
  );
};

export default PaymentsReportPage;
