import styled from "styled-components";
import styles, { Button, CustomRowTable, DataTable } from "@billfire/toybox";
import { device } from "../../breakpoints";

const { colors } = styles;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  margin: 0 auto;
  width: 332px;
  @media only screen and (${device.phone}) {
    width: 100%;
  }
`;

export const StyledHR = styled.hr`
  background: #e4e4e4;
  border: none;
  height: 1.8px;
  opacity: 0.3;
  margin: 0;
  width: 100%;
`;

export const RedButton = styled(Button)`
  background: none;
  color: ${colors.red};
  display: block;
  font-weight: 300;
  margin: 20px auto;
  padding: 0;
  text-decoration: underline;
  width: 332px;
  @media only screen and (${device.phone}) {
    width: 100%;
  }
  &:hover {
    background: none;
    color: ${colors.red};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top: solid 1px #eaf0f7;
  padding: 20px 0px 20px 0px;
  justify-content: center;

  @media only screen and (${device.tablet}) {
    padding: 20px 0px 20px 0px;
  }
`;

export const AutopayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 0px 10px 0px;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTable = styled(CustomRowTable)`
  width: 100%;
  border-collapse: separate;
  tr > th:first-child {
    width: 50px;
    text-align: left;
  }
  tr.spaceUnder > td {
    padding-bottom: 10px;
    border-spacing: 5px;
  }
  tr > td:nth-child(3) > div {
    /* Standard CSS ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-width: 90%;
  }

  @media only screen and (max-width: 600px) {
    td:nth-child(2) {
      padding-left: 20px;
    }
  }

  @media only screen and (max-width: 320px) {
    td {
      font-size: 12px;
    }

    td:nth-child(2) {
      padding-left: 2px;
    }
  }
`;

export const StyledReadonlyTable = styled(DataTable)`
  width: 100%;
  margin-left: 53px;
  margin-bottom: 12px;

  thead > tr > th {
    padding-bottom: 24px;
  }

  @media only screen and (${device.phone}) {
    margin-left: 0px;
  }

  th {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.09px;
    text-align: left;
    color: #777;
  }

  td {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.09px;
    text-align: left;
    color: #4a4a4a;
    padding-bottom: 12px;
  }
`;

export const NoInvoicesMessage = styled.div`
  text-align: center;
  font-size: 15px;
  padding: 10px 0 5px 0;
`;

export const ButtonHeaderText = styled.div`
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  text-align: center;
  @media only screen and (${device.phone}) {
    text-align: left;
  }
`;

export const ErrorMessageContainer = styled.div`
  text-align: center;
  font-size: 15px;
  padding: 5px 0;
  color: red;
  height: 5px;
`;

export const ConfirmationDetailsContainer = styled.div`
  width: 70%;
  margin: 25px auto 30px auto;

  @media only screen and (${device.tablet}) {
    width: 95%;
  }
`;

export const ErrorMessageText = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: center;
  color: #d0021b;
`;

export const ConfirmHeaderText = styled.div`
  font-size: 24px;
  padding: 25px 0px;
`;

export const ConfirmHeader = styled.div`
  text-align: center;

  h2 {
    margin-top: 20px;
    margin-bottom: 50px;
    color: rgb(80, 80, 80);
    font-weight: 400;
  }
`;

export const StyledConfirmTable = styled(DataTable)`
  width: 100%;
  margin-top: 15px;
  th {
    color: ${colors.baseGray7};
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
  }
  td {
    color: ${colors.baseGray3};
    padding: 15px;
    font-size: 14px;
  }

  thead > tr > th:nth-child(2) {
    text-align: center;
  }

  thead > tr > th:nth-child(3) {
    text-align: right;
  }

  tr > td:nth-child(1) > div {
    /* Standard CSS ellipsis */
    white-space: nowrap;

    text-align: left;
    max-width: 90%;
  }

  tr > td:nth-child(2) > div {
    text-align: center;
  }

  tr > td:nth-child(3) > div {
    text-align: right;
  }
`;

export const DesktopBreak = styled.br`
  @media only screen and (${device.tablet}) {
    display: none;
  }
`;

export const SetupStyledButton = styled(Button)`
  width: 100%;
  max-width: 330px;
  margin: auto;

  @media only screen and (${device.tablet}) {
    max-width: inherit;
  }
`;

export const StyledCaption = styled.caption`
  color: ${colors.baseGray7};
`;
