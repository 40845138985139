import { Campaign } from "./types";

export const withoutDeleted = (
  byId: Record<string, Campaign>,
  deletedId: string
): Record<string, Campaign> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [deletedId]: _deleted, ...rest } = byId;
  return rest;
};
