import * as React from "react";
import { Checkbox } from "@billfire/toybox";
import { TermsContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { loadEpaSubmit } from "../../state/entities/shared/slice";
import { getEpa, getEpaLoading } from "../../state/entities/shared/selectors";
import { StoreType } from "../../state/types";
import { Skeleton } from "@billfire/toybox";
import * as sharedTypes from "@billfire/va-types";

export interface TermsAndConditionsProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  provider: sharedTypes.PaymentProvider;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  checked,
  setChecked,
  provider
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadEpaSubmit());
  }, [dispatch]);

  const { version: epaVersion, filename: epaFilename } = useSelector(
    (state: StoreType) => getEpa(state, provider)
  );

  const [previousEpa, setPreviousEpa] = React.useState(epaVersion);

  React.useEffect(() => {
    if (epaVersion !== previousEpa) {
      if (checked) {
        setChecked(false);
      }

      setPreviousEpa(epaVersion);
    }
  }, [checked, epaVersion, setChecked, previousEpa, setPreviousEpa]);

  const epaLoading = useSelector((state: StoreType) => getEpaLoading(state));

  return (
    <TermsContainer>
      {epaLoading ? (
        <Skeleton height="25px" width="250px" />
      ) : (
        <>
          <Checkbox
            onChange={() => {
              setChecked(!checked);
            }}
            checked={checked}
            data-testid="terms-checkbox"
          />
          <span>
            Agree to the{" "}
            <a
              data-testid="terms-link"
              href={`https://${process.env.INTERNAL_STATIC_ASSETS}/epa/${epaVersion}/${epaFilename}`}
              target="_blank"
            >
              Terms and Conditions
            </a>
          </span>
        </>
      )}
    </TermsContainer>
  );
};

export default TermsAndConditions;
