import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { adsTypes as types } from "./index";

export const initialState: types.AdsState = {
  byId: {},
  loadingAds: false,
  loadedAds: false,
  loadAdsFailed: false
};

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    loadAdsSubmit: (state: types.AdsState) => {
      state.loadingAds = true;
      state.loadedAds = false;
      state.loadAdsFailed = false;
    },
    loadAdsSuccess: (
      state: types.AdsState,
      action: PayloadAction<Record<string, types.Ad>>
    ) => {
      state.loadingAds = false;
      state.loadedAds = true;
      state.loadAdsFailed = false;
      state.byId = action.payload;
    },
    loadAdsFailed: (state: types.AdsState) => {
      state.loadingAds = false;
      state.loadedAds = false;
      state.loadAdsFailed = true;
      state.byId = {};
    },
    resetStore: () => initialState
  }
});

export const { loadAdsSubmit, loadAdsSuccess, loadAdsFailed, resetStore } =
  requestSlice.actions;

export default requestSlice.reducer;
