// import * as React from "react";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../../state/entities/vendors/selectors";
import { getSelectedLocation } from "../../../state/entities/locations/selectors";
import moment from "moment";

export const useFileName = (
  documentTitle: string,
  dateSubString?: string
): string => {
  const location = useSelector(getSelectedLocation);
  const vendor = useSelector(getSelectedVendor);

  const locationLevelReports = [
    "Resolution Center",
    "Invoice Journey",
    "Payment Plan Payment Detail",
    "Payment Detail",
    "Payment Report",
    "Invoice Report",
    "Statement",
    "Payment Plan Detail",
    "Payment Confirmation"
  ];

  const isLocationReport = locationLevelReports.some(prefix =>
    documentTitle.startsWith(prefix)
  );

  const dateString = dateSubString
    ? dateSubString
    : moment().format("YYYY-MM-DD");

  return `${documentTitle}-${dateString}-${vendor.name}${
    isLocationReport ? `-${location.displayName}` : ""
  }.csv`;
};
