import * as React from "react";
import {
  StyledSquare,
  HeaderContainer,
  StyledGroupTitle,
  Container,
  ActionContainer,
  SecondaryActionWrapper,
  ActionTextWrapper,
  StyledSkeleton
} from "./styled";
import validator from "validator";

interface GroupHeaderSecondaryAction {
  text: string;
  icon: JSX.Element;
  url?: string;
}

interface GroupHeaderProps {
  title: string;
  color: string;
  loading?: boolean;
  secondaryAction?: GroupHeaderSecondaryAction;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({
  title,
  color,
  secondaryAction,
  loading = false
}) => {
  return (
    <Container color={color}>
      <StyledSquare color={color} />
      {loading ? (
        <StyledSkeleton height="29px" width="100%" />
      ) : (
        <HeaderContainer>
          <StyledGroupTitle>{title}</StyledGroupTitle>
          {secondaryAction && (
            <ActionContainer>
              {secondaryAction?.url &&
                validator.isURL(secondaryAction?.url, {
                  protocols: [
                    "http",
                    "https",
                    "ftp",
                    "blob:http",
                    "blob:https"
                  ],
                  require_protocol: true,
                  require_tld: false
                }) && (
                  <SecondaryActionWrapper
                    target="_blank"
                    rel="noopener noreferrer"
                    href={secondaryAction?.url}
                  >
                    {secondaryAction?.icon}

                    <ActionTextWrapper>
                      {secondaryAction?.text}
                    </ActionTextWrapper>
                  </SecondaryActionWrapper>
                )}
            </ActionContainer>
          )}
        </HeaderContainer>
      )}
    </Container>
  );
};

export default GroupHeader;
