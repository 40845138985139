import * as React from "react";
import { formatCurrency } from "@billfire/toybox";
import { RemitBaseRow, RemitInvoice } from "../types";
import {
  TotalsContainer,
  GrandTotalContainer,
  GrandTotalLabel,
  TotalsDataRow,
  Divider
} from "./styled";
import { getPrintClassName } from "../utils";
import { calculateTotal } from "./utils";
import { SubTotalLabel, SubTotalContainer } from "../InvoiceTotals/styled";

interface TotalsProps {
  sortedInvoices: Array<RemitBaseRow | RemitInvoice>;
  printing?: boolean;
}

const Totals: React.FC<TotalsProps> = ({ sortedInvoices, printing }) => {
  const { blankSubTotal, blankSubTotalCredits } =
    calculateTotal(sortedInvoices);

  const printClassName = printing ? getPrintClassName(sortedInvoices) : "";

  if (sortedInvoices.length === 0) {
    return null;
  }

  return (
    <TotalsContainer className={printClassName}>
      <TotalsDataRow>
        <SubTotalLabel>Subtotal</SubTotalLabel>
        <SubTotalContainer
          data-testid="subtotal-invoices-value"
          className={printClassName}
        >
          {formatCurrency(blankSubTotal.value)}
        </SubTotalContainer>
      </TotalsDataRow>
      <TotalsDataRow>
        <SubTotalLabel>Credits</SubTotalLabel>
        <SubTotalContainer
          data-testid="subtotal-credits-value"
          className={printClassName}
        >
          {formatCurrency(blankSubTotalCredits.value)}
        </SubTotalContainer>
      </TotalsDataRow>
      <Divider />
      <TotalsDataRow>
        <GrandTotalLabel>Total</GrandTotalLabel>
        <GrandTotalContainer
          data-testid="total-value"
          className={printClassName}
        >
          {formatCurrency(blankSubTotal.add(blankSubTotalCredits).value)}
        </GrandTotalContainer>
      </TotalsDataRow>
    </TotalsContainer>
  );
};

export default Totals;
