import React from "react";
import {
  Modal,
  ModalSize,
  ModalType,
  Button,
  formatCurrency
} from "@billfire/toybox";
import { usePageNav } from "../../hooks/navigation";
import { ButtonContainer, MessageContainer } from "../modals.styled";
import { invoiceTypes } from "../../state/entities/invoices";
export interface EditInvoiceModalProps {
  onClose: () => void;
  invoice: invoiceTypes.Invoice;
}
const EditInvoiceModal: React.FC<EditInvoiceModalProps> = ({
  onClose,
  invoice
}) => {
  const { appendPath } = usePageNav();
  return (
    <Modal
      title={"Change payment amount?"}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={onClose}
      loading={false}
      errored={false}
      hideActions={true}
    >
      <MessageContainer>{`Do you want to pay an amount different that the current open amount on invoice ${
        invoice.displayInvNum
      } of ${formatCurrency(invoice.openAmount)}?`}</MessageContainer>
      <ButtonContainer>
        <Button
          color="secondary"
          loading={false}
          handleClick={() => {
            onClose();
            appendPath(`${invoice.id}/dispute`);
          }}
          padding="10px 0px 0px 0px"
        >
          Dispute Invoice
        </Button>
        <Button
          color="secondary"
          loading={false}
          handleClick={() => {
            onClose();
            appendPath(`${invoice.id}/partial`);
          }}
          padding="10px 0px 0px 20px"
        >
          Partial Payment
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
export default EditInvoiceModal;
