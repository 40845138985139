// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import routes from "./routes";
import { Switch, useLocation } from "react-router-dom";
import { hot } from "react-hot-loader/root";
import { useSelectedVendorId } from "./hooks";
import { getVendorIdFromPath } from "./utils";
import { useEffect } from "react";
import useGaPageTracking from "./hooks/useGaPageTracking";
import RouteProtector from "./routeProtection/RouteProtector";

const App: React.FC<{}> = () => {
  useGaPageTracking();
  const location = useLocation();
  const vendorId = getVendorIdFromPath(location.pathname);

  useSelectedVendorId(location.pathname, vendorId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Switch>
        {routes.map(route => (
          <RouteProtector key={route.path} {...route} />
        ))}
      </Switch>
    </>
  );
};

export default hot(App);
