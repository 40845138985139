// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

import { StatementDelivery } from "../../state/entities/statementDelivery/types";
import StatementsSignupContent from "./StatementsSignupContent";
import { StatementDeliveryTitle } from "./styled";

export interface ManageStatementDeliveryProps {
  statementDelivery: StatementDelivery;
}

const ManageStatementDelivery: React.FC<ManageStatementDeliveryProps> = ({
  statementDelivery
}) => {
  return (
    <>
      <StatementDeliveryTitle>Delivery Preferences</StatementDeliveryTitle>
      <StatementsSignupContent statementDelivery={statementDelivery} />
    </>
  );
};

export default ManageStatementDelivery;
