import { Location } from "../../state/entities/locations/types";

export const locationAddress = (address: Location["address"]): string => {
  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = ""
  } = address;
  const addressStr =
    addressLine1 && addressLine2
      ? `${addressLine1} ${addressLine2}`
      : addressLine1
      ? addressLine1
      : addressLine2;

  const cityStateStr = city && state ? `${city}, ${state}` : "";

  return addressStr && cityStateStr
    ? `${addressStr}, ${cityStateStr}`
    : !cityStateStr
    ? addressStr
    : cityStateStr;
};
