import * as React from "react";
import {
  InputContainer,
  LabelContainer,
  ErrorIconContainer,
  Label,
  Input,
  ErrContainer,
  FormErr
} from "./LetMeIn.styled";
// import RedErrorX from "../../assets/RedErrorX.svg";

interface ContactInputProps {
  value: string;
  validationErr: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactInput: React.FC<ContactInputProps> = ({
  value,
  validationErr,
  onChange
}) => {
  const name = "contactinput";
  const label = "Enter Email or Phone Number";

  return (
    <div key={name}>
      <InputContainer value={value}>
        <LabelContainer>
          {!!validationErr && (
            <ErrorIconContainer>{/*<RedErrorX />*/}</ErrorIconContainer>
          )}
          <Label htmlFor={name} validationErr={!!validationErr}>
            {label}
          </Label>
        </LabelContainer>
        <div>
          <Input
            autoFocus={false}
            id={name}
            name={name}
            aria-label={`${label} input`}
            type="text"
            value={value}
            onChange={onChange}
            aria-invalid={!!validationErr}
            invalid={!!validationErr}
          />
        </div>
      </InputContainer>
      {!!validationErr && (
        <ErrContainer>
          <FormErr>{validationErr}</FormErr>
        </ErrContainer>
      )}
    </div>
  );
};

export default ContactInput;
