import * as React from "react";
import AutopayDeleteConfirmation from "../../../components/Autopay/AutopayDeleteConfirmation";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { locationPath } from "../../../utils";
import { useDeleteConfirmationPageData } from "../hooks";

const AutopayDeleteConfirmationPage: React.FC = () => {
  const { loadingState } = useDeleteConfirmationPageData();
  const history = useHistory();

  if (loadingState === "failed") {
    return <Redirect to={locationPath(history.location.pathname, "autopay")} />;
  }

  if (loadingState === "active") {
    return (
      <Redirect to={locationPath(history.location.pathname, "autopaymanage")} />
    );
  }

  return loadingState === "loading" ? (
    <SkeletonLoader />
  ) : (
    <AutopayDeleteConfirmation />
  );
};

export default AutopayDeleteConfirmationPage;
