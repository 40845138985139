import { Frequency } from "../shared/types";

export interface StatementDelivery {
  id: string;
  contactId: string;
  locationId: string;
  scheduleUnit: Frequency;
  scheduleValue: string;
  sendEmptyStatements: boolean;
}

export const getEmptyStatementDelivery = (): StatementDelivery => ({
  id: "",
  contactId: "",
  locationId: "",
  scheduleUnit: Frequency.WEEKLY,
  scheduleValue: "mon",
  sendEmptyStatements: false
});

export interface LoadingStatementDeliveryFlags {
  loadingStatementDelivery: boolean;
  loadedStatementDelivery: boolean;
  loadStatementDeliveryFailed: boolean;
}

export interface CreatingStatementDeliveryFlags {
  creatingStatementDelivery: boolean;
  createdStatementDelivery: boolean;
  createStatementDeliveryFailed: boolean;
}

export interface DeletingStatementDeliveryFlags {
  deletingStatementDelivery: boolean;
  deletedStatementDelivery: boolean;
  deleteStatementDeliveryFailed: boolean;
}

export interface StatementDeliveryState
  extends LoadingStatementDeliveryFlags,
    CreatingStatementDeliveryFlags,
    DeletingStatementDeliveryFlags {
  currentDelivery: StatementDelivery;
  currentStatementContact: string;
}
