import React from "react";
import { useSelector } from "react-redux";
import { getSelectedPaymentMethod } from "../../../../state/entities/paymentMethods/selectors";
import { StoreType } from "../../../../state/types";
import { CreditCard, Bank } from "../../../Icon";
import { PaymentMethod } from "../../../../state/entities/paymentMethods/types";
import { _getIconFill } from "./utils";
import { PaymentIcon } from "./styled.components";

export interface IconContainerProps {
  paymentMethod: PaymentMethod;
  onlyUseSelectedPmFromRedux: boolean;
}

const IconContainer: React.FC<IconContainerProps> = ({
  paymentMethod,
  onlyUseSelectedPmFromRedux
}) => {
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, onlyUseSelectedPmFromRedux)
  );
  return (
    <PaymentIcon>
      {paymentMethod.type === "ACH" ? (
        <Bank fill={_getIconFill(paymentMethod, selectedPaymentMethod?.id)} />
      ) : (
        <CreditCard
          fill={_getIconFill(paymentMethod, selectedPaymentMethod?.id)}
        />
      )}
    </PaymentIcon>
  );
};

export default IconContainer;
