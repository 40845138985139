import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { loadPaymentMethodsSubmit } from "../state/entities/paymentMethods/slice";
import { getPaymentMethodsLoading } from "../state/entities/paymentMethods/selectors";
import { useNavigation } from "./route";

export const usePaymentMethodsLoading = (forCreate = false): boolean => {
  const dispatch = useDispatch();
  const paymentMethodsLoading = useSelector((state: StoreType) =>
    getPaymentMethodsLoading(state)
  );

  const { vendorId, locationId } = useNavigation();

  React.useEffect(() => {
    if (vendorId && locationId) {
      dispatch(loadPaymentMethodsSubmit({ forCreate }));
    }
  }, [dispatch, vendorId, locationId, forCreate]);

  return paymentMethodsLoading;
};
