import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { loadAdsFailed, loadAdsSubmit, loadAdsSuccess } from "../ads/slice";
import { Ad, AdResponse } from "./types";

const reduceAds = (resp: AdResponse): Record<string, Ad> => {
  return resp.ads.reduce((byId: Record<string, Ad>, ad: Ad) => {
    byId[ad.fileId] = ad;
    return byId;
  }, {});
};

export const fetchAdsEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadAdsSubmit.type),
    switchMap(() => {
      const { selectedVendorId } = state$.value.vendors;
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(`/vendors/${selectedVendorId}/advertisements`),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp: AdResponse = api.handleAJAXResponse(response, token);
            return loadAdsSuccess(reduceAds(resp));
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadAdsFailed());
          })
        );
    })
  );
