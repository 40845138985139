// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { Icon } from "@billfire/toybox";
import { DateTime } from "luxon";
import { HeaderContainer } from "./styled.components";

export { default } from "./DateSelect";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChevronRight = (props: any) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M28.8 6.2l-12.8 12.8-12.8-12.8-3.2 3.2 16 16.4 16-16.4z"
      transform="rotate(-90 16 16)"
      fill={props.fill}
    />
  </Icon>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChevronLeft = (props: any) => (
  <Icon {...props} viewBox={"0 0 32 32"}>
    <path
      d="M28.8 6.2l-12.8 12.8-12.8-12.8-3.2 3.2 16 16.4 16-16.4z"
      transform="rotate(90 16 16)"
      fill={props.fill}
    />
  </Icon>
);

export type CustomHeaderProps = {
  decreaseMonth: () => void;
  increaseMonth: () => void;
  date: Date;
};
export const CustomHeader = ({
  decreaseMonth,
  increaseMonth,
  date
}: CustomHeaderProps) => {
  const { monthLong, year } = DateTime.fromJSDate(date);
  return (
    <HeaderContainer>
      <ChevronLeft fill="#00000" onClick={decreaseMonth} className="arrow" />
      <span className={"cal-text"}>
        {monthLong} {year}
      </span>
      <ChevronRight fill="#00000" onClick={increaseMonth} className="arrow" />
    </HeaderContainer>
  );
};
