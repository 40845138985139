import * as React from "react";
import PrintButton from "../PrintButton";
import { PrintAndVendorLogoContainer } from "./styled";
import { useSendRemitInvoiceJourney } from "./hooks";

export interface PrintProps {
  printRef: React.RefObject<HTMLDivElement>;
  hideButton?: boolean;
}

const Print: React.FC<PrintProps> = ({ printRef, hideButton }) => {
  const sendRemitInvoiceJourney = useSendRemitInvoiceJourney();

  return (
    <PrintAndVendorLogoContainer>
      {!hideButton && (
        <PrintButton
          documentTitle={"Remit Form"}
          printRef={printRef}
          onAfterPrint={sendRemitInvoiceJourney}
        />
      )}
    </PrintAndVendorLogoContainer>
  );
};

export default Print;
