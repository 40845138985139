import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import PaymentPlanSetup from "../../components/PaymentPlanSetup";
import { useGlobalHolidaysLoader } from "../../hooks/useGlobalHolidaysLoader";
import { useRequestDetailsLoader } from "../../hooks/useRequestDetailsLoader";
import { usePaymentMethodsLoading } from "../../hooks/usePaymentMethodsLoading";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";

const PaymentPlanRequestPage: React.FC = () => {
  const { loadingRequest, loadRequestFailed } = useRequestDetailsLoader();
  const paymentMethodsLoading = usePaymentMethodsLoading();
  const { locationsLoaded } = useLocationsLoader();
  const { loadedVendors } = useVendorsLoader();
  const { loadedGlobalHolidays } = useGlobalHolidaysLoader();

  if (loadRequestFailed) {
    return <div>failed to load</div>;
  }

  const loading =
    loadingRequest ||
    paymentMethodsLoading ||
    !locationsLoaded ||
    !loadedVendors ||
    !loadedGlobalHolidays;

  return loading ? <SkeletonLoader /> : <PaymentPlanSetup />;
};

export default PaymentPlanRequestPage;
