import { useSelector } from "react-redux";
import { getSelectedVendorPaymentMethodConfigOptions } from "../state/entities/vendors/selectors";
import {
  getEmptyPaymentMethodConfigOption,
  PaymentMethodOption
} from "../state/entities/vendors/types";

export const usePaymentMethodConfigOptions = () => {
  const paymentMethodOptions = useSelector(
    getSelectedVendorPaymentMethodConfigOptions
  );

  return { paymentMethodOptions };
};

export const usePaymentMethodConfigOption = (
  provider: string
): PaymentMethodOption => {
  const { paymentMethodOptions } = usePaymentMethodConfigOptions();

  const option = paymentMethodOptions.find(opt => opt.provider === provider);

  return option || getEmptyPaymentMethodConfigOption();
};
