import * as React from "react";

import { Accordion } from "@billfire/toybox";
import AddPaymentMethodLink from "../AddPaymentMethodLink/index";
import PaymentMethodSelect from "../PaymentMethodSelect";
import PaymentMethodTitle from "../PaymentMethodSelect/PaymentMethodTitle";
import { getPaymentMethods } from "../../state/entities/paymentMethods/selectors";
import { useActiveAutopay } from "./hooks";
import { useSelector } from "react-redux";

const APPaymentMethodSelect: React.FC = () => {
  const activeAutopay = useActiveAutopay();
  const hasPaymentMethods = !!useSelector(getPaymentMethods).length;
  return (
    <>
      {hasPaymentMethods ? (
        <Accordion
          title="Payment Method"
          headingPadding={"0 0 20px 0"}
          titleColor={"#777777"}
          secondaryTitle={
            <PaymentMethodTitle
              paymentMethodOverride={activeAutopay?.paymentMethod}
              onlyUseSelectedPmFromRedux={true}
            />
          }
          content={<PaymentMethodSelect />}
          fontSize="15px"
          titleWeight="normal"
          fused
          accordionWidth="100%"
        />
      ) : (
        <AddPaymentMethodLink {...{ hasPaymentMethods }} />
      )}
    </>
  );
};

export default APPaymentMethodSelect;
