import styled from "styled-components";
import { device } from "../../breakpoints";

export const LocationPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  padding: 10px 60px 10px 60px;

  @media only screen and (${device.tablet}) {
    padding: 10px 10px 10px 10px;
  }
`;
