import { StoreType } from "../../types";
import {
  CreatingStatementDeliveryFlags,
  DeletingStatementDeliveryFlags,
  LoadingStatementDeliveryFlags,
  StatementDelivery
} from "./types";

export const getStatementDeliveryLoadingFlags = (
  state: StoreType
): LoadingStatementDeliveryFlags => {
  return {
    loadingStatementDelivery: state.statementDelivery.loadingStatementDelivery,
    loadedStatementDelivery: state.statementDelivery.loadedStatementDelivery,
    loadStatementDeliveryFailed:
      state.statementDelivery.loadStatementDeliveryFailed
  };
};

export const getStatementDeliveryCreatingFlags = (
  state: StoreType
): CreatingStatementDeliveryFlags => {
  return {
    creatingStatementDelivery:
      state.statementDelivery.creatingStatementDelivery,
    createdStatementDelivery: state.statementDelivery.createdStatementDelivery,
    createStatementDeliveryFailed:
      state.statementDelivery.createStatementDeliveryFailed
  };
};

export const getStatementDeliveryDeletingFlags = (
  state: StoreType
): DeletingStatementDeliveryFlags => {
  return {
    deletingStatementDelivery:
      state.statementDelivery.deletingStatementDelivery,
    deletedStatementDelivery: state.statementDelivery.deletedStatementDelivery,
    deleteStatementDeliveryFailed:
      state.statementDelivery.deleteStatementDeliveryFailed
  };
};

export const getCurrentStatementDelivery = (
  state: StoreType
): StatementDelivery => {
  return state.statementDelivery.currentDelivery;
};

export const getCurrentStatementContact = (state: StoreType): string => {
  return state.statementDelivery.currentStatementContact;
};
