import { Panel, TextInput } from "@billfire/toybox";
import styled from "styled-components";
import { device } from "../../breakpoints";

export const Click2PayDisputePanel = styled(Panel)`
  margin: 20px auto auto 0;
  padding: 0px 50px;
  @media only screen and (${device.tablet}) {
    padding: 0px;
  }
`;

export const StyledHeader = styled.h3`
  padding: 15px 15px 0px 15px;
  margin: auto;
  width: 95%;
`;

export const StyledText = styled.div`
  margin: 0 auto 25px auto;
  width: 95%;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
`;

export const InnerPanelContainer = styled.div`
  width: 95%;
  margin: auto;
  padding-top: 30px;
`;

export const StyledTextInputContainer = styled.div`
  padding-top: 50px;
  height: 75px;
  overflow: hidden;
`;

export const StyledTextInput = styled(TextInput)`
  padding: 0;
`;

export const DropDownContainer = styled.div`
  margin: auto auto auto auto;
`;

export const StyledSpan = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
`;
