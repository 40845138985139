import * as React from "react";
import { formatCurrency } from "@billfire/toybox";
import { RemitBaseRow, RemitInvoice } from "../types";
import {
  TotalsContainer,
  SubTotalContainer,
  Divider,
  GrandTotalContainer,
  SubTotalLabel,
  GrandTotalLabel,
  TotalsDataRow
} from "./styled";
import { getPrintClassName } from "../utils";
import { calculateTotal } from "./utils";

interface TotalsProps {
  sortedInvoices: Array<RemitBaseRow | RemitInvoice>;
  printing?: boolean;
}

const Totals: React.FC<TotalsProps> = ({ sortedInvoices, printing }) => {
  const selected = sortedInvoices.filter(inv => inv.selected);
  const useInvoices =
    selected.length > 0 && printing ? selected : sortedInvoices;

  const { subTotalInvoices, subTotalCredits } = calculateTotal(useInvoices);

  const printClassName = printing ? getPrintClassName(sortedInvoices) : "";

  if (sortedInvoices.length === 0) {
    return null;
  }

  return (
    <TotalsContainer className={printClassName}>
      <TotalsDataRow>
        <SubTotalLabel>Subtotal</SubTotalLabel>
        <SubTotalContainer
          data-testid="subtotal-invoices-value"
          className={printClassName}
        >
          {formatCurrency(subTotalInvoices.value)}
        </SubTotalContainer>
      </TotalsDataRow>
      <TotalsDataRow>
        <SubTotalLabel>Credits</SubTotalLabel>
        <SubTotalContainer
          data-testid="subtotal-credits-value"
          className={printClassName}
        >
          {formatCurrency(subTotalCredits.value)}
        </SubTotalContainer>
      </TotalsDataRow>
      <Divider />
      <TotalsDataRow>
        <GrandTotalLabel>Total</GrandTotalLabel>
        <GrandTotalContainer
          data-testid="total-value"
          className={printClassName}
        >
          {formatCurrency(subTotalInvoices.add(subTotalCredits).value)}
        </GrandTotalContainer>
      </TotalsDataRow>
    </TotalsContainer>
  );
};

export default Totals;
