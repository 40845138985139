import styled from "styled-components";
import styles, { Button } from "@billfire/toybox";
import { device } from "../breakpoints";
const { colors } = styles;
const { baseGray2, baseGray3, red } = colors;

export const ModalWrapper = styled.div`
  & .modalContainer {
    padding-left: 30px;
    & .modalTitle {
      padding-bottom: 30px;
    }
    & .modalFooter {
      justify-content: center;
      padding-right: 30px;
    }
  }
`;

export const StyledMessage = styled.div`
  font-size: 36px;
  font-weight: 100;
`;

export const WhiteStyledMessage = styled.div`
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: ${baseGray3};
`;

export const RemoveAccessCancelButton = styled(Button)`
  height: 42px;
`;

export const RemoveAccessModalFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 30px 20px 0px;
`;

export const RemoveAccessButton = styled(Button)`
  height: 42px;
`;

export const RemoveAccessButtonText = styled.div`
  color: #4a90e2;
  font-size: 20px;
  display: flex;
  justify-content: center;
`;

export const RemoveAccessRadioInput = styled.input``;

export const RemoveAccessFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RemoveAccessNotAllowedMessage = styled.p`
  margin: 20px 0 40px 0;
  color: ${baseGray2};
`;

export const ButtonContainer = styled.div.attrs({
  "data-testid": "button-container"
})`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 20px;
  width: 280px;
`;

export const ButtonGroup = styled.div.attrs({
  "data-testid": "button-group"
})`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  @media screen and (${device.tablet}) {
    flex-direction: column;
    .Button:first-child {
      margin-bottom: 20px;
    }
  }
`;

export const MessageContainer = styled.div`
  margin: auto;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: ${baseGray3};
  padding: 15px 0px 15px 0px;
`;

export const ErrorMessage = styled.div`
  text-align: center;
  color: ${red};
  font-size: 14px;
`;
