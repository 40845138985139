import * as React from "react";
import CommonWrapper from "../../components/CommonWrapper";
import { CenterContainer, Divider, UpdateButton } from "./styled";
import { DateTime } from "luxon";
import { PaymentPlanScheduleDetail } from "./PaymentPlanScheduleDetail";
import { useNavigateToUpdatePage } from "./hooks";
import LocationHeaderText from "../LocationHeaderText";
import { LocationPageWrapper } from "../bits/styled";
import { usePaymentPlanSchedule } from "./usePaymentPlanSchedule";
import PaymentMethodDetailRow from "../PaymentDetail/PaymentMethodDetailRow";
import DetailRow from "../PaymentDetail/DetailRow";
import PrintExportComponent from "../PrintExportComponent";
import { mapPPDetailsCSVData, getRowData, scheduleColumns } from "./utils";
import PaymentPlanDetailPrintView from "./PaymentPlanDetailPrintView";
import { useMobile } from "../../hooks/useMobile";

const PaymentPlanDetail: React.FC = () => {
  const { paymentPlan, paymentPlanHelper, schedule } = usePaymentPlanSchedule();
  const navigateToUpdatePage = useNavigateToUpdatePage();
  const { paidTotalAmount } = paymentPlanHelper.calculatePaymentsMade(
    paymentPlan.paymentGroups
  );
  const startDate = DateTime.fromISO(paymentPlan.startDate).toLocaleString(
    DateTime.DATE_SHORT
  );
  const rowData = getRowData(paymentPlan, startDate, paidTotalAmount);
  const stillPaying = paymentPlan.status === "active";
  const {
    paymentMethod: {
      displayExtra: { name, lastFour }
    }
  } = paymentPlan;
  const { mobile } = useMobile();
  const { csvData, headers } = mapPPDetailsCSVData(paymentPlan, schedule);
  const printRef = React.useRef(null);
  const headerText = "Payment Plan Details for";

  const commonProps = {
    scheduleColumns,
    paymentPlan,
    schedule
  };

  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText={headerText} />
        <PrintExportComponent
          printRef={printRef}
          documentTitle={`Payment Plan Detail ${paymentPlan.name}`}
          csvData={csvData}
          headers={headers}
        />
        {rowData.map(displayMe => (
          <React.Fragment key={`${displayMe.left}-fragment`}>
            {displayMe.left === "Payment Method" ? (
              <PaymentMethodDetailRow name={name} lastFour={lastFour} />
            ) : (
              <DetailRow
                titleText={displayMe.left}
                detailText={displayMe.right}
              />
            )}
            <Divider />
          </React.Fragment>
        ))}
        <PaymentPlanDetailPrintView
          printRef={printRef}
          headerText={headerText}
          rowData={rowData}
          name={name}
          lastFour={lastFour}
          {...commonProps}
        />
        <PaymentPlanScheduleDetail mobile={mobile} {...commonProps} />
        <Divider />
        <CenterContainer>
          {stillPaying && (
            <UpdateButton
              hasIcon={true}
              loading={false}
              handleClick={() => navigateToUpdatePage()}
            >
              Update Payment Method
            </UpdateButton>
          )}
        </CenterContainer>
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default PaymentPlanDetail;
