import * as React from "react";
import { useRemitForm } from ".";
import InvoiceRow from "./Row/InvoiceRow";
import { PrintingComponentProps } from "./types";

const TableBody: React.FC<PrintingComponentProps> = ({ printing }) => {
  const { sortedInvoices } = useRemitForm();

  const selected = sortedInvoices.filter(inv => inv.selected);

  const useInvoices =
    printing && selected.length > 0 ? selected : sortedInvoices;

  return (
    <tbody>
      {useInvoices.map(item => (
        <InvoiceRow rowId={item.rowId} key={`row-${item.rowId}`} />
      ))}
    </tbody>
  );
};

export default TableBody;
