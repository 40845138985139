import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import DetailRow from "./DetailRow";
import { paidBy } from "../../state/entities/paymentGroups/utils";
import { formatShortDate, SortDirection } from "@billfire/toybox";
import { translatePowerPayType } from "../../utils";
import { PaymentGroupDetail } from "../../pages/PaymentDetailPage/types";
import { getSubtitleText, paymentsTableColumns } from "./utils";
import {
  StyledAccordion,
  StyledDataTable,
  StyledTotalsAndFees
} from "./styled";
import { StyledLink } from "./styled";
import { useRouteContext } from "../../routeContext";
import { LocationPageWrapper } from "../bits/styled";
import LocationHeaderText from "../LocationHeaderText";
import PaymentMethodDetailRow from "./PaymentMethodDetailRow";
import PrintExportContainer from "./PrintExportContainer";
import C2PConfirmationHeader from "./C2PConfirmationHeader";
import { usePaymentMethodConfigOption } from "../../hooks/usePaymentMethodConfigOptions";
import PaymentDetailPrintView from "./PaymentDetailPrintView";
import { useSelector } from "react-redux";
import {
  getSelectedVendor,
  selectSelectedVendorShipToConfig
} from "../../state/entities/vendors/selectors";
import { getGlobalFeatureFlags } from "../../state/entities/shared/selectors";
import ReferralCTA from "../ReferralCTA";
import { useMobile } from "../../hooks/useMobile";

interface PaymentDetailsProps {
  paymentGroupDetail: PaymentGroupDetail;
  confirmation: boolean;
}

const PaymentDetail: React.FC<PaymentDetailsProps> = ({
  paymentGroupDetail,
  confirmation
}) => {
  const [sortBy, setSortBy] = React.useState("invoiceDueDate");
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>("ASC");
  const printRef = React.useRef(null);

  const { paymentGroup, payments } = paymentGroupDetail;

  const { customerDisplayName } = usePaymentMethodConfigOption(
    paymentGroup.paymentMethod.provider
  );

  const initialData = payments.map(pmt => ({ ...pmt, rowId: pmt.id }));
  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);

  const selectedVendorShipToConfig = useSelector(
    selectSelectedVendorShipToConfig
  );

  const { mobile } = useMobile();

  const columns = paymentsTableColumns(
    vendor,
    globalFeatureFlags,
    selectedVendorShipToConfig,
    mobile
  );

  const routeContext = useRouteContext();

  const paymentPlanDetailPage = () => {
    const baseRoute = routeContext.to.split("/");
    baseRoute.pop();
    baseRoute.pop();

    baseRoute.push("plans");
    baseRoute.push(paymentGroup.paymentPlanId);

    return baseRoute.join("/");
  };

  const headerText = confirmation
    ? "Payment Scheduled for"
    : "Payment Detail for";

  const subtitleText = getSubtitleText(paymentGroup);

  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText={headerText} />
        <PaymentDetailPrintView
          printRef={printRef}
          headerText={headerText}
          initialData={initialData}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          paymentGroup={paymentGroup}
          customerDisplayName={customerDisplayName}
          subtitleText={subtitleText}
        />
        {confirmation && (
          <C2PConfirmationHeader paymentGroupDetail={paymentGroupDetail} />
        )}
        <StyledAccordion
          title="Payment Details"
          hideBoxShadow={true}
          hideBorder={true}
        >
          <PrintExportContainer
            printRef={printRef}
            confirmation={confirmation}
            paymentGroupDetail={paymentGroupDetail}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
          <DetailRow
            titleText="Confirmation"
            detailText={paymentGroup.confirmationNumber}
          />
          <DetailRow
            titleText="Payment Date"
            detailText={formatShortDate(paymentGroup.paymentDate)}
          />
          {!confirmation && (
            <StyledTotalsAndFees
              subTotalTitle={subtitleText}
              totalWithFeesTitle="Payment Total"
              totalBeforeFees={paymentGroup.paymentTotal}
              paymentMethod={paymentGroup.paymentMethod}
            />
          )}
          <DetailRow
            titleText="Payment Type"
            detailText={translatePowerPayType(paymentGroup.paymentType)}
          />
          <DetailRow titleText="Paid By" detailText={paidBy(paymentGroup)} />
          <PaymentMethodDetailRow
            name={paymentGroup.paymentMethod.displayExtra.name}
            lastFour={paymentGroup.paymentMethod.displayExtra.lastFour}
          />
          <DetailRow
            titleText="Method Type"
            detailText={customerDisplayName || ""}
          />
          {confirmation && (
            <StyledTotalsAndFees
              subTotalTitle={subtitleText}
              totalWithFeesTitle="Payment Total"
              totalBeforeFees={paymentGroup.paymentTotal}
              paymentMethod={paymentGroup.paymentMethod}
            />
          )}
          {paymentGroup.paymentPlanName ? (
            <DetailRow
              titleText="Plan ID"
              detailText={
                <StyledLink to={`${paymentPlanDetailPage()}`}>
                  {paymentGroup.paymentPlanName}
                </StyledLink>
              }
            />
          ) : (
            <StyledDataTable
              sortable
              initialData={initialData}
              columns={columns}
              externalSortBy={sortBy}
              externalSetSortBy={setSortBy}
              externalSortDirection={sortDirection}
              setExternalSortDirection={setSortDirection}
            />
          )}
        </StyledAccordion>
      </LocationPageWrapper>
      <ReferralCTA />
    </CommonWrapper>
  );
};

export default PaymentDetail;
