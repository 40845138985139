import * as React from "react";

import CommonWrapper from "../CommonWrapper";
import ManageStatementDelivery from "../ManageStatementDelivery";
import AccordionTitle from "./AccordionTitle";
import Ads from "./Ads";
import Footer from "./Footer";
import { useHandleAccordion } from "./hooks";
import StatementContent from "./StatementContent";
import { StyledAccordion } from "./styled.components";

const Statement: React.FC = () => {
  const { openAccordion, setOpenAccordion, mobile, statementDelivery } =
    useHandleAccordion();

  return (
    <CommonWrapper>
      <Ads />
      <StatementContent />
      <StyledAccordion
        openOverride={statementDelivery.id === "" || openAccordion === true}
        className="statement-delivery"
        title={<AccordionTitle {...{ statementDelivery }} />}
        headingPadding={mobile ? "15px 10px" : "28px 35px"}
        content={<ManageStatementDelivery {...{ statementDelivery }} />}
        fontSize={"18px"}
        titleWeight="500"
        titleColor="#4a90e2"
        onToggle={() => setOpenAccordion(!openAccordion)}
      />
      <Footer />
    </CommonWrapper>
  );
};

export default Statement;
