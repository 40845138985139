import * as React from "react";

import CommonWrapper from "../../CommonWrapper";
import {
  ButtonContainer,
  StyledButton,
  ButtonHeaderText,
  ErrorMessageText,
  DesktopBreak,
  SetupStyledButton
} from "../styled";
import { DateTime } from "luxon";
import { useAutopayForm, useCustomerManageAutopay } from "../hooks";
import APPaymentMethodSelect from "../APPaymentMethodSelect";
import ExcludedInvoices from "../ExcludedInvoices";
import PaymentDatePicker from "../../PaymentDatePicker";
import TermsAndConditions from "../../TermsAndConditions";
import { Redirect } from "react-router-dom";
import LocationHeaderText from "../../LocationHeaderText";
import { LocationPageWrapper } from "../../bits/styled";
import { getSelectedPaymentMethodProvider } from "../../../state/entities/paymentMethods/selectors";
import { useSelector } from "react-redux";
import { getActiveAutopayId } from "../../../state/entities/autopay/selectors";
import { useParams } from "react-router-dom";

const Autopay: React.FC = () => {
  const {
    confirmed,
    pathname,
    validDates,
    selectedStartDate,
    submitForm,
    submitting,
    isFormValid,
    epaChecked,
    setEpaChecked,
    failed
  } = useAutopayForm();
  const { token, vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
    token: string;
  }>();

  const customerManageAutopay = useCustomerManageAutopay();

  const _handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };
  const provider = useSelector(getSelectedPaymentMethodProvider);

  if (useSelector(getActiveAutopayId)) {
    return (
      <Redirect
        to={{
          pathname: `/token/${token}/vendors/${vendorId}/locations/${locationId}/autopaymanage`
        }}
      />
    );
  }

  return confirmed ? (
    <Redirect to={{ pathname }} />
  ) : (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText={"AutoPay for"} />
        <APPaymentMethodSelect />
        <PaymentDatePicker
          title="Start Date"
          selectedDate={selectedStartDate}
          validDates={validDates}
          getNewInitialPaymentDate={() => DateTime.now()}
          readOnly={!customerManageAutopay}
        />
        <ExcludedInvoices setup={true} />
        <ButtonContainer>
          <TermsAndConditions
            checked={epaChecked}
            setChecked={setEpaChecked}
            provider={provider}
          />
          <ButtonHeaderText>
            AutoPay Plus will schedule payments for the day that your invoices
            are due. <DesktopBreak />
            Non-excluded invoices due on or before the start date will be paid
            on the start date.
          </ButtonHeaderText>
          <SetupStyledButton
            loading={submitting}
            disabled={!isFormValid}
            handleClick={_handleSubmit}
          >
            Set Up AutoPay Plus
          </SetupStyledButton>
          <StyledButton loading={false} color={"secondary"}>
            Cancel
          </StyledButton>
          {failed && (
            <ErrorMessageText>Could not set up AutoPay Plus.</ErrorMessageText>
          )}
        </ButtonContainer>
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default Autopay;
