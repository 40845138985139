import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
// import Autopay from "../../components/Autopay";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useMessagesLoader } from "../../hooks/useMessagesLoader";
import ResolutionCenter from "../../components/ResolutionCenter";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";

const ResolutionCenterPage: React.FC = () => {
  const { loadingMessages } = useMessagesLoader();
  const { loadedVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();

  return loadingMessages || locationsLoading || !loadedVendors ? (
    <SkeletonLoader />
  ) : (
    <ResolutionCenter />
  );
};

export default ResolutionCenterPage;
