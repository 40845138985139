import { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { ofType, StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax, AjaxError, AjaxResponse } from "rxjs/ajax";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import api from "../../api";
import { StoreType, TokenType } from "../../types";
import { getSelectedLocationId } from "../locations/selectors";
import { isUserAction } from "../persisted/selectors";
import { saveAction } from "../persisted/slice";
import { ReplayType } from "../persisted/types";
import { createSaveAction } from "../persisted/utils";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import {
  getCreatingPaymentMethod,
  getNewPaymentMethodToken
} from "./selectors";
import {
  createPaymentMethod,
  createPaymentMethodSuccess,
  createPaymentMethodFailed,
  createTokenizedPaymentMethod,
  deletePaymentMethodsFailed,
  deletePaymentMethodsSubmit,
  deletePaymentMethodsSuccess,
  loadPaymentMethodsFailed,
  loadPaymentMethodsSubmit,
  loadPaymentMethodsSuccess,
  setCreatingPaymentMethodToken,
  setSelectedPaymentMethod,
  createPaymentMethodStateReset
} from "./slice";
import {
  ApiResponseLoadPaymentMethods,
  ConstructingPaymentMethodCC,
  isAPICodedErrorResponse,
  isConstructingPaymentMethodACH,
  MappedLoadPaymentMethodsResponse,
  PaymentMethod,
  SetPaymentMethod,
  LoadPaymentMethodsSubmit
} from "./types";
import { isCreatingPlastiqCC, getTypeFromProvider } from "./utils";

export const mapResponse = (
  resp: ApiResponseLoadPaymentMethods
): MappedLoadPaymentMethodsResponse => {
  const byId = resp.paymentMethods.reduce(
    (byId: Record<string, PaymentMethod>, paymentMethod: PaymentMethod) => {
      byId[paymentMethod.id] = {
        ...paymentMethod,
        type: getTypeFromProvider(paymentMethod.provider)
      };
      return byId;
    },
    {}
  );

  return {
    byId,
    jwt: resp.paymentMethodsJwt,
    clientFlowInfoMap: resp.clientFlowInfo
  };
};

export const fetchPaymentMethodsEpic = (
  action$: Observable<PayloadAction<LoadPaymentMethodsSubmit>>,
  state$: StateObservable<StoreType>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadPaymentMethodsSubmit.type),
    switchMap((action: PayloadAction<LoadPaymentMethodsSubmit>) => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);

      const url = `/vendors/${selectedVendorId}/locations/${selectedLocationId}/paymentmethods`;

      return ajax
        .get(
          api.API_URL(
            `${url}${action.payload.forCreate ? "?createClientFlow=true" : ""}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            const mapped = mapResponse(resp);
            return loadPaymentMethodsSuccess(mapped);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadPaymentMethodsFailed());
          })
        );
    })
  );

const _callCreateTokenizedPaymentMethodEpicSelectors = (
  state$: StateObservable<StoreType>
) => {
  const token = getAuthToken(state$.value);
  const selectedVendorId = getSelectedVendorId(state$.value);
  const selectedLocationId = getSelectedLocationId(state$.value);
  const newPaymentMethodToken = getNewPaymentMethodToken(state$.value);
  return { token, selectedVendorId, selectedLocationId, newPaymentMethodToken };
};

export const createTokenizedPaymentMethodEpic = (
  action$: Observable<PayloadAction>,
  state$: StateObservable<StoreType>
) =>
  action$.pipe(
    ofType(createTokenizedPaymentMethod.type),
    switchMap(() => {
      const {
        token,
        selectedVendorId,
        selectedLocationId,
        newPaymentMethodToken
      } = _callCreateTokenizedPaymentMethodEpicSelectors(state$);

      return ajax
        .post(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/paymentmethods`
          ),
          { paymentMethodKey: newPaymentMethodToken.key },
          api.getAJAXHeaders(token, TokenType.ContactAccess)
        )
        .pipe(
          map(res => {
            api.handleAJAXResponse(res, token);
            if (res.status !== 200) {
              return of(createPaymentMethodFailed({ code: "" }));
            }
            const paymentMethodId = {
              paymentMethodId: res.response.paymentMethod.id
            };
            return createPaymentMethodSuccess(paymentMethodId);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(createPaymentMethodFailed({ code: "" }));
          })
        );
    })
  );

const _createTokenizedCCFields = (tokenizeMe: ConstructingPaymentMethodCC) => ({
  nameOnCard: tokenizeMe.name,
  ccNumber: tokenizeMe.number,
  expDate: tokenizeMe.expiration,
  cvc: tokenizeMe.cvc,
  addressLine1: tokenizeMe.addressLine1,
  addressLine2: tokenizeMe.addressLine2,
  zipCode: tokenizeMe.zipcode,
  name: tokenizeMe.nickname,
  tin: tokenizeMe.tin,
  type: "CC",
  provider: tokenizeMe.provider
});

//This epic is for creating every payment method but Plastiq
export const createPaymentMethodEpic = (
  action$: Observable<PayloadAction>,
  state$: StateObservable<StoreType>
) => {
  return action$.pipe(
    ofType(createPaymentMethod.type),
    filter(() => !isCreatingPlastiqCC(state$.value)),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      const jwt = state$.value.paymentMethods.jwt;
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      const tokenizeMe = state$.value.paymentMethods.creatingPaymentMethod;
      const paymentMethod = isConstructingPaymentMethodACH(tokenizeMe)
        ? tokenizeMe
        : _createTokenizedCCFields(tokenizeMe);
      return ajax
        .post(
          api.PAYMENTS_API_URL(`/tokenizer`),
          paymentMethod,
          api.getAJAXHeaders(jwt, TokenType.PaymentMethod)
        )
        .pipe(
          switchMap(res => {
            const response = api.handleAJAXResponse(res, token);
            if (res.status !== 200) {
              return of(createPaymentMethodFailed({ code: "" }));
            }
            return ajax
              .post(
                api.API_URL(
                  `/vendors/${selectedVendorId}/locations/${selectedLocationId}/paymentmethods`
                ),
                { paymentMethodKey: response.key },
                api.getAJAXHeaders(token, TokenType.ContactAccess)
              )
              .pipe(
                map(res => {
                  api.handleAJAXResponse(res, token);
                  if (res.status !== 200) {
                    return createPaymentMethodFailed({ code: "" });
                  }
                  const paymentMethodId = {
                    paymentMethodId: res.response.paymentMethod.id
                  };
                  return createPaymentMethodSuccess(paymentMethodId);
                }),
                catchError(error => {
                  if (error instanceof AjaxError)
                    api.handleAJAXResponse(error, token);
                  return of(createPaymentMethodFailed({ code: "" }));
                })
              );
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(createPaymentMethodFailed({ code: "" }));
          })
        );
    })
  );
};

export const paymentMethodSuccessListener = (
  action$: Observable<PayloadAction<unknown>>
) => {
  return action$.pipe(
    ofType(createPaymentMethodSuccess.type),
    switchMap(() => {
      return of(createPaymentMethodStateReset());
    })
  );
};
export interface CcMethod {
  nameOnCard: string;
  ccNumber: string;
  expDate: Date;
  cvc: string;
  addressLine1: string;
  addressLine2?: string;
  zipCode: string;
  name: string;
  type: "CC";
}

const _handleTokenizeCCErrorResponse = (res: AjaxResponse) => {
  const payload = { code: "" };

  if (isAPICodedErrorResponse(res)) {
    payload.code = res.extra.processorErrors[0].code || "unknown";
  }

  return createPaymentMethodFailed(payload);
};

const _callTokenizeNewCCPaymentMethodSelectors = (
  state$: StateObservable<StoreType>
) => {
  const token = getAuthToken(state$.value);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const tokenizeMe = getCreatingPaymentMethod(
    state$.value
  ) as ConstructingPaymentMethodCC;
  return { token, tokenizeMe };
};

//This epic is only for creating Plastiq methods
export const tokenizePlastiqPaymentMethod = (
  action$: Observable<PayloadAction>,
  state$: StateObservable<StoreType>
) =>
  action$.pipe(
    ofType(createPaymentMethod.type),
    filter(() => isCreatingPlastiqCC(state$.value)),
    switchMap(() => {
      const { token, tokenizeMe } =
        _callTokenizeNewCCPaymentMethodSelectors(state$);
      const jwt = state$.value.paymentMethods.jwt;
      const paymentMethod = _createTokenizedCCFields(tokenizeMe);
      return ajax
        .post(
          api.PAYMENTS_API_URL(`/tokenizer`),
          paymentMethod,
          api.getAJAXHeaders(jwt, TokenType.PaymentMethod)
        )
        .pipe(
          switchMap(res => {
            const response = api.handleAJAXResponse(res, token);
            if (res.status !== 200) {
              return of(_handleTokenizeCCErrorResponse(res));
            }
            return of(setCreatingPaymentMethodToken({ token: response }));
          }),
          catchError(error => {
            if (error instanceof AjaxError) {
              api.handleAJAXResponse(error, token);
              return of(_handleTokenizeCCErrorResponse(error.response));
            }
            return of(createPaymentMethodFailed({ code: "" }));
          })
        );
    })
  );

export const deletePaymentMethodsEpic = (
  action$: Observable<PayloadAction>,
  state$: StateObservable<StoreType>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(deletePaymentMethodsSubmit.type),
    switchMap(() => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const { selectedPaymentMethodId } = state$.value.paymentMethods;
      const token = getAuthToken(state$.value);
      return ajax
        .delete(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/paymentmethods/${selectedPaymentMethodId}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return deletePaymentMethodsSuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(deletePaymentMethodsFailed());
          })
        );
    })
  );

export const persistPaymentMethodSelectedEpic = (
  action$: Observable<PayloadAction<SetPaymentMethod>>,
  state$: { value: StoreType }
) =>
  action$.pipe(
    ofType(setSelectedPaymentMethod.type),
    filter(() => isUserAction(state$.value)),
    map((action: PayloadAction<SetPaymentMethod>) => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      return saveAction(
        createSaveAction(
          { ...action, replayType: ReplayType.none },
          selectedVendorId,
          selectedLocationId,
          token
        )
      );
    })
  );
