import * as React from "react";
import { useSelector } from "react-redux";
import {
  getPaymentGroups,
  getPaymentGroupsLoadFailed
} from "../../state/entities/paymentGroups/selectors";
import { StoreType } from "../../state/types";
import {
  PaymentsReportContainer,
  ErrorContainer,
  ErrorText,
  PrintExportContainer
} from "./styled";
import CommonWrapper from "../CommonWrapper";
import LocationHeaderText from "../LocationHeaderText";
import PaymentsReportError from "./PaymentsReportError";
import PaymentsTables from "./PaymentsTables";
import PrintExportComponent from "../PrintExportComponent";
import PaymentReportPrintView from "./PaymentReportPrintView";
import { mapAndSortPaymentGroupsCSVData } from "./utils";
import { PaymentStatus } from "../../state/entities/paymentGroups/types";
import { SortBy } from "./types";

const PaymentsReport: React.FC = () => {
  const [sortBys, setSortBys] = React.useState<Record<PaymentStatus, SortBy>>({
    [PaymentStatus.SCHEDULED]: {
      sortBy: "paymentDate",
      sortDirection: "DESC"
    },
    [PaymentStatus.CANCELED]: {
      sortBy: "paymentDate",
      sortDirection: "DESC"
    },
    [PaymentStatus.COMPLETE]: {
      sortBy: "paymentDate",
      sortDirection: "DESC"
    },
    [PaymentStatus.NONE]: {
      sortBy: "paymentDate",
      sortDirection: "DESC"
    }
  });

  const paymentGroups = useSelector((state: StoreType) =>
    getPaymentGroups(state)
  );
  const loadError = useSelector((state: StoreType) =>
    getPaymentGroupsLoadFailed(state)
  );

  const printRef = React.useRef(null);
  const headerText = "Payment Report for";

  const { csvData, headers } = mapAndSortPaymentGroupsCSVData(
    paymentGroups,
    sortBys
  );

  return (
    <CommonWrapper>
      <LocationHeaderText headerText={headerText} />
      <PaymentsReportContainer>
        <PrintExportContainer>
          <PrintExportComponent
            printRef={printRef}
            documentTitle={"Payment Report"}
            csvData={csvData}
            headers={headers}
          />
        </PrintExportContainer>
        <PaymentsReportError />
        {paymentGroups.length && !loadError ? (
          <>
            <PaymentReportPrintView
              sortBys={sortBys}
              setSortBys={setSortBys}
              printRef={printRef}
              headerText={headerText}
            />
            <PaymentsTables
              sortable={true}
              sortBys={sortBys}
              setSortBys={setSortBys}
            />
          </>
        ) : !loadError ? (
          <ErrorContainer>
            <ErrorText>There are no payments to display.</ErrorText>
          </ErrorContainer>
        ) : (
          <></>
        )}
      </PaymentsReportContainer>
    </CommonWrapper>
  );
};

export default PaymentsReport;
