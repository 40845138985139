/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState } from "react";
import { ModalType, getEmptyModal } from "./types";

const context: {
  modalComponent: ModalType;
  showModal: (component: ModalType) => void;
  hideModal: () => void;
} = {
  modalComponent: getEmptyModal(),
  showModal: (component: ModalType) => {},
  hideModal: () => {}
};

export const ModalContext = createContext(context);

export const useModal = () => React.useContext(ModalContext);
export const ModalProvider: React.FC<{}> = ({ children }) => {
  const [modalComponent, setModalComponent] = useState<ModalType>(
    getEmptyModal()
  );

  const showModal = (component: ModalType) => {
    setModalComponent(component);
  };

  const hideModal = () => {
    setModalComponent(getEmptyModal());
  };

  return (
    <ModalContext.Provider value={{ modalComponent, showModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};
