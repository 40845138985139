import * as React from "react";

import { Accordion } from "@billfire/toybox";
import AddPaymentMethodLink from "../AddPaymentMethodLink/index";
import PaymentMethodSelect from "../PaymentMethodSelect";
import PaymentMethodTitle from "../PaymentMethodSelect/PaymentMethodTitle";
import { getPaymentMethods } from "../../state/entities/paymentMethods/selectors";
import { useSelector } from "react-redux";

const C2PPaymentMethodSelect: React.FC = () => {
  const hasPaymentMethods = !!useSelector(getPaymentMethods).length;

  return (
    <>
      {hasPaymentMethods ? (
        <Accordion
          title="Payment Method"
          secondaryTitle={<PaymentMethodTitle />}
          content={<PaymentMethodSelect />}
          fontSize="15px"
          titleWeight="500"
          fused
          accordionWidth="100%"
        />
      ) : (
        <AddPaymentMethodLink {...{ hasPaymentMethods }} />
      )}
    </>
  );
};

export default C2PPaymentMethodSelect;
