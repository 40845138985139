import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { getAllPaymentPlansWithActivePaymentMethods } from "../../state/entities/paymentPlans/selectors";
import {
  PaymentPlanRow,
  consolidatePaymentPlansByStatus,
  initalPaymentPlanSortBysData,
  generateCSVExport
} from "./utils";
import { useRedirectListener } from "./hooks";
import { LocationPageWrapper } from "../bits/styled";
import LocationHeaderText from "../LocationHeaderText";
import usePaymentPlanHelper from "../../hooks/usePaymentPlanHelper";
import PaymentPlanTableDisplay from "./PaymentPlanTableDisplay";
import PrintExportComponent from "../PrintExportComponent";
import PaymentPlanPrintView from "./PaymentPlanPrintView";
import { SortBys } from "./types";

const PaymentPlans: React.FC = () => {
  const [sortBys, setSortBys] = React.useState<SortBys>(
    initalPaymentPlanSortBysData
  );

  const printRef = React.useRef(null);

  const paymentPlanHelper = usePaymentPlanHelper();
  const paymentPlanRows: PaymentPlanRow[] = useSelector((state: StoreType) =>
    getAllPaymentPlansWithActivePaymentMethods(state)
  ).map(p => {
    const paidCalculation: { paidTotalAmount: number; paidTotalCount: number } =
      paymentPlanHelper.calculatePaymentsMade(p.paymentGroups);
    return {
      ...p,
      rowId: p.id,
      paidSoFar: paidCalculation.paidTotalAmount
    };
  });
  const headerText = "Payment Plans for";

  const consolidatedPaymentPlansByStatus =
    consolidatePaymentPlansByStatus(paymentPlanRows);

  const csvData = generateCSVExport(consolidatedPaymentPlansByStatus);

  const { active, complete, canceled } = consolidatedPaymentPlansByStatus;

  useRedirectListener(paymentPlanRows);

  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText={headerText} />
        <PrintExportComponent
          printRef={printRef}
          documentTitle={"Payment Plan Report"}
          csvData={csvData}
          headers={[
            { label: "Plan Name", key: "name" },
            { label: "Total", key: "totalAmount" },
            { label: "Payment", key: "eachAmount" },
            { label: "Schedule", key: "frequency" },
            { label: "Paid So Far", key: "paidSoFar" },
            { label: "Status", key: "status" }
          ]}
        />
        <PaymentPlanPrintView
          printRef={printRef}
          headerText={headerText}
          consolidatedPaymentPlansByStatus={consolidatedPaymentPlansByStatus}
          sortBys={sortBys}
          setSortBys={setSortBys}
        />
        <PaymentPlanTableDisplay
          title="Open"
          paymentPlans={active}
          sortBys={sortBys}
          status={"active"}
          setSortBys={setSortBys}
        />
        <PaymentPlanTableDisplay
          title="Completed"
          paymentPlans={complete}
          sortBys={sortBys}
          status={"complete"}
          setSortBys={setSortBys}
        />
        <PaymentPlanTableDisplay
          title="Canceled"
          paymentPlans={canceled}
          sortBys={sortBys}
          status={"canceled"}
          setSortBys={setSortBys}
        />
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default PaymentPlans;
