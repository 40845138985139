import * as React from "react";
import { GroupHeaderContainer, StyledSquare, TitleDiv } from "./styled";

interface GroupHeaderProps {
  title: string;
  color: string;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({ color, title }) => {
  return (
    <GroupHeaderContainer color={color}>
      <StyledSquare color={color} />
      <TitleDiv>{title}</TitleDiv>
    </GroupHeaderContainer>
  );
};

export default GroupHeader;
