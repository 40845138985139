import * as React from "react";
import { useLocationFromPath } from "../../hooks/useLocationFromPath";
import { StyledAddress, StyledHeader, StyledPanel, StyledText } from "./styled";
import { locationAddress } from "../LocationsListItem/utils";

export interface LocationHeaderTextProps {
  headerText: string;
  className?: string;
  bordered?: boolean;
}

const LocationHeaderText: React.FC<LocationHeaderTextProps> = ({
  headerText,
  className,
  bordered
}) => {
  const location = useLocationFromPath();

  return (
    <StyledPanel className={className} bordered={bordered}>
      <StyledHeader>{headerText}</StyledHeader>
      <StyledText>{location?.displayName}</StyledText>
      {location?.address && (
        <StyledAddress>{locationAddress(location?.address)}</StyledAddress>
      )}
    </StyledPanel>
  );
};

export default LocationHeaderText;
