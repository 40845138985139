import { Checkbox } from "@billfire/toybox";
import React from "react";
import { StyledStatementsMessage } from "./styled";

export interface SendStatementsCheckboxProps {
  checked: boolean;
  setSendEmptyStatements: (arg0: React.SetStateAction<boolean>) => void;
}

export const SendStatementsCheckbox: React.FC<SendStatementsCheckboxProps> = ({
  checked,
  setSendEmptyStatements
}) => {
  return (
    <StyledStatementsMessage>
      <Checkbox
        onChange={() => setSendEmptyStatements(!checked)}
        checked={checked}
      />
      <p>Send me statements when there are no open invoices</p>
    </StyledStatementsMessage>
  );
};

export default SendStatementsCheckbox;
