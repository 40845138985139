import React from "react";
import { Invoice } from "../../state/entities/invoices/types";
import { ReasonObject } from "./types";

export const useValidityEffect = (
  setFormIsValid: Function,
  selectedReason: ReasonObject,
  isNewAmountValid: Function,
  newAmount: number,
  invoice: Invoice
) => {
  React.useEffect(() => {
    setFormIsValid(
      selectedReason &&
        !!selectedReason.reason &&
        isNewAmountValid(newAmount, invoice)
    );
  });
};
