import * as React from "react";
import {
  DropDown,
  formatCurrency,
  formatCurrencyNoSymbol
} from "@billfire/toybox";
import {
  Click2PayPartialPayPanel,
  DropDownContainer,
  InnerPanelContainer,
  StyledTextInput,
  StyledTextInputContainer,
  FooterLabel
} from "./styled";
import { useDispatch } from "react-redux";
import {
  isNewAmountInputValid,
  isNewAmountValid,
  validateNewAmount
} from "./utils";
import { partialPayDisputeReasonArray as reasonArray } from "../../utils/reasons";
import { useState } from "react";
import DisputedInvoicesFormButtons from "../DisputedInvoicesFormButtons";
import { useParamHook } from "../../hooks/useParams";
import { useHistoryPush } from "../../hooks/useHistoryPush";
import { updateInvoice } from "../../utils";
import { useInvoiceFromPath } from "../../hooks/useInvoiceFromPath";
import { useValidityEffect } from "./hooks";
import PartialPayTotals from "./PartialPaySummary";
import currency from "currency.js";
import LocationHeaderText from "../LocationHeaderText";
import PartialDisputeInvoiceLink from "../PartialDisputeInvoiceLink";

const PanelDisplay = () => {
  const { token, vendorId, locationId } = useParamHook();
  const redirect = useHistoryPush();
  const invoice = useInvoiceFromPath();

  const path = `/token/${token}/vendors/${vendorId}/locations/${locationId}/click2pay`;

  const [newAmountIsDirty, setNewAmountDirtyBit] = useState(true);
  const [newAmountError, setNewAmountError] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedReason, setSelectedReason] = React.useState({
    reason: undefined
  });
  const [newAmount, setNewAmount] = useState(invoice.openAmount);
  const [formIsValid, setFormIsValid] = useState(false);

  const dispatch = useDispatch();
  useValidityEffect(
    setFormIsValid,
    selectedReason,
    isNewAmountValid,
    newAmount,
    invoice
  );

  const handleNewAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value;

    validateNewAmount(
      e,
      invoice,
      setNewAmount,
      setNewAmountDirtyBit,
      setNewAmountError
    );
    const curr = new currency(amount);
    setNewAmount(curr.value);
    setNewAmountDirtyBit(false);
  };

  const _setNotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const update = () =>
    updateInvoice(
      selectedReason.reason,
      notes,
      invoice,
      newAmount,
      dispatch,
      redirect,
      path
    );

  return (
    <form data-testid="partial-pay-form" onSubmit={update}>
      <Click2PayPartialPayPanel>
        <LocationHeaderText headerText="Partial Payment for" />
        <PartialDisputeInvoiceLink />
        <InnerPanelContainer>
          <StyledTextInputContainer>
            <StyledTextInput
              id={"partialPaymentAmount"}
              name={"newAmount"}
              label={"New Payment Amount*"}
              valid={isNewAmountInputValid(
                newAmountIsDirty,
                newAmount,
                invoice
              )}
              handleChange={handleNewAmount}
              validationErrMsg={newAmountError}
              format={formatCurrency}
              formatNoSymbol={formatCurrencyNoSymbol}
              initialValue={newAmount.toString()}
            />
          </StyledTextInputContainer>
          <DropDownContainer>
            <DropDown
              selected={selectedReason}
              setSelected={setSelectedReason}
              placeholder={"Choose your reason"}
              options={reasonArray}
              optionRowOrder={["reason"]}
              addNew={false}
              label={"Reason*"}
              highlight={true}
            />
          </DropDownContainer>
          <StyledTextInputContainer>
            <StyledTextInput
              id={"notesTextInput"}
              name={"notes"}
              label={"Notes"}
              valid={true}
              type={"text"}
              handleChange={_setNotes}
            />
          </StyledTextInputContainer>
          <PartialPayTotals invoice={invoice} newAmount={newAmount} />
          <DisputedInvoicesFormButtons
            disabled={!formIsValid}
            updateInvoice={update}
          />
          <FooterLabel>*Required</FooterLabel>
        </InnerPanelContainer>
      </Click2PayPartialPayPanel>
    </form>
  );
};

export default PanelDisplay;
