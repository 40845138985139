import { Panel } from "@billfire/toybox";
import { device } from "./../../breakpoints";
import styled from "styled-components";

export interface InvoiceListContainerProps {
  hasInvoices: boolean;
}

export const AddressRowContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media only screen and (max-width: 480px) {
    flex-flow: column;
    flex-direction: column-reverse;
    margin-bottom: 15px;
    > div:last-child {
      margin-bottom: 23px;
    }
  }
`;

export const PrintAndVendorLogoContainer = styled.div`
  @media print {
    padding-bottom: 0px;
    margin-top: -5px;
  }
`;

export const Container = styled.div`
  background-color: #f9f9f9;
  max-width: 850px;
  margin: auto;
`;

export const RemitContainer = styled(Panel)`
  padding: 35px 38.3px 49.5px 47.8px;
  border: solid 1px #eaf0f7;
  background-color: #fff;
  max-width: 824px;
  @media only screen and (max-width: 480px) {
    padding: 17px 14px;
  }
`;

export const InvoiceTable = styled.table.attrs({
  "data-testid": "invoiceTable"
})`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  &.invoices {
    @media only screen and (${device.tablet}) {
      min-width: 660px;
      margin-bottom: 20px;
    }
  }

  tr.tr-selected > td:first-child {
    background-color: inherit;
  }
  tr.tr-selected > td {
    background-color: #e6eef8;
  }

  tr.hidden-comments {
    display: none;
  }

  thead tr.no-invoices > th > div {
    margin-bottom: 15px;
  }

  thead tr > th {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
  }

  thead tr > th:last-child {
    text-align: right;
    padding-right: 7px;
  }

  tbody tr > td {
    vertical-align: top;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
  }
`;

export const InvoiceListContainer = styled.div<InvoiceListContainerProps>`
  margin-bottom: 38px;
  @media only screen and (${device.tablet}) {
    overflow-x: ${props => (props.hasInvoices ? `scroll` : `none`)};

    scrollbar-width: thin;
    scrollbar-color: rgba(169, 169, 169, 0.4);

    &::-webkit-scrollbar {
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(169, 169, 169, 0.8);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(169, 169, 169, 0.4);
    }
  }
`;

export const EmptyMessage = styled.div`
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #646678;
  padding-bottom: 37px;
`;

export const FormCommentsTextAreaContainer = styled.div`
  flex-grow: 1;
  margin-top: 83px;
  .styled-text-area-container {
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
  }
  .styled-text-area-count {
    margin-top: -16px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    position: absolute;
    right: 0;
  }
  .formComment {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
    ::placeholder {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #777;
    }
  }

  @media print {
    display: none;
  }
`;

export const FormCommentPrintArea = styled.div`
  margin-top: 20px;
  @media screen {
    display: none;
  }
`;

export const FormCommentPrintBox = styled.div`
  border-radius: 4px;
  border: solid 1px #c8c8c8;
  min-height: 103px;
  width: 728px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #777;
`;

export const FormCommentsLabel = styled.div`
  padding-bottom: 10px;
  font-size: 9.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #777;
`;
