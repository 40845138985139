// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "../../hooks/route";
import {
  getCurrentStatementDelivery,
  getStatementDeliveryCreatingFlags,
  getStatementDeliveryDeletingFlags
} from "../../state/entities/statementDelivery/selectors";
import {
  createStatementDeliverySubmit,
  createStatementDeliveryReset,
  deleteStatementDeliverySubmit
} from "../../state/entities/statementDelivery/slice";
import { StatementDelivery } from "../../state/entities/statementDelivery/types";
import { useModal, modalTypes } from "../../globalModal";
import { formatFrequency } from "./utils";
import { ModalSize } from "@billfire/toybox";

export const useStatementDeliverySubmit = () => {
  const dispatch = useDispatch();
  const { locationId } = useNavigation();
  const creatingFlags = useSelector(getStatementDeliveryCreatingFlags);

  const submitStatementDelivery = (statementDelivery: StatementDelivery) => {
    dispatch(
      createStatementDeliverySubmit({ ...statementDelivery, locationId })
    );
  };

  const resetCreateStatementDelivery = () => {
    dispatch(createStatementDeliveryReset());
  };

  return {
    submitStatementDelivery,
    resetCreateStatementDelivery,
    ...creatingFlags
  };
};

export const useDeleteStatementDeliverySubmit = () => {
  const dispatch = useDispatch();
  const deletingFlags = useSelector(getStatementDeliveryDeletingFlags);
  const currentDelivery = useSelector(getCurrentStatementDelivery);

  const submitDeleteStatementDelivery = () => {
    dispatch(deleteStatementDeliverySubmit(currentDelivery));
  };

  return { submitDeleteStatementDelivery, ...deletingFlags };
};

export const useUpdatedStatementListenerSuccess = (
  buttonTextOnClick: string,
  statementDelivery: StatementDelivery
) => {
  const { createdStatementDelivery } = useSelector(
    getStatementDeliveryCreatingFlags
  );
  const { showModal } = useModal();

  React.useEffect(() => {
    if (createdStatementDelivery && buttonTextOnClick === "Update") {
      showModal({
        type: modalTypes.BASIC_GRAY,
        props: {
          message: formatFrequency(statementDelivery, true),
          size: ModalSize.medium
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdStatementDelivery, buttonTextOnClick, statementDelivery]);
};
