import * as React from "react";
import {
  BlankCommentRowAmount,
  BlankCommentRowComments,
  CommentRow
} from "./styled";
import Comments from "./Comments";
import { InvoiceRowProps } from "./InvoiceRow";
import AmountInput from "./AmountInput";
import { useRemitForm } from "..";

const InvoiceMobileCommentRow: React.FC<InvoiceRowProps> = ({
  mobile,
  rowId
}) => {
  const { sortedInvoices } = useRemitForm();

  const invoice = sortedInvoices.find(row => row.rowId === rowId);

  if (!invoice) {
    return null;
  }

  return (
    <CommentRow
      {...{ mobile }}
      data-testid={`comment-input-${rowId}`}
      className={`shown-comments comments blank-row`}
    >
      <td>{rowId}.</td>
      <td>
        <BlankCommentRowComments {...{ mobile }}>
          <Comments rowId={rowId} />
        </BlankCommentRowComments>
        <BlankCommentRowAmount {...{ mobile }}>
          <AmountInput value={null} item={invoice} mobile={mobile} />
        </BlankCommentRowAmount>
      </td>
    </CommentRow>
  );
};

export default InvoiceMobileCommentRow;
