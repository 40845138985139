import * as React from "react";
import { StyledPanel, PanelName, PaymentPlanIcon, RefDiv } from "./styled";
import { useHover } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const PaymentPlanPanel: React.FC = () => {
  const { appendPath } = usePageNav();
  const [hoverRef, isHovered] = useHover();
  return (
    <RefDiv
      ref={hoverRef}
      data-testid="paymentplan-hover"
      onClick={() => appendPath("plans")}
    >
      <StyledPanel background={isHovered ? "#eaf0f7" : ""}>
        <PaymentPlanIcon fill={"#000"} data-testid="PaymentPlanIcon" />
        <PanelName>PAYMENT PLANS</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default PaymentPlanPanel;
