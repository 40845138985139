import * as React from "react";
import { BankIcon, CCIcon } from "./styled.components";
import { isACH } from "./utils";
import {
  DisplayedPaymentMethod,
  PaymentMethod
} from "../../../state/entities/paymentMethods/types";
export interface PaymentMethodIconProps {
  paymentMethod: PaymentMethod | DisplayedPaymentMethod;
}
const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = ({
  paymentMethod
}) => {
  return isACH(paymentMethod) ? (
    <BankIcon fill={"#6B778C"} />
  ) : (
    <CCIcon fill={"#6B778C"} />
  );
};

export default PaymentMethodIcon;
