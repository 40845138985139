import * as React from "react";
import { useEffect } from "react";
import { usePageNav } from "../../../hooks/navigation";
import CommonWrapper from "../../CommonWrapper";
import { useAutopaySelectors } from "../hooks";
import { ConfirmHeader, ConfirmationDetailsContainer } from "../styled";
import CheckIcon from "../../../assets/checked.svg";
import { Panel } from "@billfire/toybox";
import ExcludedInvoices from "./ExcludedInvoices";
import Details from "./Details";
import ReportPrintView from "../../PrintExportComponent/ReportPrintView";
import ConfirmationPagePrintExport from "../../ConfirmationPagePrintExport/ConfirmationPagePrintExport";
import { useFormatDocumentTitle } from "../../ConfirmationPagePrintExport/hooks";
import { ConfirmationLocationHeaderText } from "../../ConfirmationPagePrintExport/styled";

const AutopayConfirmation: React.FC = () => {
  const { selectedPaymentDate, selectedPaymentMethod } = useAutopaySelectors();
  const { locationPath } = usePageNav();

  useEffect(() => {
    if (selectedPaymentDate === null) {
      locationPath("autopaymanage");
    }
  }, [selectedPaymentDate, locationPath]);

  const printRef = React.useRef(null);
  const documentTitle = useFormatDocumentTitle();

  if (!selectedPaymentMethod || !selectedPaymentDate) {
    return null;
  }

  const headerText = "AutoPay set up for";

  const ConfirmationContent = (
    <div>
      <ConfirmHeader>
        <ConfirmationLocationHeaderText headerText={headerText} />
        <ConfirmationPagePrintExport
          printRef={printRef}
          documentTitle={documentTitle}
        />
        <CheckIcon />
      </ConfirmHeader>
      <ConfirmationDetailsContainer>
        <Details />
        <ExcludedInvoices />
      </ConfirmationDetailsContainer>
    </div>
  );

  return (
    <CommonWrapper>
      <Panel padding="0px 10px 10px 10px">
        <ReportPrintView printRef={printRef} headerText={headerText}>
          {ConfirmationContent}
        </ReportPrintView>
        {ConfirmationContent}
      </Panel>
    </CommonWrapper>
  );
};

export default AutopayConfirmation;
