import { Category, Message } from "../../state/entities/messages/types";

export interface MessageRow extends Message {
  rowId: string;
  category: Category;
}

enum MessageColumnName {
  invoiceDisplayInvNum = "invoiceDisplayInvNum",
  createdAt = "createdAt",
  category = "category"
}

export const isMessageColumnName = (
  colName?: string | null
): colName is MessageColumnName => {
  return Object.values(MessageColumnName).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    colName as unknown as MessageColumnName
  );
};
