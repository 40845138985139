import * as React from "react";

import Header from "./Header";
import { MobileAccordion } from "./styled";
import Content from "./Content";
import { InvoiceRow } from "../utils";

export interface MobileCellProps {
  invoice: InvoiceRow;
}

const MobileCell: React.FC<MobileCellProps> = ({ invoice }) => {
  return (
    <MobileAccordion
      title={<Header invoice={invoice} />}
      titleWeight="normal"
      fontSize={"18px"}
      contentPadding="0px 10px"
      hideBoxShadow={true}
      hideBorder={true}
      fused={false}
      content={<Content invoice={invoice} />}
    />
  );
};

export default MobileCell;
