import * as React from "react";
import { RemitInvoice, TableColumn } from "../types";

interface TableCellProps {
  column: TableColumn;
  item: RemitInvoice;
}

const InvoiceTableCell: React.FC<TableCellProps> = ({ column, item }) => {
  const { formatFn, name, Render, width } = column;

  // eslint-disable-next-line security/detect-object-injection
  let value = item[name];
  if (formatFn) {
    value = formatFn(value);
  }

  return (
    <td key={`cell-${item.rowId}-${name}`} width={width}>
      {Render ? (
        <Render
          key={`row-${item.rowId}-name-${name}`}
          value={value}
          item={item}
        />
      ) : (
        value
      )}
    </td>
  );
};

export default InvoiceTableCell;
