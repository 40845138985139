import { useSelector } from "react-redux";
import { getMessages } from "../../state/entities/messages/selectors";
import { SortDirection } from "@billfire/toybox";
import { mapAndSortResolutionCenterCSVData } from "./utils";

export const useResolutionCenterCSVData = (
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const resolutionCenterData = useSelector(getMessages);
  return mapAndSortResolutionCenterCSVData(
    resolutionCenterData,
    sortBy,
    sortDirection
  );
};
