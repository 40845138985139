// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import {
  formatCurrency,
  formatShortDate,
  sortDate,
  sortCurrency,
  sortString,
  SortDirection
} from "@billfire/toybox";
import InvoiceLink from "../InvoiceLink";
import { InvoiceRow, isInvoiceReportColumnName } from "./types";
import { Invoice } from "../../state/entities/invoices/types";
import { getStatus } from "../../state/entities/invoices/utils";
import { Vendor } from "../../state/entities/vendors/types";
import { featureEnabled } from "../../state/entities/shared/utils";
import { GlobalFeatureFlags } from "../../state/entities/shared/types";

const getExternalInvoiceDateValue = (value: string | null) =>
  value ? formatShortDate(value) : "-";

export const getColumns = (
  mobile: boolean,
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags
) => [
  {
    name: "displayInvNum",
    label: "Invoice",
    width: mobile ? "20%" : "",
    render: (value: string, item: InvoiceRow) => {
      return <InvoiceLink invoiceId={item.id} />;
    },
    sortFn: sortString
  },
  ...(featureEnabled(vendor, globalFeatureFlags, "displayExternalInvoiceDate")
    ? [
        {
          name: "externalInvoiceDate",
          label: "Invoice Date",
          render: (value: string | null) => {
            return <div>{getExternalInvoiceDateValue(value)}</div>;
          },
          mobile: false
        }
      ]
    : []),
  {
    name: "dueDate",
    label: "Due Date",
    formatFn: formatShortDate,
    sortFn: sortDate
  },
  {
    name: "openAmount",
    label: "Amount",
    formatFn: formatCurrency,
    sortFn: sortCurrency
  },
  {
    label: "Status",
    name: "status",
    width: mobile ? "" : "7%",
    sortFn: sortString
  }
];

export const mapInvoice = (invoices: Invoice[]): InvoiceRow[] => {
  return invoices.map(inv => ({
    ...inv,
    status: getStatus(inv),
    rowId: inv.id
  }));
};

const getInvoiceReportHeaders = (hasExternalInvoiceDate: boolean) => [
  { label: "Invoice", key: "displayInvNum" },
  ...(hasExternalInvoiceDate
    ? [{ label: "Invoice Date", key: "externalInvoiceDate" }]
    : []),
  { label: "Due Date", key: "dueDate" },
  { label: "Amount", key: "openAmount" },
  { label: "Status", key: "status" }
];

export interface InvoiceWithStatus extends Invoice {
  status: string;
}

const mapInvoicesFn = (
  inv: InvoiceWithStatus,
  hasExternalInvoiceDate: boolean
) => ({
  displayInvNum: inv.displayInvNum,
  ...(hasExternalInvoiceDate && {
    externalInvoiceDate: formatShortDate(inv.externalInvoiceDate)
  }),
  dueDate: formatShortDate(inv.dueDate),
  openAmount: formatCurrency(inv.openAmount),
  status: inv.status
});

export const mapAndSortInvoiceReportCSVData = (
  invoices: Invoice[],
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags,
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const columns = getColumns(false, vendor, globalFeatureFlags);
  const hasExternalInvoiceDate = !!columns.find(
    col => col.name === "externalInvoiceDate"
  );
  const foundColumn = columns.find(({ name }) => name === sortBy);
  const sortFn = foundColumn && foundColumn.sortFn;

  const csvData = invoices
    .map(inv => ({
      ...inv,
      status: getStatus(inv)
    }))
    .sort(
      (a, b) =>
        sortFn &&
        isInvoiceReportColumnName(sortBy) &&
        // eslint-disable-next-line security/detect-object-injection
        sortFn(a[sortBy], b[sortBy], sortDirection || "ASC")
    )
    .map(inv => mapInvoicesFn(inv, hasExternalInvoiceDate));
  return { csvData, headers: getInvoiceReportHeaders(hasExternalInvoiceDate) };
};
