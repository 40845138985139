import * as React from "react";
import { useFilteredInvoices, useInvoices } from "./hooks";
import PaymentButton from "./PaymentButton";
import InvoiceList from "./InvoiceList";
import SubtotalsView from "./SubtotalsView";
import TotalView from "./TotalView";
import { LocationStatementContainer } from "./styled";
import { showPayButton } from "./utils";

interface LocationStatementProps {
  headerText: string;
}

const LocationStatement: React.FC<LocationStatementProps> = ({
  headerText
}) => {
  const allInvoices = useInvoices();
  const { pastDue, current, credits, selectedOption, setSelectedOption } =
    useFilteredInvoices();
  const filteredInvoices = { pastDue, current, credits };

  return (
    <LocationStatementContainer>
      <InvoiceList
        {...{ headerText, filteredInvoices, selectedOption, setSelectedOption }}
      />
      <SubtotalsView {...{ filteredInvoices }} />
      <TotalView {...{ filteredInvoices }} />
      {showPayButton(allInvoices) ? <PaymentButton /> : null}
    </LocationStatementContainer>
  );
};

export default LocationStatement;
