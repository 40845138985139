import * as React from "react";
import { StyledPanel, PanelName, RemindersIcon, RefDiv } from "./styled";
import { useHover, useClearPersisted } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const RemindersPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const { appendPath } = usePageNav();
  const clearPersisted = useClearPersisted();
  return (
    <RefDiv
      onClick={() => {
        clearPersisted();
        appendPath(`reminders`);
      }}
      ref={hoverRef}
      data-testid="reminders-hover"
    >
      <StyledPanel background={isHovered ? "#efe9db" : ""}>
        <RemindersIcon fill={"#000"} data-testid="RemindersIcon" />
        <PanelName>REMINDERS</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default RemindersPanel;
