import currency from "currency.js";
import { Message, Category } from "./types";

export const calculateCategory = (message: Message): Category => {
  if (message.subject === "Payment Failed") {
    return "Payment Failed";
  } else if (message.subject === "Comment") {
    return "Comment";
  } else if (currency(message.paymentPaidAmount).value === 0.0) {
    return "Disputed";
  }

  return "Partially Paid";
};
