// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView, {
  ReportPrintViewProps
} from "../PrintExportComponent/ReportPrintView";
import { InvoicePanelDivider, StyledDataTable } from "./styled";
import { formatCurrency, SortDirection } from "@billfire/toybox";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import InvoicePanelDetailRow from "./InvoicePanelDetailRow";
import { formatDueDate, getStatus } from "../../state/entities/invoices/utils";
import { Invoice } from "../../state/entities/invoices/types";
import { DataTableJourneyRecord } from "./types";

interface InvoiceJourneyPrintViewProps extends ReportPrintViewProps {
  journeys: Array<DataTableJourneyRecord>;
  columns: Column[];
  sortBy: string;
  sortDirection: SortDirection;
  invoice: Invoice;
}

export const InvoiceJourneyPrintView: React.FC<
  InvoiceJourneyPrintViewProps
> = ({
  printRef,
  headerText,
  journeys,
  columns,
  sortBy,
  sortDirection,
  invoice
}) => {
  const { displayInvNum, dueDate, openAmount } = invoice;
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        <InvoicePanelDetailRow title={"Invoice"} detail={displayInvNum} />
        <InvoicePanelDivider />
        <InvoicePanelDetailRow
          title={"Due Date"}
          detail={formatDueDate(dueDate)}
        />
        <InvoicePanelDivider />
        <InvoicePanelDetailRow
          title={"Open Amount"}
          detail={formatCurrency(openAmount)}
        />
        <InvoicePanelDivider />
        <InvoicePanelDetailRow title={"Status"} detail={getStatus(invoice)} />
        <InvoicePanelDivider />
        <StyledDataTable
          sortable
          initialData={journeys}
          columns={columns}
          externalSortBy={sortBy}
          externalSortDirection={sortDirection}
        />
      </ReportPrintView>
    </>
  );
};

export default InvoiceJourneyPrintView;
