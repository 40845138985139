import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "../../globalModal";
import {
  selectCampaignContactId,
  selectCampaignCreatingFlags,
  selectCampaignDeletingFlags,
  selectCampaigns
} from "../../state/entities/campaigns/selectors";
import {
  createCampaignSubmit,
  deleteCampaignSubmit,
  loadCampaignsSubmit,
  resetCampaignsState
} from "../../state/entities/campaigns/slice";
import { getSelectedLocationId } from "../../state/entities/locations/selectors";
import {
  CREATE_OPTIONS,
  getEventDay,
  isDupe,
  validateRadioInput
} from "./utils";

export const useCreateReminderSubmit = () => {
  const dispatch = useDispatch();

  const submitCreateCampaign = (eventDay: number, contactId: string) => {
    dispatch(createCampaignSubmit({ eventDay, contactId }));
  };

  const creatingFlags = useSelector(selectCampaignCreatingFlags);
  return { submitCreateCampaign, ...creatingFlags };
};

export const useDeleteReminderSubmit = () => {
  const dispatch = useDispatch();

  const submitDeleteCampaign = (campaignId: string) => {
    dispatch(deleteCampaignSubmit(campaignId));
  };

  const deletingFlags = useSelector(selectCampaignDeletingFlags);
  return { submitDeleteCampaign, ...deletingFlags };
};

export const useSetReminderModal = (
  setCreateReminder: React.Dispatch<React.SetStateAction<boolean>>,
  createdCampaign: boolean
) => {
  const dispatch = useDispatch();
  const { hideModal } = useModal();
  const campaigns = useSelector(selectCampaigns);
  const contactId = useSelector(selectCampaignContactId);
  const locationId = useSelector(getSelectedLocationId);
  const [optionValue, setOptionValue] = useState(CREATE_OPTIONS[0].value);
  const [value, setValue] = useState("");
  const [dupeFormValues, setDupeFormValues] = useState({});
  const [createFailed, setCreateFailed] = useState(false);
  const [createFailedMsg, setCreateFailedMsg] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [validationErrMsg, setValidationErrMsg] = useState("");

  useEffect(() => {
    if (createFailed === true) {
      setDupeFormValues({ value, optionValue });
    } else {
      setCreateFailedMsg("Could not add a reminder. Please refresh the page.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFailed]);

  useEffect(() => {
    if (value === "dueDate") {
      setIsValid(true);
      setValidationErrMsg("");
    } else {
      const { valid, errorMessage } = validateRadioInput(value);
      setIsValid(valid);
      setValidationErrMsg(errorMessage);
    }
  }, [value]);

  useEffect(() => {
    const newFormValues = { value, optionValue };
    if (isDupe(locationId, getEventDay(optionValue, value), campaigns)) {
      setFormIsValid(false);
      setCreateFailed(true);
      setCreateFailedMsg("You already have a reminder set for that day.");
    } else if (
      JSON.stringify(dupeFormValues) !== JSON.stringify(newFormValues)
    ) {
      setFormIsValid(!!value && isValid);
    } else {
      setFormIsValid(false);
      setCreateFailed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isValid]);

  const closeModal = () => {
    hideModal();
    dispatch(resetCampaignsState());
    setCreateReminder(false);
  };

  React.useEffect(() => {
    if (createdCampaign) {
      closeModal();
      dispatch(loadCampaignsSubmit());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCampaign, dispatch]);

  return {
    closeModal,
    contactId,
    optionValue,
    setOptionValue,
    value,
    setValue,
    createFailedMsg,
    createFailed,
    isValid,
    formIsValid,
    setCreateFailed,
    validationErrMsg
  };
};
