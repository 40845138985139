/* eslint-disable security/detect-object-injection */
import { ById, SaveAction } from "./types";
import { PersistableAction } from "./types";
import { matchPath } from "react-router";
import routes from "../../../routes";

export const hasActionHistory = (
  byId: ById,
  token: string,
  selectedVendorId: string,
  selectedLocationId: string
) => {
  return (
    byId[token] &&
    byId[token][selectedVendorId] &&
    byId[token][selectedVendorId][selectedLocationId]
  );
};

export const createSaveAction = (
  action: PersistableAction,
  selectedVendorId: string,
  selectedLocationId: string,
  token: string
): SaveAction => {
  return {
    token,
    selectedVendorId,
    selectedLocationId,
    saveAction: action
  };
};

const replayableRoutes = [
  "/token/:token/vendors/:vendorId/locations/:locationId/click2pay"
];

export const isOnReplayablePage = () => {
  const route = routes.find(r => matchPath(window.location.pathname, r));
  return route && replayableRoutes.includes(route.path) ? true : false;
};

export const isOnAutopaySetupPage = (): boolean => {
  const autopayRoutes = [
    "/token/:token/vendors/:vendorId/locations/:locationId/autopay/:requestId",
    "/token/:token/vendors/:vendorId/locations/:locationId/autopay"
  ];

  const route = routes.find(r => matchPath(window.location.pathname, r));
  return !!(route && autopayRoutes.includes(route.path));
};

export const isOnAutopayManagePage = (): boolean => {
  const autopayManageRoutes = [
    "/token/:token/vendors/:vendorId/locations/:locationId/autopaymanage"
  ];

  const route = routes.find(r => matchPath(window.location.pathname, r));
  return !!(route && autopayManageRoutes.includes(route.path));
};
