import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { TokenType } from "./types";
interface APIHeaders {
  token?: string;
  withCreds?: boolean;
  accept?: string;
}

interface AjaxHeaders {
  token?: string;
  accept?: string;
}

type APIResponse = AjaxResponse | AjaxError | Response;

export default class Api {
  static debounce = (func: Function, wait: number) => {
    let timeout: number;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(func, wait);
    };
  };

  static extractErrorCode(response: APIResponse) {
    if (response instanceof AjaxResponse || response instanceof AjaxError) {
      return response.response.errorCode;
    } else {
      return response.json().then(json => json.errorCode);
    }
  }

  static extractResponse(response: APIResponse) {
    if (response instanceof AjaxResponse || response instanceof AjaxError) {
      return response.response;
    } else {
      return response;
    }
  }

  static checkResponse(
    response: Response,
    message?: string | undefined,
    path?: string
  ) {
    if (!response.ok) {
      throw new Error(message || "failed calling api on path " + path);
    } else return response;
  }

  static handleUnAuth(res: APIResponse, token: string) {
    if (res && (res.status === 401 || res.status === 403)) {
      Api.debounce(async () => {
        window.sessionStorage && window.sessionStorage.clear();
        localStorage.removeItem("persist:root");
        window.location.href = `/token/${token}/error/${
          (await Api.extractErrorCode(res)) || "invalid"
        }`;
      }, 100)();
    }
    return res;
  }

  static handleAJAXResponse(res: APIResponse, token: string) {
    res = Api.handleUnAuth(res, token);
    return res ? Api.extractResponse(res) : {};
  }

  static handleResponse(res: APIResponse, token: string) {
    res = Api.handleUnAuth(res, token);
    return res ? Api.extractResponse(res) : {};
  }

  static API_URL = (path: string): string => {
    return `https://${process.env.API_DOMAIN}${path}`;
  };

  static PAYMENTS_API_URL = (path: string): string => {
    return `https://${process.env.API_DOMAIN_PAYMENTS}${path}`;
  };

  static getAJAXHeaders(token = "", tokenType = TokenType.ContactAccess) {
    const returned: HeadersInit = { "Content-Type": "application/json" };

    returned.Authorization = `${tokenType} ${token}`;

    return returned;
  }

  static getHeaders({
    token = "",
    withCreds = true,
    accept = "application/json"
  }: APIHeaders): Record<string, string> {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      Accept: accept,
      ...(withCreds && {
        Authorization: `ContactAccess ${token}`
      })
    };

    return headers;
  }

  static GET = (
    token: string,
    withCreds = true,
    accept = "application/json"
  ): RequestInit => ({
    method: "GET",
    mode: "cors",
    headers: Api.getHeaders({ token, withCreds, accept })
  });

  static postWithoutCreds(path: string, body: object = {}): Promise<unknown> {
    const POST: RequestInit = {
      method: "POST",
      mode: "cors",
      headers: Api.getHeaders({ withCreds: false }),
      body: JSON.stringify(body)
    };

    return fetch(`https://${process.env.API_DOMAIN}${path}`, POST).then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("failed calling api");
      }
    });
  }

  static getWithToken(path: string, token: string) {
    const GET: RequestInit = {
      method: "GET",
      mode: "cors",
      cache: "no-store",
      headers: Api.getHeaders({ token, withCreds: true })
    };
    return fetch(Api.API_URL(path), GET)
      .then(res => Api.handleResponse(res, token))
      .then(res => Api.checkResponse(res, undefined, path))
      .then(res => res.json())
      .catch(e => {
        throw e;
      });
  }

  static postWithToken(path: string, body: object, token: string) {
    return fetch(Api.API_URL(path), {
      method: "POST",
      mode: "cors",
      headers: Api.getHeaders({ token, withCreds: true }),
      body: JSON.stringify(body)
    })
      .then(res => Api.handleResponse(res, token))
      .then(res => Api.checkResponse(res, undefined, path))
      .then(res => res.json())
      .catch(e => {
        throw e;
      });
  }

  static patchWithToken(
    path: string,
    body: object,
    token: string,
    signal?: AbortSignal
  ) {
    return fetch(Api.API_URL(path), {
      method: "PATCH",
      mode: "cors",
      headers: Api.getHeaders({ token, withCreds: true }),
      body: JSON.stringify(body),
      signal: signal ? signal : undefined
    })
      .then(res => Api.handleResponse(res, token))
      .then(res => Api.checkResponse(res, undefined, path))
      .then(res => res.json())
      .catch(e => {
        throw e;
      });
  }

  static deleteWithToken(path: string, token: string, signal?: AbortSignal) {
    return fetch(Api.API_URL(path), {
      method: "DELETE",
      mode: "cors",
      headers: Api.getHeaders({ token, withCreds: true }),
      signal: signal ? signal : undefined
    })
      .then(res => Api.handleResponse(res, token))
      .then(res => Api.checkResponse(res, undefined, path))
      .then(res => res.json())
      .catch(e => {
        throw e;
      });
  }
}
