import styled from "styled-components";

interface StyledAnnouncementProps {
  type: string;
}

export const StyledAnnouncement = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  color: white;
  background-color: ${(props: StyledAnnouncementProps) =>
    props.type === "error" ? "#d0021b" : "#4a90e2"};
`;

export const DismissAnnouncement = styled.span`
  cursor: pointer;
  position: absolute;
  right: 15px;
`;
