import styled from "styled-components";
import Export from "../../../assets/export.svg";
import { CSVLink } from "react-csv";

export const ExportIcon = styled(Export).attrs({
  "data-testid": "csv-export-icon"
})`
  align-self: center;
`;

export const StyledCSVLink = styled(CSVLink).attrs({
  "data-testid": "styled-csv-link"
})`
  display: flex;
  text-decoration: none;
`;

export const ExportText = styled.div`
  margin: 0 0 0 7px;
  font-size: 14px;
  align-self: center;
  color: #4990e2;
`;
