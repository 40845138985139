import * as React from "react";
import { Checkbox } from "@billfire/toybox";

export interface InvoiceCheckboxProps {
  checked: boolean;
  ariaLabel: string;
  handleClick: () => void;
  selectedItems: object;
}

const InvoiceCheckbox: React.FC<InvoiceCheckboxProps> = ({
  checked,
  ariaLabel,
  handleClick
}) => {
  return (
    <Checkbox checked={checked} onChange={handleClick} ariaLabel={ariaLabel} />
  );
};

export default InvoiceCheckbox;
