import currency from "currency.js";
import {
  DisputedInvoiceById,
  Invoice,
  DisputedInvoice,
  PartialPayment
} from "./types";
import { formatShortDate } from "@billfire/toybox";
import { Invoice as InvoiceHelper } from "@billfire/payment-lib-base-v2";
import { Vendor } from "../vendors/types";

export const getInvoiceTotal = (
  invoices: Invoice[],
  disputedInvoices: DisputedInvoiceById
): number => {
  return invoices.reduce((tot, inv) => {
    const disputed = disputedInvoices.byId[inv.id];
    if (disputed) return currency(tot).add(disputed.newAmount).value;
    return currency(tot).add(inv.openAmount).value;
  }, 0.0);
};

export const isCredit = (invoice: Invoice): boolean => {
  return currency(invoice.openAmount).value < 0.0;
};

export const isPastDue = (invoice: Invoice, vendor: Vendor): boolean => {
  const invoiceHelper = new InvoiceHelper(vendor);
  return invoiceHelper.isPastDue(invoice);
};

export const getStatus = (invoice: Invoice): string =>
  invoice.deletedAt ? "Closed" : invoice.payable ? "Open" : "Scheduled";

export const formatDueDate = (date: unknown) =>
  date ? formatShortDate(date) : "-";

export const disputedInvoiceToPartialPayment = (
  inv: DisputedInvoice
): PartialPayment => {
  const { id: invoiceId, newAmount: amount, reason, notes } = inv;
  return { invoiceId, amount: `${amount}`, reason, notes };
};

export const filterOpenInvoices = (byId: Record<string, Invoice>) => {
  return Object.values(byId).filter(inv => inv.payable);
};

export const filterSelectedPayableInvoices = (
  byId: Record<string, Invoice>,
  selectedInvoiceIds: string[]
) => {
  const newOpenInvoicesIds = filterOpenInvoices(byId).map(inv => inv.id);
  return selectedInvoiceIds.filter(selectedId =>
    newOpenInvoicesIds.includes(selectedId)
  );
};

export const filterDisputedInvoices = (
  byId: Record<string, DisputedInvoice>,
  selectedInvoiceIds: string[]
) => {
  const disputedEntries = Object.entries(byId);

  const filteredEntries = disputedEntries.filter(
    (invoiceEntry: [string, Invoice]) =>
      selectedInvoiceIds.includes(invoiceEntry[0])
  );

  return {
    byId: filteredEntries.reduce(
      (acc: Record<string, DisputedInvoice>, curr) => {
        acc[curr[0]] = curr[1];
        return acc;
      },
      {}
    )
  };
};
