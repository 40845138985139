import styled from "styled-components";
import { device } from "../../breakpoints";

export const ListContainer = styled.div.attrs({
  "data-testid": "list-container"
})`
  /* justify-content: flex-start;
  align-items: center;
  margin: 0 auto; */
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
`;

export const StyledH2 = styled.h2`
  color: #272a43;
  align-self: center;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 35px;
  font-size: 31px;
  @media only screen and (${device.tablet}) {
    font-size: 27px;
  }
`;
