import styled from "styled-components";
import { Button } from "@billfire/toybox";
import { device } from "../../breakpoints";

export const DisplayContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
  padding: 0 35px 0px 35px;

  @media only screen and (${device.tablet}) {
    padding: 0 12px 0 12px;
  }
`;

export const StyledButton = styled(Button)`
  width: 332px;
  margin: auto;

  @media only screen and (${device.tablet}) {
    width: 100%;
  }
`;

export const SubmitContainer = styled.div`
  margin: 34px auto 0 auto;
`;

export const TermsConditionsContainer = styled.div`
  width: 50%;
  margin: 0 auto 0 auto;
  display: flex;
  justify-content: center;

  @media only screen and (${device.tablet}) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 15px 0;
`;

export const ButtonHeaderText = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  width: 75%;
  margin: 0 auto 30px auto;

  @media only screen and (${device.tablet}) {
    width: 100%;
    text-align: left;
    margin: 0 auto 20px auto;
  }
`;

export const StyledAccordionTitle = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: left;
  color: #777;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff0000;
  text-align: center;
  height: 10px;
`;
