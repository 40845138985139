import styled from "styled-components";
import styles, { Panel, Button, DataTable } from "@billfire/toybox";
import { device } from "../../breakpoints";

const {
  colors: { baseGray7 }
} = styles;

export const Container = styled.div`
  padding: 0px 15px 15px 15px;
`;

export const ErrorContainer = styled.div`
  padding: 40px 26px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
`;

export const InvoiceDetailRow = styled.div`
  display: flex;
  padding: 15px 0px;
  flex-direction: row;
  justify-content: space-between;
`;

export const InvoiceDetailTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${baseGray7};
`;

export const InvoiceDetail = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4a4a4a;
`;

export const InvoicePanelDivider = styled.div`
  border-top: solid 1.8px #e4e4e4;
  opacity: 0.7;
`;

export const StyledButton = styled(Button)`
  width: 50%;
  @media only screen and (${device.tablet}) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0 15px 0;
`;

export const StyledPanel = styled(Panel)`
  margin-top: 25px;
  border: none;
`;

export const ActionContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100px;
`;

export const RowContainer = styled.div`
  width: 120%;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;

  @media screen and (max-width: 415px) and (min-width: 360px) {
    width: 116%;
  }

  @media screen and (max-width: 539px) and (min-width: 416px) {
    width: 111%;
  }

  @media screen and (min-width: 540px) {
    width: 106%;
  }

  @media print {
    width: 107%;
  }
`;

export const RowOne = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RowTwo = styled.div`
  margin-top: 20px;
  padding: 5px 0;
`;
export const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;
`;

export const DetailContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
`;

export const StyledDataTable = styled(DataTable)`
  width: 100%;
  margin: auto;
  table {
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid rgb(200, 200, 200, 0.5);
  }

  th {
    padding: 10px 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${baseGray7};
  }

  & th:nth-child(1) {
    padding-left: 10px;
  }

  & th:nth-child(2) {
    text-align: right;
  }

  @media print {
    margin: 20px auto;
  }
`;
