import styled from "styled-components";
import styles, { DataTable } from "@billfire/toybox";

const { colors } = styles;
const { baseGray2, baseGray3 } = colors;

export const ModalTableContainer = styled.div.attrs({
  "data-testid": "modal-table-container"
})`
  max-width: 600px;
  padding: 0 20px;

  @media screen and (max-width: 575px) {
    padding: 0 10px;
  }
`;

export const StyledP = styled.p`
  color: ${baseGray2};
  margin-bottom: 20px;

  :last-child {
    color: ${baseGray3};
    margin-bottom: 0;
  }
`;

export const StyledDataTable = styled(DataTable).attrs({
  "data-testid": "data-table"
})`
  th {
    color: ${baseGray3};
  }
  td {
    color: ${baseGray2};
    padding: 10px 0;
  }
`;
