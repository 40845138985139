import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getLocationArray,
  getSelectedLocationId
} from "../state/entities/locations/selectors";
import {
  getVendorArray,
  getSelectedVendorId
} from "../state/entities/vendors/selectors";
import { useRouteInfo } from "./route";
import { useHistoryPush } from "./useHistoryPush";
import * as utils from "../utils";

interface UsePageNav {
  appendPath: (path: string) => void;
  replacePath: (path: string) => void;
  locationPath: (path: string) => void;
  getReplacementPathString: (path: string) => string;
  getLocationReplacementString: (path: string) => string;
}

export const usePageNav = (): UsePageNav => {
  const history = useHistory();

  const getReplacementPathString = (newPath: string): string =>
    utils.replacePath(history.location.pathname, newPath);

  const appendPath = (path: string): void => {
    history.push(`${history.location.pathname}/${path}`);
  };

  const replacePath = (newPath: string): void =>
    history.push(getReplacementPathString(newPath));

  const locationPath = (newPath: string): void => {
    history.push(utils.locationPath(history.location.pathname, newPath));
  };

  const getLocationReplacementString = (newPath: string): string => {
    return utils.locationPath(history.location.pathname, newPath);
  };

  return {
    appendPath,
    replacePath,
    locationPath,
    getReplacementPathString,
    getLocationReplacementString
  };
};

const useHookInfo = () => {
  const history = useHistory();
  const vendorCount = useSelector(getVendorArray).length;
  const locationCount = useSelector(getLocationArray).length;
  const locationSelected = !!useSelector(getSelectedLocationId);
  const selectedVendorId = useSelector(getSelectedVendorId);
  const selectedLocationId = useSelector(getSelectedLocationId);
  const { token }: { token: string } = useParams();
  const push = useHistoryPush();

  return {
    history,
    vendorCount,
    locationCount,
    locationSelected,
    selectedLocationId,
    selectedVendorId,
    token,
    push
  };
};

export const useNavbarDisplay = () => {
  const routeInfo = useRouteInfo();
  const hookInfo = useHookInfo();

  return {
    shouldDisplayNavbar: routeInfo.isNavigatableRoute,
    hookInfo,
    routeInfo
  };
};
