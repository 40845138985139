import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { invoiceTypes, invoiceTypes as types } from "./index";
import { filterSelectedPayableInvoices, filterDisputedInvoices } from "./utils";

export const initialState: types.InvoicesState = {
  byId: {},
  disputedInvoices: {
    byId: {}
  },
  selectedInvoiceIds: [],
  loadingInvoices: false,
  loadedInvoices: false,
  loadInvoicesFailed: false,
  showAllInvoices: false,
  locationInvoiceFilters: []
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    loadInvoicesSubmit: (
      state: types.InvoicesState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        loadAll: boolean;
        type: string;
        invoiceId: string;
      }>
    ) => {
      state.loadingInvoices = true;
      state.loadedInvoices = false;
      state.loadInvoicesFailed = false;
    },
    loadShipTosSubmit: (state: types.InvoicesState) => {
      state.loadingInvoices = true;
      state.loadedInvoices = false;
      state.loadInvoicesFailed = false;
    },
    loadInvoicesSuccess: (
      state: types.InvoicesState,
      action: PayloadAction<invoiceTypes.MappedInvoiceResponse>
    ) => {
      state.loadingInvoices = false;
      state.loadedInvoices = true;
      state.loadInvoicesFailed = false;
      state.showAllInvoices = action.payload.requestInvoiceIds.length === 0;
      state.byId = action.payload.byId;
      state.selectedInvoiceIds = state.selectedInvoiceIds.length
        ? filterSelectedPayableInvoices(
            action.payload.byId,
            state.selectedInvoiceIds
          )
        : action.payload.requestInvoiceIds;
    },
    loadShipTosSuccess: (
      state: types.InvoicesState,
      action: PayloadAction<invoiceTypes.LocationInvoiceFilters>
    ) => {
      state.loadingInvoices = false;
      state.loadedInvoices = true;
      state.loadInvoicesFailed = false;
      state.loadInvoicesFailed = false;
      state.locationInvoiceFilters =
        action.payload.locationInvoiceFilters?.filter(
          location =>
            location.filterName !== null || location.filterValue !== null
        ) || [];
    },
    loadInvoicesFailed: (state: types.InvoicesState) => {
      state.loadingInvoices = false;
      state.loadedInvoices = false;
      state.loadInvoicesFailed = true;
    },
    setShowAllInvoices: (
      state: types.InvoicesState,
      action: PayloadAction<boolean>
    ) => {
      state.showAllInvoices = action.payload;
    },
    setInvoiceSelected: (
      state: types.InvoicesState,
      action: PayloadAction<{ invoiceId: string; selected: boolean }>
    ) => {
      if (action.payload.selected) {
        state.selectedInvoiceIds.push(action.payload.invoiceId);
      } else {
        state.selectedInvoiceIds = state.selectedInvoiceIds.filter(
          invId => invId !== action.payload.invoiceId
        );
      }
    },
    setSelectedInvoiceIds: (
      state: types.InvoicesState,
      action: PayloadAction<{ selectedInvoiceIds: string[] }>
    ) => {
      state.selectedInvoiceIds = action.payload.selectedInvoiceIds;
      state.disputedInvoices = filterDisputedInvoices(
        state.disputedInvoices.byId,
        action.payload.selectedInvoiceIds
      );
    },
    setDispute: (
      state: types.InvoicesState,
      action: PayloadAction<types.SetDispute>
    ) => {
      state.disputedInvoices = {
        byId: {
          ...state.disputedInvoices.byId,
          [action.payload.disputedInvoice.id]: action.payload.disputedInvoice
        }
      };
      state.selectedInvoiceIds.push(action.payload.disputedInvoice.id);
    },
    undoDispute: (
      state: types.InvoicesState,
      action: PayloadAction<types.UndoDispute>
    ) => {
      delete state.disputedInvoices.byId[action.payload.disputedInvoice.id];
    },
    undoInvoiceReplayedActions: (state: types.InvoicesState) => {
      state.disputedInvoices = {
        byId: {}
      };
    },
    resetStore: () => initialState
  }
});

export const {
  loadInvoicesSubmit,
  loadShipTosSubmit,
  loadInvoicesSuccess,
  loadShipTosSuccess,
  loadInvoicesFailed,
  setShowAllInvoices,
  setInvoiceSelected,
  setSelectedInvoiceIds,
  setDispute,
  undoDispute,
  undoInvoiceReplayedActions,
  resetStore
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
