import { Accordion } from "@billfire/toybox";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import * as React from "react";
import { PaymentPlan } from "../../../state/entities/paymentPlans/types";
import { formatFrequency } from "../../../state/entities/paymentPlans/utils";
import {
  PaymentPlanScheduleDetailContainer,
  PaymentPlanScheduleDetailSecondaryTitle,
  PaymentPlanScheduleDetailTitle,
  StyledDataTable,
  TableContainer
} from "../styled";
import { Schedule } from "../types";

interface PaymentPlanScheduleDetailProps {
  scheduleColumns: Column[];
  schedule: Schedule;
  paymentPlan: PaymentPlan;
  mobile: boolean;
}

export const PaymentPlanScheduleDetail: React.FC<
  PaymentPlanScheduleDetailProps
> = ({ scheduleColumns, schedule, paymentPlan, mobile }) => {
  return (
    <PaymentPlanScheduleDetailContainer key="payment-plan-schedule">
      <Accordion
        fused={true}
        title={
          <PaymentPlanScheduleDetailTitle>
            Payment Schedule
          </PaymentPlanScheduleDetailTitle>
        }
        secondaryTitle={
          <PaymentPlanScheduleDetailSecondaryTitle>
            {formatFrequency(paymentPlan.frequency)}
          </PaymentPlanScheduleDetailSecondaryTitle>
        }
        titleColor="#777"
        fontSize="15px"
        hideBoxShadow={true}
        headingPadding={mobile ? "15px 0px" : "15px 30px 15px 0px"}
        contentPadding="0px 0px 5px 0px"
      >
        <TableContainer>
          <StyledDataTable
            columns={scheduleColumns}
            initialData={schedule}
            mobileMQ="0px"
          />
        </TableContainer>
      </Accordion>
    </PaymentPlanScheduleDetailContainer>
  );
};
