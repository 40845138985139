import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import useLoadAutopay from "../../hooks/useLoadAutopay";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { usePaymentMethodsLoading } from "../../hooks/usePaymentMethodsLoading";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import {
  getActiveAutopay,
  isAutopayActive
} from "../../state/entities/autopay/selectors";
import { getSelectedPaymentMethod } from "../../state/entities/paymentMethods/selectors";
import { setSelectedPaymentMethod } from "../../state/entities/paymentMethods/slice";
import { getSelectedPaymentDate } from "../../state/entities/requests/selectors";
import { setSelectedPaymentDate } from "../../state/entities/requests/slice";
import { StoreType } from "../../state/types";
import { getISODate } from "../../utils";
import { getDeleteLoadingState, getLoadingState } from "./utils";

const useAutopayState = () => {
  return useSelector((state: StoreType) => {
    const selectedPaymentMethod = getSelectedPaymentMethod(state, true);

    const selectedPaymentDate = getSelectedPaymentDate(state);
    return { selectedPaymentDate, selectedPaymentMethod };
  });
};

const useResetDateEffect = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setSelectedPaymentDate(getISODate(new Date())));
      dispatch(
        setSelectedPaymentMethod({
          paymentMethodId: ""
        })
      );
    };
  }, [dispatch]);
};

const useSelectPaymentMethodAndDate = () => {
  const { selectedPaymentDate, selectedPaymentMethod } = useAutopayState();
  const dispatch = useDispatch();
  const activeAutopay = useSelector(getActiveAutopay);

  useResetDateEffect();

  useEffect(() => {
    if (!selectedPaymentDate && activeAutopay?.startDate) {
      dispatch(setSelectedPaymentDate(activeAutopay?.startDate));
    }

    if (!selectedPaymentMethod && activeAutopay?.paymentMethodId) {
      dispatch(
        setSelectedPaymentMethod({
          paymentMethodId: activeAutopay?.paymentMethodId
        })
      );
    }
  }, [dispatch, selectedPaymentDate, selectedPaymentMethod, activeAutopay]);

  return { loading: !selectedPaymentDate || !selectedPaymentMethod };
};

export const useConfirmationPageData = () => {
  const apActive = useSelector(isAutopayActive);

  const invoiceLoadingState = useInvoicesLoader({
    type: "autopay"
  });
  const paymentMethodsLoading = usePaymentMethodsLoading();

  const vendorLoadingState = useVendorsLoader();
  const autopayLoadingState = useLoadAutopay();
  const locationLoadingState = useLocationsLoader();

  const pmAndDateLoadingState = useSelectPaymentMethodAndDate();

  const loadingState = getLoadingState({
    invoiceLoadingState,
    paymentMethodsLoading,
    vendorLoadingState,
    autopayLoadingState,
    locationLoadingState,
    pmAndDateLoadingState,
    apActive
  });

  return { loadingState };
};

export const useDeleteConfirmationPageData = () => {
  const { state } = useLocation<{ deleted: boolean }>();

  const apActive = useSelector(isAutopayActive);
  const vendorLoadingState = useVendorsLoader();
  const locationLoadingState = useLocationsLoader();
  const autopayLoadingState = useLoadAutopay();

  const loadingState = getDeleteLoadingState({
    vendorLoadingState,
    locationLoadingState,
    apActive: apActive && !state?.deleted,
    autopayLoadingState
  });

  return { loadingState };
};
