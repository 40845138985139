import styled from "styled-components";
import { Accordion } from "@billfire/toybox";
import styles from "@billfire/toybox";
const {
  colors: { baseGray7 }
} = styles;
import { device } from "../../breakpoints";

export const StatementContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
  padding: 0px 35px 25px 35px;

  @media only screen and (${device.tablet}) {
    padding: 0px 10px 25px 10px;
  }
`;

export const StyledH2 = styled.h2`
  color: #272a43;
  align-self: center;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 35px;
  font-size: 31px;

  @media only screen and (${device.tablet}) {
    font-size: 27px;
  }
`;

export const StatementTitle = styled.div`
  height: 33px;
  margin: 24px 10px 3px 10px;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
`;

export const StatementSubTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #a4a4a4;
  margin-top: -12px;
`;

export const FooterText = styled.div`
  margin: 28px 0 40px 0;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: ${baseGray7};
`;

export const MessageText = styled.div`
  margin: 70px 0 20px 0;
  font-size: 27px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #646678;
`;

export const StyledAccordion = styled(Accordion)`
  &.statement-delivery {
    margin-top: 11px;
    .accordion-header[aria-expanded="true"] {
      border-bottom: solid 1px #eaf0f7;
    }
    .accordion-content {
    }
  }
`;
