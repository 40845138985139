// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { PaymentPlanHelper } from "@billfire/payment-lib-base-v2";
import { formatCurrency } from "@billfire/toybox";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { getGlobalHolidays } from "../../state/entities/shared/selectors";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";
import { PaymentScheduleItem } from "./types";
import { StoreType } from "../../state/types";
import { useParams } from "react-router-dom";

export const usePaymentPlanSchedule = () => {
  const { planId } = useParams<{ planId: string }>();
  const vendor = useSelector(getSelectedVendor);
  const holidays = useSelector(getGlobalHolidays);

  const paymentPlanHelper = new PaymentPlanHelper(vendor, holidays);
  const paymentPlan = useSelector(
    // eslint-disable-next-line security/detect-object-injection
    (state: StoreType) => state.paymentPlans.byId[planId]
  );

  const _getPaymentStatus = (findDate: string) => {
    const found = paymentPlan.paymentGroups.find(a => {
      const one = DateTime.fromFormat(a.paymentDate, "yyyy-MM-dd");
      const two = DateTime.fromFormat(findDate, "M/d/yy");

      return one.equals(two);
    });
    let displayStatus;
    switch (found?.status) {
      case "sent":
        displayStatus = "Closed";
        break;
      case "scheduled":
        displayStatus = "Scheduled";
        break;
      default:
        displayStatus = "Open";
    }

    return displayStatus;
  };

  const schedule = paymentPlanHelper
    .getPaymentPlanSchedule(paymentPlan)
    .map((a: PaymentScheduleItem, i: number) => {
      const paymentDate = DateTime.fromISO(a.paymentDate.toString()).toFormat(
        "M/d/yy"
      );
      return {
        ...a,
        paymentAmount: formatCurrency(a.paymentAmount),
        paymentDate,
        status: _getPaymentStatus(paymentDate),
        rowId: `schedule-${i}`
      };
    });

  return { schedule, paymentPlanHelper, paymentPlan };
};
