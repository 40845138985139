import FeatureFlagRules from "@billfire/feature-flag-rules";
import { Vendor } from "../vendors/types";
import { GlobalFeatureFlags, FeatureFlag } from "./types";

export const featureEnabled = (
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags,
  featureFlag: FeatureFlag
) => {
  const checker = new FeatureFlagRules(globalFeatureFlags);

  return checker.checkHasOneFeature(vendor, [featureFlag]);
};
