import * as React from "react";
import AdContainer from "./AdContainer";
import { useAds } from "./hooks";
import { AdsDiv } from "./styled.components";

const Ads: React.FC = () => {
  const { ad1, ad2, displayAds, adClick } = useAds();

  if (!displayAds) {
    return null;
  }

  const ads = [ad1, ad2]
    .filter(ad => ad.fileId !== "")
    .sort(a => (a.props.offScreen ? 1 : -1));

  return (
    <AdsDiv>
      {ads.map((ad, index) => (
        <AdContainer
          key={ad.fileId}
          id={`${index + 1}`}
          ad={ad}
          adClick={adClick}
        />
      ))}
    </AdsDiv>
  );
};

export default Ads;
