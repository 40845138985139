import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../state/entities/paymentMethods/selectors";
import { getOpenInvoices } from "../../state/entities/invoices/selectors";
import {
  getActiveAutopay,
  getExcludedInvoices
} from "../../state/entities/autopay/selectors";
import { excludeInvoices as requestExcludeInvoices } from "../../state/entities/requests/slice";
import { excludeAutopayInvoices } from "../../state/entities/autopay/slice";
import {
  getRequestExcludedInvoices,
  getSelectedPaymentDate,
  getAutopayRequest
} from "../../state/entities/requests/selectors";
import { StoreType } from "../../state/types";
import { AutopaySetupPayload, createAutopay, getValidDates } from "./utils";
import { DateTime } from "luxon";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getAuthToken, getEpa } from "../../state/entities/shared/selectors";
import { getAutopayConfig } from "../../state/entities/vendors/selectors";
import { modalTypes, useModal } from "../../globalModal";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";

export const useSetExcludedInvoices = (setup: boolean) => {
  const dispatch = useDispatch();
  const invoices = useSelector(getOpenInvoices);

  return (newSelectedInvoices: Record<string, boolean>) => {
    const selectedEntries: [string, boolean][] =
      Object.entries(newSelectedInvoices);

    const selectedIndexs = selectedEntries
      .filter((ent: [string, boolean]) => ent[1])
      .map((ent: [string, boolean]) => Number(ent[0]) - 1);
    // eslint-disable-next-line security/detect-object-injection
    const newExcludedIds = selectedIndexs.map((i: number) => invoices[i].id);
    dispatch(
      setup
        ? requestExcludeInvoices(newExcludedIds)
        : excludeAutopayInvoices(newExcludedIds)
    );
  };
};

export const useCustomerManageAutopay = () => {
  const featureEnabled = useFeatureFlag("customerManageAutopay");
  const request = useSelector(getAutopayRequest);
  const autopay = useSelector(getActiveAutopay);

  if (!featureEnabled) {
    return true;
  }

  if (autopay?.id) {
    return autopay.customerManage;
  } else if (request) {
    return request?.customerManage;
  }

  return true;
};

export const useExcludedInvoiceSelectors = (setup: boolean) => {
  const invoices = useSelector(getOpenInvoices);
  const excludedInvoiceIds = useSelector(
    setup ? getRequestExcludedInvoices : getExcludedInvoices
  );

  const readOnly = !useCustomerManageAutopay();
  const title = `Excluded Invoices${readOnly ? "" : " (Optional)"}`;

  return {
    invoices,
    excludedInvoiceIds,
    readOnly,
    title
  };
};

export const useAutopaySelectors = () => {
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state)
  );
  const provider = useSelector(getSelectedPaymentMethodProvider);
  const selectedPaymentDate = useSelector(getSelectedPaymentDate);
  return { selectedPaymentMethod, selectedPaymentDate, provider };
};

export const useActiveAutopay = () => {
  return useSelector((state: StoreType) => getActiveAutopay(state));
};

const useFeeModal = () => {
  const { showModal, hideModal } = useModal();
  const { selectedPaymentMethod } = useAutopaySelectors();

  const showFeeModal = (
    submit: (feeConfirmed: boolean) => void,
    onCancel: () => void
  ) => {
    if (selectedPaymentMethod?.displayExtra.serviceFee) {
      showModal({
        type: modalTypes.CHANGE_PAYMENT_METHOD_CONFIRM_MODAL,
        props: {
          title: "Set up AutoPay Plus?",
          submitButtonText: "Set Up",
          submit,
          onCancel
        }
      });
      return true;
    }
    return false;
  };

  return { showFeeModal, hideFeeModal: hideModal };
};

export const useSubmit = () => {
  const [submitting, setSubmitting] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [failed, setFailed] = useState(false);
  const { showFeeModal, hideFeeModal } = useFeeModal();

  const { vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
  }>();

  const { authToken } = useSelector((state: StoreType) => ({
    authToken: getAuthToken(state)
  }));

  const pathname = `/token/${authToken}/vendors/${vendorId}/locations/${locationId}/autopayconfirmation`;

  const submit = (payload: AutopaySetupPayload, feeConfirmed: boolean) => {
    setSubmitting(true);
    setFailed(false);

    const feeSubmit = () => {
      submit(payload, true);
    };

    const feeCanceled = () => {
      setSubmitting(false);
      hideFeeModal();
    };

    if (!feeConfirmed && showFeeModal(feeSubmit, feeCanceled)) {
      return;
    }

    return createAutopay(authToken, vendorId, locationId, payload)
      .then(() => {
        hideFeeModal();
        setSubmitting(false);
        setConfirmed(true);
      })
      .catch(() => {
        hideFeeModal();
        setSubmitting(false);
        setFailed(true);
      });
  };

  return { submit, submitting, confirmed, failed, pathname };
};

export const useAutopaySubmitData = (): AutopaySetupPayload => {
  const { selectedPaymentDate, selectedPaymentMethod, provider } =
    useAutopaySelectors();
  const { epaVersion, excludedInvoices } = useSelector((state: StoreType) => ({
    epaVersion: getEpa(state, provider).version,
    excludedInvoices: getRequestExcludedInvoices(state)
  }));

  const selectedStartDate =
    selectedPaymentDate === null
      ? selectedPaymentDate
      : DateTime.fromFormat(selectedPaymentDate, "yyyy-MM-dd");

  return {
    paymentMethodId: selectedPaymentMethod ? selectedPaymentMethod.id : "",
    startDate: selectedStartDate,
    excludedInvoices,
    epaVersion
  };
};

const useValidSelectedPaymentMethod = () => {
  const { selectedPaymentMethod } = useAutopaySelectors();

  return (
    selectedPaymentMethod &&
    selectedPaymentMethod.id &&
    !selectedPaymentMethod.displayExtra.verificationRequired
  );
};

export const useAutopayForm = () => {
  const submitData = useAutopaySubmitData();
  const submitInfo = useSubmit();
  const [epaChecked, setEpaChecked] = useState(false);
  const autopayConfigDays = useSelector(getAutopayConfig);
  const validDates = getValidDates(autopayConfigDays);
  const paymentMethodValid = useValidSelectedPaymentMethod();

  const isFormValid =
    !!submitData.startDate && epaChecked && paymentMethodValid;

  const paymentMethodIdStr = String(submitData.paymentMethodId);

  const submitForm = () => {
    if (isFormValid) {
      localStorage.setItem("lastPaymentMethodId", paymentMethodIdStr);
      return submitInfo.submit(submitData, false);
    }
  };

  return {
    ...submitInfo,
    validDates,
    selectedStartDate: submitData.startDate,
    submitForm,
    isFormValid,
    epaChecked,
    setEpaChecked
  };
};
