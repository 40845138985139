import { ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax, AjaxError } from "rxjs/ajax";
import { catchError, map, switchMap } from "rxjs/operators";

import { AnyAction, PayloadAction } from "@reduxjs/toolkit";

import api from "../../api";
import { StoreType } from "../../types";
import { getSelectedLocationId } from "../locations/selectors";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import {
  createCampaignFailed,
  createCampaignSubmit,
  createCampaignSuccess,
  deleteCampaignFailed,
  deleteCampaignSubmit,
  deleteCampaignSuccess,
  loadCampaignsFailed,
  loadCampaignsSubmit,
  loadCampaignsSuccess
} from "./slice";
import { Campaign } from "./types";

export const mappedResponse = (
  campaigns: Campaign[]
): Record<string, Campaign> => {
  return campaigns.reduce((byId: Record<string, Campaign>, campaign) => {
    byId[campaign.id] = campaign;
    return byId;
  }, {});
};

export const fetchCampaignsEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadCampaignsSubmit.type),
    switchMap(() => {
      const vendorId = getSelectedVendorId(state$.value);
      const locationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(`/vendors/${vendorId}/locations/${locationId}/campaigns`),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            const campaigns = resp.campaigns;
            const contactId = resp.contactId;
            const deliverTo = resp.deliverTo;
            const mapped = mappedResponse(campaigns);
            return loadCampaignsSuccess({
              campaigns: mapped,
              contactId,
              deliverTo
            });
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadCampaignsFailed());
          })
        );
    })
  );

export const createCampaignsEpic = (
  action$: Observable<PayloadAction<{ eventDay: number; contactId: string }>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(createCampaignSubmit.type),
    switchMap(({ payload }) => {
      const vendorId = getSelectedVendorId(state$.value);
      const locationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);

      return ajax
        .post(
          api.API_URL(`/vendors/${vendorId}/locations/${locationId}/campaigns`),
          {
            eventDay: payload.eventDay,
            contactId: payload.contactId
          },
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return createCampaignSuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(createCampaignFailed());
          })
        );
    })
  );

export const deleteCampaignsEpic = (
  action$: Observable<PayloadAction<Campaign["id"]>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(deleteCampaignSubmit.type),
    switchMap(({ payload }) => {
      const vendorId = getSelectedVendorId(state$.value);
      const locationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      const campaignId = payload;

      return ajax
        .delete(
          api.API_URL(
            `/vendors/${vendorId}/locations/${locationId}/campaigns/${campaignId}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            api.handleAJAXResponse(response, token);
            return deleteCampaignSuccess(campaignId);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(deleteCampaignFailed());
          })
        );
    })
  );
