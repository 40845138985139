import * as React from "react";
import { StyledPanel, PanelName, RemitIcon, RefDiv } from "./styled";
import { useHover, useClearPersisted } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const RemitPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const { appendPath } = usePageNav();
  const clearPersisted = useClearPersisted();
  return (
    <RefDiv
      onClick={() => {
        clearPersisted();
        appendPath(`remit`);
      }}
      ref={hoverRef}
      data-testid="remit-hover"
    >
      <StyledPanel background={isHovered ? "#efe9db" : ""}>
        <RemitIcon fill={"#000"} data-testid="RemitIcon" />
        <PanelName>REMIT FORM</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default RemitPanel;
