import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersistedState, SaveAction, ResetCurrentTokenAction } from "./types";
import { getReplayActions } from "./selectors";

export const initialState: PersistedState = {
  byId: {},
  replaying: false,
  hasEverDispatchedSavedActions: false
};

export const persistedSlice = createSlice({
  name: "persisted",
  initialState,
  reducers: {
    saveAction: (state, action: PayloadAction<SaveAction>) => {
      const {
        payload: { token, selectedVendorId, selectedLocationId, saveAction }
      } = action;
      const newHistory = [
        ...getReplayActions(state, token, selectedVendorId, selectedLocationId),
        {
          ...saveAction,
          replayType: saveAction.replayType
        }
      ];
      state.byId = {
        ...state.byId,
        [token]: {
          [selectedVendorId]: {
            [selectedLocationId]: {
              actionHistory: newHistory
            }
          }
        }
      };
    },
    startReplay: state => {
      state.replaying = true;
      state.hasEverDispatchedSavedActions = true;
    },
    stopReplay: state => {
      state.replaying = false;
    },
    resetPersistedTokenState: (
      state,
      action: PayloadAction<ResetCurrentTokenAction>
    ) => {
      delete state.byId[action.payload.token];
    },
    resetStore: () => initialState
  }
});

export const {
  saveAction,
  startReplay,
  stopReplay,
  resetPersistedTokenState,
  resetStore
} = persistedSlice.actions;

export default persistedSlice.reducer;
