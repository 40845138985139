import * as React from "react";
import { TableHeaderCell } from "@billfire/toybox";
import { TableColumn } from "./types";

interface HeaderCellProps {
  column: TableColumn;
}

const HeaderCell: React.FC<HeaderCellProps> = ({ column }) => {
  const { name, width, label, className = "" } = column;

  return (
    <TableHeaderCell
      key={name}
      width={width}
      label={label}
      sortableColumn={false}
      activeSort={false}
      sortDirection={"ASC"}
      handleSortClick={(): void => {
        // do nothing
      }}
      className={className}
    />
  );
};

export default HeaderCell;
