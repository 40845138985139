import * as React from "react";
import { ErrorContainer, ErrorText } from "./styled";

const Empty: React.FC = () => {
  return (
    <ErrorContainer>
      <ErrorText>There are no messages to display.</ErrorText>
    </ErrorContainer>
  );
};

export default Empty;
