import * as React from "react";
import { Vendor } from "../../state/entities/vendors/types";
import { StyledDiv, VendorLogo } from "./VendorTitle.styled";

export const useVendorTitleInfo = (
  vendor: Vendor,
  logoPadding: string | undefined,
  small: boolean
) =>
  React.useMemo(() => {
    return (
      !!vendor && (
        <StyledDiv small={small}>
          {vendor?.config?.logoUrl ? (
            <VendorLogo
              src={vendor.config.logoUrl}
              alt={vendor.name}
              logoPadding={logoPadding}
              small={small}
            />
          ) : (
            <>{vendor.name}</>
          )}
        </StyledDiv>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor, logoPadding]);
