import * as React from "react";
import { Checkbox } from "@billfire/toybox";
import SelectAllCheckBox from "./SelectAllCheckBox";

interface InvoiceCheckBoxProps {
  checked: boolean;
  ariaLabel: string;
  handleClick: () => void;
  id?: string;
}

const InvoiceCheckBox: React.FC<InvoiceCheckBoxProps> = ({
  checked,
  ariaLabel,
  handleClick,
  id
}) => {
  if (id === undefined) {
    return <SelectAllCheckBox />;
  }

  return (
    <Checkbox checked={checked} onChange={handleClick} ariaLabel={ariaLabel} />
  );
};

export default InvoiceCheckBox;
