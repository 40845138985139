import * as React from "react";
import { StyledPanel, StyledDataTable } from "./styled";
import { invoiceJourneyTableColumns } from "./utils";
import { DataTableJourneyRecord } from "./types";
import { SortDirection } from "@billfire/toybox";

export interface InvoiceJourneyTableProps {
  journeys: Array<DataTableJourneyRecord>;
  sortDirection?: SortDirection;
  setSortDirection?: React.Dispatch<React.SetStateAction<SortDirection>>;
  sortBy?: string;
  setSortBy?: React.Dispatch<React.SetStateAction<string>>;
}

const InvoiceJourneyTable: React.FC<InvoiceJourneyTableProps> = ({
  journeys,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection
}) => {
  return (
    <StyledPanel>
      <StyledDataTable
        sortable
        initialData={journeys}
        columns={invoiceJourneyTableColumns()}
        externalSortBy={sortBy}
        externalSetSortBy={setSortBy}
        externalSortDirection={sortDirection}
        setExternalSortDirection={setSortDirection}
      />
    </StyledPanel>
  );
};

export default InvoiceJourneyTable;
