import styled from "styled-components";
import Colors from "@billfire/toybox";
import { device } from "../../breakpoints";

export const TermsContainer = styled.div.attrs({
  "data-testid": "terms-container"
})`
  max-width: 256px;
  margin: auto;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  span {
    padding-left: 10px;
    a {
      color: ${Colors.colors.baseBlue0};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media only screen and (${device.tablet}) {
    margin: 0px;
  }
`;
