import { Frequency } from "../shared/types";

type Status = "sent" | "viewed" | "complete" | "canceled";

export interface Request {
  id: string;
  type: string;
  locationId: string;
  contactId: string;
  status: Status;
  paymentPlanName: string;
  totalAmount: string;
  frequency: Frequency;
  eachAmount: string;
  startDate: string;
  customerManage: boolean;
  excludedInvoiceIds?: Array<string>;
}

export const getEmptyRequest = (): Request => ({
  id: "",
  type: "",
  locationId: "",
  contactId: "",
  status: "sent",
  paymentPlanName: "",
  totalAmount: "",
  frequency: Frequency.WEEKLY,
  eachAmount: "",
  startDate: "",
  customerManage: true
});

export interface TokenInfo {
  id: string;
  type: string;
  locationId: string;
  contactId: string;
  status: Status;
  vendorId: string;
}

export const getEmptyTokenInfo = (): TokenInfo => ({
  id: "",
  type: "",
  locationId: "",
  contactId: "",
  status: "sent",
  vendorId: ""
});

export interface RequestState {
  byId: Record<string, Request>;
  requestInvoiceIds: string[];
  excludedInvoiceIds: string[];
  tokenInfo: TokenInfo;
  hasCalledUpdateStatementRequest: boolean;
}
export interface LoadingRequestFlags {
  loadingRequest: boolean;
  loadedRequest: boolean;
  loadRequestFailed: boolean;
}

export interface LoadingTokenFlags {
  loadingTokenInfo: boolean;
  loadedTokenInfo: boolean;
  loadTokenInfoFailed: boolean;
  selectedPaymentDate: string | null;
}

export interface RequestState extends LoadingRequestFlags, LoadingTokenFlags {
  byId: Record<string, Request>;
  requestInvoiceIds: string[];
  tokenInfo: TokenInfo;
}
