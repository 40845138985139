import { useSelector } from "react-redux";
import {
  getPaymentMethods,
  getSelectedPaymentMethod
} from "../../../state/entities/paymentMethods/selectors";
import { PaymentMethod } from "../../../state/entities/paymentMethods/types";
import { StoreType } from "../../../state/types";

interface PaymentMethodTitleProps {
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod?: PaymentMethod;
  verificationRequired?: boolean;
}

export const usePaymentMethodTitle = (
  onlyUseSelectedPmFromRedux: boolean
): PaymentMethodTitleProps => {
  const paymentMethods = useSelector((state: StoreType) =>
    getPaymentMethods(state)
  );

  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, onlyUseSelectedPmFromRedux)
  );

  return {
    paymentMethods,
    selectedPaymentMethod,
    verificationRequired:
      selectedPaymentMethod?.displayExtra.verificationRequired
  };
};
