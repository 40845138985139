// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { useParamHook } from "../../hooks/useParams";
import { StyledText, Container, InvoiceLinkContainer } from "./styled";
import InvoiceLink from "../InvoiceLink";

const PartialDisputeInvoiceLink: React.FC = () => {
  const { invoiceId } = useParamHook();
  return (
    <Container>
      <StyledText>Invoice</StyledText>
      <InvoiceLinkContainer>
        <InvoiceLink invoiceId={invoiceId} minEllipsesWidth={200} />
      </InvoiceLinkContainer>
    </Container>
  );
};

export default PartialDisputeInvoiceLink;
