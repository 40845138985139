import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { useNavigation } from "./route";
import { getAdsLoadingFlags } from "../state/entities/ads/selectors";
import { loadAdsSubmit } from "../state/entities/ads/slice";

export const useAdsLoader = () => {
  const dispatch = useDispatch();
  const { loadingAds, loadAdsFailed } = useSelector((state: StoreType) =>
    getAdsLoadingFlags(state)
  );
  const { vendorId } = useNavigation();

  React.useEffect(() => {
    if (vendorId) {
      dispatch(loadAdsSubmit());
    }
  }, [dispatch, vendorId]);

  return { loadingAds, loadAdsFailed };
};
