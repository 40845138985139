import * as React from "react";
import InvoicesReport from "../../components/InvoicesReport";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";

const InvoicesReportPage: React.FC = () => {
  const { loadingInvoices } = useInvoicesLoader({ loadAll: true });
  const { locationsLoading } = useLocationsLoader();
  const { loadingVendors } = useVendorsLoader();

  return loadingInvoices || locationsLoading || loadingVendors ? (
    <SkeletonLoader />
  ) : (
    <InvoicesReport />
  );
};

export default InvoicesReportPage;
