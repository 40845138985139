import styled from "styled-components";

export interface Props {
  fill?: string;
  height?: string;
  width?: string;
}

export const StyledSvg = styled.svg<Props>`
  display: inline-block;
  fill: ${props => props.fill || "#212121"};
  height: ${props => props.height};
  line-height: 1;
  vertical-align: middle;
  width: ${props => props.width};
`;
