import * as React from "react";
import { SubtotalContainer, SubtotalRow, TotalContainer } from "./styled";
import {
  InvoiceRow,
  formatTotal,
  multipleCategoriesWithInvoices
} from "./utils";

interface SubtotalsViewProps {
  filteredInvoices: {
    pastDue: InvoiceRow[];
    current: InvoiceRow[];
    credits: InvoiceRow[];
  };
}

const SubtotalsView: React.FC<SubtotalsViewProps> = ({ filteredInvoices }) => {
  const { pastDue, current, credits } = filteredInvoices;
  return (
    <>
      {multipleCategoriesWithInvoices(filteredInvoices) ? (
        <TotalContainer>
          <SubtotalContainer>
            {pastDue.length > 0 && (
              <SubtotalRow>
                <div>Past Due Subtotal</div>
                <div>{formatTotal([...pastDue])}</div>
              </SubtotalRow>
            )}
            {current.length > 0 && (
              <SubtotalRow>
                <div>Current Subtotal</div>
                <div>{formatTotal([...current])}</div>
              </SubtotalRow>
            )}
            {credits.length > 0 && (
              <SubtotalRow>
                <div>Credit Subtotal</div>
                <div>{formatTotal([...credits])}</div>
              </SubtotalRow>
            )}
          </SubtotalContainer>
        </TotalContainer>
      ) : null}
    </>
  );
};

export default SubtotalsView;
