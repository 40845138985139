// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView from "../PrintExportComponent/ReportPrintView";
import { ResolutionCenterDataTable } from "./styled";
import { getColumns, mapMessageRows } from "./utils";
import { ReportPrintViewProps } from "../PrintExportComponent/ReportPrintView";
import { Message } from "../../state/entities/messages/types";
import { SortDirection } from "@billfire/toybox";

interface ResolutionCenterPrintViewProps extends ReportPrintViewProps {
  getReplacementPathString: (path: string) => string;
  messages: Message[];
  sortDirection: SortDirection;
  externalSortBy: string;
}

const ResolutionCenterPrintView: React.FC<ResolutionCenterPrintViewProps> = ({
  printRef,
  headerText,
  getReplacementPathString,
  messages,
  sortDirection,
  externalSortBy
}) => {
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        <ResolutionCenterDataTable
          columns={getColumns(getReplacementPathString, false)}
          initialData={mapMessageRows(messages)}
          externalSortDirection={sortDirection}
          externalSortBy={externalSortBy}
        />
      </ReportPrintView>
    </>
  );
};

export default ResolutionCenterPrintView;
