import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import InvoiceJourney from "../../components/InvoiceJourney";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useInvoiceJourneyLoader } from "./hooks";
import InvoiceJourneyFailed from "../../components/InvoiceJourney/InvoiceJourneyFailed";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";

const InvoiceJourneyPage: React.FC = () => {
  const { loadingVendors, loadedVendors } = useVendorsLoader();
  const { locationsLoaded, locationsLoading } = useLocationsLoader();
  const { journeyLoading, journeyLoadFailed, invoiceJourney } =
    useInvoiceJourneyLoader();

  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoading(
      !loadedVendors ||
        !locationsLoaded ||
        loadingVendors ||
        locationsLoading ||
        journeyLoading
    );
  }, [
    loadingVendors,
    locationsLoading,
    loadedVendors,
    locationsLoaded,
    journeyLoading
  ]);

  return loading ? (
    <SkeletonLoader />
  ) : journeyLoadFailed ? (
    <InvoiceJourneyFailed />
  ) : (
    <InvoiceJourney invoiceJourney={invoiceJourney} />
  );
};

export default InvoiceJourneyPage;
