import * as React from "react";
import { usePaymentPlansLoader } from "../../hooks/usePaymentPlansLoader";
import PaymentPlans from "../../components/PaymentPlans";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useParamHook } from "../../hooks/useParams";
import { useHistory } from "react-router-dom";

const PaymentPlansPage: React.FC = () => {
  const { paymentPlansLoadingState, paymentPlanCount } =
    usePaymentPlansLoader();
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const { token, vendorId, locationId } = useParamHook();
  const history = useHistory();

  if (paymentPlansLoadingState.loadedPaymentPlans && paymentPlanCount <= 0) {
    history.push(`/token/${token}/vendors/${vendorId}/locations/${locationId}`);
  }

  return paymentPlansLoadingState.loadingPaymentPlans ||
    loadingVendors ||
    locationsLoading ? (
    <SkeletonLoader />
  ) : (
    <PaymentPlans />
  );
};

export default PaymentPlansPage;
