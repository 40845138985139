import * as React from "react";
import { useForm } from "./useForm";
import { useState } from "react";
import { DropDown } from "@billfire/toybox";
import {
  Click2PayDisputePanel,
  DropDownContainer,
  InnerPanelContainer,
  StyledText,
  StyledTextInput,
  StyledTextInputContainer
} from "./styled";
import { useDispatch } from "react-redux";
import { partialPayDisputeReasonArray as reasonArray } from "../../utils/reasons";
import { useParamHook } from "../../hooks/useParams";
import { useHistoryPush } from "../../hooks/useHistoryPush";
import DisputedInvoicesFormButtons from "../DisputedInvoicesFormButtons";
import { updateInvoice } from "../../utils";
import { useInvoiceFromPath } from "../../hooks/useInvoiceFromPath";
import LocationHeaderText from "../LocationHeaderText";
import PartialDisputeInvoiceLink from "../PartialDisputeInvoiceLink";

const PanelDisplay = () => {
  const { token, vendorId, locationId } = useParamHook();
  const redirect = useHistoryPush();

  const path = `/token/${token}/vendors/${vendorId}/locations/${locationId}/click2pay`;

  const [selected, setSelected] = useState({
    reason: undefined
  });
  const { form, setValue } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e);
  };
  const invoice = useInvoiceFromPath();
  const dispatch = useDispatch();

  const update = () =>
    updateInvoice(
      selected.reason,
      form.notes.value,
      invoice,
      0,
      dispatch,
      redirect,
      path
    );

  return (
    <form data-testid="dispute-form" onSubmit={update}>
      <Click2PayDisputePanel>
        <LocationHeaderText headerText="Dispute an Invoice for" />
        <PartialDisputeInvoiceLink />
        <StyledText>
          A dispute will result in no payment being made on this invoice.
        </StyledText>
        <InnerPanelContainer>
          <DropDownContainer>
            <DropDown
              selected={selected}
              setSelected={setSelected}
              placeholder={"Choose your reason"}
              options={reasonArray}
              optionRowOrder={["reason"]}
              addNew={false}
              label={"Reason"}
              highlight={true}
            />
          </DropDownContainer>
          <StyledTextInputContainer>
            <StyledTextInput
              id={"notesTextInput"}
              name={"notes"}
              label={"Notes (Optional)"}
              valid={true}
              type={"text"}
              handleChange={handleChange}
            />
          </StyledTextInputContainer>
          <DisputedInvoicesFormButtons
            updateInvoice={update}
            disabled={!selected.reason}
          />
        </InnerPanelContainer>
      </Click2PayDisputePanel>
    </form>
  );
};

export default PanelDisplay;
