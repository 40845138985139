/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Modal, ModalType, ModalSize, TextArea } from "@billfire/toybox";
import { useModal } from "../../../globalModal";
import { useState } from "react";
import { AddInvoiceCommentContainer } from "./styled";

export interface AddInvoiceCommentModalProps {
  handleSubmit: (comment: string) => void;
  submitFailed: boolean;
  submitting: boolean;
}

const AddInvoiceCommentModal: React.FC<AddInvoiceCommentModalProps> = ({
  handleSubmit,
  submitFailed,
  submitting
}) => {
  const { hideModal } = useModal();
  const [comment, setComment] = useState("");

  const validComment = comment.length !== 0;

  const handleSubmitModal = () => {
    if (validComment) {
      handleSubmit(comment);
    }
  };

  return (
    <Modal
      title="Add Comment"
      type={ModalType.form}
      size={ModalSize.small}
      onClose={hideModal}
      loading={submitting}
      errored={submitFailed}
      errorMessage="Could not add comment."
      onSubmit={handleSubmitModal}
      submitButtonText="Submit"
      disableSubmit={!validComment}
    >
      <AddInvoiceCommentContainer>
        <TextArea
          id="comment"
          name="comment"
          className="addInvoiceComment"
          initialValue={comment}
          label="Please share details"
          autoFocus={true}
          maxLength={150}
          rows={5}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setComment(e.target.value)
          }
          allowNewLines={false}
        />
      </AddInvoiceCommentContainer>
    </Modal>
  );
};

export default AddInvoiceCommentModal;
