import { Skeleton } from "@billfire/toybox";
import * as React from "react";
import { createContext, useRef } from "react";
import Address from "./Address";
import CheckNumber from "./CheckNumber/CheckNumber";
import Print from "./Print";
import { useRemitInvoices } from "./hooks";
import InvoicesList from "./InvoicesList";
import PrintView from "./Printing/PrintView";
import RemitWrapper from "./RemitWrapper";
import { RemitContainer, AddressRowContainer } from "./styled";
import Totals from "./InvoiceTotals/Totals";
import BlankTotals from "./BlankTotals/Totals";
import { RemitFormInfo, defaultContext, isRemitInvoice } from "./types";
import FormComments from "./FormComments";
import VendorTitle from "../VendorTitle";
import { useMobile } from "../../hooks/useMobile";

export const RemitFormContext = createContext<RemitFormInfo>(defaultContext);
export const useRemitForm = () => React.useContext(RemitFormContext);

const RemitForm: React.FC = () => {
  const printRef = useRef(null);
  const remitForm = useRemitInvoices();

  const { remitInvoicesSet, sortedInvoices } = remitForm;

  const { mobile } = useMobile();

  if (!remitInvoicesSet) {
    return <Skeleton />;
  }

  const hasInvoices = sortedInvoices.some(inv => isRemitInvoice(inv));

  const UseTotals = hasInvoices ? Totals : BlankTotals;

  return (
    <RemitFormContext.Provider value={remitForm}>
      <RemitWrapper>
        <RemitContainer>
          <AddressRowContainer>
            <Address />
            <Print printRef={printRef} />
          </AddressRowContainer>
          <AddressRowContainer>
            <Address remit />
            <VendorTitle
              hidePanel={true}
              logoPadding="0px 15px 0px 15px"
              small={true}
            />
          </AddressRowContainer>
          <CheckNumber />
          <InvoicesList />
          <UseTotals sortedInvoices={remitForm.sortedInvoices} />
          {!mobile && <FormComments />}
        </RemitContainer>
      </RemitWrapper>
      <PrintView printRef={printRef} />
    </RemitFormContext.Provider>
  );
};

export default RemitForm;
