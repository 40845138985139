import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { SharedState } from "../state/entities/shared/types";
import { getShared } from "../state/entities/shared/selectors";
import { loadGlobalHolidaysSubmit } from "../state/entities/shared/slice";

export const useGlobalHolidaysLoader = (): SharedState => {
  const dispatch = useDispatch();
  const shared = useSelector((state: StoreType) => getShared(state));

  React.useEffect(() => {
    dispatch(loadGlobalHolidaysSubmit());
  }, [dispatch]);

  return shared;
};
