import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import PanelDisplay from "./PanelDisplay";
const PartialPay: React.FC = () => {
  return (
    <div data-testid="partial-pay">
      <CommonWrapper>
        <PanelDisplay />
      </CommonWrapper>
    </div>
  );
};

export default PartialPay;
