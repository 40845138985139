import styled from "styled-components";
import styles from "@billfire/toybox";
import { Button } from "@billfire/toybox";

const { colors } = styles;
const { baseGray7 } = colors;

interface RowProps {
  showBorder?: boolean;
}

export const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  padding: ${props => (props.showBorder ? "15px" : "8px")} 0px;
  ${props =>
    props.showBorder
      ? "border-bottom: 2px solid rgb(246, 246, 246);"
      : "border-bottom: none;"}
  background-color: white;
  color: ${baseGray7};
`;

export const DataTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;

  th {
    color: ${baseGray7};
    font-size: 15px;
    font-weight: 100;
    text-align: center;
    padding-bottom: 16px;
  }

  th:first-child {
    text-align: left;
  }

  th:last-child {
    text-align: right;
  }

  td {
    color: ${baseGray7};
    font-size: 15px;
    font-weight: 100;
    text-align: center;
    padding-bottom: 16px;
  }

  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }

  table:nth-child(2) td {
    padding-bottom: 0;
  }
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  color: ${baseGray7};
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff0000;
  text-align: center;
`;

export const FeesContainer = styled.div`
  padding-top: 15px;
  border-bottom: 2px solid rgb(246, 246, 246);
`;

export const ActionAreaContainer = styled.div`
  padding 15px 0px
`;

export const TitleWrapper = styled.div`
  color: ${baseGray7};
  font-size: 15px;
  font-weight: 100;
`;
