// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { StyledImage } from "./styled";

const ImageBanner = () => {
  return (
    <StyledImage>
      <div>
        <h3>Save Time and Money with</h3>
        <h4>PerformancePay</h4>
      </div>
    </StyledImage>
  );
};

export default ImageBanner;
