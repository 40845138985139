import * as React from "react";
import { useLoadingState } from "./hooks";
import SkeletonLoader from "../../components/SkeletonLoader";
import LocationDashboard from "../../components/LocationDashboard";

const LocationDashboardPage: React.FC = () => {
  const loading = useLoadingState();
  return loading ? <SkeletonLoader /> : <LocationDashboard />;
};

export default LocationDashboardPage;
