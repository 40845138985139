/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { locationsTypes as types } from "./index";
import { LocationsLoadStart } from "./types";

export const initialState: types.LocationsState = {
  byId: {},
  selectedLocationId: "",
  locationsLoading: false,
  locationsLoaded: false,
  locationsLoadFailed: false,
  hasEverLoadedLocations: false
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    locationsLoadStart: (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _state: types.LocationsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<LocationsLoadStart>
    ) => {},
    locationsLoadSubmit: (state: types.LocationsState) => {
      state.locationsLoading = true;
      state.locationsLoaded = false;
      state.locationsLoadFailed = false;
      state.hasEverLoadedLocations = false;
    },
    locationsLoadSuccess: (
      state: types.LocationsState,
      action: PayloadAction<Record<string, types.Location>>
    ) => {
      state.locationsLoading = false;
      state.locationsLoaded = true;
      state.locationsLoadFailed = false;
      state.hasEverLoadedLocations = true;
      state.byId = action.payload;
    },
    locationsLoadFailed: (state: types.LocationsState) => {
      state.locationsLoading = false;
      state.locationsLoaded = false;
      state.locationsLoadFailed = true;
    },
    selectLocation: (state, action: PayloadAction<{ locationId: string }>) => {
      state.selectedLocationId = action.payload.locationId;
    },
    resetStore: () => initialState
  }
});

export const {
  locationsLoadStart,
  locationsLoadSubmit,
  locationsLoadSuccess,
  locationsLoadFailed,
  selectLocation,
  resetStore
} = locationsSlice.actions;

export default locationsSlice.reducer;
