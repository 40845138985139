import * as React from "react";
import { StyledPanel, PanelName, PaymentsIcon, RefDiv } from "./styled";
import { useHover } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const PaymentsPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const { appendPath } = usePageNav();

  return (
    <RefDiv
      ref={hoverRef}
      data-testid="payments-hover"
      onClick={() => appendPath("payments")}
    >
      <StyledPanel background={isHovered ? "#eaf0f7" : ""}>
        <PaymentsIcon fill={"#000"} data-testid="PaymentsIcon" />
        <PanelName>PAYMENTS</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default PaymentsPanel;
