import * as React from "react";
import {
  Logo,
  VendorContainer,
  HoverContainer,
  InnerContainer,
  LogoContainer,
  VendorNameContainer
} from "./styled";

export interface Props {
  id: string;
  src: string;
  alt: string;
  handleClick: () => void;
}

const VendorRow: React.FC<Props> = ({ id, handleClick, src, alt }) => {
  return (
    <VendorContainer key={id} onClick={() => handleClick()}>
      <HoverContainer>
        <InnerContainer>
          <LogoContainer>
            <Logo src={src} />
          </LogoContainer>
          <VendorNameContainer>
            <div>{alt}</div>
          </VendorNameContainer>
        </InnerContainer>
      </HoverContainer>
    </VendorContainer>
  );
};

export default VendorRow;
