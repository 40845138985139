import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import isDeepEqual from "fast-deep-equal/react";
import { getOpenInvoices } from "../../state/entities/invoices/selectors";
import { StoreType } from "../../state/types";
import {
  isRemitInvoice,
  RemitBaseRow,
  RemitInvoice,
  RemitInvoicesState
} from "./types";
import {
  resetInvoice,
  showComments,
  getBlankRows,
  sortRemitRows,
  reduceInvoices
} from "./utils";
import { useParams } from "react-router-dom";
import api from "../../state/api";
import { useRemitForm } from ".";

const setInvoiceComment = (
  id: string,
  comment: string,
  state: RemitInvoicesState
) => {
  const { remitInvoices, setRemitInvoices } = state;

  // eslint-disable-next-line security/detect-object-injection
  const invoiceToSet = remitInvoices[id];
  setRemitInvoices({ ...remitInvoices, [id]: { ...invoiceToSet, comment } });
};

const toggleInvoiceSelected = (id: string, state: RemitInvoicesState) => {
  const { remitInvoices, setRemitInvoices } = state;

  // eslint-disable-next-line security/detect-object-injection
  const invoiceToSet = remitInvoices[id];

  if (isRemitInvoice(invoiceToSet))
    setRemitInvoices({
      ...remitInvoices,
      [id]: resetInvoice(invoiceToSet, !invoiceToSet.selected)
    });
};

const toggleAllInvoicesSelected = (state: RemitInvoicesState) => {
  const { remitInvoices, setRemitInvoices } = state;

  const checked = Object.values(remitInvoices).filter(
    inv => inv.selected
  ).length;

  const newChecked = checked === 0;

  setRemitInvoices(
    Object.values(remitInvoices).reduce(
      (
        acc: Record<string, RemitBaseRow | RemitInvoice>,
        inv: RemitBaseRow | RemitInvoice
      ) => {
        acc[inv.id] = isRemitInvoice(inv) ? resetInvoice(inv, newChecked) : inv;
        return acc;
      },
      {}
    )
  );
};

const setInvoicePaymentAmount = (
  id: string,
  paymentAmount: number,
  state: RemitInvoicesState
) => {
  const { remitInvoices, setRemitInvoices } = state;

  // eslint-disable-next-line security/detect-object-injection
  const invoiceToSet = remitInvoices[id];

  const showCommentFlag = showComments({ ...invoiceToSet, paymentAmount });

  setRemitInvoices({
    ...remitInvoices,
    [id]: {
      ...invoiceToSet,
      paymentAmount,
      comment: showCommentFlag ? invoiceToSet.comment : ""
    }
  });
};

const useSelectedInvoices = () => {
  const [remitInvoicesSet, setRemitInvoicesSet] = useState<boolean>(false);

  const invoices = useSelector((state: StoreType) => {
    return getOpenInvoices(state);
  });

  const invoicesRef = useRef(invoices);

  if (!isDeepEqual(invoicesRef.current, invoices)) {
    invoicesRef.current = invoices;
    setRemitInvoicesSet(false);
  }

  return { remitInvoicesSet, setRemitInvoicesSet, invoicesRef };
};

const useMapRemitInvoices = () => {
  const [remitInvoices, setRemitInvoices] = useState<
    Record<string, RemitBaseRow | RemitInvoice>
  >({});

  const { remitInvoicesSet, setRemitInvoicesSet, invoicesRef } =
    useSelectedInvoices();

  useEffect(() => {
    if (!remitInvoicesSet) {
      setRemitInvoicesSet(true);

      if (invoicesRef.current.length === 0) {
        setRemitInvoices(
          getBlankRows(5).reduce((byId: Record<string, RemitBaseRow>, inv) => {
            return { ...byId, [inv.id]: inv };
          }, {})
        );
      } else {
        setRemitInvoices(reduceInvoices(invoicesRef.current));
      }
    }
  }, [invoicesRef, remitInvoicesSet, setRemitInvoicesSet]);

  return { remitInvoices, setRemitInvoices, remitInvoicesSet };
};

const useRemitInvoicesState = () => {
  const state = useMapRemitInvoices();

  return {
    ...state,
    setInvoiceComment: (id: string, comment: string) =>
      setInvoiceComment(id, comment, state),
    toggleInvoiceSelected: (id: string) => toggleInvoiceSelected(id, state),
    toggleAllInvoicesSelected: () => toggleAllInvoicesSelected(state),
    setInvoicePaymentAmount: (id: string, paymentAmount: number) =>
      setInvoicePaymentAmount(id, paymentAmount, state)
  };
};

export const useRemitInvoices = () => {
  const [checkNumber, setCheckNumber] = useState("");
  const [formComment, setFormComment] = useState("");

  const remitInvoiceState = useRemitInvoicesState();

  const { remitInvoices } = remitInvoiceState;

  return {
    ...remitInvoiceState,
    sortedInvoices: sortRemitRows(Object.values(remitInvoices)).map(
      inv => remitInvoices[inv.id]
    ),
    checkNumber,
    setCheckNumber,
    formComment,
    setFormComment
  };
};

export const useSendRemitInvoiceJourney = (): (() => void) => {
  const { token, vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
    token: string;
  }>();
  const { sortedInvoices } = useRemitForm();

  const invoiceIds = sortedInvoices
    .filter(inv => inv.selected)
    .map(inv => inv.id);

  const sendInvoiceJourney = () => {
    if (invoiceIds.length > 0) {
      api
        .postWithToken(
          `/vendors/${vendorId}/locations/${locationId}/journeys`,
          { type: "Remit form printed", invoiceIds },
          token
        )
        .catch(() => undefined);
    }
  };

  return sendInvoiceJourney;
};
