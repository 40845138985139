import * as React from "react";
import { Location } from "../../state/entities/locations/types";
import {
  ListButton,
  Hover,
  LeftCol,
  RightCol,
  LocationName,
  LocationAddress
} from "./LocationsListItem.styled";
import RightArrow from "../../assets/RightArrow.svg";
import { locationAddress } from "./utils";

export interface LocationsListItemProps {
  location: Location;
  handleClick: () => void;
}

const LocationsListItem: React.FC<LocationsListItemProps> = ({
  location,
  handleClick
}) => {
  const { id, address, displayName } = location;
  return (
    <ListButton key={id} onClick={() => handleClick()}>
      <Hover>
        <LeftCol>
          <LocationName>{displayName}</LocationName>
          <LocationAddress>{locationAddress(address)}</LocationAddress>
        </LeftCol>
        <RightCol>
          <RightArrow data-testid="right-arrow" />
        </RightCol>
      </Hover>
    </ListButton>
  );
};

export default LocationsListItem;
