enum PaymentColumnName {
  invoiceDisplayInvNum = "invoiceDisplayInvNum",
  externalInvoiceDate = "externalInvoiceDate",
  invoiceDueDate = "invoiceDueDate",
  paidAmount = "paidAmount"
}

export const isPaymentColumnName = (
  colName?: string | null
): colName is PaymentColumnName => {
  return Object.values(PaymentColumnName).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    colName as unknown as PaymentColumnName
  );
};
