import styled from "styled-components";
import { device } from "../../../breakpoints";

export const CheckNumberInput = styled.input`
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #fff;
  height: 24px;
  width: 67px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4a4a4a;
  text-align: center;
  :focus {
    border: solid 1px #d1d1d1 !important;
    outline: none;
  }
`;

export const CheckNumberContainer = styled.div.attrs({
  "data-testid": "check-number-container"
})`
  display: flex;
  justify-content: flex-end;
  padding: 38px 0px 50px 0px;

  @media print {
    padding: 0px;
  }

  @media screen and (${device.phone}) {
    padding: 20px 0px 30px;
  }
`;

export const CheckNumberLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  align-self: center;
  color: #777;
  padding-right: 20px;

  @media print {
    font-size: 9.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.21;
    letter-spacing: normal;
    align-self: center;
    color: #000;
  }
`;
