import { StoreType } from "../../types";
import { isActiveAutopayType } from "./types";

export const getAutopayLoadingState = (state: StoreType) => {
  const { loadingAutopay, loadedAutopay, loadAutopayFailed } = state.autopay;
  return { loadingAutopay, loadedAutopay, loadAutopayFailed };
};

export const getAutopayDeletingState = (state: StoreType) => {
  const { deleting, deleted } = state.autopay;
  return { deleting, deleted };
};

export const isAutopayActive = (state: StoreType) => {
  return !!state.autopay.activeAutopay.id;
};

export const getExcludedInvoices = (state: StoreType) => {
  return state.autopay.excludedInvoiceIds;
};

export const getActiveAutopayExcludedInvoices = (state: StoreType) => {
  const { activeAutopay } = state.autopay;
  if (isActiveAutopayType(activeAutopay)) {
    return activeAutopay.excludedInvoices;
  }
  return [];
};

export const getActiveAutopayPaymentMethodId = (state: StoreType) => {
  const { activeAutopay } = state.autopay;
  if (isActiveAutopayType(activeAutopay)) {
    return activeAutopay.paymentMethodId;
  }
  return "";
};

export const getActiveAutopayId = (state: StoreType) => {
  const { activeAutopay } = state.autopay;
  if (isActiveAutopayType(activeAutopay)) {
    return activeAutopay.id;
  }
  return "";
};

export const getActiveAutopay = (state: StoreType) => {
  const { activeAutopay } = state.autopay;
  if (isActiveAutopayType(activeAutopay)) {
    return activeAutopay;
  }
};

export const getActiveAutopayStartDate = (state: StoreType) => {
  const { activeAutopay } = state.autopay;
  if (isActiveAutopayType(activeAutopay)) {
    return activeAutopay.startDate;
  }
  return "";
};
