import { StoreType } from "../../types";
import { DisputedInvoiceById, Invoice } from "./types";
import { filterOpenInvoices } from "./utils";

export const getDisputedInvoicesById = (
  state: StoreType
): DisputedInvoiceById => {
  return state.invoices.disputedInvoices;
};

export const getSelectedInvoices = (state: StoreType): Invoice[] => {
  return Object.values(state.invoices.byId).filter(inv =>
    state.invoices.selectedInvoiceIds.includes(inv.id)
  );
};

export const getRequestedInvoices = (state: StoreType): Invoice[] => {
  return Object.values(state.invoices.byId).filter(inv =>
    state.requests.requestInvoiceIds.includes(inv.id)
  );
};

export const getOpenInvoices = (state: StoreType): Invoice[] => {
  return filterOpenInvoices(state.invoices.byId);
};

export const getAllInvoices = (state: StoreType): Invoice[] => {
  return Object.values(state.invoices.byId);
};

export const getInvoicesLoadState = (state: StoreType) => {
  return {
    loadingInvoices: state.invoices.loadingInvoices,
    loadInvoicesFailed: state.invoices.loadInvoicesFailed,
    loadedInvoices: state.invoices.loadedInvoices
  };
};

export const getInvoiceById = (state: StoreType, id: string): Invoice => {
  // eslint-disable-next-line security/detect-object-injection
  return state.invoices.byId[id];
};

export const selectShipTos = (state: StoreType) => {
  return state.invoices.locationInvoiceFilters;
};
