import { useSelector } from "react-redux";
import { getSelectedVendor } from "../state/entities/vendors/selectors";
import { PaymentPlanHelper } from "@billfire/payment-lib-base-v2";
import { getGlobalHolidays } from "../state/entities/shared/selectors";

const usePaymentPlanHelper = () => {
  const vendor = useSelector(getSelectedVendor);
  const holidays = useSelector(getGlobalHolidays);
  return new PaymentPlanHelper(vendor, holidays);
};

export default usePaymentPlanHelper;
