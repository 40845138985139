import * as React from "react";
import { useRemitForm } from "..";
import { getEmptyRemitInvoice } from "../types";
import { CommentRow } from "./styled";
import Comments from "./Comments";
import { showComments } from "../utils";
import { InvoiceRowProps } from "./InvoiceRow";

const InvoiceCommentRow: React.FC<InvoiceRowProps> = ({ rowId }) => {
  const { sortedInvoices } = useRemitForm();

  const invoice =
    sortedInvoices.find(inv => inv.rowId === rowId) || getEmptyRemitInvoice();

  const commentsClass = showComments(invoice)
    ? "shown-comments"
    : "hidden-comments";

  const rowClassName = invoice.selected ? "tr-selected" : "";

  return (
    <CommentRow
      data-testid={`comment-input-${rowId}`}
      className={`${rowClassName} ${commentsClass} comments`}
    >
      <td className="select-box" />
      <td colSpan={5}>
        <Comments rowId={rowId} />
      </td>
    </CommentRow>
  );
};

export default InvoiceCommentRow;
