import { PaymentMethod } from "../../../../state/entities/paymentMethods/types";

const selectedColor = "#4A90E2";
const unselectedColor = "#D8D8D8";

export const _getIconFill = (
  paymentMethod: PaymentMethod,
  selectedPaymentMethodId?: string
) => {
  const {
    displayExtra: { verificationRequired }
  } = paymentMethod;

  return paymentMethod.id === selectedPaymentMethodId && !verificationRequired
    ? selectedColor
    : unselectedColor;
};
