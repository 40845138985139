// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {
  formatCurrency,
  formatShortDate,
  NullableDateStr,
  sortCurrency,
  sortDate,
  SortDirection,
  sortString
} from "@billfire/toybox";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import { invoiceTypes } from "../../../state/entities/invoices";
import {
  getInvoiceTotal,
  isPastDue
} from "../../../state/entities/invoices/utils";
import { CustomTableColumn } from "./styled";
import { Vendor } from "../../../state/entities/vendors/types";
import PrefixEllipsis from "../../PrefixEllipsis";
import { getExternalInvoiceDateDisplay } from "../../../utils";
import { Invoice } from "../../../state/entities/invoices/types";
import { isInvoiceColumnName } from "./types";
import { GlobalFeatureFlags } from "../../../state/entities/shared/types";
import { featureEnabled } from "../../../state/entities/shared/utils";
import InvoiceLink from "../../InvoiceLink";

export const sortDueDate = (
  a: NullableDateStr,
  b: NullableDateStr,
  sortDirection: SortDirection
) => {
  if (a === "") {
    return -1;
  }

  if (b === "") {
    return 1;
  }

  return sortDate(a, b, sortDirection);
};

export const getColumns = (
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags,
  selectedVendorShipToConfig: boolean,
  mobile?: boolean | undefined
): Column[] => [
  {
    name: "displayInvNum",
    label: "Invoice",
    render: (value: string, item: InvoiceRow) => {
      return (
        <InvoiceLink invoiceId={item.id}>
          <PrefixEllipsis text={value} />
        </InvoiceLink>
      );
    },
    sortFn: sortString
  },
  ...(featureEnabled(vendor, globalFeatureFlags, "displayExternalInvoiceDate")
    ? [
        {
          mobile: false,
          name: "externalInvoiceDate",
          label: "Invoice Date",
          width: `${mobile ? "auto" : "75px"}`,
          sortFn: sortDate,
          render: (_value: string, item: invoiceTypes.Invoice) => {
            return (
              <CustomTableColumn isPastDue={false}>
                {getExternalInvoiceDateDisplay(item)}
              </CustomTableColumn>
            );
          }
        }
      ]
    : []),
  {
    name: "dueDate",
    label: "Due Date",
    width: `${mobile ? "auto" : "75px"}`,
    render: (value: string, item: invoiceTypes.Invoice) => {
      return (
        <CustomTableColumn isPastDue={isPastDue(item, vendor)}>
          {value}
        </CustomTableColumn>
      );
    },
    formatFn: formatShortDate,
    sortFn: sortDueDate
  },
  ...(selectedVendorShipToConfig
    ? [
        {
          name: "shipTo",
          label: "Ship-To",
          width: `${mobile ? "auto" : "200px"}`,
          sortFn: sortString
        }
      ]
    : []),
  ...(featureEnabled(vendor, globalFeatureFlags, "displayInvoicePoRefId")
    ? [
        {
          mobile: false,
          name: "poRefId",
          label: "PO/REF Number",
          render: (value: string) => {
            return (
              <CustomTableColumn isPastDue={false}>
                {value ? value : "-"}
              </CustomTableColumn>
            );
          },
          sortFn: sortString
        }
      ]
    : []),
  {
    name: "openAmount",
    label: "Amount",
    formatFn: formatCurrency,
    sortFn: sortCurrency,
    width: "90px"
  }
];

export interface InvoiceRow extends invoiceTypes.Invoice {
  rowId: string;
}

export const getRow = (inv: invoiceTypes.Invoice): InvoiceRow => {
  return {
    ...inv,
    rowId: inv.id
  };
};

export const formatTotal = (invoices: invoiceTypes.Invoice[]): string => {
  const total = getInvoiceTotal(invoices, { byId: {} });

  return formatCurrency(total);
};

export const showPayButton = ({
  pastDue,
  current
}: {
  pastDue: InvoiceRow[];
  current: InvoiceRow[];
}) => pastDue.length || current.length;

export const multipleCategoriesWithInvoices = (invoices: {
  pastDue: InvoiceRow[];
  current: InvoiceRow[];
  credits: InvoiceRow[];
}) => {
  return Object.values(invoices).filter(value => value.length).length > 1;
};

const getHeaders = (
  displayInvoiceDate: boolean,
  displayInvoicePoRef: boolean,
  selectedVendorShipToConfig: boolean
) => {
  return [
    { label: "Invoice", key: "displayInvNum" },
    ...(displayInvoiceDate
      ? [{ label: "Invoice Date", key: "externalInvoiceDate" }]
      : []),
    ...(displayInvoicePoRef
      ? [{ label: "PO/REF Number", key: "poRefId" }]
      : []),
    ...(selectedVendorShipToConfig
      ? [{ label: "Ship-To", key: "shipTo" }]
      : []),
    { label: "Due Date", key: "dueDate" },
    { label: "Amount", key: "openAmount" }
  ];
};

const formatInvoiceExport = (
  inv: Invoice,
  displayInvoiceDate: boolean,
  displayInvoicePoRef: boolean,
  selectedVendorShipToConfig: boolean
) => ({
  displayInvNum: inv.displayInvNum,
  ...(displayInvoiceDate && {
    externalInvoiceDate: formatShortDate(inv.externalInvoiceDate)
  }),
  ...(displayInvoicePoRef && { poRefId: inv.poRefId ? inv.poRefId : "-" }),
  ...(selectedVendorShipToConfig && { shipTo: inv.shipTo }),
  dueDate: formatShortDate(inv.dueDate),
  openAmount: formatCurrency(inv.openAmount)
});

export const mapAndSortStatementCSVData = (
  invoices: Invoice[],
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags,
  selectedVendorShipToConfig: boolean,
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const columns = getColumns(
    vendor,
    globalFeatureFlags,
    selectedVendorShipToConfig
  );
  const displayInvoiceDate = !!columns.find(
    col => col.name === "externalInvoiceDate"
  );
  const displayInvoicePoRef = !!columns.find(col => col.name === "poRefId");

  const foundColumn = columns.find(({ name }) => name === sortBy);
  const sortFn = foundColumn && foundColumn.sortFn;
  const csvData = invoices
    .sort(
      (a, b) =>
        sortFn &&
        isInvoiceColumnName(sortBy) &&
        // eslint-disable-next-line security/detect-object-injection
        sortFn(a[sortBy], b[sortBy], sortDirection || "ASC")
    )
    .map(inv =>
      formatInvoiceExport(
        inv,
        displayInvoiceDate,
        displayInvoicePoRef,
        selectedVendorShipToConfig
      )
    );
  return {
    csvData,
    headers: getHeaders(
      displayInvoiceDate,
      displayInvoicePoRef,
      selectedVendorShipToConfig
    )
  };
};
