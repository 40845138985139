import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadInvoicesSubmit,
  loadShipTosSubmit,
  loadInvoicesSuccess,
  loadShipTosSuccess,
  loadInvoicesFailed,
  setDispute,
  undoDispute,
  setSelectedInvoiceIds
} from "./slice";
import {
  ApiResponseInvoices,
  Invoice,
  MappedInvoiceResponse,
  SetDispute,
  ResponseInvoice,
  SetSelectedInvoices
} from "./types";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { saveAction } from "../persisted/slice";
import { isUserAction } from "../persisted/selectors";
import { createSaveAction } from "../persisted/utils";
import { ReplayType } from "../persisted/types";

export const mapResponse = (
  responseInvoices: ApiResponseInvoices
): MappedInvoiceResponse => {
  const byId = responseInvoices.locationInvoices.reduce(
    (byId: Record<string, Invoice>, responseInvoice: ResponseInvoice) => {
      byId[responseInvoice.id] = {
        ...responseInvoice,
        openAmount: parseFloat(responseInvoice.openAmount),
        dueDate: responseInvoice.dueDate || "",
        shipTo:
          responseInvoice.extra?.eodPassthrough?.stId &&
          responseInvoice.extra?.eodPassthrough?.stName
            ? responseInvoice.extra?.eodPassthrough?.stId +
              " - " +
              responseInvoice.extra?.eodPassthrough?.stName
            : undefined,
        filterName: responseInvoice.extra?.eodPassthrough?.stName
      };
      return byId;
    },
    {}
  );
  return { byId, requestInvoiceIds: responseInvoices.requestInvoiceIds };
};

export const fetchInvoicesEpic = (
  action$: Observable<
    PayloadAction<{ loadAll: boolean; type: string; invoiceId: string }>
  >,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadInvoicesSubmit.type),
    switchMap(({ payload: { loadAll, type, invoiceId } }) => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/invoices?loadAll=${loadAll}&type=${type}&invoiceId=${invoiceId}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            const mapped = mapResponse(resp);
            return loadInvoicesSuccess(mapped);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadInvoicesFailed());
          })
        );
    })
  );

export const persistDisputesEpic = (
  action$: Observable<PayloadAction<SetDispute>>,
  state$: { value: StoreType }
) =>
  action$.pipe(
    ofType(setDispute.type, undoDispute.type),
    filter(() => isUserAction(state$.value)),
    map((action: PayloadAction<SetDispute>) => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      return saveAction(
        createSaveAction(
          { ...action, replayType: ReplayType.none },
          selectedVendorId,
          selectedLocationId,
          token
        )
      );
    })
  );

export const persistInvoiceSelectedEpic = (
  action$: Observable<PayloadAction<SetSelectedInvoices>>,
  state$: { value: StoreType }
) =>
  action$.pipe(
    ofType(setSelectedInvoiceIds.type),
    filter(() => isUserAction(state$.value)),
    map((action: PayloadAction<SetSelectedInvoices>) => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      return saveAction(
        createSaveAction(
          { ...action, replayType: ReplayType.none },
          selectedVendorId,
          selectedLocationId,
          token
        )
      );
    })
  );

export const fetchFilteredShipTosEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadShipTosSubmit.type),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/invoices?filters=true`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return loadShipTosSuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadInvoicesFailed());
          })
        );
    })
  );
