import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import ReactGA from "react-ga4";
import routes from "../routes";

const useGaPageTracking = () => {
  const location = useLocation();
  const route = routes.find(r => matchPath(location.pathname, r));

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (process.env.REACT_GA_TOKEN) {
      ReactGA.initialize(process.env.REACT_GA_TOKEN);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && route) {
      ReactGA.send({ hitType: "pageview", page: route.path });
    }
  }, [initialized, route]);
};

export default useGaPageTracking;
