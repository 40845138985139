import styled from "styled-components";
import { device } from "../../breakpoints";

export const VendorContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
`;

export const StyledH2 = styled.h2`
  color: #272a43;
  align-self: center;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 35px;
  font-size: 31px;

  @media only screen and (${device.tablet}) {
    font-size: 27px;
  }
`;

export const GridContainer = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  grid-gap: 18px;
  padding-bottom: 25px;
  margin: auto;

  @media only screen and (${device.tablet}) {
    grid-template-columns: auto;
    width: 94%;
    grid-gap: 16px;
  }
`;
