import * as React from "react";
import { useRemitForm } from "..";
import { FormCommentsTextAreaContainer } from "./styled";
import { TextArea } from "@billfire/toybox";

const FormComments: React.FC = () => {
  const { formComment, setFormComment } = useRemitForm();

  return (
    <FormCommentsTextAreaContainer>
      <TextArea
        id={`form-comment`}
        name={`form-comment`}
        className="formComment"
        initialValue={formComment}
        label="Comments:"
        autoFocus={false}
        maxLength={1000}
        rows={4}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFormComment(e.target.value);
        }}
        allowNewLines={false}
      />
    </FormCommentsTextAreaContainer>
  );
};

export default FormComments;
