import * as React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { getVendorArray } from "../../state/entities/vendors/selectors";
import { Vendor } from "../../state/entities/vendors/types";
import { useHistory } from "react-router-dom";
import { usePageNav } from "../../hooks/navigation";

export const compareName = (a: Vendor, b: Vendor): number => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const useVendorsListener = () => {
  const vendors = useSelector((state: StoreType) => getVendorArray(state));
  const history = useHistory();
  const { appendPath } = usePageNav();
  React.useEffect(() => {
    if (vendors.length === 1) {
      const vendorId = vendors[0].id;
      appendPath(`vendors/${vendorId}/locations`);
    }
  }, [vendors, history, appendPath]);
};
