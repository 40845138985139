import {
  PaymentMethodText,
  PlusSymbol,
  PlusSymbolContainer,
  StyledH3,
  StyledRow
} from "./styled";
import { useHistory, useParams } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

export interface AddPaymentMethodLinkProps {
  hasPaymentMethods: boolean;
}

const AddPaymentMethodLink: React.FC<AddPaymentMethodLinkProps> = ({
  hasPaymentMethods
}) => {
  const history = useHistory();
  const { token, locationId, vendorId } = useParams<{
    token: string;
    locationId: string;
    vendorId: string;
  }>();

  return (
    <>
      {!hasPaymentMethods ? <StyledH3>Payment Method</StyledH3> : null}
      <StyledRow
        {...{ hasPaymentMethods }}
        onClick={() =>
          history.push(
            `/token/${token}/vendors/${vendorId}/locations/${locationId}/paymentmethods/new`
          )
        }
        role="link"
      >
        <PlusSymbolContainer>
          <PlusSymbol>+</PlusSymbol>
        </PlusSymbolContainer>
        <PaymentMethodText>Add Payment Method</PaymentMethodText>
      </StyledRow>
    </>
  );
};

export default AddPaymentMethodLink;
