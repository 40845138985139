import * as React from "react";
import { Button } from "@billfire/toybox";
import { ButtonContainer } from "../../../globalModal/modals.styled";

export interface ModalButtonContainerProps {
  onClose: (() => void) | undefined;
  hideModal: () => void;
  deletePaymentMethod: () => void;
  deleting: boolean;
  hasAutopay: boolean;
  hasPaymentPlan: boolean;
}

export const ModalButtonContainer: React.FC<ModalButtonContainerProps> = ({
  onClose,
  hideModal,
  deletePaymentMethod,
  deleting,
  hasAutopay,
  hasPaymentPlan
}) => {
  return (
    <>
      {hasAutopay || hasPaymentPlan ? null : (
        <ButtonContainer>
          <Button
            width="188px"
            loading={false}
            data-testid="CancelButton"
            handleClick={onClose || hideModal}
            disabled={deleting}
            color="secondary"
          >
            Keep
          </Button>
          <Button
            handleClick={deletePaymentMethod}
            width="188px"
            loading={deleting}
            disabled={false}
            data-testid="DeletePaymentMethod"
          >
            Delete
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

export default ModalButtonContainer;
