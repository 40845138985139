import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import PaymentPlanConfirmation from "../../components/PaymentPlanConfirmation";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";

const PaymentPlanConfirmationPage: React.FC = () => {
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();

  const loading = loadingVendors || locationsLoading;

  return loading ? <SkeletonLoader /> : <PaymentPlanConfirmation />;
};

export default PaymentPlanConfirmationPage;
