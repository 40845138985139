import { getEmptyInvoice, Invoice } from "../../state/entities/invoices/types";

export interface RemitBaseRow {
  id: string;
  rowId: string;
  comment: string;
  paymentAmount: number;
  selected: boolean;
}

export interface RemitInvoice extends Invoice, RemitBaseRow {
  checkboxProps: { id: string };
}

export const isRemitInvoice = (row: RemitBaseRow): row is RemitInvoice => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (row as RemitInvoice).openAmount !== undefined;
};

export type RemitInvoiceFieldName = keyof RemitInvoice;

export interface RemitFormInfo {
  sortedInvoices: Array<RemitBaseRow | RemitInvoice>;
  setInvoiceComment: (id: string, comment: string) => void;
  toggleInvoiceSelected: (id: string) => void;
  toggleAllInvoicesSelected: () => void;
  setInvoicePaymentAmount: (id: string, paymentAmount: number) => void;
  checkNumber: string;
  setCheckNumber: (checkNumber: string) => void;
  formComment: string;
  setFormComment: (formComment: string) => void;
}

export const getEmptyRemitBaseRow = () => ({
  id: "",
  rowId: "",
  comment: "",
  paymentAmount: 0.0,
  selected: false
});

export const getEmptyRemitInvoice = () => ({
  ...getEmptyRemitBaseRow(),
  ...getEmptyInvoice(),
  checkboxProps: { id: "" }
});

export const defaultContext: RemitFormInfo = {
  sortedInvoices: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setInvoiceComment: function (id: string, comment: string): void {
    throw new Error("Function not implemented.");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleInvoiceSelected: function (id: string): void {
    throw new Error("Function not implemented.");
  },
  toggleAllInvoicesSelected: () => {
    throw new Error("Function not implemented.");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setInvoicePaymentAmount: function (id: string, paymentAmount: number): void {
    throw new Error("Function not implemented.");
  },
  checkNumber: "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCheckNumber: function (checkNumber: string): void {
    throw new Error("Function not implemented.");
  },
  formComment: "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFormComment: function (formComment: string): void {
    throw new Error("Function not implemented.");
  }
};

export interface RenderComponentProps {
  value: string | number | boolean | { id: string } | null | undefined;
  item: RemitBaseRow | RemitInvoice;
  mobile?: boolean;
}

export interface PrintingComponentProps {
  printing?: boolean;
}

export interface TableColumn {
  name: RemitInvoiceFieldName;
  label: string;
  width: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatFn?: (
    value: string | number | boolean | { id: string } | null | undefined
  ) => string;
  Render?: React.ElementType<RenderComponentProps>;
  className?: string;
}

export interface RemitInvoicesState {
  remitInvoices: Record<string, RemitBaseRow | RemitInvoice>;
  setRemitInvoices: React.Dispatch<
    React.SetStateAction<Record<string, RemitBaseRow | RemitInvoice>>
  >;
}
