import { SortDirection } from "@billfire/toybox";
import { Row } from "@billfire/toybox/dist/components/DataTable/types";
import * as React from "react";
import { usePageNav } from "../../hooks/navigation";
import { PaymentStatus } from "../../state/entities/paymentGroups/types";
import { LinedTable, BodyRow, TBody, TableTitle } from "./styled";
import { SortBy } from "./types";
import { columns } from "./utils";

interface PaymentsTableProps {
  title: string;
  rowData: Array<Row>;
  sortable?: boolean;
  status: PaymentStatus;
  sortBys: Record<PaymentStatus, SortBy>;
  setSortBys: (sortBys: Record<PaymentStatus, SortBy>) => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({
  title,
  rowData,
  sortable,
  status,
  sortBys,
  setSortBys
}) => {
  const { locationPath } = usePageNav();

  // eslint-disable-next-line security/detect-object-injection
  const thisSortBy = sortBys[status];

  const setSortDirection: React.Dispatch<
    React.SetStateAction<SortDirection>
  > = sortDirection => {
    setSortBys({ ...sortBys, [status]: { ...thisSortBy, sortDirection } });
  };

  const setSortBy: React.Dispatch<React.SetStateAction<string>> = sortBy => {
    setSortBys({ ...sortBys, [status]: { ...thisSortBy, sortBy } });
  };

  return (
    <>
      <TableTitle>{title}</TableTitle>
      <LinedTable
        TableBodyComponent={TBody}
        RowComponent={BodyRow}
        sortable={sortable}
        columns={columns(locationPath)}
        initialData={rowData}
        externalSortDirection={thisSortBy.sortDirection}
        setExternalSortDirection={setSortDirection}
        externalSortBy={thisSortBy.sortBy}
        externalSetSortBy={setSortBy}
      />
    </>
  );
};

export default PaymentsTable;
