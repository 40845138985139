import { Location, ResponseLocations } from "./types";

export const reducedLocations = (
  res: ResponseLocations
): Record<string, Location> => {
  return res.locations.reduce(
    (byId: Record<string, Location>, res: Location) => {
      byId[res.id] = {
        id: res.id,
        displayName: res.displayName,
        address: res.address,
        createType: res.createType,
        hasUnreadMessages: res.hasUnreadMessages,
        paymentConfigs: res.paymentConfigs
      };
      return byId;
    },
    {}
  );
};
