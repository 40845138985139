import { useSelector } from "react-redux";
import { getVendor } from "../state/entities/vendors/selectors";
import { Vendor } from "../state/entities/vendors/types";
import { StoreType } from "../state/types";
import { useParamHook } from "./useParams";

export const useVendorFromPath = (): Vendor => {
  const { vendorId } = useParamHook();
  return useSelector((state: StoreType) => getVendor(state, vendorId));
};
