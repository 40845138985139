import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalTypes } from "../../../globalModal";
import { useModal } from "../../../globalModal/ModalProvider";
import { getDeleteState } from "../../../state/entities/paymentMethods/selectors";
import { deletePaymentMethodsResetError } from "../../../state/entities/paymentMethods/slice";
import { StoreType } from "../../../state/types";

const deletePaymentMethodFailed = (
  deleteFailed: boolean,
  showModal: (component: modalTypes.ModalType) => void,
  handleClose: () => void,
  name: string,
  lastFour: string,
  checkPaymentsLoading: boolean,
  setCheckPaymentsLoading: (val: boolean) => void
) => {
  deleteFailed &&
    showModal({
      type: modalTypes.PAYMENT_METHOD_DELETE,
      props: {
        onClose: handleClose,
        name,
        lastFour,
        checkPaymentsLoading,
        setCheckPaymentsLoading
      }
    });
};

const deletePaymentMethodSucceeded = (
  deleteSuccess: boolean,
  showModal: (component: modalTypes.ModalType) => void,
  handleClose: () => void
) => {
  deleteSuccess &&
    showModal({
      type: modalTypes.BASIC_GRAY,
      props: { message: "Payment Method Deleted!", onClose: handleClose }
    });
};

export const useShowDeletePaymentMethodModal = (
  checkPaymentsLoading: boolean,
  setCheckPaymentsLoading: (val: boolean) => void,
  name: string,
  lastFour: string
) => {
  const { deleting, deleteSuccess, deleteFailed } = useSelector(
    (state: StoreType) => getDeleteState(state)
  );
  const { showModal, hideModal } = useModal();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClose = () => {
      hideModal();
      dispatch(deletePaymentMethodsResetError());
    };
    deletePaymentMethodFailed(
      deleteFailed,
      showModal,
      handleClose,
      name,
      lastFour,
      checkPaymentsLoading,
      setCheckPaymentsLoading
    );
    deletePaymentMethodSucceeded(deleteSuccess, showModal, handleClose);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleting, deleteSuccess, deleteFailed, lastFour, name]);

  return { deleting, deleteFailed, showModal, hideModal };
};
