import { Frequency } from "../shared/types";
import { PaymentPlan } from "./types";
import {
  getFieldOrDefault,
  getTypeFromProvider
} from "../paymentMethods/utils";

export const mapById = (plans: PaymentPlan[]) => {
  return plans.reduce((acc: Record<string, PaymentPlan>, curr: PaymentPlan) => {
    acc[curr.id] = {
      ...curr,
      paymentMethod: {
        ...curr.paymentMethod,
        type: getFieldOrDefault(
          curr.paymentMethod.type,
          getTypeFromProvider(curr?.paymentMethod?.provider)
        )
      }
    };
    return acc;
  }, {});
};

export const formatFrequency = (frequency: Frequency) => {
  switch (frequency) {
    case Frequency.WEEKLY:
      return "Weekly";
    case Frequency.MONTHLY:
      return "Monthly";
    case Frequency.BIWEEKLY:
      return "Bi-Weekly";
    default:
      return "";
  }
};
