import * as React from "react";

import SetupReminders from "../../components/SetupReminders";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useRemindersLoader } from "../../hooks/useRemindersLoader";

const SetUpRemindersPage: React.FC = () => {
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const { loadingCampaigns } = useRemindersLoader();

  const loading = loadingVendors || locationsLoading || loadingCampaigns;

  return loading ? <SkeletonLoader /> : <SetupReminders />;
};

export default SetUpRemindersPage;
