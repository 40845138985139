import * as React from "react";

import AnnouncementBanner from "../AnnouncementBanner";
import { Announcement } from "../LetMeIn/types";
import { usePrivateAnnouncements } from "../PrivateRouteWrapper";

interface PFGWrapperProps {
  announcements?: Announcement[];
}

const PFGWrapper: React.FC<PFGWrapperProps> = ({ announcements, children }) => {
  const { announcements: privateAnnouncements } = usePrivateAnnouncements();
  const [allAnnouncements, setAllAnnouncements] = React.useState<
    Announcement[]
  >([]);

  React.useEffect(() => {
    if (announcements) {
      setAllAnnouncements([...announcements, ...privateAnnouncements]);
    } else {
      setAllAnnouncements(privateAnnouncements);
    }
  }, [announcements, privateAnnouncements, setAllAnnouncements]);

  return (
    <>
      {allAnnouncements.map((announcement: Announcement) => (
        <AnnouncementBanner key={announcement.id} announcement={announcement} />
      ))}
      {children}
    </>
  );
};

export default PFGWrapper;
