import * as React from "react";
import { MessageText } from "./styled.components";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

const ErrorMessage: React.FC = () => {
  const vendor = useSelector(getSelectedVendor);

  return (
    <MessageText>
      <p>We were unable to load the data for this statement.</p>
      <p>Please refresh your browser window to try again.</p>
      <p>
        If you continue to have difficulties viewing your statement, please
        contact {vendor.name}.
      </p>
    </MessageText>
  );
};

export default ErrorMessage;
