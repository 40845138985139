import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import TextDisplay from "./TextDisplay";
import PanelDisplay from "./PanelDisplay";
import { useForm } from "./useForm";
import { usePublicAnnouncements, useSubmitState } from "./hooks";

const LetMeIn: React.FC = () => {
  const { form, setValue, resetForm } = useForm();
  const [submitError, setSubmitError] = React.useState("");
  const { submitting, submitForm } = useSubmitState(resetForm, setSubmitError);
  const { loadAnnouncements, announcements } = usePublicAnnouncements();

  React.useEffect(() => {
    loadAnnouncements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitError("");
    setValue(e);
  };

  return (
    <CommonWrapper announcements={announcements}>
      <TextDisplay />
      <PanelDisplay
        form={form}
        setValue={setValueHandler}
        submitting={submitting}
        submitForm={submitForm}
        submitError={submitError}
      />
    </CommonWrapper>
  );
};

export default LetMeIn;
