/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Modal, ModalType, ModalSize } from "@billfire/toybox";
import { useSelector } from "react-redux";
import { getSelectedPaymentMethod } from "../../state/entities/paymentMethods/selectors";
import { useModal } from "../ModalProvider";
import { ModalMessage } from "./ConfirmFeeModal/styled";
import { StoreType } from "../../state/types";
import { useState } from "react";

export interface ChangePaymentMethodConfirmFeeModalProps {
  title: string;
  submitButtonText: string;
  submit: (feeConfirmed: boolean) => void;
  onCancel: () => void;
}

const ChangePaymentMethodConfirmFeeModal: React.FC<
  ChangePaymentMethodConfirmFeeModalProps
> = ({ title, submitButtonText, submit, onCancel }) => {
  const { hideModal } = useModal();

  const [loading, setLoading] = useState(false);

  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, true)
  );

  const handleSubmit = () => {
    setLoading(true);
    submit(true);
  };

  const handleClose = () => {
    onCancel();
    hideModal();
  };

  if (!selectedPaymentMethod?.displayExtra.serviceFee) {
    return null;
  }

  return (
    <Modal
      title={title}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={handleClose}
      loading={loading}
      errored={false}
      onSubmit={handleSubmit}
      submitButtonText={submitButtonText}
      cancelButtonText="Cancel"
    >
      <ModalMessage>
        <div>
          A{" "}
          <strong>
            {selectedPaymentMethod.displayExtra.serviceFee} service fee
          </strong>{" "}
          will be added to all payments made when you use the card{" "}
          {selectedPaymentMethod.displayExtra.name}{" "}
          {selectedPaymentMethod.displayExtra.lastFour}.
        </div>
      </ModalMessage>
    </Modal>
  );
};

export default ChangePaymentMethodConfirmFeeModal;
