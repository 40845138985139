import { Invoice } from "../../state/entities/invoices/types";
import Api from "../../state/api";

export const isReadyToLoadPDF = (
  vendorId: string,
  locationId: string,
  invoice: Invoice
) => {
  return !!vendorId && !!locationId && !!invoice.id;
};

export const loadPDF = (url: string, token: string) => {
  return fetch(Api.API_URL(url), Api.GET(token, true, "application/pdf")).then(
    res => {
      if (res.ok) {
        const fileURL = res.blob().then((blob: Blob) => {
          const file = new Blob([blob], { type: "application/pdf" });
          return URL.createObjectURL(file);
        });
        return fileURL;
      } else {
        throw Error("Unable to fetch PDF");
      }
    }
  );
};

export const loadQboPdfUrl = (
  vendorId: string,
  locationId: string,
  extInvoiceId: string,
  token: string
): Promise<string> => {
  return loadPDF(
    `/vendors/${vendorId}/locations/${locationId}/qbopdf/${extInvoiceId}`,
    token
  );
};

export const loadBillfirePdfUrl = (
  vendorId: string,
  locationId: string,
  invoiceId: string,
  token: string
): Promise<string> => {
  return loadPDF(
    `/vendors/${vendorId}/locations/${locationId}/invoices/${invoiceId}/details`,
    token
  );
};

export const loadPdfUrl = async (
  setPdfUrlLoading: (loadingState: {
    loading: boolean;
    loadFailed: boolean;
  }) => void,
  setPdfUrl: (url: string) => void,
  invoice: Invoice,
  vendorId: string,
  locationId: string,
  token: string
) => {
  setPdfUrlLoading({ loading: true, loadFailed: false });

  const readyToLoadPDF = isReadyToLoadPDF(vendorId, locationId, invoice);

  if (invoice.externalUrl) {
    setPdfUrl(invoice.externalUrl);
  } else if (readyToLoadPDF && invoice.createType === "QBO") {
    await loadQboPdfUrl(vendorId, locationId, invoice.externalId, token)
      .then((url: string) => {
        setPdfUrlLoading({ loading: false, loadFailed: false });
        setPdfUrl(url);
      })
      .catch(() => {
        setPdfUrlLoading({ loading: false, loadFailed: true });
        setPdfUrl("");
      });
  } else if (readyToLoadPDF) {
    await loadBillfirePdfUrl(vendorId, locationId, invoice.id, token)
      .then((url: string) => {
        setPdfUrlLoading({ loading: false, loadFailed: false });
        setPdfUrl(url);
      })
      .catch(() => {
        setPdfUrlLoading({ loading: false, loadFailed: true });
        setPdfUrl("");
      });
  }
};
