import * as React from "react";
import { useNavbarDisplay } from "../../hooks/navigation";
import { useLocationFromPath } from "../../hooks/useLocationFromPath";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useVendorFromPath } from "../../hooks/useVendorFromPath";
import {
  Logo,
  VendorContainer,
  VendorLocationContainer,
  Title,
  Location,
  Vendor
} from "./styled";

const VendorLocation: React.FC = () => {
  useLocationsLoader();
  const vendor = useVendorFromPath();
  const location = useLocationFromPath();
  const { shouldDisplayNavbar, routeInfo } = useNavbarDisplay();

  if (!vendor || !location) {
    return null;
  }

  return (
    <>
      <VendorLocationContainer
        key={vendor.id}
        noHeader={!shouldDisplayNavbar && !routeInfo.billfireLogo}
      >
        <VendorContainer>
          <Logo src={vendor.config.logoUrl} />
          <Title>
            <Vendor>{vendor.name}</Vendor>
            <Location>{location.displayName}</Location>
          </Title>
        </VendorContainer>
      </VendorLocationContainer>
    </>
  );
};

export default VendorLocation;
