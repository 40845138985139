import * as React from "react";
import { MessageText } from "./styled.components";

const EmptyMessage: React.FC = () => {
  return (
    <MessageText>
      There are no open credits or invoices at this time.
    </MessageText>
  );
};

export default EmptyMessage;
