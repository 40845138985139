import styled from "styled-components";

interface StyledRowProps {
  hasPaymentMethods?: boolean;
}

export const StyledRow = styled.div<StyledRowProps>`
  cursor: pointer;
  display: flex;
  margin-left: ${props => (props.hasPaymentMethods ? "15px" : "0")};
  height: 70px;
  &:hover * {
    color: #4a4a4a;
  }
`;

export const PlusSymbol = styled.div`
  width: 27px;
  height: 57px;
  font-size: 48px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #d8d8d8;
`;

export const PlusSymbolContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentMethodText = styled.div`
  color: #4a4a4a;
  display: flex;
  width: 100%;
  padding-left: 15px;
  align-self: center;
  font-size: 15px;
`;

export const StyledH3 = styled.h3`
  margin: 31px 38.1px 10px 2px;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.33;
  color: #777;
`;
