import * as React from "react";
import { useRemitForm } from ".";
import { EmptyMessage, InvoiceTable, InvoiceListContainer } from "./styled";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import { isRemitInvoice, PrintingComponentProps } from "./types";
import { getPrintClassName } from "./utils";

const InvoicesList: React.FC<PrintingComponentProps> = ({ printing }) => {
  const { sortedInvoices } = useRemitForm();

  const printClassName = printing ? getPrintClassName(sortedInvoices) : "";

  const emptyMessage = printing ? (
    "There are no open invoices at this time."
  ) : (
    <>
      There are no open invoices currently, but you can still use this remit
      form for any payments.
    </>
  );

  const hasInvoices = sortedInvoices.some(inv => isRemitInvoice(inv));

  return (
    <InvoiceListContainer {...{ hasInvoices }}>
      {!hasInvoices && (
        <EmptyMessage className="empty-message" data-testid="empty-message">
          {emptyMessage}
        </EmptyMessage>
      )}
      <InvoiceTable
        className={`${printClassName} ${hasInvoices ? "invoices" : "blank"}`}
      >
        <TableHeader {...{ printing }} />
        <TableBody printing={printing} />
      </InvoiceTable>
    </InvoiceListContainer>
  );
};

export default InvoicesList;
