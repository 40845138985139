import { PaymentScheduleItem, DataTableRow } from "./types";
import { formatCurrency } from "@billfire/toybox";
import { DateTime } from "luxon";

import api from "../../state/api";
import { PaymentPlanConfirmationApiResponse } from "../../state/entities/paymentPlans/types";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";

export const formatDate = (date: string): string =>
  DateTime.fromISO(date).toFormat("ccc L/d/yy").toUpperCase();

export const formatDataForTable = (
  paymentPlanSchedule: Array<PaymentScheduleItem>
): Array<DataTableRow> =>
  paymentPlanSchedule.map(
    ({ paymentDate, paymentAmount }: PaymentScheduleItem, index) => ({
      rowId: `${index}`,
      paymentNumber: `${index + 1}`,
      date: formatDate(paymentDate),
      amount: formatCurrency(paymentAmount)
    })
  );

export const getLastPaymentDate = (
  paymentPlanSchedule: Array<PaymentScheduleItem>
): string => paymentPlanSchedule[paymentPlanSchedule.length - 1].paymentDate;

export const dataTableColumns = (mobile: boolean) => [
  { name: "paymentNumber", label: "Number", width: mobile ? "100px" : "250px" },
  { name: "date", label: "Date", width: mobile ? "100px" : "250px" },
  { name: "amount", label: "Payment", width: mobile ? "100px" : "250px" }
];

export const submitDisabled = (
  selectedPm: PaymentMethod | undefined,
  selectedVendorId: string
): boolean => {
  return (
    !selectedPm ||
    !selectedPm.id ||
    selectedPm.displayExtra.verificationRequired ||
    !selectedVendorId
  );
};

export interface CreatePaymentPlanPayload {
  vendorId: string;
  paymentMethodId: string;
  epaVersion: string;
}

export const createPaymentPlan = (
  payload: CreatePaymentPlanPayload,
  token: string
): Promise<PaymentPlanConfirmationApiResponse> => {
  return api.postWithToken("/paymentplans", payload, token);
};
