import * as React from "react";
import { TableContainer, StyledConfirmTable, StyledCaption } from "../styled";
import { useExcludedInvoiceSelectors } from "../hooks";
import { confirmationColumns, createConfirmationRows } from "../utils";

const ExcludedInvoices: React.FC = () => {
  const { invoices, excludedInvoiceIds } = useExcludedInvoiceSelectors(true);

  return excludedInvoiceIds.length > 0 ? (
    <TableContainer data-testid="excluded-invoice-table">
      <StyledConfirmTable
        initialData={createConfirmationRows(invoices, excludedInvoiceIds)}
        columns={confirmationColumns}
        TableCaptionComponent={() => (
          <StyledCaption>Excluded Invoices</StyledCaption>
        )}
      ></StyledConfirmTable>
    </TableContainer>
  ) : null;
};

export default ExcludedInvoices;
