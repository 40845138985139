import { formatCurrency, formatShortDate } from "@billfire/toybox";
import AmountInput from "./Row/AmountInput";
import RemitInvoiceLink from "./Row/RemitInvoiceLink";
import RemitInvoiceDueDate from "./Row/RemitInvoiceDueDate";
import {
  getEmptyRemitBaseRow,
  isRemitInvoice,
  RemitBaseRow,
  RemitInvoice,
  TableColumn
} from "./types";
import { Invoice } from "../../state/entities/invoices/types";
import currency from "currency.js";
import { sortInvoices } from "../Click2Pay/Amount/InvoiceList/utils";

export const getColumns = (mobile?: boolean | undefined): TableColumn[] => [
  {
    name: "displayInvNum",
    label: "Invoice",
    width: mobile ? "13%" : "14%",
    Render: RemitInvoiceLink
  },
  {
    name: "externalInvoiceDate",
    label: "Invoice Date",
    width: mobile ? "17%" : "19%",
    formatFn: formatShortDate
  },
  {
    name: "dueDate",
    label: "Due Date",
    width: mobile ? "19%" : "17%",
    Render: RemitInvoiceDueDate,
    formatFn: formatShortDate
  },
  {
    name: "openAmount",
    label: "Open Amount",
    width: mobile ? "21%" : "14%",
    formatFn: formatCurrency
  },
  {
    name: "id",
    label: "Amount Paid/Credited",
    width: mobile ? "22%" : "24%",
    Render: AmountInput
  }
];

export const getBlankColumns = (): TableColumn[] => [
  {
    name: "comment",
    label: "Description",
    width: "70%"
  },
  {
    name: "id",
    label: "",
    width: "5%",
    className: "spacer"
  },
  {
    name: "paymentAmount",
    label: "Amount",
    width: "25%"
  }
];

export const getBlankMobileColumns = (): TableColumn[] => [
  {
    name: "rowId",
    label: "",
    width: "3%"
  },
  {
    name: "comment",
    label: "",
    width: "97%"
  }
];

export const resetInvoice = (
  invoice: RemitInvoice,
  selected: boolean
): RemitInvoice => {
  return {
    ...invoice,
    selected,
    comment: "",
    paymentAmount: invoice.openAmount
  };
};

export const stripNonDigits = (val: string): string => {
  return val.replace(/[^\d.-]/g, "");
};

export const getPrintClassName = (
  invoices: Array<RemitBaseRow | RemitInvoice>
): string => {
  return invoices.some(inv => inv.selected) ? "filled-in-form" : "blank-form";
};

export const mapInvoice = (inv: Invoice): RemitInvoice => {
  return {
    ...inv,
    rowId: inv.id,
    selected: false,
    comment: "",
    paymentAmount: inv.openAmount,
    checkboxProps: { id: inv.id }
  };
};

export const reduceInvoices = (
  invoices: Invoice[]
): Record<string, RemitInvoice> => {
  return invoices.reduce((byId: Record<string, RemitInvoice>, inv) => {
    return { ...byId, [inv.id]: mapInvoice(inv) };
  }, {});
};

export const showComments = (inv: RemitBaseRow): boolean => {
  if (!isRemitInvoice(inv)) {
    return true;
  }

  return (
    inv.selected &&
    currency(inv.openAmount).format() !== currency(inv.paymentAmount).format()
  );
};

export const getBlankRows = (rows: number): RemitBaseRow[] => {
  return new Array(rows).fill(getEmptyRemitBaseRow()).map((row, index) => ({
    ...row,
    id: `${index + 1}`,
    rowId: `${index + 1}`
  }));
};

export const sortRemitRows = (
  rows: Array<RemitBaseRow | RemitInvoice>
): Array<RemitBaseRow | Invoice> => {
  const first = sortInvoices(rows.filter(isRemitInvoice), []);

  return [
    ...first,
    ...rows
      .filter(row => !isRemitInvoice(row))
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
  ];
};
