import { formatCurrency } from "@billfire/toybox";
import React from "react";
import { Invoice } from "../../state/entities/invoices/types";
import {
  Footer,
  FooterRow,
  LeftLabelContainer,
  RightLabelContainer
} from "./styled";
import { calcBalance } from "./utils";

export interface PartialPayTotalsProps {
  invoice: Invoice;
  newAmount: number;
}

const PartialPayTotals: React.FC<PartialPayTotalsProps> = ({
  invoice,
  newAmount
}) => {
  return (
    <Footer>
      <FooterRow>
        <LeftLabelContainer>
          <label>Original Amount</label>
        </LeftLabelContainer>
        <RightLabelContainer>
          <label data-testid="original-amount">
            {formatCurrency(invoice.openAmount)}
          </label>
        </RightLabelContainer>
      </FooterRow>
      <FooterRow>
        <LeftLabelContainer>
          <label>New Payment</label>
        </LeftLabelContainer>
        <RightLabelContainer>
          <label data-testid="new-payment">{formatCurrency(newAmount)}</label>
        </RightLabelContainer>
      </FooterRow>
      <FooterRow>
        <LeftLabelContainer>
          <label>Remaining Open</label>
        </LeftLabelContainer>
        <RightLabelContainer>
          <label data-testid="remaining-open">
            {calcBalance(invoice, newAmount)}
          </label>
        </RightLabelContainer>
      </FooterRow>
    </Footer>
  );
};

export default PartialPayTotals;
