import { Tooltip } from "@billfire/toybox";
import styled, { css } from "styled-components";
import { Bank, CreditCard } from "../../Icon";
import styles from "@billfire/toybox";

const { colors } = styles;
const { baseGray3 } = colors;

export const BankIcon = styled(Bank)`
  height: 14px;
  overflow: visible;
`;

export const CCIcon = styled(CreditCard)`
  height: 14px;
  overflow: visible;
`;

interface PaymentMethodTextProps {
  styleFont: boolean;
  verificationRequired: boolean;
}

export const PaymentMethodText = styled.div<PaymentMethodTextProps>`
  ${props => {
    return props.styleFont
      ? css`
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          color: ${baseGray3};
        `
      : css``;
  }}
  line-height: 1.07;
  letter-spacing: normal;
  padding-left: 0.5rem;
  ${props => (props.verificationRequired ? "color: #c6c6c6" : "")}
`;

export const StyledToolTip = styled(Tooltip)`
  min-width: 20rem;
  max-width: 25rem;
  bottom: 1.5rem;
`;

export const PaymentMethodTitleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PaymentMethodBodyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PaymentMethodNotVerified = styled.div`
  background-color: rgb(235, 235, 235);
  color: rgb(84, 89, 105);
  font-size: 12px;
  padding: 1px 6px;
  margin-right: 4px;
  margin-top: 2px;
  border-radius: 4px;
`;

BankIcon.displayName = "BankIcon";
CCIcon.displayName = "CCIcon";
