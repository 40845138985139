import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as types from "./types";
import * as utils from "./utils";

export const initialState: types.CampaignsState = {
  byId: {},
  loadingCampaigns: false,
  loadCampaignsFailed: false,
  loadedCampaigns: false,
  creatingCampaign: false,
  createdCampaign: false,
  createCampaignFailed: false,
  deletingCampaign: false,
  deletedCampaign: false,
  deleteCampaignFailed: false,
  contactId: "",
  deliverTo: ""
};

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    loadCampaignsSubmit: (state: types.CampaignsState) => {
      state.loadingCampaigns = true;
      state.loadedCampaigns = false;
      state.loadCampaignsFailed = false;
    },
    loadCampaignsSuccess: (
      state: types.CampaignsState,
      action: PayloadAction<{
        campaigns: Record<string, types.Campaign>;
        contactId: string;
        deliverTo: string;
      }>
    ) => {
      state.loadingCampaigns = false;
      state.loadedCampaigns = true;
      state.loadCampaignsFailed = false;
      state.byId = action.payload.campaigns;
      state.contactId = action.payload.contactId;
      state.deliverTo = action.payload.deliverTo;
    },
    loadCampaignsFailed: (state: types.CampaignsState) => {
      state.loadingCampaigns = false;
      state.loadedCampaigns = false;
      state.loadCampaignsFailed = true;
      state.contactId = "";
      state.deliverTo = "";
    },
    createCampaignSubmit: (
      state: types.CampaignsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ eventDay: number; contactId: string }>
    ) => {
      state.creatingCampaign = true;
      state.createdCampaign = false;
      state.createCampaignFailed = false;
    },
    createCampaignSuccess: (
      state: types.CampaignsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<types.Campaign>
    ) => {
      state.creatingCampaign = false;
      state.createdCampaign = true;
      state.createCampaignFailed = false;
    },
    createCampaignFailed: (state: types.CampaignsState) => {
      state.creatingCampaign = false;
      state.createdCampaign = false;
      state.createCampaignFailed = true;
    },
    deleteCampaignSubmit: (
      state: types.CampaignsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<types.Campaign["id"]>
    ) => {
      state.deletingCampaign = true;
      state.deletedCampaign = false;
      state.deleteCampaignFailed = false;
    },
    deleteCampaignSuccess: (
      state: types.CampaignsState,
      _action: PayloadAction<types.Campaign["id"]>
    ) => {
      state.byId = utils.withoutDeleted(state.byId, _action.payload);
      state.deletingCampaign = false;
      state.deletedCampaign = true;
      state.deleteCampaignFailed = false;
    },
    deleteCampaignFailed: (state: types.CampaignsState) => {
      state.deletingCampaign = false;
      state.deletedCampaign = false;
      state.deleteCampaignFailed = true;
    },
    resetCampaignsState: (state: types.CampaignsState) => {
      state.deletingCampaign = false;
      state.deletedCampaign = false;
      state.deleteCampaignFailed = false;
      state.creatingCampaign = false;
      state.createdCampaign = false;
      state.createCampaignFailed = false;
      state.loadingCampaigns = false;
      state.loadedCampaigns = false;
      state.loadCampaignsFailed = false;
    },
    resetStore: () => initialState
  }
});

export const {
  loadCampaignsSubmit,
  loadCampaignsSuccess,
  loadCampaignsFailed,
  createCampaignSubmit,
  createCampaignSuccess,
  createCampaignFailed,
  deleteCampaignSubmit,
  deleteCampaignSuccess,
  deleteCampaignFailed,
  resetCampaignsState,
  resetStore
} = campaignsSlice.actions;

export default campaignsSlice.reducer;
