// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView from "../PrintExportComponent/ReportPrintView";
import PaymentTables from "./PaymentsTables";
import { ReportPrintViewProps } from "../PrintExportComponent/ReportPrintView";
import { PaymentStatus } from "../../state/entities/paymentGroups/types";
import { SortBy } from "./types";

interface PaymentReportPrintViewProps extends ReportPrintViewProps {
  sortBys: Record<PaymentStatus, SortBy>;
  setSortBys: (sortBys: Record<PaymentStatus, SortBy>) => void;
}

const PaymentReportPrintView: React.FC<PaymentReportPrintViewProps> = props => {
  return (
    <>
      <ReportPrintView printRef={props.printRef} headerText={props.headerText}>
        <PaymentTables sortBys={props.sortBys} setSortBys={props.setSortBys} />
      </ReportPrintView>
    </>
  );
};

export default PaymentReportPrintView;
