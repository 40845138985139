import * as React from "react";

import {
  AddressDiv,
  AddressLabel,
  AddressLine,
  AddressSubLabel,
  LabelBlock
} from "./styled";

interface AddressBlockProps {
  name: string;
  address: {
    postalCode?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
  };
  label: string;
  subLabel?: string;
}

const AddressBlock: React.FC<AddressBlockProps> = ({
  address,
  label,
  subLabel,
  name
}) => {
  const { postalCode, addressLine1, addressLine2, city, state } = address;

  const lastLine = [
    city,
    `${state ? state : ""} ${postalCode ? postalCode : ""}`.trim()
  ]
    .filter(part => part && part.trim().length > 0)
    .join(", ");

  return (
    <AddressDiv>
      <LabelBlock>
        <AddressLabel>{label}</AddressLabel>
        {subLabel && <AddressSubLabel>{subLabel}</AddressSubLabel>}
      </LabelBlock>
      <AddressLine>{name}</AddressLine>
      {addressLine1 && <AddressLine>{addressLine1}</AddressLine>}
      {addressLine2 && <AddressLine>{addressLine2}</AddressLine>}
      {lastLine && <AddressLine>{lastLine}</AddressLine>}
    </AddressDiv>
  );
};

export default AddressBlock;
