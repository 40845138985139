export interface NavigationConfig {
  navFn: string;
  isNavigatable?: Function;
  billfireLogo?: boolean;
  showVendorTitleComponent?: boolean;
}

const navigationConfiguration: {
  [key: string]: NavigationConfig;
} = {
  ["/token/:token/vendors/:vendorId/locations"]: {
    navFn: "changeLocationOrVendorNav",
    isNavigatable: (vendorCount: number) => vendorCount > 1,
    billfireLogo: false,
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId"]: {
    navFn: "changeLocationOrVendorNav",
    isNavigatable: (vendorCount: number, locationCount: number) =>
      vendorCount > 1 || locationCount > 1,
    billfireLogo: false,
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/click2pay"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/click2pay/:paymentGroupId/confirmation"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/click2pay/:invoiceId/dispute"]:
    {
      navFn: "backNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/click2pay/:invoiceId/partial"]:
    {
      navFn: "backNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/invoices"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/payments"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/payments/:paymentGroupId"]:
    {
      navFn: "backNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/invoices/:invoiceId"]:
    {
      navFn: "backNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/paymentmethods/new"]:
    {
      navFn: "backNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/autopay/:requestId"]:
    {
      navFn: "dashboardNav"
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/planrequests/:requestId"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/autopay"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/planrequests/:requestId/confirmation"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/statement"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/plans"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/plans/:planId"]: {
    navFn: "backNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/messages"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/autopaymanage"]: {
    navFn: "dashboardNav",
    showVendorTitleComponent: true
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/autopaymanage/confirmation"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/autopaydelete/confirmation"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/autopayconfirmation"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/plans/:planId/update"]:
    {
      navFn: "backNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/plans/:planId/update/confirmation"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/click2pay/disputed"]:
    {
      navFn: "dashboardNav",
      showVendorTitleComponent: true
    },
  ["/token/:token/vendors/:vendorId/locations/:locationId/remit"]: {
    navFn: "backNav"
  },
  ["/token/:token/vendors/:vendorId/locations/:locationId/reminders"]: {
    navFn: "backNav",
    showVendorTitleComponent: true
  }
};
export default navigationConfiguration;
