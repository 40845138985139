// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import CSVExportLink from "./CSVExportLink/CSVExportLink";
import { Container } from "./styled";
import PrintLink from "./PrintLink";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";

interface PrintExportComponentProps {
  documentTitle: string;
  printRef?: React.RefObject<HTMLDivElement>;
  csvData?: object[];
  headers?: LabelKeyObject[];
  dateSubString?: string;
}

const PrintExportComponent: React.FC<PrintExportComponentProps> = ({
  printRef,
  documentTitle,
  csvData,
  headers,
  dateSubString
}) => {
  return (
    <Container>
      {!!csvData && !!headers && (
        <CSVExportLink
          documentTitle={documentTitle}
          csvData={csvData}
          headers={headers}
          dateSubString={dateSubString}
        />
      )}
      {!!printRef && (
        <PrintLink documentTitle={documentTitle} printRef={printRef} />
      )}
    </Container>
  );
};

export default PrintExportComponent;
