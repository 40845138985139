import styled from "styled-components";
import { Spinner, XIcon } from "../../Icon";

export const StyledXIcon = styled(XIcon)`
  padding-right: 12px;
`;

export const StyledSpinner = styled(Spinner)`
  width: 10px;
  height: 10px;
  align-self: center;
  margin-right: 12px;
`;
