import { getGlobalFeatureFlags } from "../state/entities/shared/selectors";
import { useSelector } from "react-redux";
import { featureEnabled } from "../state/entities/shared/utils";
import { getSelectedVendor } from "../state/entities/vendors/selectors";
import { FeatureFlag } from "../state/entities/shared/types";

export const useFeatureFlag = (feature: FeatureFlag): boolean => {
  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);

  return featureEnabled(vendor, globalFeatureFlags, feature);
};
