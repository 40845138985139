import styled from "styled-components";

export const AddInvoiceCommentContainer = styled.div`
  .addInvoiceComment {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    ::placeholder {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #a4a4a4;
    }
  }
`;
