// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView, {
  ReportPrintViewProps
} from "../PrintExportComponent/ReportPrintView";
import { StyledDataTable, StyledTotalsAndFees } from "./styled";
import { formatShortDate, SortDirection } from "@billfire/toybox";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import {
  PaymentDetail,
  PaymentGroupWithMethod
} from "../../pages/PaymentDetailPage/types";
import DetailRow from "./DetailRow";
import { translatePowerPayType } from "../../utils";
import { paidBy } from "../../state/entities/paymentGroups/utils";
import PaymentMethodDetailRow from "./PaymentMethodDetailRow";

interface MappedPaymentDetail extends PaymentDetail {
  rowId: string;
}

interface PaymentDetailPrintViewProps extends ReportPrintViewProps {
  initialData: MappedPaymentDetail[];
  columns: Column[];
  sortBy: string;
  sortDirection: SortDirection;
  paymentGroup: PaymentGroupWithMethod;
  customerDisplayName: string | undefined;
  subtitleText: string;
}

export const PaymentDetailPrintView: React.FC<PaymentDetailPrintViewProps> = ({
  printRef,
  headerText,
  initialData,
  columns,
  sortBy,
  sortDirection,
  paymentGroup,
  customerDisplayName,
  subtitleText
}) => {
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        <DetailRow
          titleText="Confirmation"
          detailText={paymentGroup.confirmationNumber}
        />
        <DetailRow
          titleText="Payment Date"
          detailText={formatShortDate(paymentGroup.paymentDate)}
        />
        <StyledTotalsAndFees
          subTotalTitle={subtitleText}
          totalWithFeesTitle="Payment Total"
          totalBeforeFees={paymentGroup.paymentTotal}
          paymentMethod={paymentGroup.paymentMethod}
        />
        <DetailRow
          titleText="Payment Type"
          detailText={translatePowerPayType(paymentGroup.paymentType)}
        />
        <DetailRow titleText="Paid By" detailText={paidBy(paymentGroup)} />
        <PaymentMethodDetailRow
          name={paymentGroup.paymentMethod.displayExtra.name}
          lastFour={paymentGroup.paymentMethod.displayExtra.lastFour}
        />
        <DetailRow
          titleText="Method Type"
          detailText={customerDisplayName || ""}
        />
        {paymentGroup.paymentPlanName ? (
          <>
            <DetailRow
              titleText="Plan ID"
              detailText={paymentGroup.paymentPlanName}
            />
          </>
        ) : (
          <>
            <StyledDataTable
              initialData={initialData}
              columns={columns}
              externalSortBy={sortBy}
              externalSortDirection={sortDirection}
            />
          </>
        )}
      </ReportPrintView>
    </>
  );
};

export default PaymentDetailPrintView;
