import * as React from "react";
import PaymentPlanDetail from "../../components/PaymentPlanDetail";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { usePaymentPlansLoader } from "../../hooks/usePaymentPlansLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";

const PaymentPlanDetailPage: React.FC = () => {
  const { paymentPlansLoadingState } = usePaymentPlansLoader();
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();

  return paymentPlansLoadingState.loadingPaymentPlans ||
    loadingVendors ||
    locationsLoading ||
    !paymentPlansLoadingState.loadedPaymentPlans ? (
    <SkeletonLoader />
  ) : (
    <PaymentPlanDetail />
  );
};

export default PaymentPlanDetailPage;
