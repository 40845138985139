import { PaymentMethod } from "../../state/entities/paymentMethods/types";
import currency from "currency.js";

export const calculateServiceFee = (
  paymentMethod: PaymentMethod,
  subtotal: number
): currency => {
  const displayExtra = paymentMethod.displayExtra;
  if (displayExtra.serviceFee) {
    const convertedFee = parseFloat(displayExtra.serviceFee) / 100.0;
    return currency(subtotal).multiply(convertedFee);
  } else {
    return currency(0);
  }
};

export const paymentMethodHasAFee = (
  paymentMethod: PaymentMethod | undefined
) => !!paymentMethod?.displayExtra?.serviceFee;
