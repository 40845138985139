import styled from "styled-components";

export const StyledText = styled.div`
  color: #a4a4a4;
  font-family: Roboto;
  font-size: 21px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  padding-right: 15px;
`;

export const Container = styled.div`
  display: flex;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
`;

export const InvoiceLinkContainer = styled.div`
  max-width: 200px;
`;
