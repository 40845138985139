import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../state/api";
import { Announcement } from "../LetMeIn/types";

const useVendorAnnouncements = (vendorId: string, token: string) => {
  const [vendorAnnouncements, setVendorAnnouncements] = useState<
    Announcement[]
  >([]);

  useEffect(() => {
    setVendorAnnouncements([]);
    if (vendorId) {
      fetch(
        api.API_URL(`/vendors/${vendorId}/announcements`),
        api.GET(token, true)
      ).then(async res => {
        if (res.ok) {
          const json = await res.json();
          setVendorAnnouncements(json.announcements);
        }
      });
    }
  }, [vendorId, token]);

  return vendorAnnouncements;
};

const useGlobalAnnouncements = (token: string) => {
  const [globalAnnouncements, setGlobalAnnouncements] = useState<
    Announcement[]
  >([]);

  useEffect(() => {
    fetch(api.API_URL("/announcements"), api.GET(token, true)).then(
      async res => {
        if (res.ok) {
          const json = await res.json();
          setGlobalAnnouncements(json.announcements);
        }
      }
    );
  }, [token]);

  return globalAnnouncements;
};

export const useLoadPrivateAnnouncements = (authToken: string) => {
  const { vendorId } = useParams<{ vendorId: string }>();

  const vendorAnnouncements = useVendorAnnouncements(vendorId, authToken);
  const globalAnnouncements = useGlobalAnnouncements(authToken);

  return {
    globalAnnouncements,
    vendorAnnouncements
  };
};
