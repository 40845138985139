import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentPlanTypes as types } from "./index";

export const initialState: types.PaymentPlanState = {
  byId: {},
  loadingPaymentPlans: false,
  loadedPaymentPlans: false,
  loadPaymentPlansFailed: false,
  updating: false,
  updated: false,
  updateFailed: false
};

export const paymentPlans = createSlice({
  name: "paymentPlans",
  initialState,
  reducers: {
    loadPaymentPlans: (state: types.PaymentPlanState) => {
      state.loadingPaymentPlans = true;
      state.loadedPaymentPlans = false;
      state.loadPaymentPlansFailed = false;
    },
    loadPaymentPlansSuccess: (
      state: types.PaymentPlanState,
      action: PayloadAction<Record<string, types.PaymentPlan>>
    ) => {
      state.loadingPaymentPlans = false;
      state.loadedPaymentPlans = true;
      state.loadPaymentPlansFailed = false;
      state.byId = action.payload;
    },
    loadPaymentPlansFailed: (state: types.PaymentPlanState) => {
      state.loadingPaymentPlans = false;
      state.loadedPaymentPlans = false;
      state.loadPaymentPlansFailed = true;
    },
    updating: (state: types.PaymentPlanState) => {
      state.updating = true;
      state.updated = false;
      state.updateFailed = false;
    },
    updated: (state: types.PaymentPlanState) => {
      state.updating = false;
      state.updated = true;
      state.updateFailed = false;
    },
    updateFailed: (state: types.PaymentPlanState) => {
      state.updating = false;
      state.updated = false;
      state.updateFailed = true;
    },
    resetUpdateState: (state: types.PaymentPlanState) => {
      state.updating = false;
      state.updated = false;
      state.updateFailed = false;
    },
    resetStore: () => initialState
  }
});

export const {
  loadPaymentPlans,
  loadPaymentPlansSuccess,
  loadPaymentPlansFailed,
  updating,
  updated,
  updateFailed,
  resetUpdateState,
  resetStore
} = paymentPlans.actions;

export default paymentPlans.reducer;
