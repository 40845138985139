import React from "react";
import DetailRow from "../../DetailRow";
import { formatShortDate } from "@billfire/toybox";
import { useSelector } from "react-redux";
import { StoreType } from "../../../state/types";
import { getSelectedPaymentMethod } from "../../../state/entities/paymentMethods/selectors";
import { getActiveAutopayStartDate } from "../../../state/entities/autopay/selectors";
import { useActiveAutopay } from "../hooks";

const ConfirmationBody: React.FC = () => {
  const { selectedPaymentMethod, startDate } = useSelector(
    (state: StoreType) => ({
      selectedPaymentMethod: getSelectedPaymentMethod(state, true),
      startDate: getActiveAutopayStartDate(state)
    })
  );
  const activeAutopay = useActiveAutopay();

  return (
    <>
      <DetailRow
        titleText="Payment Method"
        detailText={`${
          selectedPaymentMethod?.displayExtra.name ||
          activeAutopay?.paymentMethod.displayExtra.name
        } ${
          selectedPaymentMethod?.displayExtra.lastFour ||
          activeAutopay?.paymentMethod.displayExtra.lastFour
        }`}
      />
      <DetailRow
        titleText="Start Date"
        detailText={formatShortDate(startDate)}
      />
    </>
  );
};

export default ConfirmationBody;
