import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { setSelectedPaymentMethod } from "../../../state/entities/paymentMethods/slice";
import { getActiveAutopayPaymentMethodId } from "../../../state/entities/autopay/selectors";
import {
  updateActiveAutopayExcludedInvoices,
  updateActiveAutopayPaymentMethod,
  deleteAutopay
} from "../../../state/entities/autopay/slice";
import { getSelectedVendorId } from "../../../state/entities/vendors/selectors";
import { getSelectedLocationId } from "../../../state/entities/locations/selectors";
import { getAuthToken, getEpa } from "../../../state/entities/shared/selectors";
import { StoreType } from "../../../state/types";
import {
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../../state/entities/paymentMethods/selectors";
import {
  getActiveAutopayExcludedInvoices,
  getExcludedInvoices,
  getActiveAutopayId
} from "../../../state/entities/autopay/selectors";
import { useParams } from "react-router-dom";
import { getOpenInvoices } from "../../../state/entities/invoices/selectors";
import {
  isFormValidCheck,
  arraysEqual,
  updateAutopayPaymentMethod,
  updateAutopayExcludedInvoices,
  getReIncludedInvoiceIds,
  handlePaymentMethodStatus,
  handledExcludedInvoicesStatus,
  UpdatePaymentMethodHandleStatusParams,
  ExcludeInvoicesHandleStatusParams
} from "../utils";
import { modalTypes, useModal } from "../../../globalModal";

export const useSetInitialSelectedPaymentMethod = () => {
  const dispatch = useDispatch();
  const activeAutopayPaymentMethodId = useSelector(
    getActiveAutopayPaymentMethodId
  );

  const location: { state?: { selectedPaymentMethodId?: string } } =
    useLocation();

  React.useEffect(() => {
    const selectedPaymentMethodId = location.state?.selectedPaymentMethodId
      ? location.state.selectedPaymentMethodId
      : activeAutopayPaymentMethodId;

    dispatch(
      setSelectedPaymentMethod({
        paymentMethodId: selectedPaymentMethodId
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
};

export const useGoToLocationDashboard = () => {
  const token = useSelector(getAuthToken);
  const selectedVendorId = useSelector(getSelectedVendorId);
  const selectedLocationId = useSelector(getSelectedLocationId);
  const history = useHistory();

  return () =>
    history.push(
      `/token/${token}/vendors/${selectedVendorId}/locations/${selectedLocationId}`
    );
};

const useAutopaySelectors = () => {
  const selections = useSelector((state: StoreType) => {
    return {
      selectedPaymentMethod: getSelectedPaymentMethod(state, true),
      autopayPaymentMethodId: getActiveAutopayPaymentMethodId(state),
      excludedInvoiceIds: getExcludedInvoices(state),
      activeAutopayExcludedInvoiceIds: getActiveAutopayExcludedInvoices(state),
      allInvoiceIds: getOpenInvoices(state).map(inv => inv.id),
      autopayId: getActiveAutopayId(state),
      provider: getSelectedPaymentMethodProvider(state)
    };
  });

  const selectedPaymentMethod = selections.selectedPaymentMethod;
  const isUpdatePaymentMethod =
    selections.selectedPaymentMethod &&
    selections.selectedPaymentMethod.id !== selections.autopayPaymentMethodId;
  const isUpdateExcludedInvoices = !arraysEqual(
    selections.excludedInvoiceIds,
    selections.activeAutopayExcludedInvoiceIds
  );
  const updatePaymentMethodPayload = {
    paymentMethodId: selectedPaymentMethod?.id || "",
    paymentMethodName: selectedPaymentMethod?.displayExtra.name || "",
    paymentMethodLastFour: selectedPaymentMethod?.displayExtra.lastFour || ""
  };

  return {
    ...selections,
    isUpdatePaymentMethod,
    isUpdateExcludedInvoices,
    updatePaymentMethodPayload
  };
};

const useFeeModal = () => {
  const { showModal, hideModal } = useModal();
  const selections = useAutopaySelectors();

  const showFeeModal = (
    submit: (feeConfirmed: boolean) => void,
    onCancel: () => void
  ) => {
    if (
      selections.isUpdatePaymentMethod &&
      selections.selectedPaymentMethod?.displayExtra.serviceFee
    ) {
      showModal({
        type: modalTypes.CHANGE_PAYMENT_METHOD_CONFIRM_MODAL,
        props: {
          title: "Update AutoPay Plus?",
          submitButtonText: "Update",
          submit,
          onCancel
        }
      });
      return true;
    }
    return false;
  };

  return { showFeeModal, hideFeeModal: hideModal };
};

const useAutopayUpdatePromises = () => {
  const { vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
  }>();

  const selections = useAutopaySelectors();

  const { epaVersion, authToken } = useSelector((state: StoreType) => ({
    authToken: getAuthToken(state),
    epaVersion: getEpa(state, selections.provider).version
  }));

  const autopayUpdatePromises = () => {
    const promises = [];
    if (selections.isUpdatePaymentMethod)
      promises.push(
        updateAutopayPaymentMethod(
          authToken,
          vendorId,
          locationId,
          selections.autopayId,
          {
            paymentMethodId: selections.selectedPaymentMethod?.id,
            epaVersion
          }
        )
      );

    if (selections.isUpdateExcludedInvoices)
      promises.push(
        updateAutopayExcludedInvoices(
          authToken,
          vendorId,
          locationId,
          selections.autopayId,
          {
            excludeInvoiceIds: selections.excludedInvoiceIds,
            reincludeInvoiceIds: getReIncludedInvoiceIds(
              selections.excludedInvoiceIds,
              selections.allInvoiceIds
            )
          }
        )
      );

    return promises;
  };

  return { autopayUpdatePromises };
};

export const useSubmit = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { showFeeModal, hideFeeModal } = useFeeModal();
  const { autopayUpdatePromises } = useAutopayUpdatePromises();

  const { vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
  }>();
  const { authToken } = useSelector((state: StoreType) => ({
    authToken: getAuthToken(state)
  }));

  const selections = useAutopaySelectors();

  const dispatch = useDispatch();

  const pathname = `/token/${authToken}/vendors/${vendorId}/locations/${locationId}/autopaymanage/confirmation`;

  React.useEffect(() => {
    setErrorMessage("");
  }, [selections.selectedPaymentMethod, selections.excludedInvoiceIds]);

  const submit = (feeConfirmed: boolean) => {
    setErrorMessage("");
    setSubmitting(true);

    if (!feeConfirmed && showFeeModal(submit, () => setSubmitting(false))) {
      return;
    }

    const promises = autopayUpdatePromises();

    Promise.allSettled(promises).then(results => {
      setSubmitting(false);

      if (feeConfirmed) hideFeeModal();

      const errors: string[] = [];

      results.forEach((res, i) => {
        const stdParams = { res, dispatch, errors };
        const updatePaymentMethodParams: UpdatePaymentMethodHandleStatusParams =
          {
            ...stdParams,
            action: updateActiveAutopayPaymentMethod,
            payload: selections.updatePaymentMethodPayload,
            error: "Could not update payment method."
          };
        const updateExcludedInvoicesParams: ExcludeInvoicesHandleStatusParams =
          {
            ...stdParams,
            action: updateActiveAutopayExcludedInvoices,
            payload: selections.excludedInvoiceIds,
            error: "Could not update invoice exclusions."
          };
        if (results.length === 2 && i === 0)
          handlePaymentMethodStatus(updatePaymentMethodParams);
        else if (results.length === 2 && i === 1)
          handledExcludedInvoicesStatus(updateExcludedInvoicesParams);
        else if (results.length === 1 && selections.isUpdatePaymentMethod)
          handlePaymentMethodStatus(updatePaymentMethodParams);
        else if (results.length === 1 && selections.isUpdateExcludedInvoices)
          handledExcludedInvoicesStatus(updateExcludedInvoicesParams);
      });

      if (errors.length === 2) {
        setErrorMessage("Could not update AutoPay Plus.");
      } else if (errors.length === 1) {
        setErrorMessage(errors[0]);
      } else {
        setConfirmed(true);
      }
    });
  };

  return { submit, submitting, confirmed, errorMessage, pathname };
};

export const useAutopayForm = () => {
  const apValues = useAutopaySelectors();

  const [checked, setChecked] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsFormValid(
      isFormValidCheck(
        checked,
        apValues.selectedPaymentMethod,
        apValues.autopayPaymentMethodId,
        apValues.excludedInvoiceIds,
        apValues.activeAutopayExcludedInvoiceIds
      )
    );
  }, [checked, apValues]);

  return {
    checked,
    setChecked,
    isFormValid
  };
};

export const useDeleteAutopayModal = () => {
  const { showModal, hideModal } = useModal();
  const dispatch = useDispatch();
  const openModal = () => {
    showModal({
      type: modalTypes.CONFIRM_AUTOPAY_DELETE_MODAL,
      props: {
        title: "Turn off AutoPay Plus?",
        message:
          "If you turn off AutoPay Plus, any pending or future payments tied to this location will be canceled. This may cause some invoices to become past-due.",
        onClose: hideModal,
        actions: {
          submitButtonText: "Turn Off",
          cancelButtonText: "Keep On",
          onSubmit: () => {
            dispatch(deleteAutopay());
          }
        }
      }
    });
  };

  return openModal;
};
