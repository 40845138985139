import * as React from "react";
import { StyledTable, NoInvoicesMessage, StyledReadonlyTable } from "../styled";
import { useExcludedInvoiceSelectors, useSetExcludedInvoices } from "../hooks";
import { columns, createRows, mapSelectedInvoices } from "../utils";
import InvoiceCheckbox from "./InvoiceCheckbox";

export interface ExcludedInvoicesContentProps {
  setup: boolean;
}

const ExcludedInvoicesContent: React.FC<ExcludedInvoicesContentProps> = ({
  setup
}) => {
  const { invoices, excludedInvoiceIds, readOnly } =
    useExcludedInvoiceSelectors(setup);

  const setExcludedInvoices = useSetExcludedInvoices(setup);

  const [selectedMap, setSelectedMap] = React.useState({});
  React.useEffect(() => {
    setSelectedMap(mapSelectedInvoices(invoices, excludedInvoiceIds));
  }, [excludedInvoiceIds, setSelectedMap, invoices]);

  if (readOnly && excludedInvoiceIds.length === 0) {
    return (
      <NoInvoicesMessage>There are no invoices excluded.</NoInvoicesMessage>
    );
  }

  if (invoices.length === 0) {
    return (
      <NoInvoicesMessage>
        {`There are no invoices at this location that can be excluded at this time.`}
      </NoInvoicesMessage>
    );
  }

  return readOnly ? (
    <StyledReadonlyTable
      initialData={createRows(invoices, excludedInvoiceIds, readOnly)}
      columns={columns}
    ></StyledReadonlyTable>
  ) : (
    <StyledTable
      selectAll={false}
      selected={selectedMap}
      setSelected={setExcludedInvoices}
      initialData={createRows(invoices, excludedInvoiceIds, readOnly)}
      columns={columns}
      selectTitle={"Exclude"}
      CheckboxComponent={InvoiceCheckbox}
    ></StyledTable>
  );
};

export default ExcludedInvoicesContent;
