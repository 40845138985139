import { useEffect, useLayoutEffect, useRef, useState } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const getChopAdd = (text: string): number => {
  const { length } = text;

  if (length > 1000) {
    return 500;
  } else if (length > 500) {
    return 50;
  } else if (length > 100) {
    return 25;
  } else if (length > 50) {
    return 10;
  } else if (length > 30 && length < 50) {
    return 3;
  } else {
    return 1;
  }
};

export const useTextChop = (
  text: string,
  minEllipsesWidth: number | undefined
) => {
  const [chop, setChop] = useState(0);
  const [lastScreenWidth, setLastScreenWidth] = useState(0);

  const [screenWidth] = useWindowSize();

  const ref = useRef<HTMLElement>(null);

  const parentWidth =
    ref.current?.parentElement?.parentElement?.offsetWidth || 0;

  useEffect(() => {
    if (ref.current) {
      const overflow = ref.current.offsetWidth > parentWidth;
      if (minEllipsesWidth && minEllipsesWidth > parentWidth) return;
      if (overflow) {
        const chopAdd = ref.current.innerText
          ? getChopAdd(ref.current.innerText)
          : 1;

        setChop(chop + chopAdd);
        setLastScreenWidth(screenWidth);
      } else if (lastScreenWidth !== screenWidth) {
        setChop(chop > 0 ? chop - 1 : 0);
      }
    }
  }, [text, chop, parentWidth, lastScreenWidth, screenWidth, minEllipsesWidth]);

  return { chop, ref };
};
