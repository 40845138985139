import styled from "styled-components";
import { device } from "../../breakpoints";

export const HoverContainer = styled.div.attrs({
  "data-testid": "hover-container"
})`
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #e6eef8;
    cursor: pointer;
  }
`;

export const VendorContainer = styled.div.attrs({
  "data-testid": "vendor-container"
})`
  height: 159px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #eaf0f7;
  background-color: #fff;

  @media only screen and (${device.tablet}) {
    height: 70px;
  }
`;
export const Logo = styled.img.attrs({
  "data-testid": "vendor-logo"
})`
  max-width: 250px;
  max-height: 60px;
  height: auto;
  width: auto;

  @media only screen and (${device.tablet}) {
    max-height: 45px;
    max-width: 60px;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 20px;

  @media only screen and (${device.tablet}) {
    grid-template-rows: none;
    grid-template-columns: 25% 75%;
    grid-gap: 10px;
    padding: 0 0 0 10px;
  }
`;

export const LogoContainer = styled.div`
  margin: auto;
  height: 60px;
  display: flex;
  align-items: center;

  @media only screen and (${device.tablet}) {
    height: 45px;
  }
`;

export const VendorNameContainer = styled.div`
  height: 31px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;

  @media only screen and (${device.tablet}) {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
  }
`;
