import { DateSelectProps } from "./DateSelect";

export const getDayClassName = (
  date: Date,
  filterDate: DateSelectProps["filterDate"]
): string => {
  return `cal-text day ${!filterDate(date) ? "disabled" : ""}`;
};

export const formatWeekDay = (nameOfDay: string) => nameOfDay.substr(0, 3);
