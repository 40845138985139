import styled from "styled-components";
import styles from "@billfire/toybox";

const { colors } = styles;
const { baseGray3 } = colors;

export const Header = styled.div`
  text-align: center;
  padding-bottom: 30px;
`;

export const ConfirmationNum = styled.div`
  color: ${baseGray3};
  font-size: 24px;
`;

export const HeaderSectionTitle = styled.div`
  color: #a4a4a4;
  font-size: 14px;
  padding-bottom: 5px;
`;

export const StatementDescriptorTitle = styled.div`
  color: #a4a4a4;
  font-size: 14px;
  padding-bottom: 5px;
  padding-top: 30px;
`;

export const StatementDescriptorContainer = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
`;
