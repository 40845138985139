import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { loadMessagesSubmit } from "../state/entities/messages/slice";
import { getMessagesLoadingFlags } from "../state/entities/messages/selectors";
import { useNavigation } from "./route";

export const useMessagesLoader = (
  {
    onlyMostRecent = true,
    contactNotRead = true
  }: { onlyMostRecent?: boolean; contactNotRead?: boolean } = {
    onlyMostRecent: true,
    contactNotRead: true
  }
) => {
  const dispatch = useDispatch();
  const { loadingMessages, loadMessagesFailed } = useSelector(
    (state: StoreType) => getMessagesLoadingFlags(state)
  );
  const { vendorId, locationId } = useNavigation();

  React.useEffect(() => {
    if (vendorId && locationId) {
      dispatch(loadMessagesSubmit({ onlyMostRecent, contactNotRead }));
    }
  }, [dispatch, vendorId, locationId, onlyMostRecent, contactNotRead]);

  return { loadingMessages, loadMessagesFailed };
};
