import { Invoice } from "../../state/entities/invoices/types";

export interface InvoiceRow extends Invoice {
  rowId: string;
  status: string;
}

export type SortDirection = "ASC" | "DESC";

enum InvoiceReportColumnName {
  displayInvNum = "displayInvNum",
  externalInvoiceDate = "externalInvoiceDate",
  dueDate = "dueDate",
  openAmount = "openAmount",
  status = "status"
}

export const isInvoiceReportColumnName = (
  colName?: string | null
): colName is InvoiceReportColumnName => {
  return Object.values(InvoiceReportColumnName).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    colName as unknown as InvoiceReportColumnName
  );
};
