import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentMethodTypes as types } from "./index";
import {
  PaymentMethodsState,
  getEmptyConstructingPaymentMethodACH,
  SetCreatingPaymentMethodTokenPayload,
  LoadPaymentMethodsSubmit
} from "./types";

export const initialState: types.PaymentMethodsState = {
  byId: {},
  jwt: "",
  clientFlowInfoMap: {},
  loading: false,
  loaded: false,
  loadFailed: false,
  selectedPaymentMethodId: "",
  deleting: false,
  deleteSuccess: false,
  deleteFailed: false,
  creating: false,
  creatingTokenized: false,
  createSuccess: false,
  createFailed: false,
  createFailedCode: "",
  creatingPaymentMethod: { ...getEmptyConstructingPaymentMethodACH() },
  creatingPaymentMethodToken: {
    key: "",
    providerResponse: {}
  }
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    createPaymentMethod: (state: PaymentMethodsState) => {
      state.creating = true;
      state.creatingTokenized = false;
      state.createFailed = false;
      state.createSuccess = false;
    },
    createPaymentMethodSuccess: (
      state: PaymentMethodsState,
      action: PayloadAction<{ paymentMethodId: string }>
    ) => {
      state.creating = false;
      state.creatingTokenized = false;
      state.createSuccess = true;
      state.createFailed = false;
      state.selectedPaymentMethodId = action.payload.paymentMethodId;
    },
    createPaymentMethodFailed: (
      state: PaymentMethodsState,
      action: PayloadAction<{ code: string }>
    ) => {
      state.creating = false;
      state.creatingTokenized = false;
      state.createFailed = true;
      state.createSuccess = false;
      state.createFailedCode = action.payload.code;
    },
    createTokenizedPaymentMethod: (state: PaymentMethodsState) => {
      state.creating = true;
      state.creatingTokenized = true;
      state.createFailed = false;
      state.createSuccess = false;
    },
    createPaymentMethodStateReset: (state: PaymentMethodsState) => {
      state.creating = false;
      state.createFailed = false;
      state.createSuccess = false;
      state.creatingTokenized = false;
      state.createFailedCode = "";
      state.creatingPaymentMethodToken = {
        key: "",
        providerResponse: {}
      };
    },
    loadPaymentMethodsSubmit: (
      state: types.PaymentMethodsState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<LoadPaymentMethodsSubmit>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.loadFailed = false;
    },
    loadPaymentMethodsSuccess: (
      state: types.PaymentMethodsState,
      action: PayloadAction<types.MappedLoadPaymentMethodsResponse>
    ) => {
      state.loading = false;
      state.loaded = true;
      state.loadFailed = false;
      state.byId = action.payload.byId;
      state.jwt = action.payload.jwt;
      state.clientFlowInfoMap = action.payload.clientFlowInfoMap;
    },
    loadPaymentMethodsFailed: (state: types.PaymentMethodsState) => {
      state.loading = false;
      state.loaded = false;
      state.loadFailed = true;
    },
    setSelectedPaymentMethod: (
      state: types.PaymentMethodsState,
      action: PayloadAction<{ paymentMethodId: string }>
    ) => {
      state.selectedPaymentMethodId = action.payload.paymentMethodId;
    },
    deletePaymentMethodsSubmit: (state: types.PaymentMethodsState) => {
      state.deleting = true;
      state.deleteSuccess = false;
      state.deleteFailed = false;
    },
    deletePaymentMethodsSuccess: (
      state: types.PaymentMethodsState,
      action: PayloadAction<{ paymentMethod: { id: string } }>
    ) => {
      state.deleting = false;
      state.deleteSuccess = true;
      state.deleteFailed = false;
      delete state.byId[action.payload.paymentMethod.id];
    },
    deletePaymentMethodsFailed: (state: types.PaymentMethodsState) => {
      state.deleting = false;
      state.deleteSuccess = false;
      state.deleteFailed = true;
    },
    deletePaymentMethodsResetError: (state: types.PaymentMethodsState) => {
      state.deleting = false;
      state.deleteSuccess = false;
      state.deleteFailed = false;
    },
    setCreatingPaymentMethod: (
      state: PaymentMethodsState,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.creatingPaymentMethod = {
        ...state.creatingPaymentMethod,
        ...action.payload
      };
    },
    setCreatingPaymentMethodToken: (
      state: PaymentMethodsState,
      action: PayloadAction<SetCreatingPaymentMethodTokenPayload>
    ) => {
      state.creatingPaymentMethodToken = action.payload.token;
    },
    resetStore: () => initialState
  }
});

export const {
  createPaymentMethod,
  createPaymentMethodSuccess,
  createPaymentMethodFailed,
  createTokenizedPaymentMethod,
  createPaymentMethodStateReset,
  loadPaymentMethodsSubmit,
  loadPaymentMethodsSuccess,
  loadPaymentMethodsFailed,
  setSelectedPaymentMethod,
  deletePaymentMethodsSubmit,
  deletePaymentMethodsSuccess,
  deletePaymentMethodsFailed,
  deletePaymentMethodsResetError,
  setCreatingPaymentMethod,
  setCreatingPaymentMethodToken,
  resetStore
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
