import { StoreType } from "../../types";
import { LoadingMessagesFlags, Message } from "./types";

export const getMessages = (state: StoreType): Message[] =>
  Object.values(state.messages.byId);

export const getMessagesLoadingFlags = (
  state: StoreType
): LoadingMessagesFlags => {
  return {
    loadingMessages: state.messages.loadingMessages,
    loadedMessages: state.messages.loadedMessages,
    loadMessagesFailed: state.messages.loadMessagesFailed
  };
};
