import { formatCurrency, formatCurrencyNoSymbol } from "@billfire/toybox";
import currency from "currency.js";
import { useEffect, useState } from "react";
import { useRemitForm } from "..";
import { isRemitInvoice, RemitBaseRow, RemitInvoice } from "../types";

export const useAmountInputState = (invoice: RemitBaseRow | RemitInvoice) => {
  const { setInvoicePaymentAmount, toggleInvoiceSelected } = useRemitForm();

  const [focused, setFocused] = useState(false);
  const [amount, setAmount] = useState(formatCurrency(invoice.paymentAmount));

  const isInvoice = isRemitInvoice(invoice);

  useEffect(() => {
    if (!focused) {
      setAmount(formatCurrency(invoice.paymentAmount));
    }
  }, [focused, invoice.paymentAmount]);

  const setValue = (value: string) => {
    setAmount(value);
    setInvoicePaymentAmount(invoice.id, currency(value).value);
  };

  const setGotFocus = () => {
    setFocused(true);
    setAmount(`${formatCurrencyNoSymbol(invoice.paymentAmount)}`);
    if (!invoice.selected && isInvoice) {
      toggleInvoiceSelected(invoice.id);
    }
  };

  const setLostFocus = () => {
    setFocused(false);
    setAmount(`${formatCurrency(invoice.paymentAmount)}`);
  };

  return { amount, isInvoice, setGotFocus, setLostFocus, setValue };
};
