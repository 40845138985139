import styled from "styled-components";

export const DashedLine = styled.div`
  width: 47.5%;
  margin: 5px 0;
  height: 1px;
  border-bottom: 1px dashed #000;
  opacity: 0.3;
`;

export const FoldHereText = styled.div`
  width: 5%;
  opacity: 0.3;
  font-family: Roboto;
  font-size: 7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
`;

export const FoldLineContainer = styled.div`
  display: flex;
  width: 100%;
`;
