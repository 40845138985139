import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import AutopayManage from "../../components/Autopay/AutopayManage";
import { usePaymentMethodsLoading } from "../../hooks/usePaymentMethodsLoading";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import useLoadAutopay from "../../hooks/useLoadAutopay";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { isLoading } from "./utils";

const AutopayPage: React.FC = () => {
  const { loadingInvoices } = useInvoicesLoader({
    type: "autopay"
  });
  const paymentMethodsLoading = usePaymentMethodsLoading();

  const { loadedVendors } = useVendorsLoader();
  const { loadingAutopay } = useLoadAutopay();
  const { locationsLoading } = useLocationsLoader();

  return isLoading(
    loadingAutopay,
    locationsLoading,
    loadingInvoices,
    paymentMethodsLoading,
    loadedVendors
  ) ? (
    <SkeletonLoader />
  ) : (
    <AutopayManage />
  );
};

export default AutopayPage;
