import { useParams } from "react-router-dom";
import { PaymentPlanHelper } from "@billfire/payment-lib-base-v2";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { PaymentPlan } from "../../state/entities/paymentPlans/types";
import { getGlobalHolidays } from "../../state/entities/shared/selectors";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";
import { StoreType } from "../../state/types";
import { getPaymentPlanById } from "../../state/entities/paymentPlans/selectors";

export const useNextPaymentPlanPaymentDate = () => {
  const { planId } = useParams<{ planId: string }>();
  const paymentPlan: PaymentPlan = useSelector((state: StoreType) =>
    getPaymentPlanById(state, planId)
  );
  const vendor = useSelector(getSelectedVendor);
  const holidays = useSelector(getGlobalHolidays);
  const paymentPlanHelper = new PaymentPlanHelper(vendor, holidays);

  const nextDate = paymentPlanHelper.getNextPaymentPlanDate(paymentPlan);
  return DateTime.fromISO(nextDate.paymentDate.toString()).toFormat("M/d/yy");
};
