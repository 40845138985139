export const getHeader = (error: string) => {
  switch (error) {
    case "canceled":
      return "This request was canceled.";
    case "expired":
      return "This link expired.";
    default:
      return "This link is invalid.";
  }
};

export const getMessage = (error: string) => {
  switch (error) {
    case "canceled":
      return "If you would like a new request, please contact your representative, or click the button below to request a new link.";
    case "expired":
      return "If you would like access to your account, please contact your representative, or click the button below to request a new link.";
    default:
      return "";
  }
};

export const getButtonText = (error: string) => {
  switch (error) {
    case "canceled":
      return "Request a login link";
    case "expired":
      return "Request a login link";
    default:
      return "Go to Billfire VALET";
  }
};
