import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { getLocationArray } from "../../state/entities/locations/selectors";
import { getVendorArray } from "../../state/entities/vendors/selectors";
import { usePageNav } from "../../hooks/navigation";
import { loadVendorsStart } from "../../state/entities/vendors/slice";
import { Vendor } from "../../state/entities/vendors/types";
import { useVendorFromPath } from "../../hooks/useVendorFromPath";

export const useVendor = (): Vendor => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadVendorsStart());
  }, [dispatch]);

  return useVendorFromPath();
};

export enum LocationsPageState {
  loading,
  loaded
}

const useLoadedFlags = () => {
  const locations = useSelector((state: StoreType) => getLocationArray(state));
  const vendors = useSelector((state: StoreType) => getVendorArray(state));

  const oneVendor = vendors.length === 1;
  const vendorsLoaded = vendors.length > 0;
  const oneLocation = locations.length === 1;
  const locationsLoaded = locations.length > 0;

  return { oneVendor, vendorsLoaded, oneLocation, locationsLoaded };
};

export const useLocationsPageState = (): LocationsPageState => {
  const { loaded } = useSelector((state: StoreType) => ({
    loaded: state.locations.locationsLoaded
  }));

  const { oneVendor, vendorsLoaded, oneLocation, locationsLoaded } =
    useLoadedFlags();

  if (
    loaded &&
    vendorsLoaded &&
    locationsLoaded &&
    !(oneVendor && oneLocation)
  ) {
    return LocationsPageState.loaded;
  }
  return LocationsPageState.loading;
};

export const useLocationListener = () => {
  const locations = useSelector((state: StoreType) => getLocationArray(state));
  const vendors = useSelector((state: StoreType) => getVendorArray(state));
  const { appendPath } = usePageNav();
  React.useEffect(() => {
    if (locations.length === 1 && vendors.length === 1) {
      appendPath(locations[0].id);
    }
  }, [locations, vendors, appendPath]);
};
