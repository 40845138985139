import React from "react";
import { useSelector } from "react-redux";

import { modalTypes, useModal } from "../../globalModal";
import { getCurrentStatementDelivery } from "../../state/entities/statementDelivery/selectors";
import {
  useDeleteStatementDeliverySubmit,
  useStatementDeliverySubmit
} from "./hooks";
import StatementsSignupErrorMessage from "./StatementsSignupErrorMessage";
import { StyledButton, StyledSubmitGroup } from "./styled";
import { TurnOffStatementsModalTitle } from "./TurnOffStatementsModalTitle";
import { TurnOffStatementsModalMessage } from "./TurnOffStatementsModalMessage";
import { StatementScheduleProps } from "./types";
import { useUpdatedStatementListenerSuccess } from "./hooks";

export interface SubmitGoupProps {
  statementSchedule: StatementScheduleProps;
  isFormValid: boolean;
}

export const SubmitGroup: React.FC<SubmitGoupProps> = ({
  statementSchedule,
  isFormValid
}) => {
  const { showModal, hideModal } = useModal();
  const {
    submitStatementDelivery,
    creatingStatementDelivery,
    createStatementDeliveryFailed,
    resetCreateStatementDelivery
  } = useStatementDeliverySubmit();
  const {
    submitDeleteStatementDelivery,
    deletingStatementDelivery,
    deleteStatementDeliveryFailed
  } = useDeleteStatementDeliverySubmit();
  const statementDelivery = useSelector(getCurrentStatementDelivery);

  const isSetup = statementDelivery.id !== "";
  const [buttonTextOnClick, setButtonText] = React.useState("");

  const processErrors =
    createStatementDeliveryFailed || deleteStatementDeliveryFailed;
  const handleClickOn = (buttonText: string) => {
    setButtonText(buttonText);

    submitStatementDelivery({
      ...statementDelivery,
      ...statementSchedule
    });
  };

  const openModal = () => {
    showModal({
      type: modalTypes.BASIC_WHITE,
      props: {
        title: <TurnOffStatementsModalTitle />,
        message: <TurnOffStatementsModalMessage />,
        onClose: hideModal,
        actions: {
          submitButtonText: "Turn Off",
          cancelButtonText: "Cancel",
          onSubmit: () => {
            resetCreateStatementDelivery();
            submitDeleteStatementDelivery();
            hideModal();
          }
        }
      }
    });
  };
  useUpdatedStatementListenerSuccess(buttonTextOnClick, statementDelivery);

  const buttonText = isSetup ? "Update" : "Turn On";

  return (
    <>
      <StyledSubmitGroup>
        {isSetup && (
          <StyledButton
            color="secondary"
            isSetup={isSetup}
            className="turn-off"
            children={"Turn Off Statements"}
            loading={deletingStatementDelivery}
            handleClick={() => openModal()}
            disabled={processErrors}
          />
        )}
        <StyledButton
          isSetup={isSetup}
          className="turn-on-update"
          children={buttonText}
          loading={creatingStatementDelivery}
          handleClick={() => handleClickOn(buttonText)}
          disabled={!isFormValid || processErrors}
        />
      </StyledSubmitGroup>
      {processErrors && <StatementsSignupErrorMessage />}
    </>
  );
};

export default SubmitGroup;
