import * as React from "react";
import { ButtonHeaderText } from "../styled";
import { useCustomerManageAutopay } from "../hooks";
const ButtonHeader: React.FC = () => {
  const customerManageAutopay = useCustomerManageAutopay();

  return (
    <>
      <ButtonHeaderText>
        If you are updating the payment method, the payment method that you
        select will be used for all future payments. If you already received
        notification of a scheduled payment, it will be processed using the
        previously-selected payment method.
      </ButtonHeaderText>
      {customerManageAutopay && (
        <ButtonHeaderText>
          If you are excluding invoices, please note that these exclusions may
          cause some invoices to become past-due.
        </ButtonHeaderText>
      )}
    </>
  );
};

export default ButtonHeader;
