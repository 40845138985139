import * as React from "react";
import { PaymentMethodNotVerified } from "./styled.components";

const PendingVerification: React.FC = () => {
  return (
    <PaymentMethodNotVerified title="Once you verify the micro-deposits in your bank account, this payment method will be available for use.">
      <span>Pending verification</span>
    </PaymentMethodNotVerified>
  );
};

export default PendingVerification;
