import styled from "styled-components";
import styles, { Tabs, Tab, Button } from "@billfire/toybox";
import { device } from "../../breakpoints";
const { colors } = styles;

export const StatementContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
  padding: 0px 35px 25px 35px;

  @media only screen and (${device.tablet}) {
    padding: 0px 10px 25px 10px;
  }
`;

export interface StyledButtonProps {
  isSetup: boolean;
  disabled: boolean;
}

export const StyledTitle = styled.div`
  color: #a4a4a4;
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding-bottom: 14.5px;
`;

export const Signup = styled.div`
  color: ${colors.baseBlue0};
  font-size: 18px;
  font-weight: 500;
`;

export const ContentTitle = styled.h2`
  color: ${colors.baseGray3};
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

export const Signedup = styled.div``;

export const ContentContainer = styled.div`
  max-width: 490px;
  margin: 0 auto;
`;

export const TabsTitle = styled.p`
  color: #a4a4a4;
`;

export const StyledTabs = styled(Tabs)`
  .tabs-content-container {
    padding-top: 32px;
  }
`;

export const StyledTab = styled(Tab)`
  border-top: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  font-size: 18px;
  font-weight: 500;
  box-sizing: border-box;
  width: 33.333333333%;
  text-align: center;
  &:hover {
    cursor: pointer;
    color: ${colors.baseBlue0};
    & div:last-child {
      background-color: #ebf2fa;
    }
  }
  &:first-child {
    border-left: 1px solid #c8c8c8;
  }
  &.active {
    & div:last-child {
      background-color: #d2e4f8;
      border: none;
    }
    &:hover {
      cursor: pointer;
      & div:last-child {
        background-color: #d2e4f8;
      }
    }
  }
  &.inactive {
    background-color: #ffffff;
    & div:last-child {
      color: #c8c8c8;
    }
    &:hover {
      & div:last-child {
        color: ${colors.baseBlue0};
      }
    }
  }
`;

export const StyledStatementsMessage = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  @media only screen and (${device.tablet}) {
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    margin: -2px 0 0 21px;
    color: ${colors.baseGray3};
  }
`;

export const StyledDeliverStatementsMessage = styled.div`
  p {
    font-size: 18px;
    text-align: center;
    color: ${colors.baseGray3};
    margin: 0 0 30px;
  }
`;

export const StyledSubmitGroup = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (${device.tablet}) {
    flex-direction: column-reverse;
    margin-bottom: 10px;
  }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  @media only screen and (${device.tablet}) {
    width: 100%;
  }
  &.turn-off {
    color: ${props => (props.disabled ? "#e4e4e4 " : "#d0021b")};
    font-weight: 300;
    margin-right: 58px;
    padding-left: 0;
    padding-right: 0;
    @media only screen and (${device.tablet}) {
      margin: 0 auto 0;
    }
  }
  &.turn-on-update {
    padding: 11px 35px;
    @media only screen and (${device.tablet}) {
      margin-bottom: 10px;
    }
  }
`;

export const StyledErrorMessage = styled.div`
  font-weight: 400;
  color: #d0021b;
  font-size: 18px;
  margin: 0 auto 15px;
  text-align: center;
`;

export const StyledStatementsModalContainer = styled.div`
  padding: 0 30px;
`;

export const StyledStatementsModalTitleContainer = styled.div`
  padding: 0 30px;
`;

export const StatementDeliveryTitle = styled.h2`
  text-align: center;
  width: 100%;
  margin: 20px auto;
  font-size: 24px;
  font-weight: 500;
  color: #4a4a4a;
`;
