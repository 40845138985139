import * as React from "react";
import { useSelector } from "react-redux";
import { usePageNav } from "../../hooks/navigation";
import {
  getMessages,
  getMessagesLoadingFlags
} from "../../state/entities/messages/selectors";
import { StoreType } from "../../state/types";
import { ResolutionCenterContainer, ResolutionCenterDataTable } from "./styled";
import { getColumns, mapMessageRows } from "./utils";
import Error from "./Error";
import Empty from "./Empty";
import { useMobile } from "../../hooks/useMobile";
import ResolutionCenterPrintView from "./ResolutionCenterPrintView";
import PrintExportComponent from "../PrintExportComponent";
import { SortDirection } from "@billfire/toybox";
import { useResolutionCenterCSVData } from "./hooks";

interface ResolutionCenterContentProps {
  headerText: string;
}

const Content: React.FC<ResolutionCenterContentProps> = ({ headerText }) => {
  const [sortBy, setSortBy] = React.useState<string>("createdAt");
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>("DESC");
  const messages = useSelector((state: StoreType) => getMessages(state));
  const { loadMessagesFailed } = useSelector((state: StoreType) =>
    getMessagesLoadingFlags(state)
  );
  const { getReplacementPathString } = usePageNav();
  const { mobile } = useMobile();
  const printRef = React.useRef(null);

  const { csvData, headers } = useResolutionCenterCSVData(
    sortBy,
    sortDirection
  );

  return (
    <ResolutionCenterContainer mobile={mobile}>
      <PrintExportComponent
        printRef={printRef}
        documentTitle={"Resolution Center"}
        headers={headers}
        csvData={csvData}
      />
      {loadMessagesFailed ? (
        <Error />
      ) : messages.length ? (
        <>
          <ResolutionCenterPrintView
            printRef={printRef}
            headerText={headerText}
            getReplacementPathString={getReplacementPathString}
            messages={messages}
            sortDirection={sortDirection}
            externalSortBy={sortBy}
          />
          <ResolutionCenterDataTable
            sortable
            columns={getColumns(getReplacementPathString, mobile)}
            initialData={mapMessageRows(messages)}
            externalSortDirection={sortDirection}
            setExternalSortDirection={setSortDirection}
            externalSortBy={sortBy}
            externalSetSortBy={setSortBy}
          />
        </>
      ) : (
        <Empty />
      )}
    </ResolutionCenterContainer>
  );
};

export default Content;
