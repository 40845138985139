import * as React from "react";
import { Accordion } from "@billfire/toybox";
import { useExcludedInvoiceSelectors } from "../hooks";
import ExcludedInvoicesContent from "./ExcludedInvoicesContent";

export interface ExcludedInvoicesProps {
  setup: boolean;
}

const ExcludedInvoices: React.FC<ExcludedInvoicesProps> = ({ setup }) => {
  const { invoices, excludedInvoiceIds, readOnly, title } =
    useExcludedInvoiceSelectors(setup);

  const empty =
    (readOnly && excludedInvoiceIds.length === 0) || invoices.length === 0;

  return (
    <Accordion
      headingPadding={"20px 0"}
      titleColor={"#777777"}
      startingContentHeight={empty ? "0px" : "100px"}
      fused={true}
      title={title}
      secondaryTitle={
        readOnly ? undefined : (
          <span style={{ fontSize: "15px", fontWeight: "normal" }}>
            {excludedInvoiceIds.length}/{invoices.length}
          </span>
        )
      }
      fontSize={"15px"}
      titleWeight={"normal"}
      content={<ExcludedInvoicesContent setup={setup} />}
    />
  );
};

export default ExcludedInvoices;
