import * as React from "react";
import { useRemitForm } from "..";

import {
  CheckNumberContainer,
  CheckNumberInput,
  CheckNumberLabel
} from "./styled";

const CheckNumber: React.FC = () => {
  const { checkNumber, setCheckNumber, sortedInvoices } = useRemitForm();

  if (sortedInvoices.length === 0) {
    return null;
  }

  return (
    <CheckNumberContainer>
      <CheckNumberLabel>
        <label htmlFor={`check-number`}>Check Number</label>
      </CheckNumberLabel>
      <CheckNumberInput
        id={`check-number`}
        name={"checkNumber"}
        type={"text"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCheckNumber(e.target.value)
        }
        value={checkNumber}
      />
    </CheckNumberContainer>
  );
};

export default CheckNumber;
