import { Frequency } from "../../state/entities/shared/types";
import { StatementDelivery } from "../../state/entities/statementDelivery/types";
import { StatementScheduleProps } from "./types";

export const unitAndValueAreTruthy = (formSetting: StatementScheduleProps) =>
  !!formSetting.scheduleUnit && !!formSetting.scheduleValue;

export const isFormChanged = (
  currentSetting: StatementDelivery,
  formSetting: StatementScheduleProps
): boolean => {
  return (
    (currentSetting.id === "" ||
      currentSetting.scheduleUnit !== formSetting.scheduleUnit ||
      currentSetting.scheduleValue !== formSetting.scheduleValue ||
      currentSetting.sendEmptyStatements !== formSetting.sendEmptyStatements) &&
    unitAndValueAreTruthy(formSetting)
  );
};

const singles = [
  "last day of each month",
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth"
];
const deca = ["twent", "thirt"];

const positionalNumber = (n: number): string => {
  // eslint-disable-next-line security/detect-object-injection
  if (n < 20) return singles[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + singles[n % 10];
};

const formatMonthly = (value: string, update: boolean): string => {
  const dayOfMonth = positionalNumber(parseInt(value));
  return update
    ? `Your statement delivery has been updated to monthly on the ${dayOfMonth}.`
    : `You are currently receiving monthly statements on the ${dayOfMonth}.`;
};

const abbreviations: Record<string, string> = {
  sun: "Sundays",
  mon: "Mondays",
  tue: "Tuesdays",
  wed: "Wednesdays",
  thu: "Thursdays",
  fri: "Fridays",
  sat: "Saturdays"
};

const formatWeekly = (value: string, update: boolean): string => {
  // eslint-disable-next-line security/detect-object-injection
  const abbreviation = abbreviations[value];
  return update
    ? `Your statement delivery has been updated to weekly on ${abbreviation}.`
    : `You are currently receiving weekly statements on ${abbreviation}.`;
};

const formatBiWeekly = (value: string, update: boolean): string => {
  // eslint-disable-next-line security/detect-object-injection
  const abbreviation = abbreviations[value];
  return update
    ? `Your statement delivery has been updated to biweekly on ${abbreviation}.`
    : `You are currently receiving biweekly statements on ${abbreviation}.`;
};

export const formatFrequency = (
  {
    scheduleUnit,
    scheduleValue
  }: {
    scheduleUnit: string;
    scheduleValue: string;
  },
  update = false
) => {
  switch (scheduleUnit) {
    case "monthly":
      return formatMonthly(scheduleValue, update);
    case "weekly":
      return formatWeekly(scheduleValue, update);
    case "biweekly":
      return formatBiWeekly(scheduleValue, update);
    default:
      return "";
  }
};

export const calculateDefault = (
  statementDelivery: StatementDelivery,
  unit: Frequency
): string => {
  if (unit === statementDelivery.scheduleUnit) {
    return statementDelivery.scheduleValue;
  }

  if (unit === Frequency.MONTHLY) {
    return "1";
  }
  return "mon";
};
