import React from "react";
import { CampaignEvent, CampaignEventDay } from "./types";

import { Time, Dot, TimeText, StyledAction } from "./styled";
import { Campaign } from "../../state/entities/campaigns/types";

export interface DeletReminderType {
  eventDay: number;
  id: string;
}

export const handleDeleteClick = (
  rowItem: CampaignEventDay,
  event: CampaignEvent,
  setDeleteReminder: React.Dispatch<
    React.SetStateAction<DeletReminderType | null>
  >
) => {
  setDeleteReminder({ eventDay: rowItem.eventDay, ...event });
};

export const renderActions = (
  rowItem: CampaignEventDay,
  setDeleteReminder: React.Dispatch<
    React.SetStateAction<DeletReminderType | null>
  >
) => {
  return (
    <div>
      {rowItem.events.map(event => (
        <StyledAction
          key={event.id}
          data-test-id={`delete-link-${event.id}`}
          onClick={() => handleDeleteClick(rowItem, event, setDeleteReminder)}
        >
          Delete
        </StyledAction>
      ))}
    </div>
  );
};

const calcTime = (value: number | undefined) => {
  const beforeOrAfter = value && value > 0 ? "after" : "before";
  const unit = value && Math.abs(value) > 1 ? "days" : "day";

  return value === 0
    ? "Due Date"
    : `${value && Math.abs(value)} ${unit} ${beforeOrAfter}`;
};

const renderTime = (value: number) => {
  const time = calcTime(value);
  return (
    <Time>
      <Dot positive={value > 0} />
      <TimeText>{time}</TimeText>
    </Time>
  );
};

export const tableColumnsEventDays = (
  setDeleteReminder: React.Dispatch<
    React.SetStateAction<DeletReminderType | null>
  >
) => [
  {
    name: "eventDay",
    label: "Time",
    width: "",
    render: renderTime
  },
  {
    name: "action",
    label: "Actions",
    width: "54px",
    render: (_: null, rowItem: CampaignEventDay) =>
      renderActions(rowItem, setDeleteReminder)
  }
];

export const sortDaysFunction = (a: string, b: string) => {
  return parseInt(a) - parseInt(b);
};

export const sortEventDays = (
  eventDays: Record<number, CampaignEventDay>
): CampaignEventDay[] => {
  const sortedKeys = Object.keys(eventDays).sort(sortDaysFunction);

  return sortedKeys.map((key: string) => {
    const eventDay = eventDays[parseInt(key)];

    return { ...eventDay, events: eventDay.events };
  });
};

export const denormalizeCampaignEvents = (campaigns: Campaign[]) => {
  const eventDays: Record<number, CampaignEventDay> = campaigns.reduce(
    (byDays: Record<number, CampaignEventDay>, campaign: Campaign) => {
      let eventDay = byDays[campaign.eventDay];
      if (!eventDay) {
        eventDay = {
          rowId: `${campaign.eventDay}`,
          eventDay: campaign.eventDay,
          events: []
        };
        byDays[campaign.eventDay] = eventDay;
      }

      eventDay.events.push({
        id: campaign.id
      });

      return byDays;
    },
    {}
  );

  return sortEventDays(eventDays);
};

export const deleteMessage = (deleteReminder: DeletReminderType | null) => {
  const time = calcTime(deleteReminder?.eventDay);
  const scheduleDay =
    deleteReminder?.eventDay === 0
      ? `on each invoice's due date`
      : `${time} invoice due dates`;
  return `If you delete this, you will no longer receive a reminder ${scheduleDay}.`;
};

export const setAndValidateRadioValue = (
  optionValue: string,
  newOptionValue: React.SetStateAction<string>,
  value: string,
  newValue: string,
  setCreateFailed: React.Dispatch<React.SetStateAction<boolean>>,
  setOptionValue: React.Dispatch<React.SetStateAction<string>>,
  setValue: React.Dispatch<React.SetStateAction<string>>
) => {
  if (newValue !== value || newOptionValue !== optionValue) {
    setCreateFailed(false);
  }
  setOptionValue(newOptionValue);
  setValue(newValue || "");
};

export const CREATE_OPTIONS = [
  {
    label: "On Invoice Due Date",
    value: "dueDate",
    defaultSelected: true
  },
  {
    label: "Days before an Invoice is Due",
    value: "daysBefore",
    withInput: true
  },
  {
    label: "Days after an Invoice was Due",
    value: "daysAfter",
    withInput: true
  }
];

export const getEventDay = (optionValue: string, value: string): number => {
  switch (optionValue) {
    case "dueDate":
      return 0;
    case "daysBefore":
      return parseInt(value) * -1;
    default:
      return parseInt(value);
  }
};

export const isDupe = (
  locationId: string,
  eventDay: number,
  existing: Campaign[]
): boolean => {
  return existing.some(
    campaign =>
      campaign.locationId === locationId && campaign.eventDay === eventDay
  );
};

const getErrorMessage = (
  num: number,
  isWholeNumber: boolean,
  isWithinRange: boolean
) => {
  let msg = "";
  if (!isWholeNumber) {
    msg = "Please use numbers only.";
  } else if (!isWithinRange && num > 90) {
    msg = "Days cannot be more then 90.";
  } else if (!isWithinRange && num === 0) {
    msg = "Days cannot be 0.";
  }
  return msg;
};

export const validateRadioInput = (
  value: string
): { valid: boolean; errorMessage: string } => {
  const num = parseInt(value);
  const isWholeNumber = num % 1 === 0 && /^\d+$/.test(value);
  const isWithinRange = num > 0 && num <= 90;
  const isEmpty = value === "";

  const errorMessage = getErrorMessage(num, isWholeNumber, isWithinRange);

  return {
    valid: isEmpty || (isWholeNumber && isWithinRange),
    errorMessage
  };
};
