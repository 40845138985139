export const mapWeek = (selectedDay: string) => {
  const daysInWeek = [
    { val: "mon", display: "Monday" },
    { val: "tue", display: "Tuesday" },
    { val: "wed", display: "Wednesday" },
    { val: "thu", display: "Thursday" },
    { val: "fri", display: "Friday" },
    { val: "sat", display: "Saturday" },
    { val: "sun", display: "Sunday" }
  ];

  return daysInWeek.map(day => ({
    label: day.display,
    value: day.val,
    defaultSelected: day.val === selectedDay
  }));
};
