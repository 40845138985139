import * as React from "react";
import Locations from "../../components/Locations";
import {
  useLocationsPageState,
  LocationsPageState,
  useLocationListener,
  useVendor
} from "./hooks";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";

export const LocationsPage: React.FC = () => {
  useLocationsLoader();
  useVendor();

  useLocationListener();

  return (
    <>
      {
        // eslint-disable-next-line security/detect-object-injection
        {
          [LocationsPageState.loading]: <SkeletonLoader />,
          [LocationsPageState.loaded]: <Locations />
        }[useLocationsPageState()]
      }
    </>
  );
};

export default LocationsPage;
