import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import Content from "./Content";
import LocationHeaderText from "../LocationHeaderText";

const ResolutionCenter: React.FC = () => {
  const headerText = "Resolution Center for";
  return (
    <CommonWrapper>
      <LocationHeaderText headerText={headerText} />
      <Content headerText={headerText} />
    </CommonWrapper>
  );
};

export default ResolutionCenter;
