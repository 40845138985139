import * as React from "react";
import { modalTypes, useModal } from "../../../globalModal";
import { StyledSpinner, StyledXIcon } from "./styled.components";

interface PaymentMethodDeleteProps {
  name: string;
  lastFour: string;
  checkPaymentsLoading: boolean;
  setCheckPaymentsLoading: (val: boolean) => void;
}

const PaymentMethodDelete: React.FC<PaymentMethodDeleteProps> = ({
  name,
  lastFour,
  checkPaymentsLoading,
  setCheckPaymentsLoading
}) => {
  const { showModal, hideModal } = useModal();
  const closeModal = () => {
    hideModal();
  };

  const openDeleteModal = () => {
    showModal({
      type: modalTypes.PAYMENT_METHOD_DELETE,
      props: {
        name,
        lastFour,
        checkPaymentsLoading,
        setCheckPaymentsLoading,
        onClose: () => {
          closeModal();
        }
      }
    });
  };

  return (
    <>
      {!checkPaymentsLoading ? (
        <StyledXIcon
          fill={"#ff0000"}
          width={"10px"}
          onClick={() => openDeleteModal()}
        />
      ) : (
        <StyledSpinner />
      )}
    </>
  );
};

export default PaymentMethodDelete;
