// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import VendorTitle from "../VendorTitle";
import LocationHeaderText from "../LocationHeaderText";
import Footer from "../CommonWrapper/Footer";
import { PrintStyle, DisplayNoneContainer } from "./styled";

export interface ReportPrintViewProps {
  printRef: React.RefObject<HTMLDivElement>;
  headerText: string;
}

const ReportPrintView: React.FC<ReportPrintViewProps> = ({
  children,
  headerText,
  printRef
}) => {
  return (
    <DisplayNoneContainer>
      <PrintStyle ref={printRef}>
        <VendorTitle hidePanel={true} />
        <LocationHeaderText headerText={headerText} />
        {children}
        <Footer />
      </PrintStyle>
    </DisplayNoneContainer>
  );
};

export default ReportPrintView;
