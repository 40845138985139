import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { getInvoiceById } from "../../state/entities/invoices/selectors";
import { loadPdfUrl } from "./utils";
import { useSelector } from "react-redux";
import { useParamHook } from "../../hooks/useParams";
import { StoreType } from "../../state/types";
import { ErrorMessage, ErrorMessageContainer } from "./styled";
import validator from "validator";

const PDFLoader: React.FC = () => {
  const { invoiceId, vendorId, locationId, token } = useParamHook();
  const { loadingInvoices, loadedInvoices } = useInvoicesLoader({
    invoiceId,
    loadAll: true
  });
  const invoice = useSelector((state: StoreType) =>
    getInvoiceById(state, invoiceId)
  );

  const [{ loading, loadFailed }, setLoadingPDF] = React.useState<{
    loading: boolean;
    loadFailed: boolean;
  }>({ loading: false, loadFailed: false });
  const [PDFUrl, setPDFUrl] = React.useState<string>("");

  React.useEffect(() => {
    if (loadedInvoices && invoice) {
      (async () => {
        await loadPdfUrl(
          setLoadingPDF,
          setPDFUrl,
          invoice,
          vendorId,
          locationId,
          token
        );
      })();
    }
  }, [loadedInvoices, invoice, vendorId, locationId, token]);

  React.useEffect(() => {
    if (
      PDFUrl &&
      validator.isURL(PDFUrl, {
        protocols: ["http", "https", "ftp", "blob:http", "blob:https"],
        require_protocol: true,
        require_tld: false //This is required for localhost
      })
    ) {
      window.location.replace(PDFUrl);
    }
  }, [PDFUrl]);

  if (loadFailed) {
    return (
      <ErrorMessageContainer className="error message container">
        <ErrorMessage>
          We were unable to retrieve your invoice. Please refresh the page.
        </ErrorMessage>
      </ErrorMessageContainer>
    );
  }

  return loadingInvoices || loading ? <SkeletonLoader /> : null;
};

export default PDFLoader;
