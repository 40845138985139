import * as SharedTypes from "@billfire/va-types";

export type Vendor = SharedTypes.Vendor;
export type PaymentMethodOption = SharedTypes.PaymentMethodOption;

export { PaymentProvider } from "@billfire/va-types";
export interface ApiResponseVendor extends Vendor {
  uuid: string;
  _pk: string;
  _sk: string;
}

export interface ApiResponseVendors {
  vendors: Array<ApiResponseVendor>;
}
export interface VendorContact {
  addressLine1: string;
  state: string;
  phoneNumber: string;
  city: string;
  postalCode: string;
}

export interface VendorConfig {
  achProcessor: string;
  c2pPaymentDays: string;
  logoUrl: string;
  autoPayStartDateTimeFrame?: string;
}

export const getEmptyVendorConfig = () => ({
  achProcessor: "",
  c2pPaymentDays: "",
  logoUrl: ""
});

export interface VendorEod {
  cutoff: {
    hour: number;
    timezone: string;
  };
}

export const getEmptyVendorFeatureFlags = (): VendorFeatureFlags => ({
  vendorManage: false,
  power: false,
  pro: false,
  sendCustomerWelcomeCommunications: false
});

export interface VendorFeatureFlags {
  vendorManage: boolean;
  power: boolean;
  pro: boolean;
  sendCustomerWelcomeCommunications: boolean;
}

export interface PaymentMethodConfig {
  options: Array<PaymentMethodConfigOption>;
}

export const isPaymentProvider = (
  provider: string | SharedTypes.PaymentProvider
): provider is SharedTypes.PaymentProvider => {
  return provider in SharedTypes.PaymentProvider;
};
export interface PaymentMethodConfigOption {
  displayName: string;
  provider: SharedTypes.PaymentProvider;
}

export const getEmptyPaymentMethodConfigOption = (): PaymentMethodOption => ({
  customerDisplayName: "",
  vendorDisplayName: "",
  transactExternal: false,
  config: {},
  provider: SharedTypes.PaymentProvider.NONE
});

export const getEmptyVendor = (): Vendor => ({
  createdAt: "",
  updatedAt: "",
  clusterId: "",
  initialInvitationSent: "",
  uuid: "",
  id: "",
  name: "",
  contact: {
    addressLine1: "",
    state: "",
    phoneNumber: "",
    city: "",
    postalCode: ""
  },
  config: {
    achProcessor: "",
    c2pPaymentDays: "",
    logoUrl: "",
    autoPayStartDateTimeFrame: ""
  },
  eod: {
    cutoff: {
      hour: 0,
      timezone: ""
    }
  },
  featureFlags: {
    ...getEmptyVendorFeatureFlags(),
    vendorManage: false,
    power: false,
    pro: false,
    sendCustomerWelcomeCommunications: false
  },
  paymentMethodConfig: {
    options: []
  }
});

export interface VendorsState {
  byId: Record<string, Vendor>;
  selectedVendorId: string;
  loadingVendors: boolean;
  loadedVendors: boolean;
  loadVendorsFailed: boolean;
  hasEverLoadedVendors: boolean;
}
