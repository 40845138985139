import React, { useRef } from "react";

export interface TruncateProps {
  value: string;
  maxHeight: string;
  lineClamp: number;
}

const Truncate: React.FC<TruncateProps> = ({ value, maxHeight, lineClamp }) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={divRef}
      style={{
        maxHeight: maxHeight,
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: lineClamp,
        textOverflow: "ellipsis"
      }}
      title={value}
    >
      {value}
    </div>
  );
};

export default Truncate;
