import * as React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { isAutopayActive } from "../../state/entities/autopay/selectors";
import { getAllPaymentPlansWithActivePaymentMethods } from "../../state/entities/paymentPlans/selectors";
import { getAuthToken } from "../../state/entities/shared/selectors";
import { useDispatch } from "react-redux";
import { resetPersistedTokenState } from "../../state/entities/persisted/slice";
import { getSelectedPaymentDate } from "../../state/entities/requests/selectors";
import { clearSelectedPaymentDate } from "../../state/entities/requests/slice";

export const useClearPersisted = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAuthToken);
  return () => dispatch(resetPersistedTokenState({ token }));
};

export const useHover = (): [React.RefObject<HTMLDivElement>, boolean] => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hoverRef = React.useRef<HTMLDivElement>(null);
  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);
  React.useEffect(
    () => {
      const node = hoverRef.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node?.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverRef.current] // Recall only if ref changes
  );

  return [hoverRef, isHovered];
};

export const useIsAutopayOn = () =>
  useSelector((state: StoreType) => isAutopayActive(state));

export const useLocationHasPaymentPlans = () => {
  return !!useSelector((state: StoreType) =>
    getAllPaymentPlansWithActivePaymentMethods(state)
  ).length;
};

export const useClearSelectedPaymentDate = () => {
  const dispatch = useDispatch();
  const selectedPaymentDate = useSelector(getSelectedPaymentDate);

  React.useEffect(() => {
    selectedPaymentDate && dispatch(clearSelectedPaymentDate());
  }, [dispatch, selectedPaymentDate]);
};
