import { Accordion } from "@billfire/toybox";
import styled from "styled-components";
import colors from "@billfire/toybox";

export const MobileAccordion = styled(Accordion)`
  background-color: inherit;

  .accordion-content,
  .panel {
    background-color: inherit;
  }

  .accordion-header {
    padding: 7px 9.8px 7px 10px;
  }

  .accordion__icon {
    padding-top: 3px;
    margin-bottom: auto;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const HeaderContent = styled.div`
  padding-right: 14px;
`;

export const HeaderRow1 = styled.div`
  display: grid;
  grid-template-columns: 60px 185px;
`;

export const HeaderRow2 = styled.div`
  display: grid;
  grid-template-columns: 60px 65px 30px 80px;
`;

export const HeaderLabel = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.08px;
  text-align: left;
  color: #777;
`;

export const ContentLabel = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.08px;
  text-align: left;
  color: #777;
`;

export const Value = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.09px;
  text-align: left;
  color: #4a4a4a;
`;

export const InvoiceValue = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.09px;
  text-align: left;
  color: #4a4a4a;
  width: 190px;
`;

export const DollarValue = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.09px;
  text-align: right;
  color: #4a4a4a;
  width: 91px;
`;

export const ContentDivider = styled.div`
  opacity: 0.3;
  border: solid 1px #707070;
  margin-bottom: 18px;
`;

export const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 101px 1fr;
  padding-bottom: 4px;
`;

export const EllipseContainer = styled.span`
  width: 100%;
  display: block;
`;

export const ContentActions = styled.div`
  margin-top: 11px;
  padding-bottom: 14px;
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const ContentAction = styled.div`
  margin-top: 11px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
`;

export const StyledLink = styled.button`
  color: ${colors.colors.baseBlue0};
  border: none;
  padding: 0;
  background: none;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.09px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;

  @media print {
    display: none;
  }
`;
