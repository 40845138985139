import * as React from "react";
import { Checkbox } from "@billfire/toybox";
import { useRemitForm } from "../";

interface InvoiceCheckBoxProps {
  ariaLabel: string;
  id: string;
}

const InvoiceCheckBox: React.FC<InvoiceCheckBoxProps> = ({ ariaLabel, id }) => {
  const { sortedInvoices, toggleInvoiceSelected } = useRemitForm();

  const invoice = sortedInvoices.find(inv => inv.rowId === id);

  const handleClick = () => {
    toggleInvoiceSelected(id);
  };

  return (
    <Checkbox
      data-testid={`checkbox-${id}`}
      checked={invoice ? invoice.selected : false}
      onChange={handleClick}
      ariaLabel={ariaLabel}
    />
  );
};

export default InvoiceCheckBox;
