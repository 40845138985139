import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useModal } from "../../globalModal";
import { SET_REMINDER_MODAL } from "../../globalModal/types";
import { selectDeliverTo } from "../../state/entities/campaigns/selectors";

import { LocationPageWrapper } from "../bits/styled";
import CommonWrapper from "../CommonWrapper";
import LocationHeaderText from "../LocationHeaderText";
import ReminderSchedule from "./ReminderSchedule";
import {
  RemindersDescription,
  StyledAddButton,
  ButtonContainer
} from "./styled";

const SetupReminders = () => {
  const { showModal } = useModal();
  const deliverTo = useSelector(selectDeliverTo);
  const [createReminder, setCreateReminder] = useState(false);
  const addEvent = () => {
    setCreateReminder(true);
  };

  React.useEffect(() => {
    createReminder &&
      showModal({
        type: SET_REMINDER_MODAL,
        props: {
          setCreateReminder
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createReminder]);

  return (
    <>
      <CommonWrapper>
        <LocationPageWrapper>
          <LocationHeaderText headerText={"Setup Payment Reminders"} />
          <RemindersDescription>
            Reminders will be sent to {deliverTo} based on each invoice's due
            date.
          </RemindersDescription>
          <ReminderSchedule />
          <ButtonContainer>
            <StyledAddButton loading={false} handleClick={addEvent}>
              Add a Reminder
            </StyledAddButton>
          </ButtonContainer>
        </LocationPageWrapper>
      </CommonWrapper>
    </>
  );
};

export default SetupReminders;
