import { StoreType } from "../../types";
import { getEmptyLocation, Location } from "./types";

export const getLocationsLoadState = (state: StoreType) => {
  return {
    locationsLoading: state.locations.locationsLoading,
    locationsLoaded: state.locations.locationsLoaded,
    locationsLoadFailed: state.locations.locationsLoadFailed
  };
};

export const getLocation = (state: StoreType, id: string): Location => {
  // eslint-disable-next-line security/detect-object-injection
  return state.locations.byId[id];
};

export const getSelectedLocationId = (state: StoreType): string => {
  return state.locations.selectedLocationId;
};

export const getLocationArray = (state: StoreType): Location[] => {
  return Object.values(state.locations.byId);
};

export const getSelectedLocation = (state: StoreType): Location => {
  if (state.locations.selectedLocationId) {
    return state.locations.byId[state.locations.selectedLocationId];
  }
  return getEmptyLocation();
};

export const getLocationHasUnreadMessages = (
  state: StoreType
): boolean | undefined => {
  return (
    state.locations.byId[state.locations.selectedLocationId]
      ?.hasUnreadMessages || false
  );
};
