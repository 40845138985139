import styled, { css } from "styled-components";
import { Panel, TextInput, Button } from "@billfire/toybox";

export const TitleText = styled.h1`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-top: 30px;
`;

export const ParagraphContainer = styled.div`
  margin: 30px 85px auto 85px;

  @media screen and (max-width: 600px) {
    margin: 30px 26px auto 26px;
  }

  padding-bottom: 25px;
`;

export const ContactInputPanel = styled(Panel)`
  margin: auto;
  margin-bottom: 50px;
  margin-top: 20px;
`;

export const TextDisplayPanel = styled(Panel)`
  margin: auto;
  margin-top: 52px;
  padding-top: 10px;
`;

export const InnerPanelContainer = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin: auto;
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
  max-width: 330px;
`;

export const ButtonContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 15px 15px 32.7px;
  margin-top: 15px;
`;

export const Form = styled.form`
  cursor: default;
  overflow: hidden;
  width: 90%;
  margin: auto;
`;

export const Label = styled.label<{ validationErr?: boolean }>`
  color: #999999;
  ${props =>
    props.validationErr &&
    css`
      color: #c30016;
    `}
  font-size: 20px;
`;

export const LabelContainer = styled.div`
  position: relative;
  transform: translate(0, 20px);
  transition-duration: 0.25s;
`;

export const ErrorIconContainer = styled.span`
  position: absolute;
  left: -30px;
`;

export const Input = styled.input<{ invalid: boolean }>`
  background: none;
  font-weight: 300;
  font-size: 16px;
  color: #2f2e4d;
  border: none;
  border-bottom: solid 2px;
  border-bottom-color: ${props => (props.invalid ? "#c30016" : "#d8d8d8")};
  padding-bottom: 11px;
  outline: none;
  width: 90%;
  overflow: hidden;
  max-width: 500px;
`;

const moveLabelUpAndMakeItBlue = css`
  ${LabelContainer} {
    transform: translate(0, -18px);
    transition-duration: 0.25s;

    ${Label} {
      color: #4a90e2;
    }
  }
`;

export const ErrContainer = styled.div`
  position: relative;
  margin-left: 71px;
  margin-right: 71px;

  @media screen and (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const InputContainer = styled.div<{ value: string }>`
  padding: 17.3px 15px 15px 15px;
  margin-left: 71px;
  margin-right: 71px;

  @media screen and (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  ${props => !!props.value && moveLabelUpAndMakeItBlue}

  &:focus-within {
    ${moveLabelUpAndMakeItBlue}
  }
`;

export const FormErr = styled.p`
  position: absolute;
  top: -25px;
  color: #c30016;
  left: 15px;
  opacity: 0.8;
  font-weight: 500;
`;

export const SubmitError = styled.div`
  margin-top: -26px;
  margin-bottom: 6px;
  opacity: 0.88;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.29px;
  text-align: center;
  color: #d0021b;
`;
