// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsLoadState } from "../state/entities/locations/selectors";
import { locationsLoadStart } from "../state/entities/locations/slice";
import { StoreType } from "../state/types";
import { useNavigation } from "./route";

export const useLocationsLoader = (refresh = false) => {
  const dispatch = useDispatch();
  const locationLoadState = useSelector((state: StoreType) =>
    getLocationsLoadState(state)
  );
  const { vendorId } = useNavigation();

  React.useEffect(() => {
    if (vendorId) {
      dispatch(locationsLoadStart({ refresh }));
    }
  }, [dispatch, refresh, vendorId]);

  return locationLoadState;
};
