import styled from "styled-components";
import styles from "@billfire/toybox";

const { colors } = styles;
const { baseGray3, baseGray7 } = colors;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 2px solid rgb(246, 246, 246);

  :last-child {
    border-bottom: none;
  }
`;

export const RowTitle = styled.div`
  color: ${baseGray7};
`;

export const RowContent = styled.div`
  color: ${baseGray3};
`;
