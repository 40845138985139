import * as React from "react";

import { useRemitForm } from "../";
import { useMobile } from "../../../hooks/useMobile";
import { getEmptyRemitInvoice, isRemitInvoice } from "../types";
import BlankCommentRow from "./BlankCommentRow";
import BlankMobileCommentRow from "./BlankMobileCommentRow";
import InvoiceCommentRow from "./InvoiceCommentRow";
import InvoiceDataRow from "./InvoiceDataRow";

export interface InvoiceRowProps {
  rowId: string;
  mobile?: boolean;
}

const InvoiceRow: React.FC<InvoiceRowProps> = ({ rowId }) => {
  const { mobile } = useMobile();
  const { sortedInvoices } = useRemitForm();

  const invoice =
    sortedInvoices.find(inv => inv.rowId === rowId) || getEmptyRemitInvoice();

  const isRemitInvoiceRow = isRemitInvoice(invoice);

  return (
    <>
      {isRemitInvoiceRow && <InvoiceDataRow {...{ mobile, rowId }} />}
      {isRemitInvoiceRow && <InvoiceCommentRow rowId={rowId} />}
      {!isRemitInvoiceRow && !mobile && <BlankCommentRow rowId={rowId} />}
      {!isRemitInvoiceRow && mobile && (
        <BlankMobileCommentRow {...{ mobile, rowId }} />
      )}
    </>
  );
};

export default InvoiceRow;
