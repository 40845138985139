import styled from "styled-components";
import colors from "@billfire/toybox";
// import { Link } from "react-router-dom";

export const StyledLink = styled.button`
  color: ${colors.colors.baseBlue0};
  border: none;
  padding: 0;
  background: none;
  font-weight: 100;
  text-decoration: underline;
  cursor: pointer;

  @media print {
    display: none;
  }
`;

export const PrintedText = styled.div`
  display: none;
  color: #4a4a4a;

  @media print {
    display: block;
  }
`;
