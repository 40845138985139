import * as React from "react";
import {
  TableContainer,
  StyledInvoiceTable,
  StyledCaption
} from "./styled.components";
import { useExcludedInvoiceSelectors } from "../hooks";

import { confirmationColumns, createConfirmationRows } from "../utils";

const ExcludedInvoices: React.FC = () => {
  const { invoices, excludedInvoiceIds } = useExcludedInvoiceSelectors(false);

  return excludedInvoiceIds.length > 0 ? (
    <TableContainer data-testid="excluded-invoice-table">
      <StyledInvoiceTable
        initialData={createConfirmationRows(invoices, excludedInvoiceIds)}
        columns={confirmationColumns}
        TableCaptionComponent={() => (
          <StyledCaption>Excluded Invoices</StyledCaption>
        )}
      ></StyledInvoiceTable>
    </TableContainer>
  ) : null;
};

export default ExcludedInvoices;
