import React from "react";
import { Frequency } from "../../state/entities/shared/types";

import { StatementDelivery } from "../../state/entities/statementDelivery/types";
import { DayPicker, WeeklyRadioGroup } from "./bits";
import DeliverStatementsMessage from "./DeliverStatementsMessage";
import SendStatementsCheckbox from "./SendStatementsCheckbox";
import { ContentContainer, StyledTab, StyledTabs, TabsTitle } from "./styled";
import SubmitGroup from "./SubmitGroup";
import { isFrequency } from "./types";
import { calculateDefault, isFormChanged } from "./utils";
export interface StatementsSignupContentProps {
  statementDelivery: StatementDelivery;
}

export const StatementsSignupContent: React.FC<
  StatementsSignupContentProps
> = ({ statementDelivery }) => {
  const { scheduleUnit, scheduleValue } = statementDelivery;

  const [unit, setUnit] = React.useState<Frequency>(scheduleUnit);
  const [value, setValue] = React.useState<string>(scheduleValue);
  const [sendEmptyStatements, setSendEmptyStatements] = React.useState<boolean>(
    statementDelivery.sendEmptyStatements
  );

  const isFormValid = isFormChanged(statementDelivery, {
    scheduleUnit: unit,
    scheduleValue: value,
    sendEmptyStatements
  });

  const handleTabChange = (tabId: string) => {
    if (isFrequency(tabId)) {
      setValue(calculateDefault(statementDelivery, tabId));
      setUnit(tabId);
    }
  };

  const handleRadioChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <ContentContainer>
        <TabsTitle>Frequency</TabsTitle>
        <StyledTabs
          activeTabId={statementDelivery.scheduleUnit}
          onTabClick={handleTabChange}
        >
          <StyledTab title="Weekly" id="weekly">
            <WeeklyRadioGroup
              isShowing={unit === Frequency.WEEKLY}
              currentValue={value}
              setSelectedValue={handleRadioChange}
            />
          </StyledTab>
          <StyledTab title="Biweekly" id="biweekly">
            <WeeklyRadioGroup
              isShowing={unit === Frequency.BIWEEKLY}
              currentValue={value}
              setSelectedValue={handleRadioChange}
            />
          </StyledTab>
          <StyledTab title="Monthly" id="monthly">
            <DayPicker
              currentValue={value}
              setSelectedValue={handleRadioChange}
            />
          </StyledTab>
        </StyledTabs>
        <SendStatementsCheckbox
          checked={sendEmptyStatements}
          setSendEmptyStatements={setSendEmptyStatements}
        />
      </ContentContainer>
      <SubmitGroup
        statementSchedule={{
          scheduleUnit: unit,
          scheduleValue: value,
          sendEmptyStatements
        }}
        isFormValid={isFormValid}
      />

      <DeliverStatementsMessage />
    </>
  );
};

export default StatementsSignupContent;
