import styled from "styled-components";
import styles, { Button, DataTable, TableRow } from "@billfire/toybox";
import { device } from "../../../breakpoints";

const {
  colors: { baseGray7 }
} = styles;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (${device.tablet}) {
    justify-content: center;
  }
`;

export const SubtotalContainer = styled.div`
  width: 50%;
  padding: 0 0 10px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px black solid;

  @media only screen and (${device.tablet}) {
    width: 100%;
  }
`;

export const InnerTotalContainer = styled.div`
  width: 50%;
  padding: 0 0 10px 0;

  @media only screen and (${device.tablet}) {
    width: 100%;
  }
`;

export const SubtotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #4a4a4a;
  padding: 5px 0 5px 0;
`;

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
`;

export const LocationStatementContainer = styled.div`
  margin: 32px 0 0 0;
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: auto;
  width: 330px;
  margin-top: 31px;
  font-size: 20px;

  @media only screen and (${device.tablet}) {
    width: 100%;
  }
`;

export interface CustomTableColumnProps {
  isPastDue: boolean;
}

export const CustomTableColumn = styled.div<CustomTableColumnProps>`
  color: ${props => (props.isPastDue ? "#cb000b" : "")};
`;

export const PaddingRightColumn = styled.div<{ mobile: boolean | undefined }>`
  padding-right: ${props => (props.mobile ? "0" : "20px")};
`;

export const InvoiceListMessage = styled.div`
  margin: 20px 13px 20px 13px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
`;

export const InvoiceTable = styled(DataTable).attrs({
  "data-testid": "invoiceTable"
})`
  margin: 25px 0px 20px 0px;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 0 5px;
    font-size: 16px;
    color: #4a4a4a;
    :last-child {
      text-align: right;
      padding-right: 0;
    }
    :first-child {
      padding-left: 0;
      text-align: left;
    }
    :nth-child(2) {
      text-align: left;
    }
  }

  th {
    opacity: 0.75;
    font-weight: 500;
    padding-bottom: 7px;
  }

  tbody {
    tr {
      td {
        font-weight: 300;
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }

  @media print {
    tfoot {
      display: table-row-group;
    }
  }

  @media only screen and (${device.tablet}) {
    td:nth-child(1) {
      padding: 0 0 0 0;
    }
    th,
    td {
      :nth-child(2) {
        padding-left: 25px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    th,
    td {
      :nth-child(2) {
        padding-left: 15px;
      }
    }
  }
`;

export const TBody = styled.tbody`
  border-top: solid 1px ${baseGray7};
  border-bottom: solid 1px ${baseGray7};
`;

export const BodyRow = styled(TableRow)`
  border-bottom: solid 1px #e4e4e4;
  &:last-child {
    border-bottom: none;
  }
`;
