import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sharedTypes as types } from "./index";
import {
  getEmptyConfiguration,
  getEmptyGlobalFeatureFlags,
  getEmptyEPA
} from "./types";
import { EpaInterfaces } from "@billfire/va-types";
export const initialState: types.SharedState = {
  unAuthError: false,
  authToken: "",
  epa: {
    ...getEmptyEPA()
  },
  loadingEpa: false,
  loadedEpa: false,
  loadEpaFailed: false,
  loadingGlobalHolidays: false,
  loadedGlobalHolidays: false,
  loadGlobalHolidaysFailed: false,
  globalHolidays: [],
  configuration: { ...getEmptyConfiguration() },
  loadingConfiguration: false,
  loadedConfiguration: false,
  loadConfigurationFailed: false,
  globalFeatureFlags: { ...getEmptyGlobalFeatureFlags() }
};

export const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    loadGlobalHolidaysSubmit: (state: types.SharedState) => {
      state.loadGlobalHolidaysFailed = false;
      state.loadedGlobalHolidays = false;
      state.loadingGlobalHolidays = true;
    },
    loadGlobalHolidaysSuccess: (
      state: types.SharedState,
      action: PayloadAction<string[]>
    ) => {
      state.globalHolidays = action.payload;
      state.loadGlobalHolidaysFailed = false;
      state.loadedGlobalHolidays = true;
      state.loadingGlobalHolidays = false;
    },
    loadGlobalHolidaysFailed: (state: types.SharedState) => {
      state.loadGlobalHolidaysFailed = true;
      state.loadedGlobalHolidays = false;
      state.loadingGlobalHolidays = false;
    },
    loadGlobalConfigSubmit: (state: types.SharedState) => {
      state.loadingConfiguration = true;
      state.loadConfigurationFailed = false;
      state.loadedConfiguration = false;
    },
    loadGlobalFeatureFlagsSuccess: (
      state: types.SharedState,
      action: PayloadAction<types.GlobalFeatureFlags>
    ) => {
      state.loadConfigurationFailed = false;
      state.loadedConfiguration = true;
      state.loadingConfiguration = false;
      state.globalFeatureFlags = action.payload;
    },
    loadGlobalConfigSuccess: (
      state: types.SharedState,
      action: PayloadAction<types.Configuration>
    ) => {
      state.loadConfigurationFailed = false;
      state.loadedConfiguration = true;
      state.loadingConfiguration = false;
      state.configuration = action.payload;
    },
    loadGlobalConfigFailed: (state: types.SharedState) => {
      state.loadingConfiguration = false;
      state.loadConfigurationFailed = true;
      state.loadedConfiguration = false;
    },
    unAuthorized: (state: types.SharedState) => {
      state.unAuthError = true;
    },
    reset: (state: types.SharedState) => {
      state.unAuthError = false;
    },
    setToken: (state, action: PayloadAction<{ authToken: string }>) => {
      state.unAuthError = false;
      state.authToken = action.payload.authToken;
    },
    loadEpaSubmit: (state: types.SharedState) => {
      state.loadingEpa = true;
      state.loadedEpa = false;
      state.loadEpaFailed = false;
    },
    loadEpaSuccess: (state, action: PayloadAction<EpaInterfaces.Epa>) => {
      state.epa = action.payload;
      state.loadingEpa = false;
      state.loadedEpa = true;
      state.loadEpaFailed = false;
    },
    loadEpaFailed: (state: types.SharedState) => {
      state.loadingEpa = false;
      state.loadedEpa = false;
      state.loadEpaFailed = true;
    }
  }
});

export const {
  unAuthorized,
  reset,
  setToken,
  loadEpaSubmit,
  loadEpaSuccess,
  loadEpaFailed,
  loadGlobalHolidaysSubmit,
  loadGlobalHolidaysSuccess,
  loadGlobalHolidaysFailed,
  loadGlobalConfigSubmit,
  loadGlobalConfigSuccess,
  loadGlobalConfigFailed,
  loadGlobalFeatureFlagsSuccess
} = sharedSlice.actions;

export default sharedSlice.reducer;
