import { ModalSize } from "@billfire/toybox";
import AddInvoiceCommentModal from "../components/InvoiceJourney/AddInvoiceCommentModal";
import { AddInvoiceCommentModalProps } from "../components/InvoiceJourney/AddInvoiceCommentModal/AddInvoiceCommentModal";
import { invoiceTypes } from "../state/entities/invoices";
import { BasicWhiteModalProps } from "./modals/BasicWhiteModal";
import { ChangePaymentMethodConfirmFeeModalProps } from "./modals/ChangePaymentMethodConfirmFeeModal";
import { ConfirmAutopayDeleteModalProps } from "./modals/ConfirmAutopayDeleteModal";
import { ConfirmPaymentPlanUpdateModalProps } from "./modals/ConfirmPaymentPlanUpdateModal";
import { SetReminderModalProps } from "../components/SetupReminders/SetReminderModal";
import { DeleteReminderModalProps } from "../components/SetupReminders/DeleteReminderModal";
import { BasicConfirmModalProps } from "./modals/BasicConfirmModal";
import { PdfOrHistoryModalProps } from "./modals/PdfOrHistoryModal";
export const BASIC_GRAY = "BasicGrayModal";
export const BASIC_WHITE = "BasicWhiteModal";
export const EDIT_INVOICE = "EditInvoiceModal";
export const PAYMENT_METHOD_DELETE = "PaymentMethodDeleteModal";
export const PAYMENT_METHOD_CONFIRM = "PaymentMethodConfirmModal";
export const UNDO_DISPUTED_INVOICE_MODAL = "UndoDisputedInvoiceModal";
export const CHANGE_PAYMENT_METHOD_CONFIRM_MODAL =
  "ChangePaymentMethodConfirmFeeModal";
export const CONFIRM_AUTOPAY_DELETE_MODAL = "ConfirmAutopayDeleteModal";
export const ADD_INVOICE_COMMENT_MODAL = "AddInvoiceCommentModal";
export const CONFIRM_PAYMENT_PLAN_UPDATE_MODAL =
  "ConfirmPaymentPlanUpdateModal";
export const SET_REMINDER_MODAL = "SetReminderModal";
export const DELETE_REMINDER_MODAL = "DeleteReminderModal";
export const BASIC_CONFIRM_MODAL = "BasicConfirmModal";
export const PDF_OR_HISTORY_MODAL = "PdfOrHistoryModal";

interface EmptyModalType {
  type: null;
  props: {};
}

export const getEmptyModal = (): EmptyModalType => ({
  type: null,
  props: {}
});

interface BasicGrayModal {
  type: typeof BASIC_GRAY;
  props: {
    message: string;
    onClose?: () => void;
    size?: ModalSize;
  };
}

interface BasicWhiteModal {
  type: typeof BASIC_WHITE;
  props: BasicWhiteModalProps;
}

interface ConfirmAutopayDeleteModal {
  type: typeof CONFIRM_AUTOPAY_DELETE_MODAL;
  props: ConfirmAutopayDeleteModalProps;
}

interface ConfirmPaymentPlanUpdateModal {
  type: typeof CONFIRM_PAYMENT_PLAN_UPDATE_MODAL;
  props: ConfirmPaymentPlanUpdateModalProps;
}

interface EditInvoiceModal {
  type: typeof EDIT_INVOICE;
  props: {
    onClose: () => void;
    invoice: invoiceTypes.Invoice;
  };
}

interface UndoDisputedInvoiceModal {
  type: typeof UNDO_DISPUTED_INVOICE_MODAL;
  props: {
    onClose: () => void;
    onSubmit: () => void;
    invoice: invoiceTypes.DisputedInvoice;
  };
}

interface PaymentMethodDeleteModal {
  type: typeof PAYMENT_METHOD_DELETE;
  props: {
    onClose: () => void;
    name: string;
    lastFour: string;
    checkPaymentsLoading: boolean;
    setCheckPaymentsLoading: (val: boolean) => void;
  };
}

interface PaymentMethodConfirmModal {
  type: typeof PAYMENT_METHOD_CONFIRM;
}

interface ChangePaymentMethodConfirmFeeModal {
  type: typeof CHANGE_PAYMENT_METHOD_CONFIRM_MODAL;
  props: ChangePaymentMethodConfirmFeeModalProps;
}

interface AddInvoiceCommentModal {
  type: typeof ADD_INVOICE_COMMENT_MODAL;
  props: AddInvoiceCommentModalProps;
}

interface SetReminderModal {
  type: typeof SET_REMINDER_MODAL;
  props: SetReminderModalProps;
}

interface DeleteReminderModal {
  type: typeof DELETE_REMINDER_MODAL;
  props: DeleteReminderModalProps;
}

interface BasicConfirmModal {
  type: typeof BASIC_CONFIRM_MODAL;
  props: BasicConfirmModalProps;
}

interface PdfOrHistoryModal {
  type: typeof PDF_OR_HISTORY_MODAL;
  props: PdfOrHistoryModalProps;
}

export type ModalType =
  | EmptyModalType
  | BasicGrayModal
  | BasicWhiteModal
  | EditInvoiceModal
  | PaymentMethodDeleteModal
  | PaymentMethodConfirmModal
  | UndoDisputedInvoiceModal
  | ChangePaymentMethodConfirmFeeModal
  | ConfirmAutopayDeleteModal
  | AddInvoiceCommentModal
  | ConfirmPaymentPlanUpdateModal
  | SetReminderModal
  | DeleteReminderModal
  | BasicConfirmModal
  | PdfOrHistoryModal;
