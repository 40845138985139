import * as React from "react";
import { StyledTooltip, ExclamationIcon } from "./styled";
import { useFee } from "../../hooks/useTotal";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";

export interface FeeToolTipProps {
  paymentMethod?: PaymentMethod;
}

const FeeToolTip: React.FC<FeeToolTipProps> = ({ paymentMethod }) => {
  const feePercent = useFee(paymentMethod);

  return (
    <StyledTooltip
      message={[
        <div key="ccFeeTooltip" data-testid="tip-message">
          Please note: A<strong>{` ${feePercent} service fee `}</strong>
          is added to all payments made with this card.
        </div>
      ]}
      tailDirection="center"
      pushTailFromRight="157px"
    >
      <ExclamationIcon fill={"rgb(74, 144, 226)"} />
    </StyledTooltip>
  );
};

export default FeeToolTip;
