import * as React from "react";
import DetailRow from "./DetailRow";
import { useNextPaymentPlanPaymentDate } from "./hooks";

const DateDetail: React.FC = () => {
  const nextPaymentDate = useNextPaymentPlanPaymentDate();

  return (
    <DetailRow titleText="Next Payment Date" detailText={nextPaymentDate} />
  );
};

export default DateDetail;
