import { DataTable, TableRow } from "@billfire/toybox";
import styled from "styled-components";

export const Header = styled.div`
  text-align: center;

  h2 {
    margin-top: 20px;
    margin-bottom: 50px;
    color: rgb(80, 80, 80);
    font-weight: 400;
  }
`;

export const ThankYouHeader = styled.div`
  font-size: 24px;
  padding: 25px 0px;
`;

export const CreditCardFeesContainer = styled.div`
  padding-top: 20px;
`;

export const DisputeMessage = styled.div`
  font-size: 16px;
  color: #4a4a4a;
  text-align: center;
  padding-bottom: 40px;
`;

export const InvoiceTable = styled(DataTable).attrs({
  "data-testid": "invoiceTable"
})`
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
  th {
    padding: 7px 0;
    font-size: 14px;
    color: #777;
    &:last-child {
      text-align: right;
    }
  }
`;

export const BodyRow = styled(TableRow)`
  border: none;
`;

export const TBody = styled.tbody`
  td {
    padding: 7px 0;
    color: #4a4a4a;
    &:last-child {
      text-align: right;
    }
  }
`;
