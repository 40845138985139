import * as React from "react";
import { StyledPanel, PanelName, Click2PayIcon, RefDiv } from "./styled";
import { useHover, useClearPersisted } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const Click2PayPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const { appendPath } = usePageNav();
  const clearPersisted = useClearPersisted();
  return (
    <RefDiv
      onClick={() => {
        clearPersisted();
        appendPath(`click2pay`);
      }}
      ref={hoverRef}
      data-testid="click2Pay-hover"
    >
      <StyledPanel background={isHovered ? "#efe9db" : ""}>
        <Click2PayIcon fill={"#000"} data-testid="C2PIcon" />
        <PanelName>PAY</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default Click2PayPanel;
