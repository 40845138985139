import * as React from "react";
import { Accordion } from "@billfire/toybox";
import DateSelect from "../DateSelect";
import { DateTime } from "luxon";
import { useSetDate } from "./hooks";
import { getISODate } from "../../utils";
import { Title } from "./styled";

export interface PaymentDatePickerProps {
  title: string;
  selectedDate: DateTime | null;
  validDates: Record<string, boolean>;
  getNewInitialPaymentDate: () => DateTime;
  readOnly?: boolean;
}

const PaymentDatePicker: React.FC<PaymentDatePickerProps> = ({
  title,
  selectedDate,
  validDates,
  getNewInitialPaymentDate,
  readOnly
}) => {
  const setDate = useSetDate();
  React.useEffect(() => {
    if (!selectedDate) {
      setDate(getNewInitialPaymentDate());
    }
  }, [selectedDate, getNewInitialPaymentDate, setDate]);

  return (
    <Accordion
      headingPadding="15px 0px"
      title={<Title>{title}</Title>}
      secondaryTitle={
        <span style={{ fontSize: "15px", color: "#777", fontWeight: "normal" }}>
          {selectedDate
            ? `${selectedDate.month}/${selectedDate.day}/${selectedDate.year}`
            : ""}
        </span>
      }
      fused
      content={
        readOnly ? null : (
          <DateSelect
            selectedDate={selectedDate && selectedDate.toJSDate()}
            onChange={(jsDate: Date) => setDate(DateTime.fromJSDate(jsDate))}
            filterDate={(jsDate: Date) => {
              const ISODate = getISODate(jsDate);
              // eslint-disable-next-line security/detect-object-injection
              return !!validDates[ISODate];
            }}
          />
        )
      }
      fontSize="15px"
      titleColor="#777"
      disabled={!!readOnly}
    />
  );
};

export default PaymentDatePicker;
