import * as React from "react";

import { formatDueDate } from "../../state/entities/invoices/utils";
import LocationHeaderText from "../LocationHeaderText";
import EmptyMessage from "./EmptyMessage";
import ErrorMessage from "./ErrorMessage";
import { useInvoiceErrorInfo } from "./hooks";
import LocationStatement from "./LocationStatement";
import { StatementContainer, StatementSubTitle } from "./styled.components";

const StatementContent: React.FC = () => {
  const headerText = "Account Statement";
  const { invoiceLoadError, hasInvoices } = useInvoiceErrorInfo();
  return (
    <>
      <StatementContainer>
        <LocationHeaderText headerText={headerText} />
        <StatementSubTitle>as of {formatDueDate(Date.now())}</StatementSubTitle>
        {!invoiceLoadError && hasInvoices && (
          <LocationStatement headerText={headerText} />
        )}
        {!hasInvoices && !invoiceLoadError && <EmptyMessage />}
        {invoiceLoadError && <ErrorMessage />}
      </StatementContainer>
    </>
  );
};

export default StatementContent;
