import * as React from "react";
import { useSelector } from "react-redux";
import { getSelectedPaymentMethod } from "../../../state/entities/paymentMethods/selectors";
import { StoreType } from "../../../state/types";
import { dataTableColumns } from "../PaymentMethodDeleteModal/utils";
import { InvoiceForSelectedDataRow } from "../types";
import {
  ModalTableContainer,
  StyledDataTable,
  StyledP
} from "./styled.components";

export interface SelectedInvoiceTableProps {
  invoicesForSelected: InvoiceForSelectedDataRow[];
}

const SelectedInvoiceTable: React.FC<SelectedInvoiceTableProps> = ({
  invoicesForSelected
}) => {
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state)
  );

  const name = selectedPaymentMethod?.displayExtra.name;

  return (
    <>
      <StyledP>{name} is being used to pay the following invoices:</StyledP>
      <StyledDataTable
        initialData={invoicesForSelected}
        columns={dataTableColumns}
        TableContainerComponent={({ children }) => (
          <ModalTableContainer>{children}</ModalTableContainer>
        )}
      />
      <StyledP>
        If you delete this payment method, we will send you a new Click2Pay for
        these invoices.
      </StyledP>
    </>
  );
};

export default SelectedInvoiceTable;
