import { Panel } from "@billfire/toybox";
import styled from "styled-components";

export interface PanelProps {
  noHeader: boolean;
}

interface DivProps {
  small?: boolean;
}

export const StyledDiv = styled.div<DivProps>`
  @media only screen and (max-width: 375px) {
    padding: 13px;
  }

  display: flex;
  justify-content: center;
  font-size: 27px;
  text-align: center;
  color: #272a43;
  ${props =>
    props.small
      ? `
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  `
      : ""};
`;

export interface VendorLogoProps {
  logoPadding?: string;
  small?: boolean;
}

export const VendorLogo = styled.img.attrs({
  "data-testid": "vendor-logo"
})<VendorLogoProps>`
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }

  max-height: 80px;
  ${props => (props.small ? "max-width: 287px;" : "")};
  align-self: center;
  padding: ${props => (props.logoPadding ? props.logoPadding : "10px 5px")};
`;

export const StyledPanel = styled(Panel)<PanelProps>`
  margin-top: ${props => (props.noHeader ? "20px" : "0px")};
  margin-bottom: 12px;
`;
