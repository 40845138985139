import Print from "../../assets/print.svg";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 20px;
  color: #4990e2;
  gap: 30px;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
`;

export const PrintIcon = styled(Print).attrs({
  "data-testid": "print-icon"
})`
  align-self: center;
  color: #4990e2;
`;

export const PrintText = styled.div`
  margin: 0 0 0 7px;
  font-size: 14px;
  align-self: center;
  color: #4990e2;
`;

export const StyledPrintLink = styled.a.attrs({
  "data-testid": "print-link"
})`
  display: flex;
  @media print {
    overflow: hidden;
    height: 0;
  }
  text-decoration: none;
  cursor: pointer;
`;

export const PrintStyle = styled.div`
  @media print {
    width: 85%;
    margin: 25px auto;
  }
`;

export const DisplayNoneContainer = styled.div.attrs({
  "data-testid": "report-print-view"
})`
  display: none;
`;
