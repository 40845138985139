import {
  PaymentMethod,
  PaymentMethodTypeEnum,
  ALaCartePaymentMethodProvider
} from "./types";
import { getCreatingPaymentMethod } from "./selectors";
import { StoreType } from "../../types";
import * as SharedTypes from "@billfire/va-types";

export const getDisplayName = (paymentMethod: PaymentMethod) => {
  return `${paymentMethod.displayExtra.name} ${paymentMethod.displayExtra.lastFour}`;
};

export const isCreatingPlastiqCC = (state: StoreType) => {
  const creatingMethod = getCreatingPaymentMethod(state);
  const isCC = creatingMethod.type === PaymentMethodTypeEnum.CreditCard;

  if (!isCC) return false;

  return creatingMethod.provider === ALaCartePaymentMethodProvider.Plastiq;
};

export const getTypeFromProvider = (
  provider?: SharedTypes.PaymentProvider
): "CC" | "ACH" => {
  if (provider?.endsWith("-cc")) {
    return "CC";
  }
  return "ACH";
};

export const getFieldOrDefault = (
  field: string | undefined,
  defaultValue: string | undefined
): string => {
  return field ? field : defaultValue ? defaultValue : "";
};
