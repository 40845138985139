import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AutopayState,
  ActiveAutopay,
  getEmptyAutopay,
  isActiveAutopayType
} from "./types";

export const initialState: AutopayState = {
  activeAutopay: getEmptyAutopay(),
  loadingAutopay: false,
  loadedAutopay: false,
  loadAutopayFailed: false,
  deleting: false,
  deleted: false,
  excludedInvoiceIds: []
};

export const autopaySlice = createSlice({
  name: "autopay",
  initialState,
  reducers: {
    loadAutopay: (state: AutopayState) => {
      state.loadingAutopay = true;
      state.loadedAutopay = false;
      state.loadAutopayFailed = false;
      state.activeAutopay = getEmptyAutopay();
    },
    loadAutopaySuccess: (
      state: AutopayState,
      action: PayloadAction<ActiveAutopay>
    ) => {
      state.loadingAutopay = false;
      state.loadedAutopay = true;
      state.loadAutopayFailed = false;
      state.activeAutopay = action.payload;
      state.excludedInvoiceIds = isActiveAutopayType(action.payload)
        ? action.payload.excludedInvoices
        : [];
    },
    loadAutopayFailed: (state: AutopayState) => {
      state.loadingAutopay = false;
      state.loadedAutopay = false;
      state.loadAutopayFailed = true;
      state.activeAutopay = getEmptyAutopay();
    },
    deleteAutopay: (state: AutopayState) => {
      state.deleting = true;
      state.deleted = false;
    },
    deleteAutopaySuccess: (state: AutopayState) => {
      state.deleting = false;
      state.deleted = true;
      state.activeAutopay = getEmptyAutopay();
    },
    deleteAutopayFailed: (state: AutopayState) => {
      state.deleting = false;
      state.deleted = false;
    },
    excludeAutopayInvoices: (
      state: AutopayState,
      action: PayloadAction<string[]>
    ) => {
      state.excludedInvoiceIds = action.payload;
    },
    updateActiveAutopayExcludedInvoices: (
      state: AutopayState,
      action: PayloadAction<string[]>
    ) => {
      state.activeAutopay = {
        ...state.activeAutopay,
        excludedInvoices: action.payload
      };
    },
    updateActiveAutopayPaymentMethod: (
      state: AutopayState,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.activeAutopay = {
        ...state.activeAutopay,
        paymentMethodId: action.payload.paymentMethodId,
        paymentMethodName: action.payload.paymentMethodName,
        paymentMethodLastFour: action.payload.paymentMethodLastFour
      };
    },
    resetAutopayDeleteState: (state: AutopayState) => {
      state.deleted = false;
      state.deleting = false;
    },
    resetStore: () => initialState
  }
});

export const {
  loadAutopay,
  loadAutopaySuccess,
  loadAutopayFailed,
  deleteAutopay,
  deleteAutopaySuccess,
  deleteAutopayFailed,
  excludeAutopayInvoices,
  updateActiveAutopayExcludedInvoices,
  updateActiveAutopayPaymentMethod,
  resetAutopayDeleteState,
  resetStore
} = autopaySlice.actions;

export default autopaySlice.reducer;
