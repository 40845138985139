import * as React from "react";
import { Row, RowTitle, RowContent } from "./styled";

interface DetailRowProps {
  titleText: string;
  detailText: string | JSX.Element;
}

const DetailRow: React.FC<DetailRowProps> = ({ titleText, detailText }) => {
  return (
    <Row>
      <RowTitle>{titleText}</RowTitle>
      <RowContent>{detailText}</RowContent>
    </Row>
  );
};

export default DetailRow;
