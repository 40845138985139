import * as React from "react";
import { useParams, useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../state/types";
import {
  PaymentPlan,
  PaymentPlanConfirmationApiResponse
} from "../../state/entities/paymentPlans/types";
import { getPaymentPlanById } from "../../state/entities/paymentPlans/selectors";
import { setSelectedPaymentMethod } from "../../state/entities/paymentMethods/slice";
import {
  getSelectedPaymentMethodId,
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../state/entities/paymentMethods/selectors";
import { getEpa, getAuthToken } from "../../state/entities/shared/selectors";
import { getSelectedLocationId } from "../../state/entities/locations/selectors";
import { getSelectedVendorId } from "../../state/entities/vendors/selectors";
import { updatePaymentPlanPaymentMethod } from "./utils";
import {
  updating,
  updateFailed,
  resetUpdateState,
  updated
} from "../../state/entities/paymentPlans/slice";
import { modalTypes, useModal } from "../../globalModal";

export const useGetPaymentPlan = () => {
  const { planId } = useParams<{ planId: string }>();
  const paymentPlan: PaymentPlan = useSelector((state: StoreType) =>
    getPaymentPlanById(state, planId)
  );
  return paymentPlan;
};

export const useInitSelectedPaymentMethod = (paymentPlan: PaymentPlan) => {
  const dispatch = useDispatch();

  const activePaymentPlanPaymentMethodId = paymentPlan.paymentMethodId;
  const location: { state?: { selectedPaymentMethodId?: string } } =
    useLocation();

  React.useEffect(() => {
    const selectedPaymentMethodId = location.state?.selectedPaymentMethodId
      ? location.state.selectedPaymentMethodId
      : activePaymentPlanPaymentMethodId;

    dispatch(
      setSelectedPaymentMethod({ paymentMethodId: selectedPaymentMethodId })
    );
  }, [paymentPlan, dispatch, location.state, activePaymentPlanPaymentMethodId]);
};

export const useDecidedChangedValidPaymentMethod = (
  paymentPlan: PaymentPlan
) => {
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, true)
  );

  return (
    selectedPaymentMethod?.id !== paymentPlan.paymentMethodId &&
    !selectedPaymentMethod?.displayExtra.verificationRequired
  );
};

export const useCancelNavigation = () => {
  const history = useHistory();
  return () => history.goBack();
};

const useConfirmModal = () => {
  const { showModal } = useModal();

  const openModal = (submit: () => void) => {
    showModal({
      type: modalTypes.CONFIRM_PAYMENT_PLAN_UPDATE_MODAL,
      props: {
        submit
      }
    });
  };

  return openModal;
};

export const usePaymentPlanSubmit = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const [confirmationData, setConfirmationData] = React.useState<
    PaymentPlanConfirmationApiResponse | {}
  >({});
  const [confirmed, setConfirmed] = React.useState(false);
  const openModal = useConfirmModal();

  const provider = useSelector(getSelectedPaymentMethodProvider);
  const paymentMethodId = useSelector(getSelectedPaymentMethodId);
  const paymentMethod = useSelector(getSelectedPaymentMethod);
  const vendorId = useSelector(getSelectedVendorId);
  const locationId = useSelector(getSelectedLocationId);
  const token = useSelector(getAuthToken);
  const paymentPlan = useGetPaymentPlan();
  const { version: epaVersion } = useSelector((state: StoreType) =>
    getEpa(state, provider)
  );

  const pathname = `/token/${token}/vendors/${vendorId}/locations/${locationId}/plans/${paymentPlan.id}/update/confirmation`;

  React.useEffect(() => {
    dispatch(resetUpdateState());
  }, [paymentMethodId, checked, dispatch]);

  const submit = async () => {
    dispatch(resetUpdateState());
    dispatch(updating());

    return updatePaymentPlanPaymentMethod(
      token,
      vendorId,
      locationId,
      paymentPlan.id,
      {
        paymentMethodId,
        epaVersion
      }
    )
      .then(() => {
        dispatch(updated());
        setConfirmationData({ paymentMethod, paymentPlan, update: true });
        setConfirmed(true);
        localStorage.setItem("lastPaymentMethodId", paymentMethodId);
      })
      .catch(() => {
        dispatch(updateFailed());
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (paymentMethod?.displayExtra.serviceFee) {
      openModal(submit);
    } else submit();
  };

  return {
    checked,
    setChecked,
    confirmationData,
    handleSubmit,
    confirmed,
    pathname
  };
};
