// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { StyledErrorMessage } from "./styled";

const StatementsSignupErrorMessage = () => {
  return (
    <StyledErrorMessage>
      Sorry, something went wrong. Please refresh the page and try again.
    </StyledErrorMessage>
  );
};

export default StatementsSignupErrorMessage;
