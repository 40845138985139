import React from "react";
import { useSelector } from "react-redux";

import { modalTypes, useModal } from "../../globalModal";
import { selectCampaigns } from "../../state/entities/campaigns/selectors";
import {
  EmptyReminders,
  StyledDataTable,
  TableTitle,
  DataTableContainer
} from "./styled";
import { denormalizeCampaignEvents, tableColumnsEventDays } from "./utils";

const ReminderSchedule = () => {
  const campaigns = useSelector(selectCampaigns);
  const eventDays = denormalizeCampaignEvents(campaigns);
  const [deleteReminder, setDeleteReminder] = React.useState<{
    eventDay: number;
    id: string;
  } | null>(null);
  const { showModal } = useModal();

  React.useEffect(() => {
    deleteReminder &&
      showModal({
        type: modalTypes.DELETE_REMINDER_MODAL,
        props: {
          deleteReminder,
          setDeleteReminder
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReminder]);

  return (
    <DataTableContainer>
      <TableTitle>Reminder Schedule</TableTitle>
      {eventDays.length ? (
        <StyledDataTable
          initialData={eventDays}
          columns={tableColumnsEventDays(setDeleteReminder)}
        />
      ) : (
        <EmptyReminders>No Reminders are currently set up.</EmptyReminders>
      )}
    </DataTableContainer>
  );
};

export default ReminderSchedule;
