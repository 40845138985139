import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { DatePickerCustomStyles, Wrap } from "./styled.components";
import { CustomHeader } from ".";
import { formatWeekDay, getDayClassName } from "./utils";

export type DateSelectProps = {
  selectedDate: Date | null;
  onChange: (date: Date) => void;
  filterDate: (date: Date) => boolean;
};
const DateSelect: React.FC<DateSelectProps> = ({
  selectedDate,
  onChange,
  filterDate
}) => {
  return (
    <Wrap>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        filterDate={filterDate}
        dayClassName={(date: Date) => getDayClassName(date, filterDate)}
        open
        inline
        disabledKeyboardNavigation
        formatWeekDay={formatWeekDay}
        renderCustomHeader={CustomHeader}
      />
      <DatePickerCustomStyles />
    </Wrap>
  );
};
export default DateSelect;
