import * as React from "react";
import { FooterText } from "./styled.components";

const Footer: React.FC = () => {
  return (
    <FooterText>
      Statements are generated on a specific date at a specific time. Some
      discrepancies may arise due to timing of deliveries and payments in
      transit. Recent payments to your account may not be reflected on this
      statement. Please disregard any obligations included on this statement for
      which payment has already been submitted.
    </FooterText>
  );
};

export default Footer;
