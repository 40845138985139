import React from "react";
import { Modal, ModalSize, ModalType, Button } from "@billfire/toybox";
import { ButtonGroup } from "../modals.styled";
import { invoiceTypes } from "../../state/entities/invoices";
import { usePageNav } from "../../hooks/navigation";
import PrefixEllipsis from "../../components/PrefixEllipsis";

export interface PdfOrHistoryModalProps {
  storeInvoice: invoiceTypes.Invoice;
  onClose: () => void;
}
const PdfOrHistoryModal: React.FC<PdfOrHistoryModalProps> = ({
  storeInvoice,
  onClose
}) => {
  const { getLocationReplacementString, locationPath } = usePageNav();
  const pdfLoaderPath = getLocationReplacementString(
    `invoices/${storeInvoice.id}/pdfloader`
  );

  return (
    <Modal
      title={""}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={onClose}
      loading={false}
      errored={false}
      hideActions={true}
    >
      <div style={{ padding: "0 35px 20px" }}>
        <div style={{ fontWeight: "bold", fontSize: "24px" }}>
          Invoice <PrefixEllipsis text={storeInvoice.displayInvNum} />
        </div>
        <ButtonGroup>
          <Button
            color="primary"
            loading={false}
            handleClick={() => {
              onClose();
              //eslint-disable-next-line security/detect-non-literal-fs-filename
              window.open(pdfLoaderPath, "_blank");
            }}
            padding="12px 18px"
          >
            View Invoice Copy
          </Button>
          <Button
            color="primary"
            loading={false}
            handleClick={() => {
              onClose();
              locationPath(`invoices/${storeInvoice.id}`);
            }}
            padding="12px 18px"
          >
            Invoice History
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};
export default PdfOrHistoryModal;
