import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { modalTypes, useModal } from "../../globalModal";
import { useMounted } from "../../hooks/useMounted";
import { getAuthToken } from "../../state/entities/shared/selectors";
import { StoreType } from "../../state/types";
import { createInvoiceComment, updateContactReadAt } from "./utils";

const useCommentsModal = () => {
  const { showModal, hideModal } = useModal();

  const showCommentModal = (
    handleSubmit: (comment: string) => void,
    submitting: boolean,
    submitFailed: boolean
  ) => {
    showModal({
      type: modalTypes.ADD_INVOICE_COMMENT_MODAL,
      props: {
        handleSubmit,
        submitFailed,
        submitting
      }
    });
  };

  const showSubmittedModal = () => {
    hideModal();
    showModal({
      type: modalTypes.BASIC_GRAY,
      props: { message: "Comment submitted!" }
    });
  };

  return { showCommentModal, showSubmittedModal };
};

export const useShowCommentModal = (invoiceId: string): (() => void) => {
  const { vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
  }>();

  const { authToken } = useSelector((state: StoreType) => ({
    authToken: getAuthToken(state)
  }));

  const { isMounted } = useMounted();

  const { showCommentModal, showSubmittedModal } = useCommentsModal();

  const handleSubmit = (comment: string) => {
    showCommentModal(handleSubmit, true, false);
    createInvoiceComment(authToken, vendorId, locationId, invoiceId, comment)
      .then(() => {
        isMounted() && showSubmittedModal();
      })
      .catch(() => {
        isMounted() && showCommentModal(handleSubmit, false, true);
      });
  };

  const handleShowModal = () => {
    showCommentModal(handleSubmit, false, false);
  };

  return handleShowModal;
};

export const useUpdateRelatedMessages = () => {
  const { token, vendorId, invoiceId } = useParams<{
    token: string;
    vendorId: string;
    invoiceId: string;
  }>();

  React.useEffect(() => {
    updateContactReadAt(
      `/vendors/${vendorId}/messages`,
      { invoiceId },
      token
    ).catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
