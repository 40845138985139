import { DataTable } from "@billfire/toybox";
import styled from "styled-components";
import colors from "@billfire/toybox";
import { Link } from "react-router-dom";
import { device } from "../../breakpoints";

export const InvoiceReportDataTable = styled(DataTable)`
  width: 90%;
  margin: auto;
  th {
    color: ${colors.colors.baseGray7};
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
  }
  td {
    color: ${colors.colors.baseGray3};
    padding: 15px;
    font-size: 14px;
  }

  tr > td:nth-child(1) > div {
    /* Standard CSS ellipsis */
    white-space: nowrap;
    padding-right: 20px;

    text-align: left;
    max-width: 90%;
  }

  @media only screen and (${device.tablet}) {
    width: 100%;

    th {
      padding: 15px 10px 15px 10px;
    }
    td {
      padding: 15px 5px 15px 5px;
    }
  }
`;

export const InvoiceReportContainer = styled.div.attrs({
  "data-testid": "invoice-report"
})`
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
  padding: 0 35px 35px 35px;

  @media only screen and (${device.tablet}) {
    padding: 0 10px 35px 10px;
  }
`;

export const ErrorContainer = styled.div`
  padding: 40px 25px 20px 25px;
  font-size: 18px;
  text-align: center;
  color: #999999;
`;

export const ErrorText = styled.div`
  padding-bottom: 20px;
`;

export const InvoiceLink = styled(Link)`
  color: ${colors.colors.baseBlue0};
`;
