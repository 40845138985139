import * as React from "react";

import { MobileCellProps } from ".";
import PrefixEllipsis from "../../../../PrefixEllipsis";
import {
  HeaderRow1,
  HeaderRow2,
  HeaderLabel,
  Value,
  InvoiceValue,
  DollarValue,
  HeaderContent,
  EllipseContainer
} from "./styled";
import { useGetSelectedVendor } from "../hooks";
import { isPastDue } from "../../../../../state/entities/invoices/utils";
import { CustomTableColumn } from "../styled";
import { formatShortDate, formatCurrency } from "@billfire/toybox";

const Header: React.FC<MobileCellProps> = ({ invoice }) => {
  const vendor = useGetSelectedVendor();

  const pastDue = isPastDue(invoice, vendor);

  return (
    <HeaderContent>
      <HeaderRow1>
        <HeaderLabel>Invoice</HeaderLabel>
        <InvoiceValue>
          <EllipseContainer>
            <PrefixEllipsis text={invoice.displayInvNum} />
          </EllipseContainer>
        </InvoiceValue>
      </HeaderRow1>
      <HeaderRow2>
        <HeaderLabel>Due</HeaderLabel>
        <Value>
          <CustomTableColumn isPastDue={pastDue}>
            {formatShortDate(invoice.dueDate)}
          </CustomTableColumn>
        </Value>
        <HeaderLabel>Pay</HeaderLabel>
        <DollarValue>{formatCurrency(invoice.displayAmount)}</DollarValue>
      </HeaderRow2>
    </HeaderContent>
  );
};

export default Header;
