// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import {
  formatCurrency,
  formatShortDate,
  sortDate,
  sortCurrency,
  sortString
} from "@billfire/toybox";
import { PaymentLink, PrintedText } from "./styled";
import {
  PaymentGroup,
  PaymentStatus
} from "../../state/entities/paymentGroups/types";
import PrefixEllipsis from "../PrefixEllipsis";
import { isPaymentGroupColumnName, SortBy } from "./types";

export interface PaymentGroupRow extends PaymentGroup {
  rowId: string;
}

export const columns = (locationPath: (path: string) => void) => [
  {
    name: "confirmationNumber",
    label: "Confirmation",
    width: "50px",
    render: (value: string, item: PaymentGroupRow) => {
      return (
        <>
          <PaymentLink
            onClick={() => locationPath(`payments/${item.id}`)}
            title={value}
          >
            <PrefixEllipsis text={value} />
          </PaymentLink>
          <PrintedText>
            <PrefixEllipsis text={value} />
          </PrintedText>
        </>
      );
    },
    sortFn: sortString
  },
  {
    name: "paymentDate",
    label: "Paid",
    width: "50px",
    formatFn: formatShortDate,
    sortFn: sortDate
  },
  {
    name: "paymentTotal",
    label: "Amount",
    width: "50px",
    formatFn: formatCurrency,
    sortFn: sortCurrency
  }
];

const headers = [
  { label: "Confirmation", key: "confirmationNumber" },
  { label: "Paid on", key: "paymentDate" },
  { label: "Amount", key: "paymentTotal" },
  { label: "Status", key: "status" }
];

const sortStatusGroup = (
  payments: PaymentGroup[],
  status: PaymentStatus,
  statusValue: string,
  sortBy: SortBy
): object[] => {
  const tableColumns = columns(() => "");

  const foundColumn = tableColumns.find(
    // eslint-disable-next-line security/detect-object-injection
    ({ name }) => name === sortBy.sortBy
  );
  const sortFn = foundColumn && foundColumn.sortFn;

  return payments
    .filter(pg => pg.status === status)
    .sort(
      (a, b) =>
        sortFn &&
        isPaymentGroupColumnName(sortBy.sortBy) &&
        // eslint-disable-next-line security/detect-object-injection
        sortFn(
          a[sortBy.sortBy],
          b[sortBy.sortBy],
          sortBy.sortDirection || "ASC"
        )
    )
    .map(pg => ({
      confirmationNumber: pg.confirmationNumber,
      paymentDate: formatShortDate(pg.paymentDate),
      paymentTotal: formatCurrency(pg.paymentTotal),
      status: statusValue
    }));
};

export const mapAndSortPaymentGroupsCSVData = (
  paymentGroups: PaymentGroup[],
  sortBys: Record<PaymentStatus, SortBy>
) => {
  const csvData = [
    ...sortStatusGroup(
      paymentGroups,
      PaymentStatus.SCHEDULED,
      "Scheduled",
      sortBys[PaymentStatus.SCHEDULED]
    ),
    ...sortStatusGroup(
      paymentGroups,
      PaymentStatus.COMPLETE,
      "Completed",
      sortBys[PaymentStatus.COMPLETE]
    ),
    ...sortStatusGroup(
      paymentGroups,
      PaymentStatus.CANCELED,
      "Canceled",
      sortBys[PaymentStatus.CANCELED]
    )
  ];
  return { csvData, headers };
};
