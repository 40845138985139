import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { PrintIcon, StyledPrintLink, PrintText } from "./styled";

interface PrintLinkProps {
  printRef: React.RefObject<HTMLDivElement>;
  documentTitle: string;
}

const PrintLink: React.FC<PrintLinkProps> = ({ printRef, documentTitle }) => {
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle
  });

  return (
    <StyledPrintLink onClick={handlePrint}>
      <PrintIcon />
      <PrintText>Print</PrintText>
    </StyledPrintLink>
  );
};

export default PrintLink;
