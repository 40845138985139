import { DisplayedPaymentMethod } from "../paymentMethods/types";

export interface ApiResponsePaymentGroups {
  paymentGroups: Array<PaymentGroup>;
}

export interface ProviderExtra {
  paymentId?: number;
  reference?: string;
  fee?: { amount: number; percent: string };
  statementDescriptor?: string;
}

export const getEmptyProviderExtra = (): ProviderExtra => ({
  fee: {
    amount: 0,
    percent: ""
  },
  statementDescriptor: ""
});

export enum PaymentType {
  ACH = "ACH",
  CC = "CC"
}

export enum PaymentStatus {
  SCHEDULED = "scheduled",
  COMPLETE = "sent",
  CANCELED = "canceled",
  NONE = ""
}

export interface PaymentGroup {
  id: string;
  status: PaymentStatus;
  paymentDate: string;
  confirmationNumber: string;
  createdAt: string;
  updatedAt: string;
  paymentMethodLastFour: string;
  paymentMethodName: string;
  paymentMethodType: string;
  contactFirstName: string;
  contactLastName: string;
  contactDeliverTo: string;
  sourceType: string;
  paymentPlanId: string;
  requestId: string;
  paymentPlanName?: string;
  paymentTotal: number;
  paymentType: string;
  providerExtra: ProviderExtra;
  paymentMethod?: DisplayedPaymentMethod; // TODO make not optional after cruds are updated
}

export const getEmptyPaymentGroup = (): PaymentGroup => ({
  id: "",
  status: PaymentStatus.NONE,
  paymentDate: "",
  confirmationNumber: "",
  createdAt: "",
  updatedAt: "",
  paymentMethodLastFour: "",
  paymentMethodName: "",
  paymentMethodType: "",
  contactFirstName: "",
  contactLastName: "",
  contactDeliverTo: "",
  sourceType: "",
  paymentPlanId: "",
  requestId: "",
  paymentPlanName: "",
  paymentTotal: 0,
  paymentType: "",
  providerExtra: {},
  paymentMethod: {
    type: "",
    displayExtra: {
      name: "",
      lastFour: ""
    }
  }
});

export const getEmptyPayment = () => ({
  createdAt: "",
  id: "",
  invoiceId: "",
  invoiceOriginalAmount: "",
  paymentGroupId: "",
  updatedAt: ""
});

export interface PaymentGroupsState {
  byId: Record<string, PaymentGroup>;
  loadingPaymentGroups: boolean;
  loadedPaymentGroups: boolean;
  loadPaymentGroupsFailed: boolean;
}
