import * as React from "react";
import { useReactToPrint } from "react-to-print";
import { StyledPrintLink } from "./styled";

interface PrintButtonProps {
  printRef: React.RefObject<HTMLDivElement>;
  documentTitle: string;
  onAfterPrint?: () => void;
}

const PrintButton: React.FC<PrintButtonProps> = ({
  printRef,
  documentTitle,
  onAfterPrint
}) => {
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle,
    pageStyle:
      "@page { size: auto;  margin: 7mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    onAfterPrint
  });

  return (
    <StyledPrintLink loading={false} handleClick={handlePrint}>
      Print
    </StyledPrintLink>
  );
};

export default PrintButton;
