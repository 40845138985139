import styled from "styled-components";
import { DropDown } from "@billfire/toybox";

export const StyledDropDown = styled(DropDown)<{ mobile: boolean }>`
  border-bottom: 1px solid #c8c8c8;
  min-width: ${props => (props.mobile ? "100%" : "412px")};
`;

export const DropDownContainer = styled.div<{ mobile: boolean }>`
  height: 25px;
  padding-top: 60px;
  width: fit-content;
  min-width: ${props => (props.mobile ? "100%" : "412px")};
  margin: 0 auto;
`;
