import * as React from "react";

import { PencilDisplay } from "./styled";
import { invoiceTypes } from "../../../../state/entities/invoices";
import { useDisputeModal } from "./hooks";

interface PencilIconProps {
  invoice: invoiceTypes.Invoice | invoiceTypes.DisputedInvoice;
}

const PencilIcon: React.FC<PencilIconProps> = ({ invoice }) => {
  const showDisputeModal = useDisputeModal(invoice);

  return (
    <>
      <PencilDisplay
        onClick={() => {
          showDisputeModal();
        }}
      />
    </>
  );
};

export default PencilIcon;
