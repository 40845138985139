import { Button } from "@billfire/toybox";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: center;
  max-width: 300px;
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
`;

export const StyledCancelButton = styled.a`
  height: 50px;
  width: 100%;
  color: #4a90e2;
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
`;
