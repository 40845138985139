import * as React from "react";
import { RenderComponentProps } from "../types";
import { StyledAmountBaseInput } from "./styled";
import { stripNonDigits } from "../utils";
import { useAmountInputState } from "./hooks";
import { useMobile } from "../../../hooks/useMobile";

const AmountInput: React.FC<RenderComponentProps> = ({ item }) => {
  const { isInvoice, amount, setValue, setGotFocus, setLostFocus } =
    useAmountInputState(item);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(stripNonDigits(e.target.value));
  };

  const { mobile } = useMobile();

  const amountToShow =
    item.selected || (!isInvoice && item.paymentAmount !== 0.0) ? amount : "";

  const className = isInvoice
    ? "invoice-payment-amount"
    : "blank-payment-amount";

  return (
    <StyledAmountBaseInput
      {...{ mobile }}
      className={className}
      data-testid={`amount-${item.id}`}
      id={`amount-${item.id}`}
      name={`amount-${item.id}`}
      type={"text"}
      onChange={handleChange}
      value={amountToShow}
      onFocus={setGotFocus}
      onBlur={setLostFocus}
      placeholder={mobile ? "Amount" : ""}
    />
  );
};

export default AmountInput;
