import { useSelector } from "react-redux";
import { getLocation } from "../state/entities/locations/selectors";
import { Location } from "../state/entities/locations/types";
import { StoreType } from "../state/types";
import { useParamHook } from "./useParams";

export const useLocationFromPath = (): Location => {
  const { locationId } = useParamHook();

  return useSelector((state: StoreType) => getLocation(state, locationId));
};
