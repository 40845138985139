import currency from "currency.js";
import { isRemitInvoice, RemitBaseRow, RemitInvoice } from "../types";

const isSelectedInvoice = (inv: RemitBaseRow | RemitInvoice) =>
  isRemitInvoice(inv) && inv.openAmount > 0 && inv.selected;

const isSelectedCredit = (inv: RemitBaseRow | RemitInvoice) =>
  isRemitInvoice(inv) && inv.openAmount < 0 && inv.selected;

export const calculateTotal = (
  invoices: Array<RemitBaseRow | RemitInvoice>
) => {
  const subTotalInvoices = invoices.reduce((total, inv) => {
    if (isSelectedInvoice(inv)) {
      return total.add(inv.paymentAmount);
    }
    return total;
  }, currency(0));

  const subTotalCredits = invoices.reduce((total, inv) => {
    if (isSelectedCredit(inv)) {
      return total.add(inv.paymentAmount);
    }
    return total;
  }, currency(0));

  return { subTotalCredits, subTotalInvoices };
};
