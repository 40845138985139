// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { StyledP } from "./styled";

export interface LetMeInBaseCopyProps {
  fontSize?: string;
  marginBottom?: string;
}

const LetMeInBaseCopy: React.FC<LetMeInBaseCopyProps> = ({
  fontSize,
  marginBottom
}) => {
  return (
    <StyledP {...{ fontSize, marginBottom }}>
      Please enter the email address or mobile number that you use with your
      vendors. If we find a match in our system, you will receive an email or
      text with a link to access your account. If you do not receive anything,
      please contact your vendor representative.
    </StyledP>
  );
};

export default LetMeInBaseCopy;
