import * as React from "react";
import { usePaymentPlanRequestSummary } from "./hooks";
import DetailRow from "./DetailRow";
import { DividedDataTable } from "@billfire/toybox";
import { dataTableColumns } from "./utils";
import { DataTableContainer, SummaryWrapper } from "./styled";
import { useMobile } from "../../hooks/useMobile";

const PaymentPlanSummary: React.FC = () => {
  const summary = usePaymentPlanRequestSummary();
  const { mobile } = useMobile();

  return (
    <SummaryWrapper>
      <DetailRow titleText="Total Amount" detailText={summary.totalAmount} />
      <DetailRow titleText="Paid So Far" detailText={summary.paidSoFar} />
      <DetailRow titleText="Start Date" detailText={summary.startDate} />
      <DetailRow titleText="End Date" detailText={summary.endDate} />

      <DataTableContainer>
        <DividedDataTable
          columns={dataTableColumns(mobile)}
          tableData={summary.tableData}
        />
      </DataTableContainer>
    </SummaryWrapper>
  );
};

export default PaymentPlanSummary;
