import {
  UndoDispute,
  DisputedInvoice,
  SetSelectedInvoices
} from "../invoices/types";
import { SetPaymentMethod } from "../paymentMethods/types";
import { PayloadAction } from "@reduxjs/toolkit";

export interface ById {
  [token: string]: {
    [vendorId: string]: {
      [locationId: string]: { actionHistory: Array<PersistableAction> };
    };
  };
}

export interface PersistedState {
  byId: ById;
  replaying: boolean;
  hasEverDispatchedSavedActions: boolean;
}

export interface SaveAction {
  saveAction: PersistableAction;
  token: string;
  selectedLocationId: string;
  selectedVendorId: string;
}

export enum ReplayType {
  request = "request",
  autopay = "autopay",
  none = ""
}
export type PersistableAction = PayloadAction<
  | UndoDispute
  | DisputedInvoice
  | SetPaymentMethod
  | SetSelectedInvoices
  | string
  | string[]
> & {
  replayType: ReplayType;
};

export interface ResetCurrentTokenAction {
  token: string;
}
