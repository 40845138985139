import * as React from "react";
import { getBlankColumns, getColumns, getBlankMobileColumns } from "./utils";
import SelectAllCheckBox from "./SelectAllCheckBox";
import { isRemitInvoice, PrintingComponentProps } from "./types";
import { useRemitForm } from ".";
import HeaderCell from "./HeaderCell";
import { useMobile } from "../../hooks/useMobile";

const TableHeader: React.FC<PrintingComponentProps> = () => {
  const { sortedInvoices } = useRemitForm();

  const hasInvoices = sortedInvoices.some(inv => isRemitInvoice(inv));

  const { mobile } = useMobile();

  const columns = hasInvoices
    ? getColumns(mobile)
    : mobile
    ? getBlankMobileColumns()
    : getBlankColumns();
  const headerClassName = !hasInvoices ? "no-invoices" : "";

  return (
    <thead>
      <tr className={headerClassName}>
        {hasInvoices && (
          <th className="select-box">
            <SelectAllCheckBox key={"select-all"} />
          </th>
        )}
        {columns.map(column => {
          return (
            <HeaderCell key={`header-cell-${column.name}`} column={column} />
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
