export interface ResponseInvoice extends Omit<Invoice, "openAmount"> {
  openAmount: string;
  extra?: {
    eodPassthrough: {
      stId: string;
      stName: string;
    };
  };
}

export interface ApiResponseInvoices {
  locationInvoices: Array<ResponseInvoice>;
  requestInvoiceIds: Array<string>;
}

export interface MappedInvoiceResponse {
  byId: Record<string, Invoice>;
  requestInvoiceIds: Array<string>;
}

export interface Invoice {
  id: string;
  externalId: string;
  displayInvNum: string;
  openAmount: number;
  dueDate: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  locationId: string;
  createType: string;
  externalUrl: string | null;
  payable: boolean;
  externalInvoiceDate: string | null;
  invoiceDate: string;
  poRefId: string | null;
  shipTo?: string;
  filterName?: string;
}

export const getEmptyInvoice = (): Invoice => ({
  id: "",
  externalId: "",
  displayInvNum: "",
  openAmount: 0,
  dueDate: "",
  createdAt: "",
  updatedAt: "",
  deletedAt: null,
  locationId: "",
  createType: "",
  externalUrl: null,
  payable: false,
  externalInvoiceDate: null,
  invoiceDate: "",
  poRefId: null,
  shipTo: undefined,
  filterName: undefined
});

export const getEmptyDisputedInvoice = (): DisputedInvoice => ({
  newAmount: 0,
  reason: "",
  notes: "",
  ...getEmptyInvoice()
});

export interface InvoicesState {
  byId: Record<string, Invoice>;
  disputedInvoices: DisputedInvoiceById;
  selectedInvoiceIds: Array<string>;
  loadingInvoices: boolean;
  loadedInvoices: boolean;
  loadInvoicesFailed: boolean;
  showAllInvoices: boolean;
  locationInvoiceFilters: Array<Record<string, string>>;
}

export interface DisputedInvoiceById {
  byId: Record<string, DisputedInvoice>;
}

export interface SetDispute {
  disputedInvoice: DisputedInvoice;
}

export interface SetSelectedInvoices {
  selectedInvoiceIds: string[];
}

export interface UndoDispute {
  disputedInvoice: DisputedInvoice;
}

export interface DisputedInvoice extends Invoice {
  newAmount: number;
  reason: string;
  notes: string;
}

export interface PartialPayment {
  invoiceId: string;
  amount: string;
  reason: string;
  notes: string;
}

export interface CreatePaymentGroupRequestBody {
  vendorId: string | null;
  paymentMethodId: string | null;
  paymentDate: string | null;
  partialPayments?: PartialPayment[];
  epaVersion: string | null;
  rejectedPayments?: Invoice[];
}

export const isDisputedInvoice = (
  invoice: Invoice | DisputedInvoice
): invoice is DisputedInvoice => {
  return "newAmount" in invoice;
};

export interface LocationInvoiceFilters {
  locationInvoiceFilters: Array<Record<string, string>>;
}
