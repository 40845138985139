import * as React from "react";
import { StyledPanel, PanelName, AutoPayIcon, RefDiv } from "./styled";
import { useHover, useIsAutopayOn } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const AutoPayPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const autopayOn = useIsAutopayOn();
  const { appendPath } = usePageNav();

  return (
    <RefDiv
      onClick={() => appendPath(autopayOn ? "autopaymanage" : "autopay")}
      ref={hoverRef}
      data-testid="autopay-hover"
    >
      <StyledPanel background={isHovered ? "#efe9db" : ""}>
        <AutoPayIcon fill={"#000"} data-testid="APIcon" />
        <PanelName>AUTOPAY{autopayOn && " ON"}</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default AutoPayPanel;
