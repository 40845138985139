import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { loadAutopay } from "../state/entities/autopay/slice";
import { getAutopayLoadingState } from "../state/entities/autopay/selectors";

const useLoadAutopay = () => {
  const dispatch = useDispatch();
  const autopayLoadingState = useSelector((state: StoreType) =>
    getAutopayLoadingState(state)
  );
  useEffect(() => {
    dispatch(loadAutopay());
  }, [dispatch]);

  return autopayLoadingState;
};

export default useLoadAutopay;
