import React from "react";
import { useModal } from "../index";
import { Modal, ModalSize, ModalType } from "@billfire/toybox";
import { WhiteStyledMessage } from "../modals.styled";

export interface BasicWhiteModalProps {
  title: string | JSX.Element | undefined;
  message: string | JSX.Element;
  onClose?: () => void;
  actions?: {
    submitButtonText?: string;
    cancelButtonText?: string;
    onSubmit: () => void;
  };
  loading?: boolean;
}

const BasicWhiteModal: React.FC<BasicWhiteModalProps> = ({
  title,
  message,
  onClose,
  actions,
  loading = false
}) => {
  const { hideModal } = useModal();
  const showActions = !!actions;

  return (
    <Modal
      title={title}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={onClose || hideModal}
      loading={loading}
      errored={false}
      hideActions={!showActions}
      submitButtonText={actions?.submitButtonText}
      cancelButtonText={actions?.cancelButtonText}
      onSubmit={actions?.onSubmit}
    >
      <WhiteStyledMessage>{message}</WhiteStyledMessage>
    </Modal>
  );
};

export default BasicWhiteModal;
