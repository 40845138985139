export const partialPayDisputeReasonArray = [
  { reason: "Damaged/Quality" },
  { reason: "Discount" },
  { reason: "Partial Payment" },
  { reason: "Price Incorrect" },
  { reason: "Quantity/Weight Incorrect" },
  { reason: "Refused" },
  { reason: "Tax Incorrect" },
  { reason: "Wrong Item" }
];
