import styled from "styled-components";
import styles, { RadioGroup } from "@billfire/toybox";
const { colors } = styles;
import { device } from "../../../breakpoints";

export const StyledRadioGroup = styled(RadioGroup)`
  &.radio-group {
    box-sizing: border-box;
    flex-direction: column;
    flex-wrap: wrap;
    height: 180px;
    margin-left: 90px;
    @media screen and (${device.tablet}) {
      margin-left: 30px;
    }
    .radio-label {
      width: 130px;
      height: 40px;
      .styled-radio {
        margin-right: 10px;
      }
    }
  }
`;

export const HiddenInput = styled.input.attrs({
  type: "radio",
  "data-testid": "hidden-radio-input"
})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const DayContainer = styled.div`
  display: grid;
  max-width: 301px;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto 36px;
  padding: 0;
  border: 1px solid ${colors.baseGray1};
  .day {
    background-color: white;
    cursor: pointer;
    &:hover {
      transition: background-color 0.1s ease-in;
      background-color: #ebf2fa;
      color: ${colors.baseBlue0};
    }
    &.checked {
      background: #c3d6ed;
      color: ${colors.baseBlue0};
    }
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    border-right: 1px solid ${colors.baseGray1};
    text-align: center;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    &:nth-child(-n + 28) {
      border-bottom: 1px solid ${colors.baseGray1};
    }
    &:nth-child(7n) {
      border-right: none;
    }
    &.last {
      grid-column: span 7;
      width: 100%;
      border-right: none;
    }
  }
`;
