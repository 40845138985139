import * as React from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

const ErrorMessage: React.FC = () => {
  const vendor = useSelector((state: StoreType) => getSelectedVendor(state));

  return (
    <>
      <p>
        We were unable to process your payment. You can refresh this page and
        try again, or try a different payment method.
      </p>
      <p>
        If you keep seeing this message, contact your {vendor.name}{" "}
        representative.
      </p>
    </>
  );
};

export default ErrorMessage;
