import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadEpaSubmit,
  loadEpaSuccess,
  loadEpaFailed,
  loadGlobalHolidaysSuccess,
  loadGlobalHolidaysSubmit,
  loadGlobalHolidaysFailed,
  loadGlobalConfigSubmit,
  loadGlobalConfigSuccess,
  loadGlobalConfigFailed,
  loadGlobalFeatureFlagsSuccess
} from "./slice";
import { StoreType } from "../../types";
import { EpaInterfaces } from "@billfire/va-types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";

export const fetchEpaEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadEpaSubmit.type),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      return ajax
        .get(api.API_URL(`/config?key=epa`), api.getHeaders({ token }))
        .pipe(
          map(response => {
            const resp: EpaInterfaces.Epa = api.handleAJAXResponse(
              response,
              token
            );
            return loadEpaSuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadEpaFailed());
          })
        );
    })
  );

export const fetchGlobalHolidaysEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadGlobalHolidaysSubmit.type),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      return ajax
        .get(api.API_URL(`/config?key=holidays`), api.getHeaders({ token }))
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return loadGlobalHolidaysSuccess(resp.holidays);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadGlobalHolidaysFailed());
          })
        );
    })
  );

export const fetchGlobalConfigEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadGlobalConfigSubmit.type),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(`/config?key=configuration`),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const result = api.handleAJAXResponse(response, token);

            const actionPayload = {
              paymentRequest: {
                c2pPaymentDays: result.paymentRequest.c2pPaymentDays
              },
              autopayConfig: result.autoPayConfig
            };

            return loadGlobalConfigSuccess(actionPayload);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadGlobalConfigFailed());
          })
        );
    })
  );

export const fetchGlobalFeatureFlags = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadGlobalConfigSubmit.type),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      return ajax
        .get(api.API_URL(`/config?key=featureFlags`), api.getHeaders({ token }))
        .pipe(
          map(response => {
            const result = api.handleAJAXResponse(response, token);

            return loadGlobalFeatureFlagsSuccess(result);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadGlobalConfigFailed());
          })
        );
    })
  );
