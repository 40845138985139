import * as React from "react";
import { compareName, useVendorsListener } from "./hooks";
import SkeletonLoader from "../SkeletonLoader";
import CommonWrapper from "../CommonWrapper";
import VendorRow from "../VendorRow";
import { VendorContainer, StyledH2, GridContainer } from "./styled";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { locationsLoadSubmit } from "../../state/entities/locations/slice";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";

const Landing: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const handleClick = (vendorId: string) => {
    dispatch(locationsLoadSubmit());
    history.push(`${token}/vendors/${vendorId}/locations`);
  };

  const { vendors, loadingVendors } = useVendorsLoader();

  useVendorsListener();

  const vendorCount = vendors.length;
  return loadingVendors || vendorCount === 1 ? (
    <SkeletonLoader />
  ) : (
    <CommonWrapper>
      <VendorContainer>
        <StyledH2>Choose a Vendor</StyledH2>
        <GridContainer>
          {vendors.sort(compareName).map(vendor => (
            <VendorRow
              id={vendor.id}
              key={vendor.id}
              src={vendor.config.logoUrl || ""}
              alt={vendor.name}
              handleClick={() => handleClick(vendor.id)}
            />
          ))}
        </GridContainer>
      </VendorContainer>
    </CommonWrapper>
  );
};

export default Landing;
