import styled from "styled-components";
import PFGImage from "../../assets/PFGDesktop.png";
import { PFGLogo } from "../../assets/PFGLogo.js";
import { Button } from "@billfire/toybox";

const errorRed = "rgb(208,2,27)";
const pfgRed = "rgb(157,54,57)";
const pfgLightRed = "rgb(199,88,91)";
const black = "rgb(0,0,0)";
const blackTransparent = "rgba(0,0,0, 0.4)";
export const white = "rgb(255,255,255)";
const gray = " rgb(160, 160, 160)";
const lightGray = "rgb(214,214,214)";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${black};

  @media only screen and (max-width: 820px) {
    width: 100%;
    min-height: auto;
    flex-direction: column-reverse;
  }
`;

export const ColumnWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 590px;
  min-width: 590px;
  padding: 50px 40px 50px 45px;

  @media only screen and (min-width: 821px) {
    min-height: 100vh;
  }

  > p {
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    max-width: 335px;
    margin-bottom: 60px;
  }

  p:nth-child(2) {
    @media only screen and (max-width: 820px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 420px) {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1050px) {
    width: 410px;
    min-width: 410px;
  }
  @media only screen and (max-width: 820px) {
    > p {
      max-width: 100%;
      margin-bottom: 40px;
    }
    width: 100%;
    min-width: 100%;
  }
  @media only screen and (max-width: 420px) {
    > p {
      font-size: 14px;
    }
  }
`;

export const StyledLogo = styled.div`
  background-image: url(${PFGLogo});
  background-size: cover;
  width: 420px;
  height: 56px;
  margin-bottom: 75px;

  @media only screen and (max-width: 1050px) {
    width: 300px;
    height: 40px;
    margin-bottom: 75px;
  }
  @media only screen and (max-width: 820px) {
    width: 300px;
    height: 40px;
    margin-bottom: 25px;
  }
`;

export const StyledImage = styled.div`
  font-family: "HelveticaNeue-Light", "Helvetica", "Arial", sans-serif;
  background-image: url(${PFGImage});
  background-position: top center;
  background-size: cover;
  width: 74%;
  min-height: 100vh;
  position: relative;
  div {
    width: 100%;
    position: absolute;
    top: 525px;
    text-align: center;
    color: ${white};
    background-color: ${blackTransparent};
    padding: 25px 0;
    @media only screen and (max-width: 480px) {
      text-align: left;
    }
    @media only screen and (max-width: 820px) {
      top: 330px;
    }
    @media only screen and (max-width: 480px) {
      top: 160px;
    }
    h3 {
      font-size: 92px;
      font-weight: 300;
      line-height: 0.98;
    }
    h4 {
      letter-spacing: 0.03em;
      font-size: 106px;
      text-transform: uppercase;
      line-height: 0.98;
    }

    @media only screen and (max-width: 1700px) {
      h3 {
        font-size: 70px;
      }
      h4 {
        font-size: 81px;
      }
    }
    @media only screen and (max-width: 1421px) {
      h3 {
        font-size: 45px;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 52px;
      }
    }
    @media only screen and (max-width: 1133px) {
      h3 {
        font-size: 33px;
      }
      h4 {
        font-size: 38px;
      }
    }
    @media only screen and (max-width: 480px) {
      h3 {
        font-size: 22.5px;
        margin-left: 45px;
      }
      h4 {
        font-size: 34px;
        margin-left: 45px;
      }
    }
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
    min-height: 560px;
  }
  @media only screen and (max-width: 480px) {
    min-height: 320px;
  }
`;

export const StyledPanel = styled.div<{ inputValid: boolean }>`
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  border: 1px solid ${lightGray};
  border-radius: 5px;
  padding: 20px 18px;
  margin-bottom: ${props => (props.inputValid ? "48px" : 0)};
  @media only screen and (max-width: 1050px) {
    margin-bottom: ${props => (props.inputValid ? "71px" : 0)};
  }
  @media only screen and (max-width: 820px) {
    max-width: 70%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledH1 = styled.h1`
  font-size: 64px;
  font-weight: 700;
  max-width: 297px;
  line-height: 1.04;
  margin-bottom: 17px;
  @media only screen and (max-width: 820px) {
    max-width: 100%;
    font-size: 60px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 579px) {
    max-width: 100%;
    font-size: 45px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 33px;
  }
`;
export const StyledH3 = styled.h3`
  font-size: 24px;
  margin-bottom: 18px;
  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid ${black};
  margin-bottom: 25px;
  background: none;
  padding: 5px 0;
  font-size: 20px;
  ::placeholder {
    color: ${gray};
    font-weight: 300;
    font-size: 20px;
    @media only screen and (max-width: 420px) {
      font-size: 14px;
    }
  }
  :focus {
    outline-width: 0;
  }
`;

export const StyledButton = styled(Button).attrs({
  "data-testid": "submitButton"
})<{ disabled: boolean }>`
  font-size: 20px;
  background-color: ${props => (props.disabled ? lightGray : pfgRed)};
  :hover {
    background-color: ${pfgLightRed};
  }
  :disabled {
    background-color: ${lightGray};
    color: ${white};
  }
  color: ${white};
  font-weight: 700;
  border-radius: 5px;
  line-height: 0;
  padding: 35px;
  border: none;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 420px) {
    font-size: 14px;
    padding: 25px;
  }
`;

export const ValidationError = styled.div`
  font-size: 20px;
  margin-bottom: 25px;
  color: ${errorRed};
  text-align: center;
`;

export const SubmitError = styled.div`
  font-size: 20px;
  margin-top: 25px;
  opacity: 0.88;
  text-align: center;
  color: ${errorRed};
`;
