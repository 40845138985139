/* eslint-disable-next-linemax-len */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React from "react";
import classNames from "classnames";
import { StyledSvg } from "./styled.components";

const _getIconAriaData = (label: string) => {
  const aria: Record<string, string> = {};

  if (label) {
    aria["aria-label"] = label;
  } else {
    aria["aria-hidden"] = "true";
  }

  return aria;
};

export interface IconProps {
  className?: string;
  iconName?: string;
  width?: string;
  height?: string;
  label?: string;
  viewBox?: string;
  fill?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const Icon: React.FC<IconProps> = ({
  children,
  className,
  label = "Bank Icon",
  viewBox,
  width,
  height,
  fill,
  onClick
}) => {
  const aria = _getIconAriaData(label);

  return (
    <StyledSvg
      data-testid={`${label}-icon`}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("icon", className)}
      fill={fill}
      fillRule="nonzero"
      viewBox={viewBox}
      {...aria}
      onClick={onClick ? onClick : undefined}
    >
      {children}
    </StyledSvg>
  );
};

Icon.defaultProps = {
  viewBox: "0 0 20 20"
};

export const Bank = (props: IconProps) => (
  <Icon {...props} viewBox={"0 1 16 16"} onClick={props.onClick} label="bank">
    <g
      id="Contact-C2P"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="c2p-payment-landing"
        transform="translate(-217.000000, -266.000000)"
      >
        <g
          id="baseline-account_balance-24px"
          transform="translate(216.000000, 266.000000)"
        >
          <polygon id="Path" points="0 0 19 0 19 19 0 19"></polygon>
          <path
            d="M3.16666667,7.91666667 L3.16666667,13.4583333 L5.54166667,13.4583333 L5.54166667,7.91666667 L3.16666667,7.91666667 Z M7.91666667,7.91666667 L7.91666667,13.4583333 L10.2916667,13.4583333 L10.2916667,7.91666667 L7.91666667,7.91666667 Z M1.58333333,17.4166667 L16.625,17.4166667 L16.625,15.0416667 L1.58333333,15.0416667 L1.58333333,17.4166667 Z M12.6666667,7.91666667 L12.6666667,13.4583333 L15.0416667,13.4583333 L15.0416667,7.91666667 L12.6666667,7.91666667 Z M9.10416667,0.791666667 L1.58333333,4.75 L1.58333333,6.33333333 L16.625,6.33333333 L16.625,4.75 L9.10416667,0.791666667 Z"
            id="Shape"
            fill={props.fill}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </Icon>
);

export const CreditCard = (props: IconProps) => (
  <Icon {...props} viewBox={"0 -3 30 30"} onClick={props.onClick} label="CC">
    <g
      id="Wallet-Mobile"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mobile-Wallet-new-method-cc"
        transform="translate(-213.000000, -222.000000)"
        fill={props.fill}
        fillRule="nonzero"
      >
        <g
          id="ic_credit_card_black_24px"
          transform="translate(213.000000, 222.000000)"
        >
          <path
            d="M26.6243141,0 L2.95825712,0 C1.31642442,0 0.0147912856,1.33191176 0.0147912856,2.99306015 L0,20.951421 C0,22.6125694 1.31642442,23.9444812 2.95825712,23.9444812 L26.6243141,23.9444812 C28.2661468,23.9444812 29.5825712,22.6125694 29.5825712,20.951421 L29.5825712,2.99306015 C29.5825712,1.33191176 28.2661468,0 26.6243141,0 Z M26.6243141,20.951421 L2.95825712,20.951421 L2.95825712,11.9722406 L26.6243141,11.9722406 L26.6243141,20.951421 Z M26.6243141,5.98612029 L2.95825712,5.98612029 L2.95825712,2.99306015 L26.6243141,2.99306015 L26.6243141,5.98612029 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </Icon>
);

export const XIcon = (props: IconProps) => (
  <Icon
    data-testid="XIcon"
    {...props}
    viewBox={"0 0 28 29"}
    onClick={props.onClick}
    label="X"
  >
    <path
      fill={props.fill}
      fillRule="nonzero"
      d="M28 2.9L25.2 0 14 11.6 2.8 0 0 2.9l11.2 11.6L0 26.1 2.8 29 14 17.4 25.2 29l2.8-2.9-11.2-11.6z"
    />
  </Icon>
);

export const FilledExclamation = (props: IconProps) => (
  <Icon {...props} label="exclamation">
    <g>
      <path
        d="M10,0C4.473,0 0,4.473 0,10C0,15.527 4.473,20 10,20C15.527,20 20,15.527 20,10C20,4.473 15.527,0 10,0Z"
        style={{ fill: props.fill }}
      />
      <path
        d="M10,5.556C9.386,5.556 8.889,5.909 8.889,6.346L8.889,11.432C8.889,11.869 9.386,12.222 10,12.222C10.614,12.222 11.111,11.869 11.111,11.432L11.111,6.345C11.111,5.909 10.614,5.555 10,5.555L10,5.556Z"
        style={{ fill: "white" }}
      />
      <circle cx="10" cy="14.444" r="1.111" style={{ fill: "white" }} />
    </g>
  </Icon>
);

export const Spinner = (props: IconProps) => (
  <Icon data-testid="Spinner" {...props} viewBox={"0 0 24 24"} label="spinner">
    <path
      fillRule="evenodd"
      d="M7.99.568L9.573 0l1.136 3.164-1.583.568a8.71 8.71 0 0 0-5.763 8.2c0 4.809 3.894 8.706 8.697 8.706 3.724 0 7-2.365 8.21-5.827l.556-1.586L24 14.335l-.555 1.586A12.064 12.064 0 0 1 12.06 24C5.399 24 0 18.596 0 11.931 0 6.795 3.236 2.273 7.99.568z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </Icon>
);

export const Canceled = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"0 0 18 18"}
    label="canceled"
    width="18"
    height="18"
  >
    <title>Canceled</title>
    <path
      id="canceledPath"
      data-name="CanceledPath"
      d="M18,1.8,16.2,0,9,7.2,1.8,0,0,1.8,7.2,9,0,16.2,1.8,18,9,10.8,16.2,18,18,16.2,10.8,9Z"
      fill="#c30016"
    />
  </Icon>
);

export const Scheduled = (props: IconProps) => (
  <Icon
    {...props}
    viewBox={"0 0 19 20"}
    label="scheduled"
    width="19px"
    height="20px"
  >
    <title>Scheduled</title>
    <path
      id="scheduledPath"
      data-name="ScheduledPath"
      d="M14079,11992.584a1.978,1.978,0,0,1-2-1.956l.009-13.711a1.976,1.976,0,0,1,1.99-1.958h1V11973h2v1.956h8V11973h2v1.956h1a1.98,1.98,0,0,1,2,1.958v13.711a1.978,1.978,0,0,1-2,1.956Zm0-1.956h14v-10.771h-14Zm10.048-.934v-1.987h2.033v1.987Zm-4.062,0v-1.987h2.027v1.987Zm-4.064,0v-1.987h2.033v1.987Zm8.127-2.981v-1.983h2.033v1.983Zm-4.062,0v-1.983h2.027v1.983Zm-4.064,0v-1.983h2.033v1.983Zm8.127-2.979v-1.983h2.033v1.983Zm-4.062,0v-1.983h2.027v1.983Zm-4.064,0v-1.983h2.033v1.983Z"
      transform="translate(-14076.503 -11972.503)"
      fill="#4a90e2"
      stroke="rgba(0,0,0,0)"
      stroke-miterlimit="10"
      stroke-width="1"
    />
  </Icon>
);

export const Sent = (props: IconProps) => (
  <Icon {...props} viewBox={"0 0 18 14"} label="sent" width="18" height="14">
    <title>Sent</title>
    <path
      id="sentPath"
      data-name="SentPath"
      d="M5.664,10.72,1.416,6.473,0,7.889l5.664,5.664L17.8,1.416,16.384,0Z"
      fill="#2ca01c"
    />
  </Icon>
);

export const CheckMarkWithBackground = (props: IconProps) => (
  <Icon {...props} label="checkmarkWithBackground">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-141.000000, -216.000000)" fillRule="nonzero">
        <g id="checked" transform="translate(141.000000, 216.000000)">
          <circle
            id="Oval"
            fill="#4A90E2"
            cx="46.5"
            cy="46.5"
            r="46.5"
          ></circle>
          <polygon
            id="Shape"
            fill="#FFFFFF"
            points="43.25 71 21 53.7952294 27.3235294 45.7344162 40.9882353 56.2987235 63.5382353 24 72 29.8186166"
          ></polygon>
        </g>
      </g>
    </g>
  </Icon>
);

export default {
  Bank,
  CreditCard,
  XIcon,
  FilledExclamation,
  Canceled,
  Scheduled,
  Sent,
  CheckMarkWithBackground
};
