import styled from "styled-components";
import { Button } from "@billfire/toybox";
import { device } from "../../breakpoints";

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 330px;
  margin: auto;

  @media only screen and (${device.tablet}) {
    max-width: inherit;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  padding: 20px 0px 20px 0px;
  justify-content: center;

  @media only screen and (${device.tablet}) {
    padding: 20px 0px 20px 0px;
  }
`;

export const StyledCancelButton = styled.a`
  color: #4a90e2;
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  padding: 11px 15px;
`;
