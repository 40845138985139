import * as React from "react";
import { useSelector } from "react-redux";
import { paymentMethodSelectors } from "../../state/entities/paymentMethods";
import { StoreType } from "../../state/types";
import { formatCurrency } from "@billfire/toybox";
import { FlexContainer, Row, Column } from "./styled";
import FeeToolTip from "./FeeToolTip";
import { calculateServiceFee, paymentMethodHasAFee } from "./utils";
import currency from "currency.js";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";

interface TotalsAndFeesProps {
  subTotalTitle: string;
  totalWithFeesTitle: string;
  totalBeforeFees: number;
  paymentMethod?: PaymentMethod;
  className?: string;
}

const TotalsAndFees: React.FC<TotalsAndFeesProps> = ({
  subTotalTitle,
  totalWithFeesTitle,
  totalBeforeFees,
  paymentMethod,
  className
}) => {
  const [serviceFee, setServicefee] = React.useState<currency>(currency(0));
  const [hasFee, setHasFee] = React.useState<boolean>(false);
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    paymentMethodSelectors.getSelectedPaymentMethod(state)
  );

  const paymentMethodToUse = paymentMethod
    ? paymentMethod
    : selectedPaymentMethod;

  React.useEffect(() => {
    const hasFee = paymentMethodHasAFee(paymentMethodToUse);

    if (paymentMethodToUse && hasFee) {
      setServicefee(calculateServiceFee(paymentMethodToUse, totalBeforeFees));
    } else {
      setServicefee(currency(0));
    }
    setHasFee(hasFee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodToUse, totalBeforeFees]);

  return (
    <FlexContainer className={className}>
      <Row>
        <Column>{subTotalTitle}</Column>
        <Column>{formatCurrency(totalBeforeFees)}</Column>
      </Row>

      {hasFee && (
        <>
          <Row>
            <Column>
              Card Service Fee <FeeToolTip paymentMethod={paymentMethod} />
            </Column>
            <Column>+{formatCurrency(serviceFee.value)}</Column>
          </Row>
          <Row>
            <Column>{totalWithFeesTitle}</Column>
            <Column>
              {formatCurrency(serviceFee.add(totalBeforeFees).value)}
            </Column>
          </Row>
        </>
      )}
    </FlexContainer>
  );
};

export default TotalsAndFees;
