import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectVendor } from "./state/entities/vendors/slice";
import { getSelectedVendorId } from "./state/entities/vendors/selectors";
import { StoreType } from "./state/types";

export const useSelectedVendorId = (pathName: string, vendorId: string) => {
  const selectedVendorId = useSelector((state: StoreType) =>
    getSelectedVendorId(state)
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (pathName.includes("vendors")) {
      vendorId !== selectedVendorId && dispatch(selectVendor({ vendorId }));
    }
  }, [dispatch, pathName, selectedVendorId, vendorId]);
};
