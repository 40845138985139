import { PersistedState, PersistableAction, ReplayType } from "./types";
import { StoreType } from "../../types";
import { hasActionHistory } from "./utils";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { getAuthToken } from "../shared/selectors";

export const getReplayActions = (
  state: PersistedState,
  token: string,
  selectedVendorId: string | null,
  selectedLocationId: string | null,
  type = ReplayType.none
): Array<PersistableAction> | [] => {
  if (!selectedLocationId || !selectedVendorId) return [];
  const actions = hasActionHistory(
    state.byId,
    token,
    selectedVendorId,
    selectedLocationId
  )
    ? // eslint-disable-next-line security/detect-object-injection
      state.byId[token][selectedVendorId][selectedLocationId].actionHistory
    : [];

  type && actions.filter(action => action.replayType === type);
  return actions;
};

export const isUserAction = (state: StoreType) => {
  return !state.persisted.replaying;
};

export const getHasEverDispatchedSavedActions = (state: StoreType) => {
  return state.persisted.hasEverDispatchedSavedActions;
};

export const hasAllValuesNeeded = (store: StoreType) =>
  !!getSelectedVendorId(store) &&
  !!getSelectedLocationId(store) &&
  !!getAuthToken(store);
