import * as React from "react";
import { CheckboxStates, MultiStateCheckbox } from "@billfire/toybox";
import { useDispatch, useSelector } from "react-redux";
import { StoreType } from "../../../../state/types";
import { useInvoicesToDisplay } from "./hooks";
import { setSelectedInvoiceIds } from "../../../../state/entities/invoices/slice";

const SelectAllCheckBox: React.FC = () => {
  const invoices = useInvoicesToDisplay();

  const selectedInvoiceIds = useSelector(
    (state: StoreType) => state.invoices.selectedInvoiceIds
  );

  const dispatch = useDispatch();

  const state =
    selectedInvoiceIds.length === 0
      ? CheckboxStates.none
      : selectedInvoiceIds.length === invoices.length
      ? CheckboxStates.checked
      : CheckboxStates.minus;

  const clickHandler = () => {
    const newSelectedInvoiceIds =
      state === CheckboxStates.checked ? [] : invoices.map(inv => inv.id);

    dispatch(
      setSelectedInvoiceIds({ selectedInvoiceIds: newSelectedInvoiceIds })
    );
  };

  return (
    <MultiStateCheckbox id="selectAll" onClick={clickHandler} state={state} />
  );
};

export default SelectAllCheckBox;
