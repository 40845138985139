import { StoreType } from "../../types";
import { LoadingRequestFlags, Request } from "./types";

export const getRequestExcludedInvoices = (state: StoreType) => {
  return state.requests.excludedInvoiceIds;
};

export const getSelectedPaymentDate = (state: StoreType) => {
  return state.requests.selectedPaymentDate;
};

export const getRequestLoadingFlags = (
  state: StoreType
): LoadingRequestFlags => {
  return {
    loadingRequest: state.requests.loadingRequest,
    loadedRequest: state.requests.loadedRequest,
    loadRequestFailed: state.requests.loadRequestFailed
  };
};

export const getRequestDetails = (
  state: StoreType,
  requestId: string
): Request => {
  // eslint-disable-next-line security/detect-object-injection
  return state.requests.byId[requestId];
};

export const getHasCalledUpdateStatementRequest = (state: StoreType) =>
  state.requests.hasCalledUpdateStatementRequest;

export const getAutopayRequest = (state: StoreType) => {
  return Object.values(state.requests.byId).find(req => req.type === "autopay");
};
