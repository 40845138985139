import * as React from "react";
import { useNavbarDisplay } from "../../hooks/navigation";
import { useRouteContext } from "../../routeContext";
import { Header, PageContent } from "../CommonWrapper/CommonWrapper.styled";
import Footer from "../CommonWrapper/Footer";
import Navbar from "../CommonWrapper/Navbar";
import { navigationFunctions } from "../CommonWrapper/utils";
import { Container } from "./styled";

const RemitWrapper: React.FC = ({ children }) => {
  const { hookInfo, routeInfo } = useNavbarDisplay();
  const routeContext = useRouteContext();

  return (
    <>
      <Header
        clickable={true}
        onClick={() => {
          navigationFunctions[routeInfo.navFn] &&
            navigationFunctions[routeInfo.navFn](
              hookInfo,
              routeContext
            ).navigate();
        }}
      >
        <Navbar />
      </Header>

      <Container>
        <PageContent>{children}</PageContent>
        <Footer />
      </Container>
    </>
  );
};

export default RemitWrapper;
