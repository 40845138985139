/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React from "react";
import { PaymentGroup } from "../../state/entities/paymentGroups/types";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";
import {
  Header,
  HeaderSectionTitle,
  ConfirmationNum,
  StatementDescriptorTitle,
  StatementDescriptorContainer
} from "./styled.components";

export interface ConfirmationNumberProps {
  paymentGroup?: PaymentGroup;
  paymentMethod?: PaymentMethod;
  confirmationNumber?: string;
  statementDescriptor?: string;
}

const ConfirmationNumber: React.FC<ConfirmationNumberProps> = ({
  paymentGroup,
  confirmationNumber,
  statementDescriptor
}) => {
  const descriptor =
    statementDescriptor ||
    paymentGroup?.providerExtra.statementDescriptor ||
    "";

  return (
    <Header>
      <HeaderSectionTitle>Confirmation #</HeaderSectionTitle>
      <ConfirmationNum>
        {paymentGroup?.confirmationNumber || confirmationNumber}
      </ConfirmationNum>
      {descriptor && (
        <>
          <StatementDescriptorTitle>
            Your Credit Card payment will show:
          </StatementDescriptorTitle>
          <StatementDescriptorContainer>
            {descriptor}
          </StatementDescriptorContainer>
        </>
      )}
    </Header>
  );
};

export default ConfirmationNumber;
