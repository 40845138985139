import * as React from "react";
import { useDispatch } from "react-redux";
import { loadPaymentGroupsSubmit } from "../state/entities/paymentGroups/slice";
import { useNavigation } from "./route";

export const usePaymentGroupsLoader = () => {
  const dispatch = useDispatch();
  const { vendorId, locationId } = useNavigation();

  React.useEffect(() => {
    if (vendorId && locationId) {
      dispatch(loadPaymentGroupsSubmit());
    }
  }, [dispatch, vendorId, locationId]);
};
