import { DateHelper } from "@billfire/payment-lib-base-v2";
import { DateTime } from "luxon";
import { getNextPaymentDate, ValidDates } from "./utils";

export const isValidDate = (
  days: number,
  now: DateTime,
  dateHelper: DateHelper,
  provider: string | undefined = "direct-ach"
): ValidDates => {
  const date = now.plus({ days });
  const jsDate = date.toJSDate();
  const nextPaymentDate = getNextPaymentDate(jsDate, dateHelper, provider);

  const ISODate = date.toISODate();

  return {
    [ISODate]: date.hasSame(nextPaymentDate, "day")
  };
};
