// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { DateTime } from "luxon";
import { Invoice } from "../../state/entities/invoices/types";
import { formatShortDate, formatCurrency } from "@billfire/toybox";
import { getISODate } from "../../utils";
import Api from "../../state/api";
import { PayloadAction, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import InvoiceLink from "../InvoiceLink";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";

export type ValidDates = Record<string, boolean>;

export const getValidDates = (configuredDays: number): ValidDates => {
  const datesInRange: ValidDates = {};

  const now = DateTime.now();

  for (let i = 0; i <= configuredDays - 1; i++) {
    const date = now.plus({ days: i });
    const jsDate = date.toJSDate();
    const ISODate = getISODate(jsDate);
    // eslint-disable-next-line security/detect-object-injection
    datesInRange[ISODate] = true;
  }

  return datesInRange;
};

export const columns = [
  {
    name: "dueDate",
    label: "Due Date",
    width: "33%",
    mobile: true
  },
  {
    name: "invoiceId",
    label: "Invoice",
    mobile: true,
    width: "33%",
    render: (_value: string, item: Invoice) => {
      return <InvoiceLink invoiceId={item.id} />;
    }
  },
  {
    name: "amount",
    label: "Amount",
    width: "33%",
    mobile: true
  }
];

export const createRows = (
  invoices: Invoice[],
  excludedInvoiceIds: string[],
  readOnly: boolean
) => {
  return invoices
    .filter(inv => !readOnly || excludedInvoiceIds.includes(inv.id))
    .map((invoice, i) => ({
      rowId: (i + 1).toString(),
      id: invoice.id,
      invoiceId: invoice.displayInvNum,
      dueDate: formatShortDate(invoice.dueDate),
      amount: formatCurrency(invoice.openAmount),
      externalUrl: invoice.externalUrl
    }));
};

export const mapSelectedInvoices = (
  invoices: Invoice[],
  excludedInvoiceIds: string[]
) =>
  Object.fromEntries(
    invoices.map((invoice, i) => [
      i + 1,
      excludedInvoiceIds.includes(invoice.id)
    ])
  );

export const getReIncludedInvoiceIds = (
  excludedInvoiceIds: Array<string>,
  allInvoiceIds: Array<string>
) => {
  return allInvoiceIds.filter(invId => !excludedInvoiceIds.includes(invId));
};

const compare = (a: string[], b: string[]) => {
  for (let i = 0; i < a.length; ++i) {
    // eslint-disable-next-line security/detect-object-injection
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const arraysEqual = (a: string[], b: string[]) => {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  const tempA = [...a].sort();
  const tempB = [...b].sort();

  return compare(tempA, tempB);
};

export const isFormValidCheck = (
  checked: boolean,
  selectedPaymentMethod: PaymentMethod | undefined,
  autopayPaymentMethodId: string,
  excludedInvoiceIds: string[],
  activeAutopayExcludedInvoiceIds: string[]
) => {
  return (
    checked &&
    ((selectedPaymentMethod?.id &&
      selectedPaymentMethod?.id !== autopayPaymentMethodId &&
      !selectedPaymentMethod.displayExtra.verificationRequired) ||
      !arraysEqual(excludedInvoiceIds, activeAutopayExcludedInvoiceIds))
  );
};

interface ExcludedInvoiceUpdatePayload {
  excludeInvoiceIds: string[];
  reincludeInvoiceIds: string[];
}

export const updateAutopayExcludedInvoices = async (
  token: string,
  vendorId: string | null,
  locationId: string | null,
  autopayId: string,
  payload: ExcludedInvoiceUpdatePayload
): Promise<{}> => {
  const res = await Api.postWithToken(
    `/vendors/${vendorId}/locations/${locationId}/autopay/${autopayId}`,
    payload,
    token
  );

  return res;
};

interface PaymentMethodUpdatePayload {
  paymentMethodId: string | undefined;
  epaVersion: string;
}

export const updateAutopayPaymentMethod = async (
  token: string,
  vendorId: string | null,
  locationId: string | null,
  autopayId: string,
  payload: PaymentMethodUpdatePayload
): Promise<{}> => {
  const res = await Api.patchWithToken(
    `/vendors/${vendorId}/locations/${locationId}/autopay/${autopayId}`,
    payload,
    token
  );

  return res;
};

export interface AutopaySetupPayload {
  paymentMethodId: string;
  startDate: DateTime | null;
  excludedInvoices: string[];
  epaVersion: string;
}

export interface AutopaySetupResponse {
  id: string;
  success: "true";
}

export const createAutopay = async (
  token: string,
  vendorId: string,
  locationId: string,
  payload: AutopaySetupPayload
): Promise<AutopaySetupResponse> => {
  const res = await Api.postWithToken(
    `/vendors/${vendorId}/locations/${locationId}/autopays`,
    payload,
    token
  );

  return res;
};

export interface AllSettledResponse {
  status: string;
}

export type ActionPayload = string[] | Record<string, string>;

export interface BaseHandleStatusParams {
  res: AllSettledResponse;
  errors: string[];
  dispatch: (action: PayloadAction<ActionPayload>) => void;
  error: string;
}

export interface ExcludeInvoicesHandleStatusParams
  extends BaseHandleStatusParams {
  payload: string[];
  action: ActionCreatorWithPayload<string[]>;
}

export interface UpdatePaymentMethodHandleStatusParams
  extends BaseHandleStatusParams {
  payload: Record<string, string>;
  action: ActionCreatorWithPayload<Record<string, string>>;
}

export const handledExcludedInvoicesStatus = ({
  res,
  errors,
  dispatch,
  action,
  payload,
  error
}: ExcludeInvoicesHandleStatusParams) => {
  if (res.status === "fulfilled") dispatch(action(payload));
  else errors.push(error);
};

export const handlePaymentMethodStatus = ({
  res,
  errors,
  dispatch,
  action,
  payload,
  error
}: UpdatePaymentMethodHandleStatusParams) => {
  if (res.status === "fulfilled") dispatch(action(payload));
  else errors.push(error);
};

export const confirmationColumns = [
  {
    name: "invoiceId",
    label: "Invoice",
    mobile: true,
    render: (_value: string, item: Invoice) => {
      return <InvoiceLink invoiceId={item.id} />;
    }
  },
  {
    name: "dueDate",
    label: "Due Date",
    mobile: true
  },
  {
    name: "amount",
    label: "Amount",
    mobile: true
  }
];

export const createConfirmationRows = (
  invoices: Invoice[],
  excludedInvoiceIds: Array<string>
) => {
  return invoices
    .filter(inv => excludedInvoiceIds.includes(inv.id))
    .map((invoice, i) => ({
      rowId: (i + 1).toString(),
      id: invoice.id,
      invoiceId: invoice.displayInvNum,
      dueDate: formatShortDate(invoice.dueDate),
      amount: formatCurrency(invoice.openAmount)
    }));
};
