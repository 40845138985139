import { useSelector } from "react-redux";
import { getOpenInvoices } from "../../../../state/entities/invoices/selectors";
import { isPastDue } from "../../../../state/entities/invoices/utils";
import { StoreType } from "../../../../state/types";
import { getSelectedVendor } from "../../../../state/entities/vendors/selectors";

export const useRequestInvoiceTotals = (): {
  moreInvoices: number;
  morePastDue: number;
} => {
  const { openInvoices, requestInvoiceIds, showAllInvoices } = useSelector(
    (state: StoreType) => {
      const openInvoices = getOpenInvoices(state);
      const requestInvoiceIds = state.requests.requestInvoiceIds;
      return {
        openInvoices,
        requestInvoiceIds,
        showAllInvoices: state.invoices.showAllInvoices
      };
    }
  );
  const vendor = useSelector(getSelectedVendor);

  if (showAllInvoices) {
    return { moreInvoices: 0, morePastDue: 0 };
  }

  return openInvoices.reduce(
    (acc, inv) => {
      const notRequested = !requestInvoiceIds.includes(inv.id);

      notRequested && acc.moreInvoices++;
      isPastDue(inv, vendor) && notRequested && acc.morePastDue++;
      return acc;
    },
    { moreInvoices: 0, morePastDue: 0 }
  );
};
