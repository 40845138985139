/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, filter, startWith } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  locationsLoadStart,
  locationsLoadSubmit,
  locationsLoadSuccess,
  locationsLoadFailed
} from "./slice";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { reducedLocations } from "./utils";
import { LocationsLoadStart } from "./types";

export const fetchLocationsEpic = (
  action$: Observable<PayloadAction<LocationsLoadStart>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(locationsLoadStart.type),
    filter((action: PayloadAction<LocationsLoadStart>) => {
      return (
        !state$.value.locations.hasEverLoadedLocations ||
        !!action.payload?.refresh
      );
    }),
    switchMap(() => {
      const { selectedVendorId } = state$.value.vendors;
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(`/vendors/${selectedVendorId}/locations`),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            const reduced = reducedLocations(resp);
            return locationsLoadSuccess(reduced);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(locationsLoadFailed());
          })
        );
    }),
    startWith(locationsLoadSubmit())
  );
