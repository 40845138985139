import * as React from "react";
import CommonWrapper from "../../CommonWrapper";
import { CancelMessage, LeftColumn, RightColumn, Row } from "./styled";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { resetAutopayDeleteState } from "../../../state/entities/autopay/slice";
import { LocationPageWrapper } from "../../bits/styled";
import ReportPrintView from "../../PrintExportComponent/ReportPrintView";
import ConfirmationPagePrintExport from "../../ConfirmationPagePrintExport/ConfirmationPagePrintExport";
import { ConfirmationLocationHeaderText } from "../../ConfirmationPagePrintExport/styled";
import { useFormatDocumentTitle } from "../../ConfirmationPagePrintExport/hooks";

const AutopayDeleteConfirm: React.FC = () => {
  const dispatch = useDispatch();

  dispatch(resetAutopayDeleteState());

  const printRef = React.useRef(null);
  const documentTitle = useFormatDocumentTitle();

  const headerText = "";
  const ConfirmationContent = (
    <div>
      <ConfirmationLocationHeaderText headerText={headerText} />
      <ConfirmationPagePrintExport
        printRef={printRef}
        documentTitle={documentTitle}
      />
      <CancelMessage>AutoPay turned off</CancelMessage>
      <Row>
        <LeftColumn>Deactivation Date</LeftColumn>
        <RightColumn>{DateTime.now().toFormat("M/d/yy")}</RightColumn>
      </Row>
    </div>
  );

  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <ReportPrintView printRef={printRef} headerText={headerText}>
          {ConfirmationContent}
        </ReportPrintView>
        {ConfirmationContent}
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default AutopayDeleteConfirm;
