import styled, { css } from "styled-components";
import { Panel } from "@billfire/toybox";
export type MobileProps = {
  mobile: boolean;
};
const sharedMainWrapperStyles = css`
  max-width: 1042px;
  margin: 0;
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
`;
export const Wrapper = {
  Body: styled.div`
    padding-top: 26px;
    background-color: #f9f9f9;
    padding-left: 7px;
    padding-right: 7px;
    max-width: 1042px;
    margin: 0 auto;
  `,
  VendorAndLocation: styled.div<MobileProps>`
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: ${props =>
      props.mobile ? "15px 15px 15px 15px" : "15px 0 15px 0"};
    ${sharedMainWrapperStyles}
    width: auto;
    ${props =>
      !props.mobile &&
      css`
        display: grid;
        grid-template-columns: minmax(112px, 1fr) repeat(2, 2fr);
        grid-template-rows: 1fr;
      `}
  `
};
export const HoverContainer = styled.div.attrs({
  "data-testid": "hover-container"
})<MobileProps>`
  display: flex;
  align-items: ${props => (props.mobile ? "top" : "center")};
  margin: 3px 3px 7px 3px;
  &:hover {
    background-color: #e6eef8;
    cursor: pointer;
  }
  padding: 15px 7px 15px 7px;
`;

export const VendorLocationContainer = styled(Panel)<{ noHeader: boolean }>`
  align-items: center;
  margin-top: ${props => (props.noHeader ? "20px" : "7px")};
  margin-bottom: 25px;
  background-color: #ffffff;
  border-radius: 4px;
  border-bottom: solid 1px #eaf0f7;
`;

export const VendorContainer = styled.div.attrs({
  "data-testid": "vendor-container"
})`
  display: flex;
  margin: 8px 10px;
  background-color: #ffffff;
  padding: 15px 7px 15px 7px;
  align-items: start;
`;
export const Logo = styled.img.attrs({
  "data-testid": "vendor-logo"
})`
  display: block;
  width: 100%;
  max-width: 73px;
  margin: 0 23px 0 0;
  height: auto;
`;

export const Title = styled.div.attrs({ "data-testid": "title" })`
  display: flex;
  flex-direction: column;
`;

export const Vendor = styled.h3.attrs({
  "data-testid": "vendor-name"
})`
  font-size: 20px;
  color: #212121;
  display: block;
  margin-bottom: 14px;
  font-weight: 500;
`;

export const Location = styled.h4.attrs({ "data-testid": "location-name" })`
  font-size: 18px;
  color: #4a4a4a;
  font-weight: 400;
`;

export const Arrow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
`;
