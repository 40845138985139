import * as React from "react";
import {
  ContactInputPanel,
  InnerPanelContainer,
  ButtonContainer,
  StyledButton,
  SubmitError
} from "./LetMeIn.styled";
import { FormState } from "./useForm";
import ContactInput from "./ContactInput";
import { isValidSmsOrEmail, Debouncer } from "../../utils";
import { useState } from "react";

export interface PanelDisplayProps {
  form: FormState;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitting: boolean;
  submitForm: (contact: string) => void;
  submitError: string;
}

const PanelDisplay: React.FC<PanelDisplayProps> = ({
  form,
  setValue,
  submitting,
  submitForm,
  submitError
}) => {
  const [inputValid, setInputValid] = useState(true);
  const [debouncer] = useState(new Debouncer());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(e);
    const isValidValue = !value || isValidSmsOrEmail(value);

    debouncer.debounce(
      () => {
        setInputValid(isValidValue);
      },
      2000,
      isValidValue
    );
  };

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm(form.contactinput.value);
  };

  return (
    <form data-testid="let-me-in-form" onSubmit={onFormSubmit}>
      <ContactInputPanel>
        <InnerPanelContainer>
          <ContactInput
            value={form.contactinput.value}
            onChange={handleChange}
            validationErr={
              inputValid ? "" : "Invalid email address or phone number."
            }
          />
          <ButtonContainer>
            <StyledButton
              disabled={!isValidSmsOrEmail(form.contactinput.value)}
              loading={submitting}
              type="submit"
            >
              Request Link
            </StyledButton>
          </ButtonContainer>
          {submitError && <SubmitError>{submitError}</SubmitError>}
        </InnerPanelContainer>
      </ContactInputPanel>
    </form>
  );
};

export default PanelDisplay;
