import Api from "../../state/api";
import * as React from "react";
import { useParamHook } from "../../hooks/useParams";
import { useSelector } from "react-redux";
import { getHasCalledUpdateStatementRequest } from "../../state/entities/requests/selectors";
import { useDispatch } from "react-redux";
import { setHasCalledUpdateStatementRequest } from "../../state/entities/requests/slice";
export const useSetRequestViewed = () => {
  const { vendorId, locationId, token } = useParamHook();
  const hasCalledUpdateStatementRequest = useSelector(
    getHasCalledUpdateStatementRequest
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      if (!hasCalledUpdateStatementRequest) {
        await Api.patchWithToken(
          `/vendors/${vendorId}/locations/${locationId}/statementrequest`,
          {},
          token
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        ).catch(() => {});
        dispatch(setHasCalledUpdateStatementRequest());
      }
    })();
  }, [locationId, vendorId, token, hasCalledUpdateStatementRequest, dispatch]);
};
