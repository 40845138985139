// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import loadable from "@loadable/component";

import LetMeInPage from "../app/pages/LetMeInPage";
import PFGPage from "../app/pages/PFGPage";
import LandingPage from "../app/pages/LandingPage";
import RequestDeepLink from "./pages/RequestDeepLink";
import ErrorPage from "./pages/ErrorPage";
import Click2PayPage from "../app/pages/Click2PayPage";
import AutopaySetupPage from "../app/pages/AutopaySetupPage";
import LocationDashboardPage from "../app/pages/LocationDashboardPage";
import PrivateRouteWrapper from "./components/PrivateRouteWrapper";
import LocationsPage from "./pages/LocationsPage";
import DisputePage from "./pages/DisputePage";
import PartialPage from "./pages/PartialPage";
import InvoicesReportPage from "./pages/InvoicesReportPage";
import PaymentsReportPage from "./pages/PaymentsReportPage";
export const CreateNewPaymentMethodPage = loadable(
  () =>
    import(
      /* webpackChunkName: "CreateNewPaymentMethodPage" */ "./pages/CreateNewPaymentMethodPage"
    )
);
import InvoiceJourneyPage from "./pages/InvoiceJourneyPage";
import ProfilePage from "./pages/ProfilePage";
import PaymentPlanRequestPage from "./pages/PaymentPlanRequestPage";
import StatementPage from "./pages/StatementPage";
import PaymentDetailPage from "./pages/PaymentDetailPage";
import PaymentPlansPage from "./pages/PaymentPlansPage";
import ResolutionCenterPage from "./pages/ResolutionCenterPage";
import PaymentPlanDetailPage from "./pages/PaymentPlanDetailPage";
import AutopayManagePage from "./pages/AutopayManagePage";
import AutopayConfirmationPage from "./pages/AutopayConfirmationPages/AutopayConfirmationPage";
import PaymentPlanUpdatePage from "./pages/PaymentPlanUpdatePage";
import PaymentPlanConfirmationPage from "./pages/PaymentPlanConfirmationPage";
import AutopayDeleteConfirmationPage from "./pages/AutopayConfirmationPages/AutopayDeleteConfirmationPage";
import NotFoundPage from "./pages/NotFoundPage";
import C2PAllDisputedPage from "./pages/C2PAllDisputedPage";
import RemitPage from "./pages/RemitPage";
import PDFLoaderPage from "./pages/PDFLoaderPage";
import SetUpRemindersPage from "./pages/SetUpRemindersPage";
import TalkToUsPage from "./pages/TalkToUsPage";

const routes = [
  {
    path: "/",
    exact: true,
    render: () => <LetMeInPage />
  },
  {
    path: "/performancefoodservice",
    exact: true,
    render: () => <PFGPage />
  },
  {
    path: "/token/:token",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <LandingPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/error/:error?",
    exact: true,
    render: () => <ErrorPage />
  },
  {
    path: "/token/:token/c2p",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <RequestDeepLink />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/ap",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <RequestDeepLink />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <LocationsPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <LocationDashboardPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/click2pay",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <Click2PayPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/click2pay/disputed",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <C2PAllDisputedPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/click2pay/:paymentGroupId/confirmation",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentDetailPage confirmation={true} />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/click2pay/:invoiceId/dispute",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <DisputePage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/click2pay/:invoiceId/partial",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PartialPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/invoices",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <InvoicesReportPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/payments",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentsReportPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/invoices/:invoiceId",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <InvoiceJourneyPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/paymentmethods/new",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <CreateNewPaymentMethodPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/profile",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <ProfilePage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/autopay/:requestId",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <AutopaySetupPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/autopay",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <AutopaySetupPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/pp",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <RequestDeepLink />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/planrequests/:requestId",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentPlanRequestPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/planrequests/:requestId/confirmation",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentPlanConfirmationPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/statement",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <StatementPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/payments/:paymentGroupId",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentDetailPage confirmation={false} />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/plans",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentPlansPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/plans/:planId",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentPlanDetailPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/messages",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <ResolutionCenterPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/autopaymanage",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <AutopayManagePage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/autopaymanage/confirmation",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <AutopayConfirmationPage update={true} />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/autopayconfirmation",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <AutopayConfirmationPage update={false} />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/autopaydelete/confirmation",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <AutopayDeleteConfirmationPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/plans/:planId/update",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentPlanUpdatePage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/plans/:planId/update/confirmation",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PaymentPlanConfirmationPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/remit",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <RemitPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/reminders",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <SetUpRemindersPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/talktous",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <TalkToUsPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/token/:token/vendors/:vendorId/locations/:locationId/invoices/:invoiceId/pdfloader",
    exact: true,
    render: () => (
      <PrivateRouteWrapper>
        <PDFLoaderPage />
      </PrivateRouteWrapper>
    )
  },
  {
    path: "/404",
    exact: true,
    render: () => <NotFoundPage />
  },
  {
    path: "*",
    exact: true,
    render: () => <NotFoundPage />
  }
];

export default routes;
