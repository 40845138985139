// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { FoldHereText, DashedLine, FoldLineContainer } from "./styled";
const FoldLine: React.FC = () => {
  return (
    <FoldLineContainer>
      <DashedLine />
      <FoldHereText>Fold Here</FoldHereText>
      <DashedLine />
    </FoldLineContainer>
  );
};

export default FoldLine;
