import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { getHeader, getMessage, getButtonText } from "./utils";

import {
  StyledPanel,
  Wrapper,
  Header,
  Message,
  FooterContainer,
  FooterText,
  StyledButton,
  StyledSup
} from "./Error.styled";
import ExclamationIcon from "../../assets/Exclamation.svg";

const Error: React.FC = () => {
  const { error } = useParams<{ error: string }>();
  const history = useHistory();

  const date = new Date();

  return (
    <>
      <Wrapper>
        <StyledPanel>
          <div>
            <ExclamationIcon />
            <Header>{getHeader(error)}</Header>
            <Message>{getMessage(error)}</Message>
            <StyledButton
              handleClick={() => history.push("/")}
              loading={false}
              hasIcon={true}
            >
              {getButtonText(error)}
            </StyledButton>
          </div>
        </StyledPanel>
      </Wrapper>
      <FooterContainer>
        <FooterText>
          &copy; {date.getFullYear()} Billfire<StyledSup>&reg;</StyledSup>. All
          Rights Reserved.
        </FooterText>
      </FooterContainer>
    </>
  );
};

export default Error;
