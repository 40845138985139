import * as React from "react";
import { useLocationFromPath } from "../../../hooks/useLocationFromPath";
import { useVendor } from "../../Locations/hooks";
import AddressBlock from "./AddressBlock";

export interface AddressProps {
  remit?: boolean;
}

const Address: React.FC<AddressProps> = ({ remit }) => {
  const vendor = useVendor();
  const location = useLocationFromPath();

  const appName = vendor.config.appName ? vendor.config.appName : "VALET";

  const vendorAddress = vendor.remit || {};

  return remit ? (
    <AddressBlock
      label={`ELECTRONIC PAYMENTS VIA ${appName} PREFERRED`}
      subLabel={"Or remit payment to:"}
      name={vendor.name}
      address={vendorAddress}
    />
  ) : (
    <AddressBlock
      label={"Payer:"}
      name={location.displayName}
      address={location.address}
    />
  );
};

export default Address;
