import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadTokenInfoSubmit,
  loadTokenInfoSuccess,
  loadTokenInfoFailed,
  setRequestInvoiceIds,
  loadRequestDetailsSubmit,
  loadRequestDetailsSuccess,
  loadRequestDetailsFailed,
  excludeInvoices,
  setSelectedPaymentDate,
  loadRequestTypeDetailsSubmit,
  loadRequestTypeDetailsSuccess
} from "./slice";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { loadInvoicesSuccess } from "../invoices/slice";
import { invoiceTypes } from "../invoices";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { isUserAction } from "../persisted/selectors";
import { saveAction } from "../persisted/slice";
import { createSaveAction } from "../persisted/utils";
import { ReplayType } from "../persisted/types";
import { requestTypes as types } from "./index";

export const fetchTokenInfoEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadTokenInfoSubmit.type),
    switchMap(() => {
      const token = getAuthToken(state$.value);
      return ajax.get(api.API_URL("/requests"), api.getHeaders({ token })).pipe(
        map(response => {
          const resp = api.handleAJAXResponse(response, token);
          return loadTokenInfoSuccess(resp);
        }),
        catchError(error => {
          if (error instanceof AjaxError) api.handleAJAXResponse(error, token);
          return of(loadTokenInfoFailed());
        })
      );
    })
  );

export const setRequestInvoiceIdsEpic = (
  action$: Observable<PayloadAction<invoiceTypes.MappedInvoiceResponse>>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadInvoicesSuccess.type),
    switchMap((action: PayloadAction<invoiceTypes.MappedInvoiceResponse>) =>
      of(setRequestInvoiceIds(action.payload.requestInvoiceIds))
    )
  );

export const fetchRequestDetailsEpic = (
  action$: Observable<PayloadAction<{ requestId: string }>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadRequestDetailsSubmit.type),
    switchMap(({ payload: { requestId } }) => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/requests/${requestId}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return loadRequestDetailsSuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadRequestDetailsFailed());
          })
        );
    })
  );

const mapRequestsById = (
  requests: Array<types.Request>
): Record<string, types.Request> => {
  return requests.reduce((byId: Record<string, types.Request>, request) => {
    byId[request.id] = request;
    return byId;
  }, {});
};

export const fetchRequestTypeDetailsEpic = (
  action$: Observable<PayloadAction<{ type: string }>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadRequestTypeDetailsSubmit.type),
    switchMap(({ payload: { type } }) => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/requests?requestType=${type}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return loadRequestTypeDetailsSuccess(
              mapRequestsById(resp.requests)
            );
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadRequestDetailsFailed());
          })
        );
    })
  );

export const persistAutopayRequestInvoiceSelectionEpic = (
  action$: Observable<PayloadAction<string[]>>,
  state$: { value: StoreType }
) =>
  action$.pipe(
    ofType(excludeInvoices.type),
    filter(() => isUserAction(state$.value)),
    map(action => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      const newAction = { ...action, replayType: ReplayType.request };
      return saveAction(
        createSaveAction(newAction, selectedVendorId, selectedLocationId, token)
      );
    })
  );

export const persistPaymentDateSelectedEpic = (
  action$: Observable<PayloadAction<string>>,
  state$: { value: StoreType }
) =>
  action$.pipe(
    ofType(setSelectedPaymentDate.type),
    filter(() => isUserAction(state$.value)),
    map((action: PayloadAction<string>) => {
      const token = getAuthToken(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);

      return saveAction(
        createSaveAction(
          { ...action, replayType: ReplayType.none },
          selectedVendorId,
          selectedLocationId,
          token
        )
      );
    })
  );
