import React, { useEffect, useState } from "react";
import { DismissAnnouncement, StyledAnnouncement } from "./styled";
import WhiteX from "../../assets/WhiteX.svg";

interface AnnouncementBannerProps {
  announcement: { id: string; type: string; message: string };
}

const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  announcement
}) => {
  const [dismissedAnnouncements, setDismissedAnnouncements] = useState<
    string[]
  >([]);

  useEffect(() => {
    const dismissed = window.sessionStorage.getItem("bf-messages-dismissed");
    setDismissedAnnouncements(dismissed?.split(" ") || []);
  }, []);

  const dismissAnnouncement = () => {
    setDismissedAnnouncements([...dismissedAnnouncements, announcement.id]);
    window.sessionStorage.setItem(
      "bf-messages-dismissed",
      [...dismissedAnnouncements, announcement.id].join(" ")
    );
  };

  return !dismissedAnnouncements.includes(announcement.id) ? (
    <StyledAnnouncement
      type={announcement.type}
      data-testid="announcement-banner"
    >
      {announcement.message}
      {announcement.type !== "error" && (
        <DismissAnnouncement>
          <WhiteX onClick={dismissAnnouncement} />
        </DismissAnnouncement>
      )}
    </StyledAnnouncement>
  ) : (
    <></>
  );
};

export default AnnouncementBanner;
