import { formatCurrency } from "@billfire/toybox";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { calculateServiceFee } from "../../hooks/useTotal";
import { PaymentPlanConfirmationData } from "../../state/entities/paymentPlans/types";
import { formatFrequency } from "../../state/entities/paymentPlans/utils";
import { RowNoLine } from "./styled";

const PaymentDetailRowServiceFee: React.FC = () => {
  const { state } = useLocation<PaymentPlanConfirmationData>();
  const { paymentPlan, paymentMethod } = state;

  const serviceFee = calculateServiceFee(
    paymentMethod.displayExtra.serviceFee
      ? paymentMethod.displayExtra.serviceFee
      : "",
    parseFloat(paymentPlan.eachAmount)
  );

  const total = serviceFee.add(paymentPlan.eachAmount).value;

  return (
    <>
      <RowNoLine>
        <div>{`${formatFrequency(paymentPlan.frequency)} Payment`}</div>
        <div>{formatCurrency(paymentPlan.eachAmount)}</div>
      </RowNoLine>
      <RowNoLine>
        <div>Service Fee</div>
        <div>+{formatCurrency(serviceFee.value)}</div>
      </RowNoLine>
      <RowNoLine>
        <div>{`${formatFrequency(paymentPlan.frequency)} Payment Total`}</div>
        <div>{formatCurrency(total)}</div>
      </RowNoLine>
    </>
  );
};

export default PaymentDetailRowServiceFee;
