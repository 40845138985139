import * as React from "react";
import { InvoiceDetailRow, InvoiceDetailTitle, InvoiceDetail } from "./styled";
import { useParamHook } from "../../hooks/useParams";
import InvoiceLink from "../InvoiceLink";
import { Invoice } from "../../state/entities/invoices/types";
interface InvoicePanelDetailRowProps {
  title: string;
  detail: string;
  invoiceLink?: boolean;
  invoice?: Invoice;
}

const InvoicePanelDetailRow: React.FC<InvoicePanelDetailRowProps> = ({
  title,
  detail,
  invoiceLink,
  invoice
}) => {
  const { invoiceId } = useParamHook();

  if (invoiceLink) {
    return (
      <InvoiceDetailRow>
        <InvoiceDetailTitle>{title}</InvoiceDetailTitle>
        <InvoiceLink invoice={invoice} invoiceId={invoiceId} linkToPdf={true} />
      </InvoiceDetailRow>
    );
  }
  return (
    <InvoiceDetailRow>
      <InvoiceDetailTitle>{title}</InvoiceDetailTitle>
      <InvoiceDetail>{detail}</InvoiceDetail>
    </InvoiceDetailRow>
  );
};

export default InvoicePanelDetailRow;
