import styled from "styled-components";

export const ErrorMessageContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessage = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 100;
`;
