import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPaymentPlansWithActivePaymentMethods,
  getPaymentPlanLoadingState
} from "../state/entities/paymentPlans/selectors";
import { loadPaymentPlans } from "../state/entities/paymentPlans/slice";
import { StoreType } from "../state/types";
import { useNavigation } from "./route";

export const usePaymentPlansLoader = () => {
  const dispatch = useDispatch();
  const { vendorId, locationId } = useNavigation();
  const paymentPlansLoadingState = useSelector((state: StoreType) =>
    getPaymentPlanLoadingState(state)
  );
  const paymentPlanCount = useSelector(
    getAllPaymentPlansWithActivePaymentMethods
  ).length;

  React.useEffect(() => {
    if (vendorId && locationId) {
      dispatch(loadPaymentPlans());
    }
  }, [dispatch, vendorId, locationId]);

  return { paymentPlansLoadingState, paymentPlanCount };
};
