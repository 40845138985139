import * as React from "react";
import { useSelector } from "react-redux";
import { getPaymentGroups } from "../../state/entities/paymentGroups/selectors";
import { StoreType } from "../../state/types";
import { ErrorContainer, ErrorText, TablesContainer } from "./styled";
import { PaymentGroupRow } from "./utils";
import { PaymentStatus } from "../../state/entities/paymentGroups/types";
import PaymentsTable from "./PaymentsTable";
import { SortBy } from "./types";

interface PaymentsTablesProps {
  sortable?: boolean;
  sortBys: Record<PaymentStatus, SortBy>;
  setSortBys: (sortBys: Record<PaymentStatus, SortBy>) => void;
}

const PaymentsTables: React.FC<PaymentsTablesProps> = ({
  sortable,
  sortBys,
  setSortBys
}) => {
  const paymentGroups = useSelector((state: StoreType) =>
    getPaymentGroups(state)
  );

  const scheduledPayments: PaymentGroupRow[] = paymentGroups
    .filter(payment => payment.status === PaymentStatus.SCHEDULED)
    .map(payment => ({
      ...payment,
      rowId: payment.id
    }));

  const completedPayments: PaymentGroupRow[] = paymentGroups
    .filter(payment => payment.status === PaymentStatus.COMPLETE)
    .map(payment => ({
      ...payment,
      rowId: payment.id
    }));

  const cancelledPayments: PaymentGroupRow[] = paymentGroups
    .filter(payment => payment.status === PaymentStatus.CANCELED)
    .map(payment => ({
      ...payment,
      rowId: payment.id
    }));

  return (
    <>
      {paymentGroups.length ? (
        <TablesContainer>
          {scheduledPayments.length ? (
            <PaymentsTable
              sortable={sortable}
              title="Scheduled"
              rowData={scheduledPayments}
              status={PaymentStatus.SCHEDULED}
              sortBys={sortBys}
              setSortBys={setSortBys}
            />
          ) : (
            <></>
          )}
          {completedPayments.length ? (
            <PaymentsTable
              sortable={sortable}
              title="Completed"
              rowData={completedPayments}
              status={PaymentStatus.COMPLETE}
              sortBys={sortBys}
              setSortBys={setSortBys}
            />
          ) : (
            <></>
          )}
          {cancelledPayments.length ? (
            <PaymentsTable
              sortable={sortable}
              title="Canceled"
              rowData={cancelledPayments}
              status={PaymentStatus.CANCELED}
              sortBys={sortBys}
              setSortBys={setSortBys}
            />
          ) : (
            <></>
          )}
        </TablesContainer>
      ) : (
        <ErrorContainer>
          <ErrorText>There are no payments to display.</ErrorText>
        </ErrorContainer>
      )}
    </>
  );
};

export default PaymentsTables;
