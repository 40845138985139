import styled from "styled-components";

export const NavbarContainer = styled.div`
  height: 41px;
  background-color: #62656a;
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 800px;
  padding: 0 25px 0 0;

  @media only screen and (max-width: 850px) {
    padding: 0 7px 0 0;
  }

  @media only screen and (max-width: 376px) {
    padding: 0 0 0 0;
  }
`;

export const NavbarText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-decoration: underline;
  padding-left: 20px;
`;

export const LeftArrow = styled.div.attrs({ "data-testid": "backArrow" })`
  width: 17px;
  height: 17px;
  border: solid 1px #ffffff;
  border-width: 0 1px 1px 0;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 28px;
`;

export const LogoContainer = styled.div`
  margin-left: auto;
  padding-right: 20px;

  @media only screen and (max-width: 376px) {
    padding-right: 10px;
  }
`;
