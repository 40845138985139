import styled from "styled-components";
import styles, { Tooltip } from "@billfire/toybox";
import { FilledExclamation } from "../Icon";

const {
  colors: { baseGray7 }
} = styles;

export const StyledTooltip = styled(Tooltip)`
  min-width: 300px;
  height: 65px;
  left: -120px;
  top: -75px;
`;

export const ExclamationIcon = styled(FilledExclamation)`
  height: 15px;
  overflow: visible;
  padding-left: 5px;
  padding-bottom: 3px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  color: ${baseGray7};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

export const Column = styled.div`
  display: flex;
`;
