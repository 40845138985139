// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import CheckIcon from "../../assets/checked.svg";
import { PaymentGroupDetail } from "../../pages/PaymentDetailPage/types";
import ConfirmationNumber from "../ConfirmationNumber";
import { Header, ThankYouHeader } from "./styled";

interface C2PConfirmationHeaderProps {
  paymentGroupDetail: PaymentGroupDetail;
}

const C2PConfirmationHeader: React.FC<C2PConfirmationHeaderProps> = ({
  paymentGroupDetail
}) => {
  const { confirmationNumber, providerExtra } = paymentGroupDetail.paymentGroup;
  return (
    <Header>
      <CheckIcon />
      <ThankYouHeader>Thank you for your payment!</ThankYouHeader>
      <ConfirmationNumber
        confirmationNumber={confirmationNumber}
        statementDescriptor={
          providerExtra.statementDescriptor
            ? providerExtra.statementDescriptor
            : ""
        }
      />
    </Header>
  );
};

export default C2PConfirmationHeader;
