import * as React from "react";
import { useRequestInvoiceTotals } from "./hooks";
import { moreInvoicesSentence, morePastDueSentence } from "./utils";
import { MoreContainer, MoreText } from "../styled";
import MoreButton from "./MoreButton";

const MoreInvoices: React.FC = () => {
  const { moreInvoices, morePastDue } = useRequestInvoiceTotals();

  if (moreInvoices === 0) {
    return null;
  }

  return (
    <>
      <MoreContainer>
        <MoreText>
          {moreInvoicesSentence(moreInvoices)}{" "}
          {morePastDueSentence(moreInvoices, morePastDue)}
        </MoreText>
        <MoreButton />
      </MoreContainer>
    </>
  );
};

export default MoreInvoices;
