import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { getRequestDetails } from "../../state/entities/requests/selectors";
import {
  StyledButton,
  ErrorText,
  FeesContainer,
  ActionAreaContainer
} from "./styled";
import { Accordion, formatShortDate } from "@billfire/toybox";
import DetailRow from "./DetailRow";
import PaymentPlanSummary from "./PaymentPlanSummary";
import TotalsAndFees from "../TotalsAndFees";
import TermsAndConditions from "../TermsAndConditions";
import { formatFrequency } from "../../state/entities/paymentPlans/utils";
import { usePaymentPlanSubmit } from "./hooks";
import { Redirect } from "react-router-dom";
import LocationHeaderText from "../LocationHeaderText";
import { LocationPageWrapper } from "../bits/styled";
import { getSelectedPaymentMethodProvider } from "../../state/entities/paymentMethods/selectors";
import PPPaymentMethodSelect from "./PPPaymentMethodSelect";

const PaymentPlanSetup: React.FC = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const request = useSelector((state: StoreType) => {
    return getRequestDetails(state, requestId);
  });
  const formattedFrequency = formatFrequency(request.frequency);
  const [checked, setChecked] = React.useState<boolean>(false);

  const {
    submitDisabled,
    submitting,
    showError,
    confirmationData,
    submit,
    confirmed,
    pathname
  } = usePaymentPlanSubmit();

  const _handleSubmit = async () => {
    await submit();
  };

  const provider = useSelector(getSelectedPaymentMethodProvider);

  return confirmed ? (
    <Redirect
      to={{
        pathname,
        state: { ...confirmationData }
      }}
    />
  ) : (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText="Payment Plan for" />
        <DetailRow
          titleText="Plan Nickname"
          detailText={request.paymentPlanName}
          showBorder={true}
        />
        <PPPaymentMethodSelect />
        <DetailRow
          titleText="Start Date"
          detailText={formatShortDate(request.startDate)}
          showBorder={true}
        />
        <FeesContainer>
          <TotalsAndFees
            subTotalTitle={`${formattedFrequency} Payment`}
            totalWithFeesTitle={`${formattedFrequency} Payment Total`}
            totalBeforeFees={parseFloat(request.eachAmount)}
          />
        </FeesContainer>
        <Accordion
          title="Plan Summary"
          headingPadding={"15px 0"}
          titleColor={"#777777"}
          titleWeight="100"
          content={<PaymentPlanSummary />}
          fontSize="15px"
          hideBoxShadow={true}
          fused
        />
        <ActionAreaContainer>
          <TermsAndConditions
            checked={checked}
            setChecked={setChecked}
            provider={provider}
          />
          <StyledButton
            loading={submitting}
            disabled={!checked || submitDisabled}
            handleClick={_handleSubmit}
          >
            Set Up Payment Plan
          </StyledButton>
          {showError ? (
            <ErrorText>Could not set up payment plan.</ErrorText>
          ) : null}
        </ActionAreaContainer>
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default PaymentPlanSetup;
