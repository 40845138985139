import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { statementDeliveryTypes as types } from "./index";
import { getEmptyStatementDelivery, StatementDelivery } from "./types";

export const initialState: types.StatementDeliveryState = {
  currentDelivery: getEmptyStatementDelivery(),
  loadingStatementDelivery: false,
  loadedStatementDelivery: false,
  loadStatementDeliveryFailed: false,
  createStatementDeliveryFailed: false,
  createdStatementDelivery: false,
  creatingStatementDelivery: false,
  deleteStatementDeliveryFailed: false,
  deletedStatementDelivery: false,
  deletingStatementDelivery: false,
  currentStatementContact: ""
};

export const statementDeliverySlice = createSlice({
  name: "statementDelivery",
  initialState,
  reducers: {
    loadStatementDeliverySubmit: (state: types.StatementDeliveryState) => {
      state.loadingStatementDelivery = true;
      state.loadedStatementDelivery = false;
      state.loadStatementDeliveryFailed = false;
    },
    loadStatementDeliverySuccess: (
      state: types.StatementDeliveryState,
      action: PayloadAction<{
        statementDeliveries: types.StatementDelivery[];
        currentContact: string;
      }>
    ) => {
      state.loadingStatementDelivery = false;
      state.loadedStatementDelivery = true;
      state.loadStatementDeliveryFailed = false;
      state.currentDelivery =
        action.payload.statementDeliveries.length > 0
          ? action.payload.statementDeliveries[0]
          : getEmptyStatementDelivery();
      state.currentStatementContact = action.payload.currentContact;
    },
    loadStatementDeliveryFailed: (state: types.StatementDeliveryState) => {
      state.loadingStatementDelivery = false;
      state.loadedStatementDelivery = false;
      state.loadStatementDeliveryFailed = true;
      state.currentDelivery = getEmptyStatementDelivery();
    },
    createStatementDeliverySubmit: (
      state: types.StatementDeliveryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<StatementDelivery>
    ) => {
      state.creatingStatementDelivery = true;
      state.createdStatementDelivery = false;
      state.createStatementDeliveryFailed = false;
    },
    createStatementDeliverySuccess: (
      state: types.StatementDeliveryState,
      action: PayloadAction<StatementDelivery>
    ) => {
      state.creatingStatementDelivery = false;
      state.createdStatementDelivery = true;
      state.createStatementDeliveryFailed = false;
      state.currentDelivery = action.payload;
    },
    createStatementDeliveryFailed: (state: types.StatementDeliveryState) => {
      state.creatingStatementDelivery = false;
      state.createdStatementDelivery = false;
      state.createStatementDeliveryFailed = true;
    },
    createStatementDeliveryReset: (state: types.StatementDeliveryState) => {
      state.creatingStatementDelivery = false;
      state.createdStatementDelivery = false;
      state.createStatementDeliveryFailed = false;
    },
    deleteStatementDeliverySubmit: (
      state: types.StatementDeliveryState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<StatementDelivery>
    ) => {
      state.deletingStatementDelivery = true;
      state.deletedStatementDelivery = false;
      state.deleteStatementDeliveryFailed = false;
    },
    deleteStatementDeliverySuccess: (state: types.StatementDeliveryState) => {
      state.deletingStatementDelivery = false;
      state.deletedStatementDelivery = true;
      state.deleteStatementDeliveryFailed = false;
      state.currentDelivery = getEmptyStatementDelivery();
    },
    deleteStatementDeliveryFailed: (state: types.StatementDeliveryState) => {
      state.deletingStatementDelivery = false;
      state.deletedStatementDelivery = false;
      state.deleteStatementDeliveryFailed = true;
    },
    deleteStatementDeliveryReset: (state: types.StatementDeliveryState) => {
      state.deletingStatementDelivery = false;
      state.deletedStatementDelivery = false;
      state.deleteStatementDeliveryFailed = false;
    },
    resetStore: () => initialState
  }
});

export const {
  loadStatementDeliverySubmit,
  loadStatementDeliverySuccess,
  loadStatementDeliveryFailed,
  createStatementDeliverySubmit,
  createStatementDeliverySuccess,
  createStatementDeliveryFailed,
  createStatementDeliveryReset,
  deleteStatementDeliverySubmit,
  deleteStatementDeliverySuccess,
  deleteStatementDeliveryFailed,
  deleteStatementDeliveryReset,
  resetStore
} = statementDeliverySlice.actions;

export default statementDeliverySlice.reducer;
