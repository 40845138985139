import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { getSelectedLocation } from "../../state/entities/locations/selectors";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

export const useFormatDocumentTitle = () => {
  const formattedDate = DateTime.now().toLocaleString();
  const locationName = useSelector(getSelectedLocation).displayName;
  const vendorName = useSelector(getSelectedVendor).name;
  return `confirmation_${vendorName}_${locationName}_${formattedDate}`;
};
