import styled from "styled-components";
import styles from "@billfire/toybox";
import { CheckMarkWithBackground } from "../Icon";
import PaymentMethodDetailRow from "../PaymentDetail/PaymentMethodDetailRow";
const { colors } = styles;
const { baseGray3, baseGray7 } = colors;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 17px;
  border-bottom: 2px solid rgb(246, 246, 246);
  background-color: white;

  div:first-child {
    color: ${baseGray7};
  }

  div:last-child {
    color: ${baseGray3};
  }

  :last-child {
    border-bottom: none;
  }
`;

export const RowNoLine = styled(Row)`
  border-bottom: none;
  padding: 15px 17px 0px 17px;

  :last-child {
    padding: 15px 17px;
  }
`;

export const CheckMarkIcon = styled(CheckMarkWithBackground)`
  margin: auto;
  padding-bottom: 30px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  width: 304px;
  height: 28px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${baseGray3};
  margin-bottom: 30px;
  margin: auto;
`;

export const StyledPaymentMethodDetailRow = styled(PaymentMethodDetailRow)`
  padding: 15px 17px;
`;
