import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadMessagesFailed,
  loadMessagesSubmit,
  loadMessagesSuccess
} from "./slice";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { Message } from "./types";

export const mapResponse = (messages: Message[]): Record<string, Message> => {
  return messages.reduce<Record<string, Message>>((acc, message) => {
    acc[message.id] = message;
    return acc;
  }, {});
};

export const fetchMessagesEpic = (
  action$: Observable<
    PayloadAction<{
      onlyMostRecent: boolean;
      contactNotRead: boolean;
    }>
  >,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadMessagesSubmit.type),
    switchMap(({ payload: { onlyMostRecent, contactNotRead } }) => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/messages?onlyMostRecent=${onlyMostRecent}&contactNotRead=${contactNotRead}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return loadMessagesSuccess(mapResponse(resp));
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadMessagesFailed());
          })
        );
    })
  );
