import * as React from "react";
import { Row } from "./styled";

interface DetailRowProps {
  titleText: string;
  detailText: string;
}

const DetailRow: React.FC<DetailRowProps> = ({ titleText, detailText }) => {
  return (
    <Row>
      <div>{titleText}</div>
      <div>{detailText}</div>
    </Row>
  );
};

export default DetailRow;
