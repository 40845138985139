// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./App";
import storeData from "./state/store";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { ModalProvider, ModalRoot } from "./globalModal";
import { RouteContextProvider } from "./routeContext";
import { PersistGate } from "redux-persist/integration/react";
import history from "./history";

export const Normalize = createGlobalStyle`
  ${normalize}

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  body {
    background: #F9F9F9;
    font-family: 'Roboto','Helvetica Neue','Helvetica','Arial',sans-serif;
    font-weight: 100;
  }

  #root{
    min-height: 100vh;
  }

  table {
    border-collapse: collapse;
  }
`;

const { store, persistor } = storeData;

const rootEl = (
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor()}>
      <Router history={history}>
        <RouteContextProvider>
          <ModalProvider>
            <Normalize />
            <ModalRoot />
            <App />
          </ModalProvider>
        </RouteContextProvider>
      </Router>
    </PersistGate>
  </ReduxProvider>
);

ReactDOM.render(rootEl, document.getElementById("root"));
