import { useSelector } from "react-redux";
import { useModal, modalTypes } from "../../globalModal";
import { getInvoiceById } from "../../state/entities/invoices/selectors";
import { StoreType } from "../../state/types";
import { decideInvoice } from "./utils";
import { Invoice } from "../../state/entities/invoices/types";

export const useInvoiceLink = (
  invoiceId: string,
  invoice: Invoice | undefined,
  invoiceDisplayInvNum: string | undefined
) => {
  const storeInvoice = useSelector((state: StoreType) =>
    getInvoiceById(state, invoiceId)
  );
  const usedInvoice = decideInvoice(invoice, storeInvoice);

  const displayInvNum = usedInvoice?.displayInvNum
    ? usedInvoice?.displayInvNum
    : invoiceDisplayInvNum
    ? invoiceDisplayInvNum
    : "";

  const { showModal, hideModal } = useModal();

  const openModal = () => {
    showModal({
      type: modalTypes.PDF_OR_HISTORY_MODAL,
      props: {
        storeInvoice,
        onClose: hideModal
      }
    });
  };

  return { openModal, displayInvNum };
};
