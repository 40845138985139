import * as React from "react";
import { Ad } from "../../../state/entities/ads/types";
import { AdSlide, useHandleClickUrl } from "./hooks";
import { StyledAdContainer, AdsImage } from "./styled.components";

interface AddContainerProps {
  id: string;
  ad: AdSlide;
  adClick: (ad: Ad) => boolean;
}

const AdContainer: React.FC<AddContainerProps> = ({ id, ad, adClick }) => {
  const key = `ad-${id}`;

  const clickUrl = useHandleClickUrl(ad);

  return (
    <StyledAdContainer id={id} key={key} {...ad.props}>
      {typeof clickUrl === "undefined" ? (
        <AdsImage src={ad.fileUrl} alt="Vendor Advertisement" />
      ) : (
        <a
          onClick={() => adClick(ad)}
          href={clickUrl}
          target={clickUrl?.includes("http") ? "_blank" : "_self"}
          rel="noopener noreferrer"
          data-testid={`test-${key}`}
        >
          <AdsImage src={ad.fileUrl} alt="Vendor Advertisement" />
        </a>
      )}
    </StyledAdContainer>
  );
};

export default AdContainer;
