import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadCampaignsSubmit } from "../state/entities/campaigns/slice";
import {
  selectCampaigns,
  selectCampaignsLoadingFlags
} from "../state/entities/campaigns/selectors";

export const useRemindersLoader = () => {
  const dispatch = useDispatch();
  const campaigns = useSelector(selectCampaigns);
  const { loadCampaignsFailed, loadedCampaigns, loadingCampaigns } =
    useSelector(selectCampaignsLoadingFlags);

  React.useEffect(() => {
    dispatch(loadCampaignsSubmit());
  }, [dispatch]);

  return {
    campaigns,
    loadCampaignsFailed,
    loadedCampaigns,
    loadingCampaigns
  };
};
