import Api from "../../../state/api";
import { Ad } from "../../../state/entities/ads/types";
import { AdTickParams } from "./types";

const pathMap = {
  impression: "impressions",
  click: "clicks"
};

export const sendAdTick = async (
  ad: Ad,
  type: "impression" | "click",
  params: AdTickParams
) => {
  const payload = {
    locationId: params.locationId,
    displayPage: "Statement",
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  await fetch(
    Api.API_URL(
      // eslint-disable-next-line security/detect-object-injection
      `/vendors/${params.vendorId}/advertisements/${ad.fileId}/${pathMap[type]}`
    ),
    {
      method: "POST",
      mode: "cors",
      headers: Api.getHeaders({ token: params.token, withCreds: true }),
      body: JSON.stringify(payload)
    }
  );
};

export const sendImpressions = (ads: Ad[], params: AdTickParams) => {
  ads.map(ad => {
    ad.clickUrl !== "" && sendAdTick(ad, "impression", params);
  });
};

export const calculateAdFlags = (currentAd: Ad, ads: Ad[]) => {
  const displayAds = ads.length > 0;
  const animate = currentAd.duration > 0 && ads.length > 1;

  return { displayAds, animate };
};
