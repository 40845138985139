import * as React from "react";
import { StatementDelivery } from "../../state/entities/statementDelivery/types";
import { formatFrequency } from "../ManageStatementDelivery/utils";

export interface AccordionTitleProps {
  statementDelivery: StatementDelivery;
}

const AccordionTitle: React.FC<AccordionTitleProps> = ({
  statementDelivery
}) => {
  return (
    <div>
      {statementDelivery.id !== ""
        ? formatFrequency(statementDelivery)
        : "You’re not yet set up for automated statement delivery"}
    </div>
  );
};

export default AccordionTitle;
