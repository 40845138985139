import * as React from "react";
import { useSelector } from "react-redux";
import {
  getAllInvoices,
  getInvoicesLoadState
} from "../../state/entities/invoices/selectors";
import { StoreType } from "../../state/types";
import {
  InvoiceReportDataTable,
  InvoiceReportContainer,
  ErrorContainer,
  ErrorText
} from "./styled";
import {
  getColumns,
  mapAndSortInvoiceReportCSVData,
  mapInvoice
} from "./utils";
import { useMobile } from "../../hooks/useMobile";
import CommonWrapper from "../CommonWrapper";
import LocationHeaderText from "../LocationHeaderText";
import PrintExportComponent from "../PrintExportComponent";
import InvoiceReportPrintView from "./InvoiceReportPrintView";
import { SortDirection } from "@billfire/toybox";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";
import { getGlobalFeatureFlags } from "../../state/entities/shared/selectors";

const InvoicesReport: React.FC = () => {
  const [sortBy, setSortBy] = React.useState<string>("dueDate");

  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>("ASC");
  const invoices = useSelector((state: StoreType) => getAllInvoices(state));
  const { loadInvoicesFailed } = useSelector((state: StoreType) =>
    getInvoicesLoadState(state)
  );

  const rows = mapInvoice(invoices);

  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);
  const { csvData, headers } = mapAndSortInvoiceReportCSVData(
    invoices,
    vendor,
    globalFeatureFlags,
    sortBy,
    sortDirection
  );
  const { mobile } = useMobile();
  const printRef = React.useRef(null);
  const headerText = "Invoice Report for";

  return (
    <CommonWrapper>
      <InvoiceReportContainer>
        <LocationHeaderText headerText={headerText} />
        <PrintExportComponent
          printRef={printRef}
          documentTitle={"Invoice Report"}
          csvData={csvData}
          headers={headers}
        />
        {loadInvoicesFailed ? (
          <ErrorContainer>
            <ErrorText>
              We were unable to load the data for this report. Please refresh
              your browser window to try again.
            </ErrorText>
            <ErrorText>
              If you continue to have difficulties viewing this page, please
              contact {vendor.name}.
            </ErrorText>
          </ErrorContainer>
        ) : invoices.length ? (
          <>
            <InvoiceReportPrintView
              printRef={printRef}
              headerText={headerText}
              rows={rows}
              sortDirection={sortDirection}
              externalSortBy={sortBy}
            />
            <InvoiceReportDataTable
              sortable
              columns={getColumns(mobile, vendor, globalFeatureFlags)}
              initialData={rows}
              externalSortDirection={sortDirection}
              setExternalSortDirection={setSortDirection}
              externalSortBy={sortBy}
              externalSetSortBy={setSortBy}
            />
          </>
        ) : (
          <ErrorContainer>
            <ErrorText>There are no invoices to display.</ErrorText>
          </ErrorContainer>
        )}
      </InvoiceReportContainer>
    </CommonWrapper>
  );
};

export default InvoicesReport;
