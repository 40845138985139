import * as React from "react";
import { Row } from "./styled";

interface DetailRowProps {
  titleText: string;
  detailText: string;
  showBorder?: boolean;
}

const DetailRow: React.FC<DetailRowProps> = ({
  titleText,
  detailText,
  showBorder = false
}) => {
  return (
    <Row showBorder={showBorder}>
      <div>{titleText}</div>
      <div>{detailText}</div>
    </Row>
  );
};

export default DetailRow;
