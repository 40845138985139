import * as React from "react";
import { LocationPageWrapper } from "../bits/styled";
import CommonWrapper from "../CommonWrapper";
import LocationHeaderText from "../LocationHeaderText";
import { ErrorContainer } from "./styled";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

const PaymentDetailFailed: React.FC = () => {
  const vendor = useSelector(getSelectedVendor);

  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText="Payment Detail for" />
        <ErrorContainer>
          <p>
            We were unable to load the data for this payment detail. Please
            refresh your browser window to try again.
          </p>

          <p>
            If you continue to have difficulties viewing this page, please
            contact {vendor.name}.
          </p>
        </ErrorContainer>
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default PaymentDetailFailed;
