import { DateTime } from "luxon";
import { DateHelper } from "@billfire/payment-lib-base-v2";
import { PartialPayment, Invoice } from "../../state/entities/invoices/types";
import { isValidDate } from "./isValidDate";
import Api from "../../state/api";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";

export interface CreatePaymentGroupResponseBody {
  paymentGroupId: string;
  allDisputed?: boolean;
  confirmationNumber: string;
  paymentAmount: number;
  paymentDate: string;
  paymentMethod: {
    displayExtra: {
      lastFour: string;
      name: string;
    };
  };
}
export interface CreatePaymentGroupCCResponseBody
  extends CreatePaymentGroupResponseBody {
  providerExtra: {
    fee: {
      amount: number;
      percent: string;
    };
  };
}

export type C2PState = {
  date: DateTime;
  provider: string;
};

interface PaymentGroupPayload {
  paymentMethodId: string;
  paymentDate: DateTime;
  partialPayments: PartialPayment[];
  invoices: string[];
  epaVersion: string;
}

export const getISODate = (date: Date): string => {
  const ISODate = date.toISOString().split("T")[0];
  return ISODate;
};

export const getNextPaymentDate = (
  date: Date | null,
  dateHelper: DateHelper,
  provider: string | undefined
) => {
  const nextPaymentDate = dateHelper
    .getNextPaymentDate(date, { provider })
    .toISOString();
  return DateTime.fromISO(nextPaymentDate);
};

export type ValidDates = Record<string, boolean>;

//pass provider type from payment method
export const getValidDates = (
  c2pPaymentDays: string | number = "30",
  dateHelper: DateHelper,
  provider: string | undefined
): ValidDates => {
  let datesInRange: ValidDates = {};

  const now = DateTime.now();

  for (let i = 0; i <= Number(c2pPaymentDays); i++) {
    // eslint-disable-next-line security/detect-object-injection
    datesInRange = {
      ...datesInRange,
      ...isValidDate(i, now, dateHelper, provider)
    };
  }

  return datesInRange;
};

export const isFormValidCheck = (
  checked: boolean,
  paymentMethod: PaymentMethod | undefined,
  paymentDate: DateTime | undefined,
  paymentTotal: number,
  selectedInvoices: Invoice[]
) => {
  return (
    checked &&
    !!paymentMethod?.id &&
    !paymentMethod?.displayExtra.verificationRequired &&
    !!paymentDate &&
    paymentTotal >= 0 &&
    selectedInvoices.length > 0
  );
};

export function isPlastiqPaymentGroupResponse(
  response: CreatePaymentGroupResponseBody & CreatePaymentGroupCCResponseBody
): response is CreatePaymentGroupCCResponseBody {
  return response?.providerExtra?.fee !== undefined;
}

export const createPaymentGroup = async (
  token: string,
  vendorId: string | null,
  locationId: string | null,
  payload: PaymentGroupPayload
): Promise<
  CreatePaymentGroupResponseBody & CreatePaymentGroupCCResponseBody
> => {
  const res = await Api.postWithToken(
    `/vendors/${vendorId}/locations/${locationId}/paymentgroups`,
    payload,
    token
  );

  return res;
};

export const handleDateInPast = (date: DateTime) => {
  const isInPast = Date.now() > date.toMillis();
  return isInPast ? DateTime.local() : date;
};
