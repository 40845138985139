import * as React from "react";

import { MobileCellProps } from ".";
import { ContentActions, StyledLink, ContentAction } from "./styled";
import { useInvoiceLink } from "../../../../InvoiceLink/hooks";
import { useDisputeModal } from "../hooks";

const Actions: React.FC<MobileCellProps> = ({ invoice }) => {
  const { openModal: showInvoice } = useInvoiceLink(invoice.id, invoice, "");
  const showDisputeModal = useDisputeModal(invoice);

  const credit = invoice.openAmount < 0;

  const Wrapper = credit ? ContentAction : ContentActions;

  return (
    <Wrapper>
      <StyledLink
        onClick={e => {
          e.preventDefault();
          showInvoice();
        }}
        title={invoice.displayInvNum}
      >
        View Invoice
      </StyledLink>
      {!credit && (
        <StyledLink
          onClick={e => {
            e.preventDefault();
            showDisputeModal();
          }}
          title={`Dispute/Partial Pay ${invoice.displayInvNum}`}
        >
          Dispute/Partial Pay
        </StyledLink>
      )}
    </Wrapper>
  );
};

export default Actions;
