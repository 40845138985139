import { useEffect, useState } from "react";
import { device } from "../breakpoints";

export const useMobile = () => {
  const [mobile, setMobile] = useState<boolean>(false);

  useEffect(() => {
    const getMQMatch = (): boolean => {
      return window.matchMedia(`(${device.tablet})`).matches;
    };

    setMobile(getMQMatch());
    const handler = () => {
      setMobile(getMQMatch());
    };
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);

  return { mobile };
};
