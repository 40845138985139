import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import useLoadAutopay from "../../hooks/useLoadAutopay";
import { usePaymentPlansLoader } from "../../hooks/usePaymentPlansLoader";

export const useLoadDataForPage = () => {
  const { loadedVendors, loadingVendors } = useVendorsLoader();
  const { locationsLoading, locationsLoaded } = useLocationsLoader(true);
  const { loadingAutopay } = useLoadAutopay();
  const { paymentPlansLoadingState } = usePaymentPlansLoader();

  return {
    loadedVendors,
    loadingVendors,
    locationsLoading,
    locationsLoaded,
    loadingAutopay,
    loadingPaymentPlans: paymentPlansLoadingState.loadingPaymentPlans
  };
};

export const useLoadingState = () => {
  const loadingState = useLoadDataForPage();

  return (
    !loadingState.loadedVendors ||
    !loadingState.locationsLoaded ||
    loadingState.loadingVendors ||
    loadingState.locationsLoading ||
    loadingState.loadingAutopay ||
    loadingState.loadingPaymentPlans
  );
};
