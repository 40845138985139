import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreType } from "../../../state/types";
import { getRow, InvoiceRow, mapAndSortStatementCSVData } from "./utils";
import { getOpenInvoices } from "../../../state/entities/invoices/selectors";
import { isPastDue, isCredit } from "../../../state/entities/invoices/utils";
import {
  getSelectedVendor,
  selectSelectedVendorShipToConfig
} from "../../../state/entities/vendors/selectors";
import { SortDirection } from "@billfire/toybox";
import { getGlobalFeatureFlags } from "../../../state/entities/shared/selectors";

export const useInvoices = () => {
  const invoices = useSelector((state: StoreType) => getOpenInvoices(state));
  const vendor = useSelector(getSelectedVendor);

  return invoices.reduce<{
    pastDue: InvoiceRow[];
    current: InvoiceRow[];
    credits: InvoiceRow[];
  }>(
    (ret, inv) => {
      if (isCredit(inv)) {
        ret.credits.push(getRow(inv));
      } else if (isPastDue(inv, vendor)) {
        ret.pastDue.push(getRow(inv));
      } else {
        ret.current.push(getRow(inv));
      }
      return ret;
    },
    {
      pastDue: [],
      current: [],
      credits: []
    }
  );
};

export const useFilteredInvoices = (): {
  pastDue: InvoiceRow[];
  current: InvoiceRow[];
  credits: InvoiceRow[];
  selectedOption: Record<string, string>;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
} => {
  const [selectedOption, setSelectedOption] = useState<Record<string, string>>({
    filterName: "All",
    filterValue: ""
  });
  const invoices = useSelector((state: StoreType) => getOpenInvoices(state));
  const vendor = useSelector(getSelectedVendor);
  const [filteredInvoicesArray, setFilteredInvoicesArray] = useState(invoices);
  const selectedVendorShipToConfig = useSelector(
    selectSelectedVendorShipToConfig
  );

  useEffect(() => {
    setFilteredInvoicesArray(
      !selectedVendorShipToConfig || selectedOption?.filterName === "All"
        ? invoices
        : invoices.filter(
            invoice => invoice.filterName === selectedOption?.filterName
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const { pastDue, current, credits } = filteredInvoicesArray.reduce<{
    pastDue: InvoiceRow[];
    current: InvoiceRow[];
    credits: InvoiceRow[];
  }>(
    (ret, inv) => {
      if (isCredit(inv)) {
        ret.credits.push(getRow(inv));
      } else if (isPastDue(inv, vendor)) {
        ret.pastDue.push(getRow(inv));
      } else {
        ret.current.push(getRow(inv));
      }
      return ret;
    },
    {
      pastDue: [],
      current: [],
      credits: []
    }
  );

  return { pastDue, current, credits, selectedOption, setSelectedOption };
};

export const useStatementExport = (
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);
  const invoices = useSelector((state: StoreType) => getOpenInvoices(state));
  const selectedVendorShipToConfig = useSelector(
    selectSelectedVendorShipToConfig
  );

  return mapAndSortStatementCSVData(
    invoices,
    vendor,
    globalFeatureFlags,
    selectedVendorShipToConfig,
    sortBy,
    sortDirection
  );
};
