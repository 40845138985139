import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import ToolsPanels from "./ToolsPanels";
import ReportsPanels from "./ReportsPanels";
import { useClearSelectedPaymentDate } from "./hooks";
import LocationHeaderText from "../LocationHeaderText";

const LocationDashboard: React.FC = () => {
  useClearSelectedPaymentDate();

  return (
    <div data-testid="location-dashboard">
      <CommonWrapper>
        <LocationHeaderText headerText="Dashboard for" bordered />
        <ToolsPanels />
        <ReportsPanels />
      </CommonWrapper>
    </div>
  );
};

export default LocationDashboard;
