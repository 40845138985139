/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Modal, ModalType, ModalSize } from "@billfire/toybox";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewPaymentMethodToken,
  getPaymentMethodCreateState
} from "../../../state/entities/paymentMethods/selectors";
import { useModal } from "../../ModalProvider";
import { ModalMessage } from "./styled";
import {
  createPaymentMethodStateReset,
  createTokenizedPaymentMethod
} from "../../../state/entities/paymentMethods/slice";

const ConfirmFeeModal: React.FC = () => {
  const { hideModal } = useModal();
  const { providerResponse } = useSelector(getNewPaymentMethodToken);
  const dispatch = useDispatch();

  const { creatingTokenized, createSuccess, createFailed } = useSelector(
    getPaymentMethodCreateState
  );

  React.useEffect(() => {
    if (createSuccess) {
      hideModal();
    }
  }, [createSuccess, hideModal]);

  const handleAcceptFee = () => {
    dispatch(createTokenizedPaymentMethod());
  };

  const hideModalLocal = () => {
    dispatch(createPaymentMethodStateReset());
    hideModal();
  };

  const title = () => {
    switch (providerResponse.cardType) {
      case "CREDIT":
        return "Add Credit Card?";
      case "DEBIT":
        return "Add Debit Card?";
      default:
        return "Add Card?";
    }
  };

  return (
    <Modal
      title={title()}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={hideModalLocal}
      loading={creatingTokenized}
      errored={createFailed}
      errorMessage="Could not add card."
      onSubmit={handleAcceptFee}
      submitButtonText="Confirm"
    >
      <ModalMessage>
        <div>Please note:</div>
        <div>
          A <strong>{providerResponse.plastiqServiceFee} service fee</strong>{" "}
          will be added to all payments made with this card.
        </div>
      </ModalMessage>
    </Modal>
  );
};

export default ConfirmFeeModal;
