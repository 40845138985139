import styled from "styled-components";

export const CcContainer = styled.div`
  text-align: center;
`;

export const ModalMessage = styled.div.attrs({ "data-testid": "modalMessage" })`
  font-size: 16px;
  padding: 10px 23px 0 0;
  font-weight: 300;
  strong {
    font-weight: bold;
  }
`;

export const FeeValue = styled.div`
  font-weight: bold;
`;
