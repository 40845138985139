import { Selector, StoreType } from "../../types";
import { getEmptyVendor, Vendor } from "./types";
import { getPaymentConfigOptions4Display } from "./utils";
import { createSelector } from "@reduxjs/toolkit";

export const getVendorArray = (state: StoreType): Vendor[] => {
  return Object.values(state.vendors.byId);
};

export const getSelectedVendorId = (state: StoreType): string => {
  return state.vendors.selectedVendorId;
};

export const getVendorsLoadState = (state: StoreType) => {
  return {
    loadVendorsFailed: state.vendors.loadVendorsFailed,
    loadingVendors: state.vendors.loadingVendors,
    loadedVendors: state.vendors.loadedVendors,
    hasEverLoadedVendors: state.vendors.hasEverLoadedVendors
  };
};

export const getVendor: Selector<Vendor> = (state, vendorId: string) =>
  // eslint-disable-next-line security/detect-object-injection
  state.vendors.byId[vendorId];

export const getSelectedVendor: Selector<Vendor> = state => {
  if (state.vendors.selectedVendorId) {
    return state.vendors.byId[state.vendors.selectedVendorId];
  }
  return getEmptyVendor();
};

export const getSelectedVendorPaymentMethodConfigOptions = (
  state: StoreType
) => {
  const vendor = getSelectedVendor(state);
  return getPaymentConfigOptions4Display(vendor);
};

export const getAutopayConfig: Selector<number> = (state: StoreType) => {
  const selectedVendorId = state.vendors.selectedVendorId;
  const autoPayStartDateTimeFrame =
    // eslint-disable-next-line security/detect-object-injection
    state.vendors.byId[selectedVendorId].config?.autoPayStartDateTimeFrame;
  return autoPayStartDateTimeFrame
    ? parseInt(autoPayStartDateTimeFrame)
    : state.shared.configuration.autopayConfig.startDateTimeFrame;
};

export const selectSelectedVendorShipToConfig: Selector<boolean> =
  createSelector(
    [(state: StoreType) => state.vendors.byId[state.vendors.selectedVendorId]],
    selectedVendor => !!selectedVendor?.invoiceFilterColumns?.length
  );
