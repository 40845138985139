import React, { useState } from "react";

import { EmptyMessage, InvoiceTable } from "./styled";
import { getRows } from "./utils";
import {
  useDisputedInvoicesToDisplay,
  useInvoicesToDisplay,
  useSelectedMap,
  useGetColumns,
  useDropDownMaxHeight
} from "./hooks";

import InvoiceCheckBox from "./InvoiceCheckBox";
import { TableRow } from "@billfire/toybox";
import { useMobile } from "../../../../hooks/useMobile";
import { selectSelectedVendorShipToConfig } from "../../../../state/entities/vendors/selectors";
import { useSelector } from "react-redux";
import { selectShipTos } from "../../../../state/entities/invoices/selectors";
import ShipToDropDown from "../../../ShipToDropDown";

interface TableRowProps {
  children: React.ReactNode;
  selected?: boolean;
}

const renderTableRow: React.FC<TableRowProps> = ({ children, selected }) => (
  <TableRow selected={selected}>{children}</TableRow>
);

const InvoiceList: React.FC = () => {
  const invoices = useInvoicesToDisplay();
  const { selectedMap, setSelected } = useSelectedMap(invoices);
  const [selectedOption, setSelectedOption] = useState<Record<string, string>>({
    filterName: "All",
    filterValue: ""
  });
  const disputedInvoices = useDisputedInvoicesToDisplay();
  const rows = getRows(invoices, disputedInvoices);
  const selectedVendorShipToConfig = useSelector(
    selectSelectedVendorShipToConfig
  );
  const shipToOptions = useSelector(selectShipTos);
  const columns = useGetColumns();
  const { mobile } = useMobile();
  const { tableRef, dropDownMaxHeight } = useDropDownMaxHeight(selectedOption);

  return (
    <>
      {invoices.length > 0 ? (
        <div ref={tableRef}>
          <ShipToDropDown
            {...{
              selectedVendorShipToConfig,
              mobile,
              shipToOptions,
              selectedOption,
              dropDownMaxHeight,
              setSelectedOption
            }}
          />
          <InvoiceTable
            selected={selectedMap}
            setSelected={setSelected}
            initialData={rows}
            columns={columns}
            CheckboxComponent={InvoiceCheckBox}
            RowComponent={renderTableRow}
            selectAll
            sortable
            mobile={mobile}
            animatedRows={selectedVendorShipToConfig}
            animatedRowsDuration={350}
            animatedRowsEasing={"ease-in-out"}
            animatedRowsStaggerDuration={"10"}
            groupedSort={selectedVendorShipToConfig}
            selectedOption={selectedOption}
            optionKey={"filterName"}
            defaultOption={"All"}
          />
        </div>
      ) : (
        <EmptyMessage>You do not have any open invoices to pay.</EmptyMessage>
      )}
    </>
  );
};

export default InvoiceList;
