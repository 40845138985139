import * as React from "react";
import { PaymentPlanRow } from "./utils";
import { useParams, useHistory } from "react-router-dom";
import { useRouteContext } from "../../routeContext";

export const useRedirectListener = (paymentPlanRows: PaymentPlanRow[]) => {
  const { token, vendorId, locationId } = useParams<{
    token: string;
    vendorId: string;
    locationId: string;
  }>();
  const { to, from } = useRouteContext();
  const history = useHistory();
  React.useEffect(() => {
    const firstPPDetailPage = `/token/${token}/vendors/${vendorId}/locations/${locationId}/plans/${paymentPlanRows[0]?.id}`;
    if (paymentPlanRows.length === 1) {
      if (to === firstPPDetailPage || from === firstPPDetailPage) {
        history.push(
          `/token/${token}/vendors/${vendorId}/locations/${locationId}`
        );
      } else {
        history.push(firstPPDetailPage);
      }
    }
  }, [paymentPlanRows, to, from, token, vendorId, locationId, history]);
};
