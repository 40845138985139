import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { messageTypes as types } from "./index";

export const initialState: types.MessageState = {
  byId: {},
  loadingMessages: false,
  loadedMessages: false,
  loadMessagesFailed: false
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    loadMessagesSubmit: (
      state: types.MessageState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        onlyMostRecent: boolean;
        contactNotRead: boolean;
      }>
    ) => {
      state.loadingMessages = true;
      state.loadedMessages = false;
      state.loadMessagesFailed = false;
    },
    loadMessagesSuccess: (
      state: types.MessageState,
      action: PayloadAction<Record<string, types.Message>>
    ) => {
      state.loadingMessages = false;
      state.loadedMessages = true;
      state.loadMessagesFailed = false;
      state.byId = action.payload;
    },
    loadMessagesFailed: (state: types.MessageState) => {
      state.loadingMessages = false;
      state.loadedMessages = false;
      state.loadMessagesFailed = true;
    },
    resetStore: () => initialState
  }
});

export const {
  loadMessagesSubmit,
  loadMessagesSuccess,
  loadMessagesFailed,
  resetStore
} = messagesSlice.actions;

export default messagesSlice.reducer;
