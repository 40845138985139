import { DisplayedPaymentMethod } from "../paymentMethods/types";

export interface ActiveAutopay {
  id: string;
  startDate: string;
  paymentMethodName: string;
  paymentMethodLastFour: string;
  contactId: string;
  paymentMethodType: string;
  excludedInvoices: string[];
  paymentGroups: string[];
  paymentMethodId: string;
  paymentMethod: DisplayedPaymentMethod;
  customerManage: boolean;
}

export const getEmptyAutopay = (): ActiveAutopay => ({
  id: "",
  startDate: "",
  paymentMethodName: "",
  paymentMethodLastFour: "",
  contactId: "",
  paymentMethodType: "",
  excludedInvoices: [],
  paymentGroups: [],
  paymentMethodId: "",
  paymentMethod: {
    type: "",
    displayExtra: {
      name: "",
      lastFour: ""
    }
  },
  customerManage: true
});

export const isActiveAutopayType = (arg: unknown): arg is ActiveAutopay => {
  return !!arg && typeof arg === "object" && arg !== null && "id" in arg;
};

export interface ApiResponseAutoPay {
  success: boolean;
  autoPay: ActiveAutopay;
}

export interface AutopayState {
  loadingAutopay: boolean;
  loadedAutopay: boolean;
  loadAutopayFailed: boolean;
  deleting: boolean;
  deleted: boolean;
  activeAutopay: ActiveAutopay;
  excludedInvoiceIds: string[];
}
