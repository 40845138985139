import * as React from "react";

export const useAbortController = () => {
  const controller = new AbortController();
  React.useEffect(() => {
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return controller.signal;
};
