import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadPaymentGroupsSubmit,
  loadPaymentGroupsSuccess,
  loadPaymentGroupsFailed
} from "./slice";
import { ApiResponsePaymentGroups, PaymentGroup } from "./types";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import {
  getFieldOrDefault,
  getTypeFromProvider
} from "../paymentMethods/utils";

export const mapResponse = (
  responsePaymentGroups: ApiResponsePaymentGroups
): Record<string, PaymentGroup> => {
  return responsePaymentGroups.paymentGroups.reduce(
    (
      byId: Record<string, PaymentGroup>,
      responsePaymentGroup: PaymentGroup
    ) => {
      byId[responsePaymentGroup.id] = {
        ...responsePaymentGroup,
        sourceType: responsePaymentGroup.sourceType || "",
        paymentPlanId: responsePaymentGroup.paymentPlanId || "",
        requestId: responsePaymentGroup.requestId || "",
        paymentMethodLastFour: getFieldOrDefault(
          responsePaymentGroup.paymentMethodLastFour,
          responsePaymentGroup?.paymentMethod?.displayExtra?.lastFour
        ),
        paymentMethodName: getFieldOrDefault(
          responsePaymentGroup.paymentMethodName,
          responsePaymentGroup?.paymentMethod?.displayExtra?.name
        ),
        paymentMethodType: getFieldOrDefault(
          responsePaymentGroup.paymentMethodType,
          getTypeFromProvider(responsePaymentGroup?.paymentMethod?.provider)
        )
      };
      return byId;
    },
    {}
  );
};

export const fetchPaymentGroupsEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadPaymentGroupsSubmit.type),
    switchMap(() => {
      const selectedVendorId = getSelectedVendorId(state$.value);
      const selectedLocationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/paymentgroups?onlyPending=false`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            const mapped = mapResponse(resp);
            return loadPaymentGroupsSuccess(mapped);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadPaymentGroupsFailed());
          })
        );
    })
  );
