import styled from "styled-components";
import { device } from "../../breakpoints";
import styles, { Button, DataTable, RadioGroup } from "@billfire/toybox";
const { colors } = styles;

export const RemindersDescription = styled.p`
  color: #000;
  font-size: 18px;
  font-weight: 300;
  margin: 16px auto 60px;

  @media only screen and (${device.tablet}) {
    text-align: center;
    padding: 0px 10px 0px 10px;
  }
`;

export const TableTitle = styled.h3`
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 32px;
`;

export const DataTableContainer = styled.div`
  @media only screen and (${device.tablet}) {
    padding: 0px 10px 0px 10px;
  }
`;

export const StyledDataTable = styled(DataTable)`
  width: 100%;
  margin: 0 auto 30px;
  th {
    color: #999999;
    padding: 0 0 27px 0;
    font-weight: 300;
    text-align: left;
  }
  td {
    padding: 0 0 16px 0;
    text-align: left;
  }
  tr {
    padding-top: 0px;
  }
`;

export interface DotProps {
  positive: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  margin: 4px 10px 0px 0px;
  background-color: ${props => (props.positive ? "#d0021b" : "#4a90e2")};
  border-radius: 50%;
`;

export const TimeText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
`;

export const StyledAction = styled.button`
  color: ${colors.linkPrimaryHover};
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  font-weight: 100;
  -webkit-text-decoration: underline;
  text-decoration: underline;
`;

export const EmptyReminders = styled.div`
  margin: 54px auto 74px;
  font-size: 22px;
  font-weight: 300;
  color: #646678;
  text-align: center;
`;

export const StyledAddButton = styled(Button)`
  display: inline-block;
  width: 200px;

  @media only screen and (${device.tablet}) {
    width: 100%;
    margin: 0px 10px 0px 10px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Time = styled.div`
  display: flex;
  vertical-align: center;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  &.create-reminders-group {
    padding: 0px 0px 10px 0px;
  }
`;

export const ModalContent = styled.div`
  width: auto;
  margin: 0 30px 0;
  overflow: hidden;
`;

export const ModalTitle = styled.h2`
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 33px;
`;
