import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import RemitForm from "../../components/RemitForm";

const RemitPage: React.FC = () => {
  const { loadingInvoices } = useInvoicesLoader({
    type: "click2pay"
  });

  const { loadedVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();

  return loadingInvoices || !loadedVendors || locationsLoading ? (
    <SkeletonLoader />
  ) : (
    <RemitForm />
  );
};

export default RemitPage;
