import * as React from "react";
import { useDispatch } from "react-redux";
import { setShowAllInvoices } from "../../../../state/entities/invoices/slice";
import { StyledMoreButton } from "../styled";

const MoreButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setShowAllInvoices(true));
  };

  return (
    <StyledMoreButton
      loading={false}
      hasIcon
      iconType=""
      handleClick={handleClick}
    >
      View More Invoices
    </StyledMoreButton>
  );
};

export default MoreButton;
