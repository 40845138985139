import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadStatementDeliverySubmit } from "../state/entities/statementDelivery/slice";
import {
  getCurrentStatementDelivery,
  getStatementDeliveryLoadingFlags
} from "../state/entities/statementDelivery/selectors";

export const useStatementDeliveryLoader = () => {
  const dispatch = useDispatch();
  const currentDelivery = useSelector(getCurrentStatementDelivery);
  const {
    loadStatementDeliveryFailed,
    loadedStatementDelivery,
    loadingStatementDelivery
  } = useSelector(getStatementDeliveryLoadingFlags);

  React.useEffect(() => {
    dispatch(loadStatementDeliverySubmit());
  }, [dispatch]);

  return {
    currentDelivery,
    loadStatementDeliveryFailed,
    loadedStatementDelivery,
    loadingStatementDelivery
  };
};
