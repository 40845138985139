import * as React from "react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import AutopayConfirmation from "../../../components/Autopay/AutopayConfirmation";
import AutopayUpdateConfirmation from "../../../components/Autopay/AutopayUpdateConfirmation";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { locationPath } from "../../../utils";
import { useConfirmationPageData } from "../hooks";

interface AutopayConfirmationPageProps {
  update: boolean;
}

const AutopayConfirmationPage: React.FC<AutopayConfirmationPageProps> = ({
  update
}) => {
  const { loadingState } = useConfirmationPageData();
  const history = useHistory();

  if (loadingState === "failed") {
    return (
      <Redirect to={locationPath(history.location.pathname, "autopaymanage")} />
    );
  } else if (loadingState === "inactive") {
    return <Redirect to={locationPath(history.location.pathname, "autopay")} />;
  }

  return loadingState === "loading" ? (
    <SkeletonLoader />
  ) : update ? (
    <AutopayUpdateConfirmation />
  ) : (
    <AutopayConfirmation />
  );
};

export default AutopayConfirmationPage;
