import React from "react";
import { useSelector } from "react-redux";

import { useMobile } from "../../hooks/useMobile";
import {
  getInvoicesLoadState,
  getOpenInvoices
} from "../../state/entities/invoices/selectors";
import { getLocationsLoadState } from "../../state/entities/locations/selectors";
import { getCurrentStatementDelivery } from "../../state/entities/statementDelivery/selectors";
import { getVendorsLoadState } from "../../state/entities/vendors/selectors";
import { StoreType } from "../../state/types";

export const useInvoiceErrorInfo = () => {
  const { loadInvoicesFailed } = useSelector((state: StoreType) =>
    getInvoicesLoadState(state)
  );

  const { locationsLoadFailed } = useSelector((state: StoreType) =>
    getLocationsLoadState(state)
  );

  const { loadVendorsFailed } = useSelector((state: StoreType) =>
    getVendorsLoadState(state)
  );

  const hasInvoices = useSelector(
    (state: StoreType) => getOpenInvoices(state).length > 0
  );

  const invoiceLoadError =
    loadInvoicesFailed || locationsLoadFailed || loadVendorsFailed;

  return { invoiceLoadError, hasInvoices };
};

export const useHandleAccordion = () => {
  const { mobile } = useMobile();

  const statementDelivery = useSelector(getCurrentStatementDelivery);

  const [openAccordion, setOpenAccordion] = React.useState(true);

  React.useEffect(() => {
    if (statementDelivery.id === "") {
      setOpenAccordion(true);
    } else {
      setOpenAccordion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statementDelivery]);

  return { openAccordion, setOpenAccordion, mobile, statementDelivery };
};
