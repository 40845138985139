// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import AllDisputedConfirmationView from "../../components/AllDisputedConfirmationView";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";

const C2PAllDisputedPage: React.FC = () => {
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();

  const loading = loadingVendors || locationsLoading;
  return loading ? <SkeletonLoader /> : <AllDisputedConfirmationView />;
};

export default C2PAllDisputedPage;
