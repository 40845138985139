import styled from "styled-components";

export const AddressAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressDiv = styled.div`
  flex-grow: 1;

  @media print {
    margin-top: -4px;
    padding-bottom: 23px;
  }
`;

export const AddressLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  white-space: nowrap;

  @media print {
    font-size: 9.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
`;

export const LabelBlock = styled.div`
  padding-bottom: 7px;
`;

export const AddressSubLabel = styled.div`
  margin: 0.3px 56px 4.7px 0;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;

  @media print {
    font-size: 9.5px;
    font-size: 9.5px;
    font-weight: normal;
  }
`;

export const AddressLine = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;

  @media print {
    font-size: 9.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
  }
`;
