import * as React from "react";
import { useSelector } from "react-redux";
import { paymentMethodSelectors } from "../../state/entities/paymentMethods";
import { invoiceSelectors, invoiceUtils } from "../../state/entities/invoices";
import { StoreType } from "../../state/types";
import TotalsAndFees from "../TotalsAndFees";
import { Divider } from "../Click2Pay/Amount/styled";
import * as SharedTypes from "@billfire/va-types";

const InvoiceTotalsAndFees: React.FC = () => {
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    paymentMethodSelectors.getSelectedPaymentMethod(state)
  );

  const invoiceTotal = useSelector((state: StoreType) =>
    invoiceUtils.getInvoiceTotal(
      invoiceSelectors.getSelectedInvoices(state),
      invoiceSelectors.getDisputedInvoicesById(state)
    )
  );

  return selectedPaymentMethod &&
    selectedPaymentMethod?.provider ===
      SharedTypes.PaymentProvider.PLASTIQ_CC ? (
    <div data-testid="totals-and-fees">
      <Divider />

      <TotalsAndFees
        subTotalTitle="Invoice Subtotal"
        totalWithFeesTitle="Payment Total"
        totalBeforeFees={invoiceTotal}
      />
    </div>
  ) : null;
};

export default InvoiceTotalsAndFees;
