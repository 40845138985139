import { Panel, TextInput } from "@billfire/toybox";
import styled from "styled-components";
import { device } from "../../breakpoints";

export const Click2PayPartialPayPanel = styled(Panel)`
  margin: auto;
  padding: 0px 50px;
  @media only screen and (${device.tablet}) {
    padding: 0px;
  }
  margin-top: 20px;
`;

export const InnerPanelContainer = styled.div`
  width: 95%;
  margin: auto;
  padding-top: 10px;
`;

export const StyledTextInputContainer = styled.div`
  margin-top: 50px;
  height: 60px;
`;

export const StyledTextInput = styled(TextInput)`
  padding: 0;
`;

export const DropDownContainer = styled.div`
  margin: 50px auto auto auto;
`;

export const StyledHeader = styled.h3`
  padding: 15px 15px 0px 15px;
  margin: auto;
  width: 95%;
`;

export const StyledText = styled.div`
  padding: 15px;
  margin: auto;
  width: 95%;
`;

export const StyledSpan = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
`;

export const FooterRow = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 0px;
`;

export const Footer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 18px;
`;

export const LeftLabelContainer = styled.div`
  text-align: left;
  display: inline-block;
  width: 50%;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: #4a4a4a;
`;

export const RightLabelContainer = styled.div`
  text-align: right;
  display: inline-block;
  width: 50%;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: #4a4a4a;
`;

export const FooterLabel = styled.div`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 11px;
`;
