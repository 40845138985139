import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import AutopaySetup from "../../components/Autopay/AutopaySetup";
import { usePaymentMethodsLoading } from "../../hooks/usePaymentMethodsLoading";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { useAutopayRequestDetailsLoader } from "../../hooks/useRequestDetailsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useGlobalConfigurationLoader } from "../../hooks/useGlobalConfigurationLoader";
import { isLoading } from "./utils";
import useLoadAutopay from "../../hooks/useLoadAutopay";

const AutopaySetupPage: React.FC = () => {
  const { loadingRequest } = useAutopayRequestDetailsLoader();
  const { loadingInvoices } = useInvoicesLoader({
    type: "autopay"
  });
  const paymentMethodsLoading = usePaymentMethodsLoading();
  const { loadedVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const { loadingAutopay } = useLoadAutopay();
  const { loadedConfiguration } = useGlobalConfigurationLoader();

  return isLoading(
    loadingRequest,
    locationsLoading,
    loadingInvoices,
    paymentMethodsLoading,
    loadedVendors,
    loadedConfiguration,
    loadingAutopay
  ) ? (
    <SkeletonLoader />
  ) : (
    <AutopaySetup />
  );
};

export default AutopaySetupPage;
