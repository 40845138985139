import {
  DisplayedPaymentMethod,
  PaymentMethod,
  PaymentMethodProvider
} from "../../../state/entities/paymentMethods/types";

export const truncateName = (
  name: string,
  lastFour: string,
  charNum: number
) => {
  return name.length > charNum
    ? name.substring(0, charNum).trim() + "..." + lastFour
    : name + " " + lastFour;
};

export const formatPaymentMethod = (
  paymentMethod: PaymentMethod | DisplayedPaymentMethod
) => {
  const { name, lastFour } = paymentMethod.displayExtra;
  if (screen.width < 420) {
    return truncateName(name, lastFour, 5);
  } else {
    return truncateName(name, lastFour, 10);
  }
};

export const isACH = (
  paymentMethod: PaymentMethod | DisplayedPaymentMethod
): boolean => paymentMethod.type === "ACH";

export const visibility = (
  paymentMethod: PaymentMethod | DisplayedPaymentMethod
): string =>
  paymentMethod.displayExtra.name.length < 10 ? "hidden" : "visible";

export const decidePaymentMethod = (
  selectedPaymentMethod: PaymentMethod | undefined,
  onlyUseSelectedPmFromRedux: boolean,
  paymentMethodTitleOverride: DisplayedPaymentMethod | undefined
): PaymentMethod | DisplayedPaymentMethod | undefined => {
  if (onlyUseSelectedPmFromRedux) {
    if (selectedPaymentMethod) return selectedPaymentMethod;
    if (paymentMethodTitleOverride) return paymentMethodTitleOverride;
  }
  return selectedPaymentMethod;
};

export const paymentMethodTitle = (
  selectedPaymentMethod: PaymentMethod<PaymentMethodProvider> | undefined,
  onlyUseSelectedPmFromRedux: boolean,
  paymentMethodOverride: DisplayedPaymentMethod | undefined,
  paymentMethods: PaymentMethod[] | undefined
) => {
  const paymentMethod = decidePaymentMethod(
    selectedPaymentMethod,
    onlyUseSelectedPmFromRedux,
    paymentMethodOverride
  );

  if (paymentMethod && paymentMethod.displayExtra.name !== "") {
    return paymentMethod;
  } else if (paymentMethods && paymentMethods.length > 0) {
    return paymentMethods[0];
  } else {
    return null;
  }
};
