import * as React from "react";
import { StyledPanel, PanelName, InvoicesIcon, RefDiv } from "./styled";
import { useHover } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const InvoicesPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const { appendPath } = usePageNav();

  return (
    <RefDiv
      onClick={() => appendPath("invoices")}
      ref={hoverRef}
      data-testid="invoices-hover"
    >
      <StyledPanel background={isHovered ? "#eaf0f7" : ""}>
        <InvoicesIcon fill={"#000"} data-testid="InvoicesIcon" />
        <PanelName>INVOICES</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default InvoicesPanel;
