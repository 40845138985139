import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  createStatementDeliveryFailed,
  createStatementDeliverySubmit,
  createStatementDeliverySuccess,
  deleteStatementDeliveryFailed,
  deleteStatementDeliverySubmit,
  deleteStatementDeliverySuccess,
  loadStatementDeliveryFailed,
  loadStatementDeliverySubmit,
  loadStatementDeliverySuccess
} from "./slice";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { StatementDelivery } from "./types";

export const fetchStatementDeliveryEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadStatementDeliverySubmit.type),
    switchMap(() => {
      const vendorId = getSelectedVendorId(state$.value);
      const locationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${vendorId}/locations/${locationId}/statementdelivery`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return loadStatementDeliverySuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadStatementDeliveryFailed());
          })
        );
    })
  );

export const createStatementDeliveryEpic = (
  action$: Observable<PayloadAction<StatementDelivery>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(createStatementDeliverySubmit.type),
    switchMap(({ payload }) => {
      const vendorId = getSelectedVendorId(state$.value);
      const locationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);

      return ajax
        .post(
          api.API_URL(
            `/vendors/${vendorId}/locations/${locationId}/statementdelivery`
          ),
          {
            scheduleUnit: payload.scheduleUnit,
            scheduleValue: payload.scheduleValue,
            sendEmptyStatements: payload.sendEmptyStatements
          },
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            return createStatementDeliverySuccess(resp);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(createStatementDeliveryFailed());
          })
        );
    })
  );

export const deleteStatementDeliveryEpic = (
  action$: Observable<PayloadAction<StatementDelivery>>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(deleteStatementDeliverySubmit.type),
    switchMap(({ payload }) => {
      const vendorId = getSelectedVendorId(state$.value);
      const locationId = getSelectedLocationId(state$.value);
      const token = getAuthToken(state$.value);

      return ajax
        .delete(
          api.API_URL(
            `/vendors/${vendorId}/locations/${locationId}/statementdelivery/${payload.id}`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            api.handleAJAXResponse(response, token);
            return deleteStatementDeliverySuccess();
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(deleteStatementDeliveryFailed());
          })
        );
    })
  );
