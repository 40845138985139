import * as React from "react";
import { ErrorContainer, ErrorText } from "./styled";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

const Error: React.FC = () => {
  const vendor = useSelector(getSelectedVendor);

  return (
    <ErrorContainer>
      <ErrorText>
        We were unable to load the data for this report. Please refresh your
        browser window to try again.
      </ErrorText>
      <ErrorText>
        If you continue to have difficulties viewing this page, please contact{" "}
        {vendor.name}.
      </ErrorText>
    </ErrorContainer>
  );
};

export default Error;
