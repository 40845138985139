import { SortDirection } from "@billfire/toybox";
import * as React from "react";
import ReportPrintView, {
  ReportPrintViewProps
} from "../../PrintExportComponent/ReportPrintView";
import { BodyRow, InvoiceTable, TBody } from "./styled";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import { InvoiceRow } from "./utils";
import { StatementSubTitle } from "../styled.components";
import { formatDueDate } from "../../../state/entities/invoices/utils";
import SubtotalsView from "./SubtotalsView";
import TotalView from "./TotalView";
import Footer from "../Footer";

interface LocationStatementPrintViewProps extends ReportPrintViewProps {
  columns: Column[];
  invoices: {
    pastDue: InvoiceRow[];
    current: InvoiceRow[];
    credits: InvoiceRow[];
  };
  invoicesArray: InvoiceRow[];
  sortDirection: SortDirection;
  sortBy: string;
}

export const LocationStatementPrintView: React.FC<
  LocationStatementPrintViewProps
> = ({
  printRef,
  headerText,
  columns,
  invoices,
  invoicesArray,
  sortBy,
  sortDirection
}) => {
  const { pastDue, current, credits } = invoices;
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        <StatementSubTitle>as of {formatDueDate(Date.now())}</StatementSubTitle>
        <InvoiceTable
          TableBodyComponent={TBody}
          RowComponent={BodyRow}
          initialData={invoicesArray}
          columns={columns}
          externalSortBy={sortBy}
          externalSortDirection={sortDirection}
        />
        <SubtotalsView filteredInvoices={{ pastDue, current, credits }} />
        <TotalView filteredInvoices={{ pastDue, current, credits }} />
        <Footer />
      </ReportPrintView>
    </>
  );
};

export default LocationStatementPrintView;
