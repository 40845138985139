import styled, { css } from "styled-components";
import {
  Messages,
  Click2Pay,
  AutoPay,
  Invoices,
  Payments,
  Statement,
  PaymentPlan,
  Remit,
  Reminders
} from "../Icon/dashboard";
import { device } from "../../breakpoints";

const baseIconStyle = css`
  margin: 42px auto 0;
  max-width: 66px;
  max-height: 66px;
  @media only screen and (${device.tablet}) {
    margin: 0 auto;
    max-width: 45px;
    max-height: 45px;
  }
`;

export const MessagesIcon = styled(Messages)`
  ${baseIconStyle}
`;

export const Click2PayIcon = styled(Click2Pay)`
  ${baseIconStyle}
`;

export const PaymentPlanIcon = styled(PaymentPlan)`
  ${baseIconStyle}
  max-width: 72px;
`;

export const AutoPayIcon = styled(AutoPay)`
  ${baseIconStyle}
`;

export const InvoicesIcon = styled(Invoices)`
  ${baseIconStyle}
  max-width: 80px;
  max-height: 80px;
`;

export const PaymentsIcon = styled(Payments)`
  ${baseIconStyle}
`;

export const StatementIcon = styled(Statement)`
  ${baseIconStyle}
`;

export const RemitIcon = styled(Remit)`
  ${baseIconStyle}
  max-width: 60px;
`;

export const RemindersIcon = styled(Reminders)`
  ${baseIconStyle}
  max-width: 66px;
`;

export interface PanelProps {
  background: string;
}

export const RefDiv = styled.div`
  width: 100%;
`;

export const GroupPanel = styled.div`
  padding: 0 35px 35px;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
  row-gap: 35px;

  @media only screen and (${device.tablet}) {
    grid-template-columns: 99%;
    justify-items: start;
    grid-gap: 16px;
    padding: 0 13px 13px;
  }
`;

export const DisplayContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #eaf0f7;
  background-color: #ffffff;
  margin-bottom: 18px;

  @media only screen and (${device.tablet}) {
    padding: 0;
  }
`;

export const StyledPanel = styled.div<PanelProps>`
  display: grid;
  grid-template-rows: 76% 20px;
  max-width: 207px;
  border-radius: 24px;
  height: 177px;
  background-color: ${({ background }) =>
    background ? background : "#f4f4f4"};
  cursor: pointer;
  border: solid 1px #eaf0f7;

  @media only screen and (${device.tablet}) {
    grid-template-rows: none;
    grid-template-columns: 25% 75%;
    align-items: center;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
    height: 70px;
    padding: 0;
  }
`;

export const PanelName = styled.div.attrs({
  "data-testid": "dashboard-panel-name"
})`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
  text-align: center;
  color: #4a4a4a;
  margin: auto;

  @media only screen and (${device.tablet}) {
    text-align: left;
    width: 100%;
    padding-left: 10px;
  }
`;

export const StyledH2 = styled.h2`
  color: #272a43;
  align-self: center;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin: 18px 0 0 0;
  font-size: 31px;

  @media only screen and (${device.tablet}) {
    font-size: 27px;
  }
`;

export const LocationName = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #272a43;
  margin: 5px 0 5px 0;
`;

export const AddressInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: rgba(100, 102, 120, 0.76);
`;

export interface StyledHeaderProps {
  color: string;
}

export const GroupHeaderContainer = styled.div<StyledHeaderProps>`
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 6px solid;
  border-color: ${(props: StyledHeaderProps) => props.color};
`;

export const StyledSquare = styled.div.attrs({
  "data-testid": "styled-group-square"
})<StyledHeaderProps>`
  background-color: ${(props: StyledHeaderProps) => props.color};
  width: 11px;
  height: 44px;
`;

export const TitleDiv = styled.div.attrs({
  "data-testid": "styled-group-title"
})`
  font-size: 24px;
  font-weight: 300;
  line-height: 1.17;
  text-align: left;
  color: #4a4a4a;
  margin-left: 25px;

  @media only screen and (${device.tablet}) {
    font-size: 18px;
  }
`;
