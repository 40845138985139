import api from "../../state/api";
import {
  InvoiceJourneyApiResponse,
  UpdatedInvoiceJourney,
  JourneyRecord
} from "../../components/InvoiceJourney/types";

export const loadInvoiceJourney = (
  token: string,
  vendorId: string,
  locationId: string,
  invoiceId: string,
  signal: AbortSignal
) => {
  return fetch(
    api.API_URL(
      `/vendors/${vendorId}/locations/${locationId}/invoices/${invoiceId}/journey`
    ),
    { ...api.GET(token, true), signal }
  ).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("failed calling api");
    }
  });
};

export const mapRowIdToJourneyRecord = (
  invoiceJourney: InvoiceJourneyApiResponse
): UpdatedInvoiceJourney => {
  const updatedJourneyRecords = invoiceJourney.journeys.map(
    (journey: JourneyRecord) => {
      return {
        ...journey,
        rowId: journey.id
      };
    }
  );

  return {
    ...invoiceJourney,
    journeys: updatedJourneyRecords
  };
};
