import { Modal, ModalSize, ModalType } from "@billfire/toybox";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "../../../globalModal/modals.styled";
import { deletePaymentMethodsSubmit } from "../../../state/entities/paymentMethods/slice";
import ModalButtonContainer from "../ModalButtonContainer/index";
import PaymentMethodModalContent from "../PaymentMethodModalContent/index";
import {
  useFetchPaymentDetails,
  usePaymentDetailsParams
} from "./useFetchPaymentDetails";
import { useShowDeletePaymentMethodModal } from "./useShowDeletePaymentMethodModal";
import { setModalTitle, setErrorMessage } from "./utils";

interface DeletePaymentMethodModalProps {
  onClose?: () => void;
  name: string;
  lastFour: string;
  checkPaymentsLoading: boolean;
  setCheckPaymentsLoading: (val: boolean) => void;
}

const PaymentMethodDeleteModal: React.FC<DeletePaymentMethodModalProps> = ({
  onClose,
  name,
  lastFour,
  checkPaymentsLoading,
  setCheckPaymentsLoading
}) => {
  const dispatch = useDispatch();

  const [paymentsLoaded, setPaymentsLoaded] = useState(false);

  const { hideModal, deleting, deleteFailed } = useShowDeletePaymentMethodModal(
    checkPaymentsLoading,
    setCheckPaymentsLoading,
    name,
    lastFour
  );

  const paymentMethodLocalState = usePaymentDetailsParams();

  const { invoicesForSelected, hasPaymentPlan, hasAutopay } =
    useFetchPaymentDetails(
      paymentMethodLocalState,
      checkPaymentsLoading,
      setCheckPaymentsLoading,
      setPaymentsLoaded
    );

  const deletePaymentMethod = () => {
    dispatch(deletePaymentMethodsSubmit());
  };

  return paymentsLoaded ? (
    <Modal
      onClose={onClose || hideModal}
      type={ModalType.form}
      size={ModalSize.small}
      loading={false}
      errored={false}
      title={setModalTitle(hasPaymentPlan, hasAutopay, { name, lastFour })}
      hideActions={true}
    >
      <PaymentMethodModalContent
        invoicesForSelected={invoicesForSelected}
        hasPaymentPlan={hasPaymentPlan}
        hasAutopay={hasAutopay}
      />
      <ModalButtonContainer
        deletePaymentMethod={deletePaymentMethod}
        onClose={onClose}
        hideModal={hideModal}
        deleting={deleting}
        hasPaymentPlan={hasPaymentPlan}
        hasAutopay={hasAutopay}
      />
      {deleteFailed ? (
        <ErrorMessage>
          {setErrorMessage(deleteFailed, { name, lastFour })}
        </ErrorMessage>
      ) : null}
    </Modal>
  ) : null;
};

export default PaymentMethodDeleteModal;
