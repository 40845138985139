// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView from "../PrintExportComponent/ReportPrintView";
import { ReportPrintViewProps } from "../PrintExportComponent/ReportPrintView";
import PaymentPlanTableDisplay from "./PaymentPlanTableDisplay";
import { SortBys, SetSortBys, ConsolidatedPaymentPlansByStatus } from "./types";

interface PaymentPlanPrintViewProps extends ReportPrintViewProps {
  consolidatedPaymentPlansByStatus: ConsolidatedPaymentPlansByStatus;
  sortBys: SortBys;
  setSortBys: SetSortBys;
}

export const PaymentPlanPrintView: React.FC<PaymentPlanPrintViewProps> = ({
  printRef,
  headerText,
  consolidatedPaymentPlansByStatus,
  sortBys,
  setSortBys
}) => {
  const { active, complete, canceled } = consolidatedPaymentPlansByStatus;
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        <>
          <PaymentPlanTableDisplay
            title="Open"
            paymentPlans={active}
            sortBys={sortBys}
            status={"active"}
            setSortBys={setSortBys}
          />
          <PaymentPlanTableDisplay
            title="Completed"
            paymentPlans={complete}
            sortBys={sortBys}
            status={"complete"}
            setSortBys={setSortBys}
          />
          <PaymentPlanTableDisplay
            title="Canceled"
            paymentPlans={canceled}
            sortBys={sortBys}
            status={"canceled"}
            setSortBys={setSortBys}
          />
        </>
      </ReportPrintView>
    </>
  );
};

export default PaymentPlanPrintView;
