import React from "react";
import { DayContainer } from "./styled";
import { Day } from "../bits";
export interface DayPickerProps {
  currentValue: string;
  setSelectedValue: (val: string) => void;
}

export const DayPicker: React.FC<DayPickerProps> = ({
  currentValue,
  setSelectedValue
}) => {
  return (
    <DayContainer>
      {Array(29)
        .fill(1, 1)
        .map((_, i) => {
          const stringValue = i.toString();
          return (
            <Day
              key={`day-${stringValue}`}
              htmlFor={`day-${stringValue}`}
              name={`day-${stringValue}`}
              id={`day-${stringValue}`}
              value={i}
              handleChange={() => setSelectedValue(stringValue)}
              checked={currentValue === stringValue}
              className={currentValue === stringValue ? "checked" : ""}
            >
              {stringValue}
            </Day>
          );
        })}
      <Day
        value={0}
        htmlFor={"day-0"}
        name={"day-0"}
        id={"day-0"}
        handleChange={() => setSelectedValue("0")}
        checked={currentValue === "0"}
        className={`last ${currentValue === "0" ? "checked" : ""}`}
      >
        Last day of month
      </Day>
    </DayContainer>
  );
};

export default DayPicker;
