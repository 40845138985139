import Api from "../../state/api";

export const buttonIsDisabled = (
  paymentMethodHasChanged: boolean,
  checked: boolean
) => {
  if (checked && paymentMethodHasChanged) {
    return false;
  }
  return true;
};

interface PaymentMethodUpdatePayload {
  paymentMethodId: string;
  epaVersion: string;
}

export const updatePaymentPlanPaymentMethod = async (
  token: string,
  vendorId: string | null,
  locationId: string | null,
  paymentPlanId: string,
  payload: PaymentMethodUpdatePayload
): Promise<{}> => {
  const res = await Api.patchWithToken(
    `/vendors/${vendorId}/locations/${locationId}/paymentplans/${paymentPlanId}`,
    payload,
    token
  );

  return res;
};
