import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StoreType } from "../../state/types";

import { getLocationArray } from "../../state/entities/locations/selectors";
import { loadVendorsStart } from "../../state/entities/vendors/slice";
import { Vendor } from "../../state/entities/vendors/types";
import { useVendorFromPath } from "../../hooks/useVendorFromPath";
import { Location } from "../../state/entities/locations/types";
import { sortString } from "@billfire/toybox";

export const useSortedLocations = (): Location[] => {
  const locations = useSelector((state: StoreType) => getLocationArray(state));
  return locations.sort((a, b) =>
    sortString(a.displayName, b.displayName, "ASC")
  );
};

export const useVendor = (): Vendor => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadVendorsStart());
  }, [dispatch]);

  return useVendorFromPath();
};
