import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import Statement from "../../components/Statement";
import { useAdsLoader } from "../../hooks/useAdsLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useStatementDeliveryLoader } from "../../hooks/useStatementDeliveryLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useSetRequestViewed } from "./hooks";
import { useGlobalConfigurationLoader } from "../../hooks/useGlobalConfigurationLoader";

const StatementPage: React.FC = () => {
  useSetRequestViewed();
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const { loadingInvoices } = useInvoicesLoader();
  const { loadingStatementDelivery } = useStatementDeliveryLoader();
  const { loadingAds, loadAdsFailed } = useAdsLoader();
  const { loadingConfiguration } = useGlobalConfigurationLoader();

  const loading =
    loadingConfiguration ||
    loadingVendors ||
    locationsLoading ||
    loadingInvoices ||
    loadingStatementDelivery ||
    (loadingAds && !loadAdsFailed);

  return loading ? <SkeletonLoader /> : <Statement />;
};

export default StatementPage;
