import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import PaymentDetail from "../../components/PaymentDetail";
import PaymentDetailFailed from "../../components/PaymentDetail/PaymentDetailFailed";
import { useLocationDashboardPath, usePaymentDetailsLoader } from "./hooks";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { Redirect } from "react-router";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";

interface PaymentDetailPageProps {
  confirmation: boolean;
}

const PaymentDetailPage: React.FC<PaymentDetailPageProps> = ({
  confirmation
}) => {
  const {
    loading: loadingPaymentGroupDetail,
    loadFailed,
    paymentGroupDetail
  } = usePaymentDetailsLoader();

  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const { pathname } = useLocationDashboardPath();
  const { loadingInvoices } = useInvoicesLoader({
    loadAll: true
  });

  const loading =
    loadingPaymentGroupDetail ||
    loadingVendors ||
    locationsLoading ||
    loadingInvoices;

  const failed = loadFailed && !loading;
  const redirect = failed && confirmation;

  return !loading && paymentGroupDetail ? (
    <PaymentDetail
      paymentGroupDetail={paymentGroupDetail}
      confirmation={confirmation}
    />
  ) : redirect ? (
    <Redirect
      to={{
        pathname
      }}
    />
  ) : failed ? (
    <PaymentDetailFailed />
  ) : (
    <SkeletonLoader />
  );
};

export default PaymentDetailPage;
