import currency from "currency.js";
import { isRemitInvoice, RemitBaseRow, RemitInvoice } from "../types";

const isBlank = (inv: RemitBaseRow | RemitInvoice) =>
  !isRemitInvoice(inv) && inv.paymentAmount > 0;

const isCredit = (inv: RemitBaseRow | RemitInvoice) =>
  !isRemitInvoice(inv) && inv.paymentAmount < 0;

export const calculateTotal = (
  invoices: Array<RemitBaseRow | RemitInvoice>
) => {
  const blankSubTotal = invoices.reduce((total, inv) => {
    if (isBlank(inv)) {
      return total.add(inv.paymentAmount);
    }
    return total;
  }, currency(0));

  const blankSubTotalCredits = invoices.reduce((total, inv) => {
    if (isCredit(inv)) {
      return total.add(inv.paymentAmount);
    }
    return total;
  }, currency(0));

  return { blankSubTotal, blankSubTotalCredits };
};
