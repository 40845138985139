import styles from "@billfire/toybox";
import styled from "styled-components";

const { colors } = styles;
const { linkPrimaryHover } = colors;

export const StyledA = styled.a`
  color: ${linkPrimaryHover};
  font-weight: 300;
  text-decoration: underline;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;
