import React from "react";
import { useVendorFromPath } from "../../hooks/useVendorFromPath";
import { DisputeMessage } from "./styled.components";

const DisputeConfirmationBody: React.FC = () => {
  const vendor = useVendorFromPath();
  return (
    <>
      <DisputeMessage>
        We will send the dispute information to your {vendor.name}{" "}
        representative.
      </DisputeMessage>
    </>
  );
};

export default DisputeConfirmationBody;
