import { StoreType } from "../../types";
import {
  ConstructingPaymentMethodACH,
  ConstructingPaymentMethodCC,
  PaymentMethod,
  PaymentMethodCreateState,
  PaymentMethodsState,
  PaymentMethodTokenResult,
  ClientFlowInfo
} from "./types";
import * as sharedTypes from "@billfire/va-types";

export const getPaymentMethodsState = (
  state: StoreType
): PaymentMethodsState => {
  return state.paymentMethods;
};

export const getPaymentMethods = (state: StoreType): Array<PaymentMethod> =>
  Object.values(state.paymentMethods.byId);

export const getSelectedPaymentMethod = (
  state: StoreType,
  onlyUseSelectedPmFromRedux = false
): PaymentMethod | undefined => {
  const { byId } = state.paymentMethods;

  const selectedPaymentMethod: PaymentMethod =
    byId[String(state.paymentMethods.selectedPaymentMethodId)];

  const localStoragePaymentMethod: PaymentMethod | undefined =
    byId[String(localStorage.getItem("lastPaymentMethodId"))];

  if (onlyUseSelectedPmFromRedux) return selectedPaymentMethod;

  return (
    selectedPaymentMethod ||
    localStoragePaymentMethod ||
    getPaymentMethods(state)[0]
  );
};

export const getSelectedPaymentMethodProvider = (state: StoreType) => {
  const pm = getSelectedPaymentMethod(state);
  return pm ? pm.provider : sharedTypes.PaymentProvider.NONE;
};

export const getPaymentMethodsLoading = (state: StoreType): boolean =>
  state.paymentMethods.loading;

export const getSelectedPaymentMethodId = (state: StoreType): string =>
  state.paymentMethods.selectedPaymentMethodId;

export const getDeleteState = (state: StoreType): { [s: string]: boolean } => {
  const { deleting, deleteSuccess, deleteFailed } = state.paymentMethods;
  return { deleting, deleteSuccess, deleteFailed };
};

export const getPaymentMethodCreateState = (
  state: StoreType
): PaymentMethodCreateState => {
  const {
    creating,
    creatingTokenized,
    createSuccess,
    createFailed,
    creatingPaymentMethod,
    creatingPaymentMethodToken,
    createFailedCode
  } = state.paymentMethods;
  return {
    creating,
    creatingTokenized,
    createSuccess,
    createFailed,
    creatingPaymentMethod,
    creatingPaymentMethodToken,
    createFailedCode
  };
};

export const getNewPaymentMethodToken = (
  state: StoreType
): PaymentMethodTokenResult => {
  return state.paymentMethods.creatingPaymentMethodToken;
};

export const getCreatingPaymentMethod = (
  state: StoreType
): ConstructingPaymentMethodACH | ConstructingPaymentMethodCC =>
  state.paymentMethods.creatingPaymentMethod;

export const getProviderClientFlowInfo = (
  state: StoreType,
  provider: string
): ClientFlowInfo => {
  // eslint-disable-next-line security/detect-object-injection
  return state.paymentMethods.clientFlowInfoMap[provider];
};
