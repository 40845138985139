import { StoreType } from "../../types";

export const getPaymentPlanLoadingState = (state: StoreType) => {
  const { loadPaymentPlansFailed, loadedPaymentPlans, loadingPaymentPlans } =
    state.paymentPlans;

  return { loadPaymentPlansFailed, loadedPaymentPlans, loadingPaymentPlans };
};

export const getAllPaymentPlansWithActivePaymentMethods = (
  state: StoreType
) => {
  return Object.values(state.paymentPlans.byId).filter(
    plan => !plan.paymentMethod?.deletedAt
  );
};

export const getPaymentPlanById = (state: StoreType, id: string) => {
  // eslint-disable-next-line security/detect-object-injection
  return state.paymentPlans.byId[id];
};

export const getPaymentPlansUpdating = (state: StoreType) => {
  return state.paymentPlans.updating;
};

export const getPaymentPlansUpdated = (state: StoreType) => {
  return state.paymentPlans.updated;
};

export const getPaymentPlanByName = (state: StoreType) => {
  return state.paymentPlans.byId;
};

export const getPaymentPlansUpdateFailed = (state: StoreType) => {
  return state.paymentPlans.updateFailed;
};
