import validator from "validator";

const uuidParamsBlacklist: string[] = ["error"];

export const areUuidsCorrect = (params: Record<string, string>): boolean => {
  const keys = Object.keys(params);

  const uuidsCorrect = keys.every(
    // eslint-disable-next-line security/detect-object-injection
    key => uuidParamsBlacklist.includes(key) || validator.isUUID(params[key])
  );

  return uuidsCorrect;
};
