export enum ConfirmationLoadingState {
  loaded = "loaded",
  loading = "loading",
  failed = "failed",
  inactive = "inactive",
  active = "active"
}

export interface AutopayBaseConfirmState {
  vendorLoadingState: { loadVendorsFailed: boolean; loadingVendors: boolean };
  locationLoadingState: {
    locationsLoading: boolean;
    locationsLoadFailed: boolean;
  };
  apActive: boolean;
  autopayLoadingState: {
    loadingAutopay: boolean;
    loadAutopayFailed: boolean;
    loadedAutopay: boolean;
  };
}

export interface AutopayConfirmState extends AutopayBaseConfirmState {
  invoiceLoadingState: {
    loadingInvoices: boolean;
    loadInvoicesFailed: boolean;
  };
  paymentMethodsLoading: boolean;
  pmAndDateLoadingState: { loading: boolean };
}
