import { Accordion, Button, Panel } from "@billfire/toybox";

import { Link } from "react-router-dom";
import { device } from "../../breakpoints";
import styled from "styled-components";

export const StyledH3 = styled.h3`
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  color: #4a4a4a;
`;
export const StyledP = styled.p`
  .Jake {
    margin: 15px 0;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.31;
    color: #4a4a4a;
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.accordion {
    .accordion-header {
      color: #777;
      font-weight: 300;
      font-size: 16px;
      border-bottom: 2px solid rgb(246, 246, 246);
    }
    .accordion-content {
      padding-top: 20px;
    }
  }
`;

export const StyledPanel = styled(Panel)`
  &.Panel {
    text-align: center;
  }
`;

export const StyledButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration: none;
`;

export const StyledButton = styled(Button)`
  &.Button {
    width: 100%;
    max-width: 186px;
    @media screen and (${device.phone}) {
      max-width: 100%;
    }
  }
`;
