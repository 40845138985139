import * as React from "react";
import { StyledPanel, PanelName, StatementIcon, RefDiv } from "./styled";
import { useHover } from "./hooks";
import { usePageNav } from "../../hooks/navigation";

const StatementPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();
  const { appendPath } = usePageNav();
  return (
    <RefDiv
      onClick={() => appendPath("statement")}
      ref={hoverRef}
      data-testid="statement-hover"
    >
      <StyledPanel background={isHovered ? "#efe9db" : ""}>
        <StatementIcon fill={"#000"} data-testid="PaymentsIcon" />
        <PanelName>STATEMENTS</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default StatementPanel;
