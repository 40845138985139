import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { requestTypes as types } from "./index";
import { getEmptyTokenInfo } from "./types";

export const initialState: types.RequestState = {
  byId: {},
  requestInvoiceIds: [],
  excludedInvoiceIds: [],
  tokenInfo: getEmptyTokenInfo(),
  loadingTokenInfo: false,
  loadedTokenInfo: false,
  loadTokenInfoFailed: false,
  selectedPaymentDate: null,
  loadingRequest: false,
  loadedRequest: false,
  loadRequestFailed: false,
  hasCalledUpdateStatementRequest: false
};

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    loadTokenInfoSubmit: (state: types.RequestState) => {
      state.loadingTokenInfo = true;
      state.loadedTokenInfo = false;
      state.loadTokenInfoFailed = false;
      state.tokenInfo = getEmptyTokenInfo();
    },
    loadTokenInfoSuccess: (
      state: types.RequestState,
      action: PayloadAction<types.TokenInfo>
    ) => {
      state.loadingTokenInfo = false;
      state.loadedTokenInfo = true;
      state.loadTokenInfoFailed = false;
      state.tokenInfo = action.payload;
    },
    loadTokenInfoFailed: (state: types.RequestState) => {
      state.loadingTokenInfo = false;
      state.loadedTokenInfo = false;
      state.loadTokenInfoFailed = true;
      state.tokenInfo = getEmptyTokenInfo();
    },
    setRequestInvoiceIds: (
      state: types.RequestState,
      action: PayloadAction<string[]>
    ) => {
      state.requestInvoiceIds = action.payload;
    },
    excludeInvoices: (
      state: types.RequestState,
      action: PayloadAction<string[]>
    ) => {
      state.excludedInvoiceIds = action.payload;
    },
    setSelectedPaymentDate: (
      state: types.RequestState,
      action: PayloadAction<string>
    ) => {
      state.selectedPaymentDate = action.payload;
    },
    clearSelectedPaymentDate: (state: types.RequestState) => {
      state.selectedPaymentDate = null;
    },
    loadRequestDetailsSubmit: (
      state: types.RequestState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ requestId: string }>
    ) => {
      state.loadingRequest = true;
      state.loadedRequest = false;
      state.loadRequestFailed = false;
    },
    loadRequestTypeDetailsSubmit: (
      state: types.RequestState,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{ type: string }>
    ) => {
      state.loadingRequest = true;
      state.loadedRequest = false;
      state.loadRequestFailed = false;
    },
    loadRequestDetailsSuccess: (
      state: types.RequestState,
      action: PayloadAction<types.Request>
    ) => {
      const { id, startDate } = action.payload;
      state.loadingRequest = false;
      state.loadedRequest = true;
      state.loadRequestFailed = false;
      state.byId = { ...state.byId, [id]: action.payload };
      state.selectedPaymentDate = startDate ? startDate : null;
    },
    loadRequestTypeDetailsSuccess: (
      state: types.RequestState,
      action: PayloadAction<Record<string, types.Request>>
    ) => {
      state.loadingRequest = false;
      state.loadedRequest = true;
      state.loadRequestFailed = false;
      state.byId = { ...state.byId, ...action.payload };
      const autopay = Object.values(action.payload).find(
        req => req.type === "autopay"
      );

      if (autopay) {
        state.excludedInvoiceIds = autopay.excludedInvoiceIds || [];
        state.selectedPaymentDate = autopay.startDate;
      }
    },
    loadRequestDetailsFailed: (state: types.RequestState) => {
      state.loadingRequest = false;
      state.loadedRequest = false;
      state.loadRequestFailed = true;
    },
    setHasCalledUpdateStatementRequest: (state: types.RequestState) => {
      state.hasCalledUpdateStatementRequest = true;
    },
    resetStore: () => initialState
  }
});

export const {
  loadTokenInfoSubmit,
  loadTokenInfoSuccess,
  loadTokenInfoFailed,
  setRequestInvoiceIds,
  excludeInvoices,
  setSelectedPaymentDate,
  clearSelectedPaymentDate,
  loadRequestTypeDetailsSubmit,
  loadRequestDetailsSubmit,
  loadRequestDetailsSuccess,
  loadRequestTypeDetailsSuccess,
  loadRequestDetailsFailed,
  setHasCalledUpdateStatementRequest,
  resetStore
} = requestSlice.actions;

export default requestSlice.reducer;
