import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { vendorTypes as types } from "./index";

export const initialState: types.VendorsState = {
  byId: {},
  selectedVendorId: "",
  loadingVendors: false,
  loadedVendors: false,
  loadVendorsFailed: false,
  hasEverLoadedVendors: false
};

export const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadVendorsStart: () => {},
    loadVendorsSubmit: (state: types.VendorsState) => {
      state.loadingVendors = true;
      state.loadedVendors = false;
      state.loadVendorsFailed = false;
    },
    loadVendorsSuccess: (
      state: types.VendorsState,
      action: PayloadAction<Record<string, types.Vendor>>
    ) => {
      state.loadingVendors = false;
      state.loadedVendors = true;
      state.loadVendorsFailed = false;
      state.hasEverLoadedVendors = true;
      state.byId = action.payload;
    },
    loadVendorsFailed: (state: types.VendorsState) => {
      state.loadingVendors = false;
      state.loadedVendors = false;
      state.loadVendorsFailed = true;
    },
    selectVendor: (state, action: PayloadAction<{ vendorId: string }>) => {
      state.selectedVendorId = action.payload.vendorId;
    },
    resetStore: () => initialState
  }
});

export const {
  loadVendorsStart,
  loadVendorsSubmit,
  loadVendorsSuccess,
  loadVendorsFailed,
  selectVendor,
  resetStore
} = vendorsSlice.actions;

export default vendorsSlice.reducer;
