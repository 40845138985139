import { SortDirection } from "@billfire/toybox";
import * as React from "react";
import { PaymentGroupDetail } from "../../pages/PaymentDetailPage/types";
import PrintExportComponent from "../PrintExportComponent";
import { PrintExportLinkWrapper } from "./styled";
import { mapAndSortPaymentCSVData } from "./utils";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";
import { getGlobalFeatureFlags } from "../../state/entities/shared/selectors";

interface PrintExportContainerProps {
  confirmation: boolean;
  paymentGroupDetail: PaymentGroupDetail;
  sortBy?: string;
  sortDirection?: SortDirection;
  printRef: React.RefObject<HTMLDivElement>;
}

const PrintExportContainer: React.FC<PrintExportContainerProps> = ({
  confirmation,
  paymentGroupDetail,
  sortBy,
  sortDirection,
  printRef
}) => {
  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);
  const { csvData, headers, documentTitle } = mapAndSortPaymentCSVData(
    confirmation,
    paymentGroupDetail,
    vendor,
    globalFeatureFlags,
    sortBy,
    sortDirection
  );

  return (
    <PrintExportLinkWrapper>
      <PrintExportComponent
        printRef={printRef}
        documentTitle={documentTitle}
        csvData={csvData}
        headers={headers}
        dateSubString={paymentGroupDetail.paymentGroup.confirmationNumber}
      />
    </PrintExportLinkWrapper>
  );
};

export default PrintExportContainer;
