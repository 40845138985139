import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { loadVendorsStart } from "../state/entities/vendors/slice";
import {
  getVendorArray,
  getVendorsLoadState
} from "../state/entities/vendors/selectors";

export const useVendorsLoader = () => {
  const dispatch = useDispatch();
  const vendors = useSelector((state: StoreType) => getVendorArray(state));
  const {
    loadVendorsFailed,
    loadingVendors,
    loadedVendors,
    hasEverLoadedVendors
  } = useSelector((state: StoreType) => getVendorsLoadState(state));

  React.useEffect(() => {
    dispatch(loadVendorsStart());
  }, [dispatch]);

  return {
    vendors,
    loadVendorsFailed,
    loadingVendors,
    loadedVendors,
    hasEverLoadedVendors
  };
};
