import * as React from "react";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { ExportIcon, StyledCSVLink, ExportText } from "./CSVExportLink.styled";
import { useFileName } from "./hooks";
export interface CSVExportLinkProps {
  documentTitle: string;
  csvData: object[];
  headers: LabelKeyObject[];
  dateSubString?: string;
}

export const CSVExportLink: React.FC<CSVExportLinkProps> = ({
  documentTitle,
  csvData,
  headers,
  dateSubString
}) => {
  const fileName = useFileName(documentTitle, dateSubString);
  return (
    <StyledCSVLink data={csvData} headers={headers} filename={fileName}>
      <ExportIcon />
      <ExportText>Export</ExportText>
    </StyledCSVLink>
  );
};

export default CSVExportLink;
