import styled from "styled-components";

export const AdsDiv = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 25.68%;
  margin-bottom: 12px;
  overflow: clip;
`;

export interface AdDurationProps {
  duration: number;
  offScreen: boolean;
  id: string;
}

export const StyledAdContainer = styled.div<AdDurationProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props =>
    props.offScreen
      ? `
  left: 100%;
  animation: ad-slide-${props.id} 1s forwards;
  animation-delay: ${props.duration - 1.5}s;

  @keyframes ad-slide-${props.id} {
    100% {
      left: 0;
    }
  }
  `
      : `
      left: 0;
      `}
`;

export const AdsImage = styled.img`
  width: 100%;
  height: 100%;
`;
