import React from "react";
import { StyledRadioGroup } from "../bits/styled";
import * as utils from "./utils";
export interface WeeklyRadioGroupProps {
  isShowing: boolean;
  currentValue: string;
  setSelectedValue: (val: string) => void;
}

export const WeeklyRadioGroup: React.FC<WeeklyRadioGroupProps> = ({
  isShowing,
  currentValue,
  setSelectedValue
}) => {
  const mappedWeek = utils.mapWeek(currentValue);

  return (
    <div>
      {isShowing && (
        <StyledRadioGroup
          options={mappedWeek}
          onChange={(option, selectedValue) => {
            setSelectedValue(selectedValue);
          }}
        />
      )}
    </div>
  );
};

export default WeeklyRadioGroup;
