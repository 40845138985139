import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedPaymentMethodId,
  getSelectedPaymentMethod
} from "../../state/entities/paymentMethods/selectors";
import { setSelectedPaymentMethod } from "../../state/entities/paymentMethods/slice";
import { PaymentMethod } from "../../state/entities/paymentMethods/types";
import { StoreType } from "../../state/types";

export const useSetInitialSelectedPaymentMethod = (
  paymentMethods: PaymentMethod[],
  onlyUseSelectedPmFromRedux: boolean
) => {
  const selectedPaymentMethodId = useSelector(getSelectedPaymentMethodId);
  const selectedMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, onlyUseSelectedPmFromRedux)
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!selectedPaymentMethodId && paymentMethods.length && selectedMethod) {
      dispatch(
        setSelectedPaymentMethod({ paymentMethodId: selectedMethod.id })
      );
    }
  }, [paymentMethods, dispatch, selectedPaymentMethodId, selectedMethod]);
};
