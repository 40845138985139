import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../state/entities/paymentMethods/selectors";
import {
  getSelectedInvoices,
  getDisputedInvoicesById
} from "../../state/entities/invoices/selectors";
import { disputedInvoiceToPartialPayment } from "../../state/entities/invoices/utils";
import { Invoice } from "../../state/entities/invoices/types";
import {
  getGlobalHolidays,
  getEpa,
  getAuthToken,
  getDefaultC2pPaymentDays
} from "../../state/entities/shared/selectors";
import { useParams } from "react-router-dom";
import { getVendor } from "../../state/entities/vendors/selectors";
import { StoreType } from "../../state/types";
import { DateHelper } from "@billfire/payment-lib-base-v2";
import {
  getNextPaymentDate,
  getValidDates,
  isFormValidCheck,
  createPaymentGroup,
  CreatePaymentGroupResponseBody,
  CreatePaymentGroupCCResponseBody,
  C2PState,
  handleDateInPast
} from "./utils";
import React, { useEffect, useState } from "react";
import { useTotal } from "../../hooks/useTotal";
import currency from "currency.js";
import { useModal, modalTypes } from "../../globalModal";
import { ModalType } from "../../globalModal/types";
import { DateTime } from "luxon";
import { setSelectedInvoiceIds } from "../../state/entities/invoices/slice";
import ErrorMessage from "./ErrorMessage";
import { getSelectedPaymentDate } from "../../state/entities/requests/selectors";

const defaultForm = (
  dateHelper: DateHelper,
  selectedPaymentDate: string | null,
  provider: string | undefined = "direct-ach"
): C2PState => ({
  date: selectedPaymentDate
    ? DateTime.fromFormat(selectedPaymentDate, "yyyy-MM-dd")
    : getNextPaymentDate(null, dateHelper, provider),
  provider
});

const handleFail = (
  showModal: (component: ModalType) => void,
  hideModal: () => void
): void => {
  showModal({
    type: modalTypes.BASIC_WHITE,
    props: {
      title: "Could not process payment",
      message: <ErrorMessage />,
      onClose: () => {
        hideModal();
      }
    }
  });
};

const useClick2PaySelectors = () => {
  const { vendorId } = useParams<{ vendorId: string }>();
  const selections = useSelector((state: StoreType) => {
    return {
      vendor: getVendor(state, vendorId),
      holidays: getGlobalHolidays(state),
      selectedPaymentMethod: getSelectedPaymentMethod(state),
      selectedInvoices: getSelectedInvoices(state),
      defaultC2pPaymentDays: getDefaultC2pPaymentDays(state)
    };
  });

  const [invoiceTotal] = useTotal();

  return {
    ...selections,
    invoiceTotal
  };
};

export const useSubmit = () => {
  const { showModal, hideModal } = useModal();
  const [submitting, setSubmitting] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [pathname, setPathname] = useState<null | string>(null);
  const { vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
  }>();
  const provider = useSelector(getSelectedPaymentMethodProvider);
  const { epaVersion, authToken, partialPayments } = useSelector(
    (state: StoreType) => ({
      authToken: getAuthToken(state),
      epaVersion: getEpa(state, provider).version,
      partialPayments: Object.values(getDisputedInvoicesById(state).byId).map(
        inv => disputedInvoiceToPartialPayment(inv)
      )
    })
  );
  const dispatch = useDispatch();

  const submit = (
    paymentMethodId: string,
    paymentDate: DateTime,
    invoices: Invoice[]
  ) => {
    setSubmitting(true);
    return createPaymentGroup(authToken, vendorId, locationId, {
      paymentMethodId,
      paymentDate: handleDateInPast(paymentDate),
      partialPayments,
      invoices: invoices.map(invoice => invoice.id),
      epaVersion
    })
      .then(
        (
          res: CreatePaymentGroupResponseBody & CreatePaymentGroupCCResponseBody
        ) => {
          setSubmitting(false);
          dispatch(setSelectedInvoiceIds({ selectedInvoiceIds: [] }));
          const { allDisputed, paymentGroupId } = res;
          if (allDisputed) {
            setPathname(
              `/token/${authToken}/vendors/${vendorId}/locations/${locationId}/click2pay/disputed`
            );
          } else {
            setPathname(
              `/token/${authToken}/vendors/${vendorId}/locations/${locationId}/click2pay/${paymentGroupId}/confirmation`
            );
          }
          setConfirmed(true);
        }
      )
      .catch(() => {
        setSubmitting(false);
        handleFail(showModal, hideModal);
      });
  };

  return {
    submit,
    submitting,
    confirmed,
    pathname
  };
};

export const useClick2PayForm = () => {
  const {
    vendor,
    holidays,
    selectedPaymentMethod,
    invoiceTotal,
    selectedInvoices,
    defaultC2pPaymentDays
  } = useClick2PaySelectors();

  const dateHelper = new DateHelper(vendor, holidays);
  const provider = selectedPaymentMethod?.provider;
  const c2pPaymentDays = vendor.config.c2pPaymentDays || defaultC2pPaymentDays;
  const validDates = getValidDates(c2pPaymentDays, dateHelper, provider);
  const selectedPaymentDate = useSelector(getSelectedPaymentDate);

  const [form, setForm] = React.useState<C2PState>(
    defaultForm(dateHelper, selectedPaymentDate, provider)
  );

  const [checked, setChecked] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    setForm(defaultForm(dateHelper, selectedPaymentDate, provider));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provider !== form.provider) {
      setForm(defaultForm(dateHelper, null, provider));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.provider, provider]);

  useEffect(() => {
    setIsFormValid(
      isFormValidCheck(
        checked,
        selectedPaymentMethod,
        form.date,
        currency(invoiceTotal).value,
        selectedInvoices
      )
    );
  }, [form, checked, selectedPaymentMethod, invoiceTotal, selectedInvoices]);

  const { submit, submitting, confirmed, pathname } = useSubmit();

  const submitForm = () => {
    if (selectedPaymentMethod)
      return submit(selectedPaymentMethod.id, form.date, selectedInvoices);
  };

  const updateForm = (field: string, value: unknown) => {
    setForm((prev: C2PState) => ({
      ...prev,
      [field]: value
    }));
  };

  return {
    selectedPaymentMethod,
    validDates,
    form,
    checked,
    setChecked,
    isFormValid,
    submitForm,
    updateForm,
    submitting,
    confirmed,
    pathname
  };
};
