import styled from "styled-components";

export const FormCommentsTextAreaContainer = styled.div`
  flex-grow: 1;
  margin-top: 83px;
  .styled-text-area-container {
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
  }
  .styled-text-area-count {
    margin-top: -16px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    position: absolute;
    right: 0;
  }
  .formComment {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
    ::placeholder {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #777;
    }
  }

  @media print {
    display: none;
  }
`;

export const FormCommentPrintArea = styled.div`
  margin-top: 20px;
  @media screen {
    display: none;
  }
`;

export const FormCommentPrintBox = styled.div`
  border-radius: 4px;
  border: solid 1px #c8c8c8;
  min-height: 103px;
  width: 728px;
  padding: 8.5px 13px 8.5px 13px;
  font-size: 9.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
`;

export const FormCommentsLabel = styled.div`
  padding-bottom: 10px;
  font-size: 9.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #777;
`;
