import { useState, useEffect } from "react";
import { useModal, modalTypes } from "../../globalModal";
import Api from "../../state/api";
import { ModalType } from "../../globalModal/types";
import { Announcement } from "./types";
import { DateTime } from "luxon";
import { FormState } from "./useForm";

interface LetMeInSubmitState {
  submitForm: (contact: string) => void;
  submitting: boolean;
}

const handleSuccess = (
  showModal: (component: ModalType) => void,
  hideModal: () => void,
  resetForm: (arg0?: FormState) => void,
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  showModal({
    type: modalTypes.BASIC_WHITE,
    props: {
      message:
        "If an account matches the email address or phone number that you entered, you should receive a message with a login link soon.",
      title: "Access Requested",
      onClose: () => {
        hideModal();
      }
    }
  });
  resetForm();
  setSubmitting(false);
};

export const useSubmitState = (
  resetForm: (arg0?: FormState) => void,
  setSubmitError: (err: string) => void
): LetMeInSubmitState => {
  const { showModal, hideModal } = useModal();
  const [submitting, setSubmitting] = useState(false);
  const closeModal = () => {
    setSubmitting(false);
    hideModal();
  };

  return {
    submitForm: (contact: string): void => {
      setSubmitting(true);

      Api.postWithoutCreds("/letmein", { contact })
        .then(() => {
          handleSuccess(showModal, closeModal, resetForm, setSubmitting);
        })
        .catch(() => {
          setSubmitting(false);
          setSubmitError("Unable to send request.");
        });
    },
    submitting
  };
};

export const filterAnnouncement = (announcement: Announcement): boolean => {
  if (!announcement.expirationDate) {
    return true;
  }

  const exp = DateTime.fromISO(announcement.expirationDate);

  return exp.endOf("day") >= DateTime.now();
};

export const usePublicAnnouncements = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const loadAnnouncements = async () => {
    fetch(`https://${process.env.PUBLIC_ANNOUNCEMENTS}/announcements.json`, {
      method: "GET",
      cache: "no-cache"
    }).then(async res => {
      if (res.ok) {
        const json = await res.json();
        setAnnouncements(json.filter(filterAnnouncement));
      }
    });
  };

  useEffect(() => {
    return () => {
      setAnnouncements([]);
    };
  }, []);

  return { loadAnnouncements, announcements };
};
