import styled from "styled-components";
import { device } from "../../../breakpoints";

export const TotalsContainer = styled.div`
  div.blank-form {
    color: rgba(0, 0, 0, 0);
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    width: 150px;
    height: 24px;
  }
  margin-right: 20px;
  margin-left: auto;
  max-width: 320px;
  @media only screen and (${device.tablet}) {
    max-width: 100%;
  }

  @media print {
    margin-right: 0px;
  }
`;

export const TotalsDataRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-bottom: 6px;
  margin-left: 12px;
  margin-right: 12px;

  @media print {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
  }
`;

export const SubTotalContainer = styled.div`
  display: flex;
`;

export const SubTotalLabel = styled.div``;

export const GrandTotalContainer = styled.div`
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;

  @media print {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
  }
`;

export const GrandTotalLabel = styled.div`
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;

  @media print {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
  }
`;

export const Divider = styled.div`
  height: 0px;
  border: solid 1px #4a4a4a;
  margin-top: 3.6px;
  margin-bottom: 6.9px;
`;
