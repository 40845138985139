import styled from "styled-components";

import styles, { DataTable, TableRow } from "@billfire/toybox";
import { device } from "../../breakpoints";

export const PaymentsReportDataTable = styled(DataTable)`
  width: 100%;
  th {
    color: ${styles.colors.baseGray3};
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
  }
  td {
    color: ${styles.colors.baseGray2};
    padding: 15px;
    font-size: 14px;
  }
`;

export const PaymentsReportContainer = styled.div.attrs({
  "data-testid": "payments-report"
})`
  background-color: white;
`;

export const ErrorContainer = styled.div`
  padding: 40px 25px 20px 25px;
  font-size: 18px;
  text-align: center;
  color: #999999;
`;

export const ErrorText = styled.div`
  padding-bottom: 20px;
`;

export const PaymentLink = styled.div`
  color: ${styles.colors.baseBlue0};
  white-space: nowrap;
  text-align: left;
  max-width: 90%;
  &:hover {
    cursor: pointer;
  }

  @media print {
    display: none;
  }
`;

export const PrintedText = styled.div`
  display: none;
  color: #4a4a4a;

  @media print {
    display: block;
  }
`;

export const LinedTable = styled(DataTable).attrs({
  "data-testid": "LinedTable"
})`
  margin: 9.8px 0px 20px 0px;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    font-size: 16px;
    color: #4a4a4a;
    width: 30%;
    &:first-child {
      @media screen and (min-width: 376px) {
        width: 48%;
      }
      width: 40%;
      > div {
        margin-right: 40px;
      }
    }
    &:last-child {
      text-align: right;
    }
  }

  th {
    opacity: 0.75;
    font-weight: 500;
    padding-bottom: 7px;
  }

  tbody {
    tr {
      td {
        font-weight: 300;
        padding: 11px 0;
      }
    }
  }

  @media print {
    tfoot {
      display: table-row-group;
    }
  }
`;

export const TBody = styled.tbody`
  border-top: solid 1px ${styles.colors.baseGray7};
  border-bottom: solid 1px ${styles.colors.baseGray7};
`;

export const BodyRow = styled(TableRow)`
  border-bottom: solid 1px #e4e4e4;
  &:last-child {
    border-bottom: none;
  }
`;

export const TableTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  padding-top: 26px;
`;

export const TablesContainer = styled.div`
  padding: 0px 38px 10px 38px;
  @media only screen and (${device.tablet}) {
    padding: 0px 10px 25px 10px;
  }
`;

export const PrintExportContainer = styled.div`
  padding-right: 40px;
  @media only screen and (${device.tablet}) {
    padding-right: 0px;
  }
`;
