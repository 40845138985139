import styled, { createGlobalStyle } from "styled-components";

export const DatePickerCustomStyles = createGlobalStyle`
  .react-datepicker {
    border: none;
    border-radius: 0;
    font-family: 'Roboto','Helvetica Neue','Helvetica','Arial',sans-serif;
  }

  .cal-text {
    font-weight: bold;
    font-size: 14px;
  }

  .react-datepicker__day-names {
    margin-top: 1rem;
  }

  .cal-text, .react-datepicker__day-name {
    color: rgb(74, 74, 74);
  }

  .react-datepicker__day-name {
    font-size: 11px;
    font-weight: 300;
  }

  .day, .arrow {
    cursor: pointer;
  }
  
  .day, .day:hover {
    border-radius: 0;
  }

  .day:hover {
    background-color: rgb(240, 240, 240);
  }

  .day:hover, .day.react-datepicker__day--selected {
    color: rgb(73, 144, 226);
    outline: none;
  }

  .day.react-datepicker__day--selected {
    background: rgb(195, 214, 237);
  }

  .day.react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .day.disabled { 
    color: rgb(216, 216, 216);;
    cursor: default;
    background: none;
  }

  .arrow {
    fill: rgb(73, 144, 226);
    height: 1.2rem;
    width: 1.2rem;
  }
  
  .react-datepicker__header {
    border: none;
    background: none;
  }
`;

export const Wrap = styled.div`
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
