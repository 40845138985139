import * as React from "react";
import { usePageNav } from "../../../hooks/navigation";
import { StyledButton } from "./styled";

const PaymentButton: React.FC = () => {
  const { replacePath } = usePageNav();

  return (
    <StyledButton
      loading={false}
      hasIcon={true}
      handleClick={() => replacePath("click2pay")}
    >
      Make a Payment
    </StyledButton>
  );
};

export default PaymentButton;
