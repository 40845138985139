import * as React from "react";
import {
  BlankCommentRowAmount,
  BlankCommentRowAmountTd,
  BlankCommentRowComments,
  CommentRow
} from "./styled";
import Comments from "./Comments";
import { InvoiceRowProps } from "./InvoiceRow";
import AmountInput from "./AmountInput";
import { useRemitForm } from "..";

const InvoiceCommentRow: React.FC<InvoiceRowProps> = ({ rowId }) => {
  const { sortedInvoices } = useRemitForm();

  const invoice = sortedInvoices.find(row => row.rowId === rowId);

  if (!invoice) {
    return null;
  }

  return (
    <CommentRow
      data-testid={`comment-input-${rowId}`}
      className={`shown-comments comments blank-row`}
    >
      <td>
        <BlankCommentRowComments>
          {rowId}.
          <Comments rowId={rowId} />
        </BlankCommentRowComments>
      </td>
      <td className="spacer"></td>
      <BlankCommentRowAmountTd>
        <BlankCommentRowAmount>
          <AmountInput value={null} item={invoice} />
        </BlankCommentRowAmount>
      </BlankCommentRowAmountTd>
    </CommentRow>
  );
};

export default InvoiceCommentRow;
