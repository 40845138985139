import * as React from "react";
import InvoicePanelDetailRow from "./InvoicePanelDetailRow";
import {
  StyledPanel,
  Container,
  InvoicePanelDivider,
  StyledButton,
  ButtonContainer
} from "./styled";
import { getStatus, formatDueDate } from "../../state/entities/invoices/utils";
import { formatCurrency, SortDirection } from "@billfire/toybox";
import { Invoice } from "../../state/entities/invoices/types";
import { useShowCommentModal } from "./hooks";
import InvoiceJourneyTable from "./InvoiceJourneyTable";
import { DataTableJourneyRecord } from "./types";
import LocationHeaderText from "../LocationHeaderText";
import PrintExportComponent from "../PrintExportComponent";
import {
  invoiceJourneyTableColumns,
  mapAndSortInvoiceJourneyCSVData
} from "./utils";
import InvoiceJourneyPrintView from "./InvoiceJourneyPrintView";

export interface InvoicePanelProps {
  invoice: Invoice;
  journeys: Array<DataTableJourneyRecord>;
}

const InvoicePanel: React.FC<InvoicePanelProps> = ({ invoice, journeys }) => {
  const { id, displayInvNum, dueDate, openAmount } = invoice;

  const [sortBy, setSortBy] = React.useState<string>("updatedAt");
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>("DESC");
  const printRef = React.useRef(null);

  const handleShowModal = useShowCommentModal(id);

  const headerText = "Invoice Journey for";

  const { csvData, headers } = mapAndSortInvoiceJourneyCSVData(
    invoice,
    journeys,
    sortBy,
    sortDirection
  );

  return (
    <StyledPanel>
      <Container>
        <LocationHeaderText headerText={headerText} />
        <PrintExportComponent
          printRef={printRef}
          documentTitle={`Invoice Journey ${displayInvNum}`}
          csvData={csvData}
          headers={headers}
        />
        <InvoicePanelDetailRow
          invoiceLink
          title={"Invoice"}
          detail={displayInvNum}
          invoice={invoice}
        />
        <InvoicePanelDivider />
        <InvoicePanelDetailRow
          title={"Due Date"}
          detail={formatDueDate(dueDate)}
        />
        <InvoicePanelDivider />
        <InvoicePanelDetailRow
          title={"Open Amount"}
          detail={formatCurrency(openAmount)}
        />
        <InvoicePanelDivider />
        <InvoicePanelDetailRow title={"Status"} detail={getStatus(invoice)} />
        <InvoicePanelDivider />
        <ButtonContainer>
          <StyledButton
            loading={false}
            hasIcon={true}
            padding={"14px"}
            handleClick={handleShowModal}
          >
            Add Comment
          </StyledButton>
        </ButtonContainer>
        <InvoicePanelDivider />
        <InvoiceJourneyPrintView
          printRef={printRef}
          headerText={headerText}
          journeys={journeys}
          columns={invoiceJourneyTableColumns()}
          sortBy={sortBy}
          sortDirection={sortDirection}
          invoice={invoice}
        />
        <InvoiceJourneyTable
          journeys={journeys}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
      </Container>
    </StyledPanel>
  );
};

export default InvoicePanel;
