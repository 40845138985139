// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { Route, useRouteMatch, Redirect, RouteProps } from "react-router-dom";
import { areUuidsCorrect } from "./utils";

interface RouteProtectorProps extends RouteProps {
  path: string;
}

const RouteProtector: React.FC<RouteProtectorProps> = ({
  render,
  path,
  ...rest
}) => {
  const match = useRouteMatch(path);

  const badPath: boolean = !!match && !areUuidsCorrect(match.params);

  const internalRender = badPath ? () => <Redirect to="/404" /> : render;

  return <Route path={path} render={internalRender} {...rest} />;
};

export default RouteProtector;
