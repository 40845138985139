import styled from "styled-components";

export interface CustomTableColumnProps {
  isPastDue: boolean;
}

export const CustomTableColumn = styled.div<CustomTableColumnProps>`
  color: ${props => (props.isPastDue ? "#cb000b" : "")};
`;

export interface InvoiceCommentContainerProps {
  isBlank?: boolean;
  mobile?: boolean;
}

export interface BlankCommentRowAmountProps {
  mobile?: boolean;
}

export const InvoiceCommentContainer = styled.div<InvoiceCommentContainerProps>`
  padding: ${props =>
    props.isBlank
      ? props.mobile
        ? 0
        : "0px 0px 14px 10px"
      : "10px 0px 10px 0px"};
  flex-grow: 1;
  .styled-text-area-container {
    padding: 5px 9px;
    position: relative;
  }
  .styled-text-area-count {
    margin-top: -16px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    position: absolute;
    right: 0;
  }
  .invoiceComment {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
    ::placeholder {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #777;
      opacity: 0.3;
    }
  }

  @media print {
    padding: 0px;
  }
`;

export const StyledAmountBaseInput = styled.input<{ mobile?: boolean }>`
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  background-color: #fff;
  height: 24px;
  width: ${props => (props.mobile ? "100%" : "95%")};
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4a4a4a;
  text-align: right;
  padding: 0 9px;
  :focus {
    border: solid 1px #d1d1d1 !important;
    outline: none;
  }
  ::placeholder {
    text-align: left;
    color: #777;
    opacity: 0.3;
  }
`;

export const CommentsTextAreaContainer = styled.div`
  @media print {
    display: none;
  }
  flex-grow: 1;
`;

export const PrintedCommentsContainer = styled.div<InvoiceCommentContainerProps>`
  @media screen {
    display: none;
  }

  display: flex;
  height: 26px;
  margin-top: ${props => (props.isBlank ? "0px" : "-10px")};

  font-size: 9.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  padding: 0px;
`;

export const CommentsLabel = styled.div`
  @media screen {
    display: none;
  }
  margin-right: 18px;
`;

export const CommentsText = styled.div`
  @media screen {
    display: none;
  }
`;

export const DataRow = styled.tr`
  border-top: 3px solid white;

  td:not(:first-child) {
    padding: 7px 7px 6px 7px;
  }

  td {
    media-print {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }

  @media print {
    border-top: 1px solid #777;
    height: 42px;
  }
`;

export const CommentRow = styled.tr<{ mobile?: boolean }>`
  height: 1px;
  td:not(:first-child) {
    padding: ${props => (props.mobile ? "0 0 20px 10px" : "0px 7px 6px 7px")};
  }
`;

export const BlankCommentRowComments = styled.div<{ mobile?: boolean }>`
  display: flex;
  gap: 5px;
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #777;
  margin-bottom: ${props => (props.mobile ? "4px" : 0)};
`;

export const BlankCommentRowAmount = styled.div<BlankCommentRowAmountProps>`
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: ${props => (props.mobile ? 0 : "10px")};
`;

export const BlankCommentRowAmountTd = styled.td`
  height: inherit;
`;
