import * as React from "react";
import { useSelector } from "react-redux";
import {
  getVendor,
  getSelectedVendorId
} from "../../../state/entities/vendors/selectors";
import { StoreType } from "../../../state/types";
import SelectedInvoiceTable from "../SelectedInvoiceTable";
import { InvoiceForSelectedDataRow } from "../types";
import { ContentContainer } from "./styled.components";
import { getPaymentType, isBeingUsed } from "./utils";

export interface PaymentMethodModalContentProps {
  invoicesForSelected: InvoiceForSelectedDataRow[];
  hasPaymentPlan: boolean;
  hasAutopay: boolean;
}

const PaymentMethodModalContent: React.FC<PaymentMethodModalContentProps> = ({
  invoicesForSelected,
  hasPaymentPlan,
  hasAutopay
}) => {
  const paymentType = getPaymentType(hasPaymentPlan, hasAutopay);

  const selectedVendorId =
    useSelector((state: StoreType) => getSelectedVendorId(state)) || "";

  const selectedVendorName = useSelector(
    (state: StoreType) => getVendor(state, selectedVendorId).name
  );

  return (
    <ContentContainer>
      {isBeingUsed(hasPaymentPlan, hasAutopay, selectedVendorName) ? (
        `This payment method is being used for ${paymentType}. Please contact your ${selectedVendorName} representative for assistance.`
      ) : invoicesForSelected.length > 0 ? (
        <SelectedInvoiceTable invoicesForSelected={invoicesForSelected} />
      ) : null}
    </ContentContainer>
  );
};

export default PaymentMethodModalContent;
