import { Selector, StoreType } from "../../types";
import { GlobalFeatureFlags, GlobalHolidays, SharedState } from "./types";
import { PaymentUtils } from "@billfire/payment-lib-base-v2";
import * as sharedTypes from "@billfire/va-types";
import { EpaInterfaces } from "@billfire/va-types";
export const getShared = (state: StoreType): SharedState => {
  return state.shared;
};

export const getAuthToken = (state: StoreType): string => {
  return state.shared.authToken;
};

export const getEpa = (
  state: StoreType,
  provider: sharedTypes.PaymentProvider
): EpaInterfaces.EpaConfig => PaymentUtils.getEPA(state.shared.epa, provider);

export const getEpaLoading = (state: StoreType): boolean => {
  return state.shared.loadingEpa;
};

export const getGlobalHolidays: Selector<GlobalHolidays> = state => {
  return state.shared.globalHolidays;
};

export const getDefaultC2pPaymentDays = (
  state: StoreType
): number | undefined => {
  return state.shared.configuration?.paymentRequest.c2pPaymentDays;
};

export const getGlobalFeatureFlags = (state: StoreType): GlobalFeatureFlags => {
  return state.shared.globalFeatureFlags;
};
