import { useEffect, useRef } from "react";

export const useMounted = () => {
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return { isMounted: () => mountedRef.current };
};
