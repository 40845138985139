import * as React from "react";
import Dispute from "../../components/Dispute";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";

const DisputePage: React.FC = () => {
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const { loadingInvoices } = useInvoicesLoader({
    type: "click2pay"
  });

  const [loading, setLoading] = React.useState<boolean>(
    loadingVendors || locationsLoading || loadingInvoices
  );

  React.useEffect(() => {
    setLoading(loadingVendors || locationsLoading || loadingInvoices);
  }, [loadingVendors, locationsLoading, loadingInvoices]);

  return loading ? <SkeletonLoader /> : <Dispute />;
};

export default DisputePage;
