import * as React from "react";

export type FormValue = string;

export interface FormProperty {
  value: FormValue;
}

export interface FormState {
  [name: string]: FormProperty;
}

const initialState: FormState = {
  contactinput: {
    value: ""
  }
};

export const useForm = () => {
  const [form, setForm] = React.useState<FormState>(initialState);

  const resetForm = () => {
    setForm(initialState);
  };

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: {
        // eslint-disable-next-line security/detect-object-injection
        ...form[name],
        value
      }
    });
  };

  return { form, setValue, resetForm };
};
