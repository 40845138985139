import * as React from "react";
import { InnerTotalContainer, TotalContainer, TotalRow } from "./styled";
import { InvoiceRow, formatTotal } from "./utils";
interface TotalsViewProps {
  filteredInvoices: {
    pastDue: InvoiceRow[];
    current: InvoiceRow[];
    credits: InvoiceRow[];
  };
}

const TotalView: React.FC<TotalsViewProps> = ({ filteredInvoices }) => {
  const { pastDue, current, credits } = filteredInvoices;
  return (
    <TotalContainer>
      <InnerTotalContainer>
        <TotalRow>
          <div>Total</div>
          <div>{formatTotal([...pastDue, ...current, ...credits])}</div>
        </TotalRow>
      </InnerTotalContainer>
    </TotalContainer>
  );
};

export default TotalView;
