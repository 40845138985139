import styled from "styled-components";
import styles, { DataTable } from "@billfire/toybox";
import colors from "@billfire/toybox";
import { Link } from "react-router-dom";
import { device } from "../../breakpoints";

const {
  colors: { baseGray7, baseGray3 }
} = styles;

export const StyledLink = styled(Link)`
  color: ${colors.colors.baseBlue0};
`;

export const Container = styled.div.attrs({
  "data-testid": "payment-plans-table-container"
})`
  padding: 0px 15px 15px 15px;
  background: white;
`;

export const StyledDataTable = styled(DataTable)`
  width: 100%;
  margin: auto;
  table {
    border-collapse: collapse;
  }

  a {
    color: #4a90e2;
  }

  tr {
    border-bottom: solid 1px #777;
  }

  th {
    padding: 10px 0px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${baseGray7};
  }

  td {
    padding: 11px 0 11px 0px;
    color: ${baseGray3};
  }

  tr > td:nth-child(1) > div {
    /* Standard CSS ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  tr > td:nth-child(5) > div {
    text-align: right;
  }

  tr > th:nth-child(5) > div {
    text-align: right;
  }

  @media only screen and (${device.phone}) {
    tr > td:nth-child(4) > div {
      text-align: right;
    }

    tr > th:nth-child(4) > div {
      text-align: right;
    }
  }
`;

export const TableTitle = styled.div`
  margin-top: 30px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
`;
