// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView, {
  ReportPrintViewProps
} from "../PrintExportComponent/ReportPrintView";
import PaymentMethodDetailRow from "../PaymentDetail/PaymentMethodDetailRow";
import DetailRow from "../DetailRow";
import { Divider, PrintChevron } from "./styled";
import { RowData, Schedule } from "./types";
import {
  StyledDataTable,
  PrintTitleContainer,
  PaymentPlanScheduleDetailTitle,
  PaymentPlanScheduleDetailSecondaryTitle
} from "./styled";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";
import { formatFrequency } from "../../state/entities/paymentPlans/utils";
import { baseGray2 } from "@billfire/toybox/dist/styles/colors";
import { PaymentPlan } from "../../state/entities/paymentPlans/types";

export interface PaymentPlanDetailPrintViewProps extends ReportPrintViewProps {
  rowData: RowData;
  name: string;
  lastFour: string;
  scheduleColumns: Column[];
  schedule: Schedule;
  paymentPlan: PaymentPlan;
}

export const PaymentPlanDetailPrintView: React.FC<
  PaymentPlanDetailPrintViewProps
> = ({
  printRef,
  headerText,
  rowData,
  name,
  lastFour,
  scheduleColumns,
  schedule,
  paymentPlan
}) => {
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        {rowData.map(displayMe => (
          <React.Fragment key={`${displayMe.left}-fragment`}>
            {displayMe.left === "Payment Method" ? (
              <PaymentMethodDetailRow name={name} lastFour={lastFour} />
            ) : (
              <DetailRow
                titleText={displayMe.left}
                detailText={displayMe.right}
              />
            )}
            <Divider />
          </React.Fragment>
        ))}

        <PrintTitleContainer>
          <PaymentPlanScheduleDetailTitle>
            Payment Schedule
          </PaymentPlanScheduleDetailTitle>
          <PaymentPlanScheduleDetailSecondaryTitle>
            <span>{formatFrequency(paymentPlan.frequency)} </span>
            <span>
              <PrintChevron fill={baseGray2} />
            </span>
          </PaymentPlanScheduleDetailSecondaryTitle>
        </PrintTitleContainer>
        <StyledDataTable
          columns={scheduleColumns}
          initialData={schedule}
          mobileMQ="0px"
        />
      </ReportPrintView>
    </>
  );
};

export default PaymentPlanDetailPrintView;
