import { Button, DataTable } from "@billfire/toybox";
import styled from "styled-components";
import { device } from "../../breakpoints";
import { ChevronUp } from "@billfire/toybox/dist/components/Icon/Icon";

export const LeftColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: left;
  color: #777;
  padding-left: 15px;
  @media only screen and (${device.tablet}) {
    padding-left: 0px;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: right;
  color: #4a4a4a;
  padding-right: 15px;

  @media only screen and (${device.tablet}) {
    padding-right: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 0px 15px 0px;
`;

export const Divider = styled.div`
  height: 1.8px;
  opacity: 0.3;
  background-color: #e4e4e4;
  padding: 0px 13px 0px 13px;
  width: 100%;

  @media only screen and (${device.tablet}) {
    padding: 0px;
  }
`;

export const CenterContainer = styled.div`
  justify-content: center;
  width: 100%;
  padding: 0px 13px 20px 13px;
  display: flex;
  @media only screen and (${device.tablet}) {
    padding: 0px 0px 20px 0px;
  }
`;

export const TableRow = styled.tr`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  padding-bottom: 10px;
  height: 20px;
  width: 100%;
`;

export const StyledDataTable = styled(DataTable)`
  width: 100%;
  min-width: 100%;
  td {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    padding-top: 10px;
    height: 20px;
    &:last-child {
      text-align: right;
    }
  }

  tr {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    padding-top: 10px;
    height: 20px;
  }

  th {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
    &:first-child(3) {
      width: 28%;
    }
    &:nth-child(2) {
      width: 28%;
    }
    &:nth-child(3) {
      width: 28%;
    }
    &:last-child {
      text-align: right;
      width: 16%;
    }
  }
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 93%;
  padding-left: 5px;
`;

export const PaymentPlanScheduleDetailTitle = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: left;
  color: #777;
`;

export const PaymentPlanScheduleDetailSecondaryTitle = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: right;
  color: #777;
`;

export const PaymentPlanScheduleDetailContainer = styled.div`
  width: 100%;
  padding-left: 15px;
  @media only screen and (${device.tablet}) {
    padding-left: 0px;
  }
`;

export const UpdateButton = styled(Button)`
  margin-top: 24px;

  @media only screen and (${device.tablet}) {
    width: 100%;
  }
`;

export const PrintTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px auto 25px auto;
`;

export const PrintChevron = styled(ChevronUp)`
  width: 15px;
`;
