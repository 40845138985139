import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import InvoiceJourneyPanel from "./InvoiceJourneyPanel";
import { UpdatedInvoiceJourney } from "./types";

import { useUpdateRelatedMessages } from "./hooks";

export interface InvoiceJoureyProps {
  invoiceJourney: UpdatedInvoiceJourney;
}

const InvoiceJourney: React.FC<InvoiceJoureyProps> = ({ invoiceJourney }) => {
  useUpdateRelatedMessages();

  return (
    <CommonWrapper>
      <InvoiceJourneyPanel
        invoice={invoiceJourney.invoice}
        journeys={invoiceJourney.journeys}
      />
    </CommonWrapper>
  );
};

export default InvoiceJourney;
