import * as React from "react";
import LetMeInBaseCopy from "../LetMeInBaseCopy";
import {
  TitleText,
  ParagraphContainer,
  TextDisplayPanel
} from "./LetMeIn.styled";

const TextDisplay: React.FC = () => {
  return (
    <TextDisplayPanel>
      <TitleText>Access Billfire VALET</TitleText>
      <ParagraphContainer>
        <LetMeInBaseCopy fontSize={"14px"} />
      </ParagraphContainer>
    </TextDisplayPanel>
  );
};

export default TextDisplay;
