import styled from "styled-components";
import { device } from "../../breakpoints";
import LocationHeaderText from "../LocationHeaderText";

export const PrintComponentContainer = styled.div`
  margin: 0 40px 30px 0;
  @media only screen and (${device.tablet}) {
    margin: 0 0 30px 0;
  }

  @media print {
    display: none;
  }
`;

export const ConfirmationLocationHeaderText = styled(LocationHeaderText)`
  @media print {
    display: none;
  }
`;
