import api from "../../state/api";
import { PaymentGroupDetail } from "./types";

export const loadPaymentGroupDetails = async (
  token: string,
  vendorId: string,
  locationId: string,
  paymentGroupId: string
): Promise<PaymentGroupDetail> => {
  const url = api.API_URL(
    `/vendors/${vendorId}/locations/${locationId}/paymentgroups/${paymentGroupId}/payments`
  );
  return fetch(url, api.GET(token, true)).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("failed calling api");
    }
  });
};
