import * as React from "react";
import {
  Row,
  PMDisplayNameContainer,
  EllipsisPMName,
  RowContent,
  RowTitle
} from "./styled";

export interface PaymentMethodDetailRowProps {
  name: string;
  lastFour: string;
  className?: string;
}

const PaymentMethodDetailRow: React.FC<PaymentMethodDetailRowProps> = ({
  name,
  lastFour,
  className
}) => {
  return (
    <Row className={className}>
      <RowTitle>Payment Method</RowTitle>
      <PMDisplayNameContainer>
        <EllipsisPMName>{name}</EllipsisPMName>
        <RowContent>{lastFour}</RowContent>
      </PMDisplayNameContainer>
    </Row>
  );
};

export default PaymentMethodDetailRow;
