import React from "react";
import { Debouncer, isValidSmsOrEmail } from "../../utils";
import { useSubmitState } from "../LetMeIn/hooks";
import { useForm } from "./useForm";

export const useHandleForm = () => {
  const { form, setValue, resetForm } = useForm();
  const [debouncer] = React.useState(new Debouncer());
  const [submitError, setSubmitError] = React.useState("");
  const { submitting, submitForm } = useSubmitState(resetForm, setSubmitError);
  const [inputValid, setInputValid] = React.useState(true);
  const isValid = isValidSmsOrEmail(form.contactinput.value);

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm(form.contactinput.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(e);
    setSubmitError("");
    const isValidValue = !value || isValidSmsOrEmail(value);

    debouncer.debounce(
      () => {
        setInputValid(isValidValue);
      },
      2000,
      isValidValue
    );
  };

  return {
    form,
    onFormSubmit,
    handleChange,
    submitError,
    submitting,
    inputValid,
    isValid
  };
};
