import styled from "styled-components";

export interface StyledPProps {
  fontSize?: string;
  marginBottom?: string;
}

export const StyledP = styled.p<StyledPProps>`
  font-size: ${props => props.fontSize};
  margin-bottom: ${props => props.marginBottom};
`;
