// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import {
  formatShortDate,
  sortDate,
  sortString,
  SortDirection
} from "@billfire/toybox";
import { InvoiceLink } from "./styled";
import { Message } from "../../state/entities/messages/types";
import { MessageRow, isMessageColumnName } from "./types";
import PrefixEllipsis from "../PrefixEllipsis";
import { calculateCategory } from "../../state/entities/messages/utils";

export const getColumns = (
  getReplacementPathString: (path: string) => string,
  mobile: boolean
) => {
  return [
    {
      name: "invoiceDisplayInvNum",
      label: "Invoice",
      width: mobile ? "" : "40%",
      render: (value: string, item: MessageRow) => {
        return (
          <InvoiceLink
            href={getReplacementPathString(
              `invoices/${item.invoiceId}/pdfloader`
            )}
            title={value}
            target={"_blank"}
          >
            <PrefixEllipsis text={value} />
          </InvoiceLink>
        );
      },
      sortFn: sortString
    },
    {
      name: "createdAt",
      label: "Date",
      width: mobile ? "" : "40%",
      formatFn: formatShortDate,
      sortFn: sortDate
    },
    {
      name: "category",
      label: "Category",
      width: mobile ? "" : "20%",
      render: (value: string, item: MessageRow) => {
        return (
          <InvoiceLink
            href={getReplacementPathString(`invoices/${item.invoiceId}`)}
            title={value}
          >
            {value}
          </InvoiceLink>
        );
      },

      sortFn: sortString
    }
  ];
};

export const mapMessageRows = (messages: Message[]): MessageRow[] => {
  return messages.map(message => ({
    ...message,
    rowId: message.id,
    category: calculateCategory(message)
  }));
};

const headers = [
  { label: "Invoice", key: "invoiceDisplayInvNum" },
  { label: "Date", key: "createdAt" },
  { label: "Category", key: "category" }
];

export const mapAndSortResolutionCenterCSVData = (
  messages: Message[],
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const columns = getColumns(() => "path", false);

  const foundColumn = columns.find(({ name }) => name === sortBy);
  const sortFn = foundColumn && foundColumn.sortFn;

  const csvData = messages
    .map(msg => ({
      invoiceDisplayInvNum: msg.invoiceDisplayInvNum,
      createdAt: formatShortDate(msg.createdAt),
      category: calculateCategory(msg)
    }))
    .sort(
      (a, b) =>
        sortFn &&
        isMessageColumnName(sortBy) &&
        // eslint-disable-next-line security/detect-object-injection
        sortFn(a[sortBy], b[sortBy], sortDirection || "ASC")
    );
  return { csvData, headers };
};
