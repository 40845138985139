import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DetailRow from "./DetailRow";
import DateDetail from "./DateDetail";
import { formatShortDate, formatCurrency } from "@billfire/toybox";
import {
  CheckMarkIcon,
  Title,
  IconWrapper,
  StyledPaymentMethodDetailRow
} from "./styled";
import { PaymentPlanConfirmationData } from "../../state/entities/paymentPlans/types";
import { resetUpdateState } from "../../state/entities/paymentPlans/slice";
import { getAuthToken } from "../../state/entities/shared/selectors";
import { StoreType } from "../../state/types";
import PaymentDetailRow from "./PaymentDetailRow";
import { LocationPageWrapper } from "../bits/styled";
import ConfirmationPagePrintExport from "../ConfirmationPagePrintExport/ConfirmationPagePrintExport";
import { useFormatDocumentTitle } from "../ConfirmationPagePrintExport/hooks";
import ReportPrintView from "../PrintExportComponent/ReportPrintView";
import { ConfirmationLocationHeaderText } from "../ConfirmationPagePrintExport/styled";

const PaymentPlanConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { vendorId, locationId } = useParams<{
    vendorId: string;
    locationId: string;
  }>();
  const tokenId = useSelector((state: StoreType) => getAuthToken(state));

  const { state } = useLocation<PaymentPlanConfirmationData>();

  const printRef = React.useRef(null);
  const documentTitle = useFormatDocumentTitle();

  if (state === undefined) {
    history.push(
      `/token/${tokenId}/vendors/${vendorId}/locations/${locationId}`
    );
    return null;
  }

  const { paymentMethod, paymentPlan, update } = state;

  if (update) dispatch(resetUpdateState());

  const headerText = `Payment Plan ${update ? "Updated" : "set up"} for`;

  const ConfirmationContent = (
    <>
      <ConfirmationLocationHeaderText headerText={headerText} />
      <ConfirmationPagePrintExport
        printRef={printRef}
        documentTitle={documentTitle}
      />
      <IconWrapper>
        <CheckMarkIcon height={"93px"} width={"93px"} viewBox="0 0 93 93" />
      </IconWrapper>
      <Title>
        {update ? "Payment plan updated" : "Your payment plan is set up!"}
      </Title>
      <DetailRow titleText="Plan Nickname" detailText={paymentPlan.name} />
      <StyledPaymentMethodDetailRow
        name={paymentMethod.displayExtra.name}
        lastFour={paymentMethod.displayExtra.lastFour}
      />
      {update ? (
        <DateDetail />
      ) : (
        <DetailRow
          titleText="Start Date"
          detailText={formatShortDate(paymentPlan.startDate)}
        />
      )}
      <DetailRow
        titleText="Total Amount"
        detailText={formatCurrency(paymentPlan.totalAmount)}
      />
      <PaymentDetailRow />
    </>
  );

  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <ReportPrintView printRef={printRef} headerText={headerText}>
          {ConfirmationContent}
        </ReportPrintView>
        {ConfirmationContent}
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default PaymentPlanConfirmation;
