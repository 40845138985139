import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import GroupHeader from "../GroupHeader";
import { StyledPanel, ErrorContainer } from "./styled";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

const InvoiceJourneyFailed: React.FC = () => {
  const vendor = useSelector(getSelectedVendor);

  return (
    <CommonWrapper>
      <GroupHeader title={"Invoice Journey"} color={"#c8c8c8"} />
      <StyledPanel>
        <ErrorContainer>
          <p>
            We were unable to load the data for this invoice journey. Please
            refresh your browser window to try again.
          </p>

          <p>
            If you continue to have difficulties viewing this page, please
            contact {vendor.name}.
          </p>
        </ErrorContainer>
      </StyledPanel>
    </CommonWrapper>
  );
};

export default InvoiceJourneyFailed;
