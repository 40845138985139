import * as React from "react";
import {
  LeftArrow,
  NavbarContainer,
  NavbarText,
  LogoContainer
} from "./navbar.styled";
import { useNavbarDisplay } from "../../../hooks/navigation";
import { navigationFunctions } from "../utils";
import { useRouteContext } from "../../../routeContext";
import BillfireValetLogo from "../../../assets/Logo_Billfire_Valet_APP.svg";

const Navbar: React.FC = () => {
  const { shouldDisplayNavbar, hookInfo, routeInfo } = useNavbarDisplay();
  const routeContext = useRouteContext();

  return shouldDisplayNavbar ? (
    <NavbarContainer>
      <LeftArrow />
      <NavbarText>
        {navigationFunctions[routeInfo.navFn] &&
          navigationFunctions[routeInfo.navFn](hookInfo, routeContext).text}
      </NavbarText>

      <LogoContainer>
        <BillfireValetLogo data-testid="billfire-valet-logo" />
      </LogoContainer>
    </NavbarContainer>
  ) : (
    <></>
  );
};

export default Navbar;
