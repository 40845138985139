/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Skeleton } from "@billfire/toybox";
import { SkeletonWrapper } from "./styled.components";

const SkeletonLoader: React.FC = () => {
  return (
    <SkeletonWrapper>
      <Skeleton />
    </SkeletonWrapper>
  );
};

export default SkeletonLoader;
