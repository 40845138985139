import AddPaymentMethodLink from "../AddPaymentMethodLink";
import PaymentMethodRow from "./PaymentMethodRow";
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import React from "react";
import { StoreType } from "../../state/types";
import { getPaymentMethods } from "../../state/entities/paymentMethods/selectors";
import { useSelector } from "react-redux";
import { useSetInitialSelectedPaymentMethod } from "./hooks";

export interface PaymentMethodSelectProps {
  onlyUseSelectedPmFromRedux?: boolean;
}
const PaymentMethodSelect: React.FC<PaymentMethodSelectProps> = ({
  onlyUseSelectedPmFromRedux = false
}) => {
  const paymentMethods = useSelector((state: StoreType) =>
    getPaymentMethods(state)
  );

  useSetInitialSelectedPaymentMethod(
    paymentMethods,
    onlyUseSelectedPmFromRedux
  );

  return (
    <div>
      {paymentMethods &&
        paymentMethods.map(paymentMethod => (
          <PaymentMethodRow
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            onlyUseSelectedPmFromRedux={onlyUseSelectedPmFromRedux}
          />
        ))}
      <AddPaymentMethodLink hasPaymentMethods={!!paymentMethods} />
    </div>
  );
};

export default PaymentMethodSelect;
