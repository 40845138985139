import React, { useState } from "react";
import { useModal } from "../index";
import { Modal, ModalSize, ModalType } from "@billfire/toybox";
import { WhiteStyledMessage } from "../modals.styled";

export interface BasicConfirmModalProps {
  title: string;
  message: string | JSX.Element;
  onClose?: () => void;
  actions?: {
    submitButtonText?: string;
    cancelButtonText?: string;
    onSubmit: () => void;
  };
}

const BasicConfirmModal: React.FC<BasicConfirmModalProps> = ({
  title,
  message,
  onClose,
  actions
}) => {
  const { hideModal } = useModal();
  const showActions = !!actions;
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    actions?.onSubmit && actions.onSubmit();
  };

  return (
    <Modal
      title={title}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={onClose || hideModal}
      loading={submitting}
      errored={false}
      errorMessage="Could not turn off AutoPay Plus."
      hideActions={!showActions}
      submitButtonText={actions?.submitButtonText}
      cancelButtonText={actions?.cancelButtonText}
      onSubmit={handleSubmit}
    >
      <WhiteStyledMessage>{message}</WhiteStyledMessage>
    </Modal>
  );
};

export default BasicConfirmModal;
