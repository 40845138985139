import { Modal, ModalType, ModalSize } from "@billfire/toybox";
import React from "react";
import { useDispatch } from "react-redux";
import { useModal } from "../../globalModal";
import { WhiteStyledMessage } from "../../globalModal/modals.styled";
import { resetCampaignsState } from "../../state/entities/campaigns/slice";
import { useDeleteReminderSubmit } from "./hooks";
import { deleteMessage, DeletReminderType } from "./utils";

export interface DeleteReminderModalProps {
  deleteReminder: DeletReminderType | null;
  setDeleteReminder: React.Dispatch<
    React.SetStateAction<DeletReminderType | null>
  >;
}

const DeleteReminderModal: React.FC<DeleteReminderModalProps> = ({
  deleteReminder,
  setDeleteReminder
}) => {
  const deleteReminderId = deleteReminder !== null ? deleteReminder.id : "";
  const { hideModal } = useModal();
  const dispatch = useDispatch();
  const {
    submitDeleteCampaign,
    deletingCampaign,
    deletedCampaign,
    deleteCampaignFailed
  } = useDeleteReminderSubmit();

  React.useEffect(() => {
    if (deletedCampaign) {
      setDeleteReminder(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedCampaign]);

  const closeModal = () => {
    dispatch(resetCampaignsState());
    hideModal();
  };

  React.useEffect(() => {
    if (deletedCampaign) closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedCampaign]);

  return (
    <Modal
      title={"Delete the Reminder"}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={() => closeModal()}
      loading={deletingCampaign}
      errored={deleteCampaignFailed}
      submitButtonText={"Delete"}
      cancelButtonText={"Keep"}
      onSubmit={() => submitDeleteCampaign(deleteReminderId)}
      errorMessage={"Could not delete the reminder. Please refresh the page."}
    >
      <WhiteStyledMessage>{deleteMessage(deleteReminder)}</WhiteStyledMessage>
    </Modal>
  );
};

export default DeleteReminderModal;
