import * as React from "react";
import CommonWrapper from "../../CommonWrapper";
import {
  ButtonContainer,
  StyledButton,
  ErrorMessageContainer
} from "../styled";
import APPaymentMethodSelect from "../APPaymentMethodSelect";
import ExcludedInvoices from "../ExcludedInvoices";
import TermsAndConditions from "../../TermsAndConditions";
import {
  useSetInitialSelectedPaymentMethod,
  useGoToLocationDashboard,
  useAutopayForm,
  useSubmit
} from "./hooks";
import { useIsAutopayOn } from "../../LocationDashboard/hooks";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreType } from "../../../state/types";
import {
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../../state/entities/paymentMethods/selectors";
import { locationPath } from "../../../utils";
import LocationHeaderText from "../../LocationHeaderText";
import { LocationPageWrapper } from "../../bits/styled";
import ButtonHeader from "./ButtonHeader";
import TurnOffButton from "./TurnOffButton";

const Autopay: React.FC = () => {
  const autopayOn = useIsAutopayOn();
  const goToLocationDashboard = useGoToLocationDashboard();
  useSetInitialSelectedPaymentMethod();
  const history = useHistory();
  const autopaySetupPath = locationPath(history.location.pathname, "autopay");

  const { checked, setChecked, isFormValid } = useAutopayForm();
  const { submit, submitting, confirmed, errorMessage, pathname } = useSubmit();
  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state)
  );

  const _handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormValid) {
      submit(false);
      localStorage.setItem(
        "lastPaymentMethodId",
        String(selectedPaymentMethod && selectedPaymentMethod.id)
      );
    }
  };

  const provider = useSelector(getSelectedPaymentMethodProvider);

  if (!autopayOn) return <Redirect to={{ pathname: autopaySetupPath }} />;

  return confirmed ? (
    <Redirect
      to={{
        pathname,
        state: { confirmed }
      }}
    />
  ) : (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText={"AutoPay for"} />
        <APPaymentMethodSelect />
        <ExcludedInvoices setup={false} />
        <ButtonContainer>
          <TermsAndConditions
            checked={checked}
            setChecked={setChecked}
            provider={provider}
          />
          <ButtonHeader />
          <StyledButton
            className={`this is the right button ${isFormValid}`}
            loading={submitting}
            disabled={!isFormValid}
            handleClick={_handleSubmit}
          >
            Update AutoPay Plus
          </StyledButton>
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          <StyledButton
            loading={false}
            color={"secondary"}
            handleClick={() => goToLocationDashboard()}
          >
            Cancel
          </StyledButton>
        </ButtonContainer>
        <TurnOffButton />
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default Autopay;
