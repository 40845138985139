import * as React from "react";

import { MobileCellProps } from ".";
import { ContentDivider, HeaderLabel, Value, ContentRow } from "./styled";
import { useColumnFlags } from "../hooks";
import { formatShortDate, formatCurrency } from "@billfire/toybox";
import PORef from "./PORef";
import Actions from "./Actions";

const Content: React.FC<MobileCellProps> = ({ invoice }) => {
  const { isInvoiceDateEnabled } = useColumnFlags();

  return (
    <div>
      <ContentDivider />
      <PORef invoice={invoice} />
      {isInvoiceDateEnabled && (
        <ContentRow>
          <HeaderLabel>Invoice Date</HeaderLabel>
          <Value>{formatShortDate(invoice.externalInvoiceDate)}</Value>
        </ContentRow>
      )}
      <ContentRow>
        <HeaderLabel>Open Amount</HeaderLabel>
        <Value>{formatCurrency(invoice.openAmount)}</Value>
      </ContentRow>
      <ContentRow>
        <HeaderLabel>Ship-To</HeaderLabel>
        <Value>{invoice.shipTo}</Value>
      </ContentRow>
      <Actions invoice={invoice} />
    </div>
  );
};

export default Content;
