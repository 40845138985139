import { sortNumber } from "@billfire/toybox";
import { StoreType } from "../../types";
import { LoadingAdsFlags, Ad } from "./types";

export const getAdsLoadingFlags = (state: StoreType): LoadingAdsFlags => {
  return {
    loadingAds: state.ads.loadingAds,
    loadedAds: state.ads.loadedAds,
    loadAdsFailed: state.ads.loadAdsFailed
  };
};

export const getAdDetails = (state: StoreType, adId: string): Ad => {
  // eslint-disable-next-line security/detect-object-injection
  return state.ads.byId[adId];
};

export const getSortedAds = (state: StoreType): Ad[] => {
  return Object.values(state.ads.byId).sort((ad1: Ad, ad2: Ad) =>
    sortNumber(ad1.order, ad2.order, "ACS")
  );
};
