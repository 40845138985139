import styled from "styled-components";
import Colors, { Skeleton } from "@billfire/toybox";
export interface StyledHeaderProps {
  color: string;
}

export const StyledSquare = styled.div.attrs({
  "data-testid": "styled-group-square"
})<StyledHeaderProps>`
  background-color: ${(props: StyledHeaderProps) => props.color};
  height: 26.7px;
  width: 28px;
`;

export const ActionContainer = styled.div`
  display: flex;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 0 0 0 11px;
`;

export const ActionTextWrapper = styled.span`
  margin-left: 7px;
  color: #4990e2;
  font-weight: 100;
  font-size: 16px;
`;

export const SecondaryActionWrapper = styled.a`
  display: flex;
  padding: 5px;
  &:hover {
    cursor: pointer;
  }
  text-decoration: none;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledGroupTitle = styled.div.attrs({
  "data-testid": "styled-group-title"
})`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.colors.baseGray3};
  padding-left: 15px;
  margin-top: 5px;
`;

export const Container = styled.div<StyledHeaderProps>`
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  margin-bottom: 28px;
  border-bottom: 2px solid ${props => props.color};
  align-items: center;
  display: flex;
`;
