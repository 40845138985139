import React from "react";
import { useSelector } from "react-redux";
import { getCurrentStatementContact } from "../../state/entities/statementDelivery/selectors";
import { StyledDeliverStatementsMessage } from "./styled";

export const DeliverStatementsMessage: React.FC = () => {
  const deliverTo = useSelector(getCurrentStatementContact);

  return (
    <StyledDeliverStatementsMessage>
      <p>{`Statements will be delivered automatically to ${deliverTo}`}</p>
    </StyledDeliverStatementsMessage>
  );
};

export default DeliverStatementsMessage;
