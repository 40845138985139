/* eslint-disable no-dupe-else-if */
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, matchPath } from "react-router";
import { selectLocation } from "../state/entities/locations/slice";
import { selectVendor } from "../state/entities/vendors/slice";
import routes from "../routes";
import navConfig, {
  NavigationConfig
} from "./config/routeNavigationconfiguration";
import { useParamHook } from "./useParams";
import {
  getSelectedVendorId,
  getVendorArray
} from "../state/entities/vendors/selectors";
import {
  getSelectedLocationId,
  getLocationArray
} from "../state/entities/locations/selectors";

export function useNavigation() {
  const dispatch = useDispatch();
  const { vendorId, locationId } = useParamHook();
  const selectedVendorId = useSelector(getSelectedVendorId);
  const selectedLocationId = useSelector(getSelectedLocationId);
  React.useEffect(() => {
    selectedVendorId !== vendorId && dispatch(selectVendor({ vendorId }));
    selectedLocationId !== locationId &&
      dispatch(selectLocation({ locationId }));
  }, [dispatch, vendorId, locationId, selectedVendorId, selectedLocationId]);

  return { vendorId, locationId };
}

const _getBillfireLogo = (
  navConfig: {
    [key: string]: NavigationConfig;
  },
  route: {
    path: string;
    exact: boolean;
    render: () => JSX.Element;
  },
  isNavigatableRoute: boolean,
  vendorCount: number,
  locationCount: number
): boolean => {
  return navConfig[route.path].billfireLogo === undefined
    ? isNavigatableRoute
    : !!navConfig[route.path].billfireLogo ||
        vendorCount === 1 ||
        locationCount === 1;
};

interface RouteInfoReturn extends NavigationConfig {
  isNavigatableRoute: boolean;
}

export const useRouteInfo = (): RouteInfoReturn => {
  const location = useLocation();
  const route = routes.find(r => matchPath(location.pathname, r));
  const vendorCount = useSelector(getVendorArray).length;
  const locationCount = useSelector(getLocationArray).length;

  if (route && navConfig[route.path]) {
    let isNavigatableRoute = true;

    const isNavigatableFn = navConfig[route.path].isNavigatable;
    if (isNavigatableFn) {
      isNavigatableRoute = isNavigatableFn(vendorCount, locationCount);
    }

    return {
      ...navConfig[route.path],
      isNavigatableRoute,
      billfireLogo:
        _getBillfireLogo(
          navConfig,
          route,
          isNavigatableRoute,
          vendorCount,
          locationCount
        ) && !isNavigatableRoute
    };
  }

  return { isNavigatableRoute: false, navFn: "", billfireLogo: true };
};
