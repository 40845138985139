/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import * as React from "react";
import CommonWrapper from "../../CommonWrapper";
import ConfirmationBody from "./ConfirmationBody";
import ExcludedInvoices from "./ExcludedInvoices";
import { Panel } from "@billfire/toybox";
import { ConfirmationDetailsContainer, IconContainer } from "../styled";
import { CheckMarkWithBackground } from "../../Icon";
import ReportPrintView from "../../PrintExportComponent/ReportPrintView";
import ConfirmationPagePrintExport from "../../ConfirmationPagePrintExport/ConfirmationPagePrintExport";
import { useFormatDocumentTitle } from "../../ConfirmationPagePrintExport/hooks";
import { ConfirmationLocationHeaderText } from "../../ConfirmationPagePrintExport/styled";
const AutopayUpdateConfirmation: React.FC = () => {
  const printRef = React.useRef(null);
  const documentTitle = useFormatDocumentTitle();

  const headerText = "AutoPay updated for";
  const ConfirmationContent = (
    <div>
      <ConfirmationLocationHeaderText headerText={headerText} />
      <ConfirmationPagePrintExport
        printRef={printRef}
        documentTitle={documentTitle}
      />
      <IconContainer>
        <CheckMarkWithBackground
          height={"93px"}
          width={"93px"}
          viewBox="0 0 93 93"
        />
      </IconContainer>
      <ConfirmationDetailsContainer>
        <ConfirmationBody />
        <ExcludedInvoices />
      </ConfirmationDetailsContainer>
    </div>
  );

  return (
    <CommonWrapper>
      <Panel padding="0px 10px 10px 10px">
        <ReportPrintView printRef={printRef} headerText={headerText}>
          {ConfirmationContent}
        </ReportPrintView>
        {ConfirmationContent}
      </Panel>
    </CommonWrapper>
  );
};

export default AutopayUpdateConfirmation;
