import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import PanelDisplay from "./PanelDisplay";

const Dispute: React.FC = () => {
  return (
    <div data-testid="dispute-page">
      <CommonWrapper>
        <PanelDisplay />
      </CommonWrapper>
    </div>
  );
};

export default Dispute;
