// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {
  StyledLogo,
  StyledPanel,
  ColumnWrapper,
  StyledH1,
  StyledH3,
  StyledButton,
  StyledInput,
  SubmitError,
  ValidationError
} from "./styled";
import LetMeInBaseCopy from "../LetMeInBaseCopy";
import { useHandleForm } from "./hooks";

const PFGContent = () => {
  const {
    onFormSubmit,
    handleChange,
    submitError,
    submitting,
    inputValid,
    isValid,
    form
  } = useHandleForm();

  return (
    <ColumnWrapper>
      <StyledLogo />
      <StyledH1>Simplify Your Life</StyledH1>
      <p>
        Now, you can have your bills paid automatically from your checking or
        savings account. And, you won't have to change your present banking
        relationship to take advantage of this service.
      </p>
      <StyledPanel {...{ inputValid }}>
        <StyledH3>Access PerformancePay</StyledH3>
        <LetMeInBaseCopy fontSize={"20px"} marginBottom={"45px"} />
        <form data-testid="let-me-in-form" onSubmit={onFormSubmit}>
          <StyledInput
            name="contactinput"
            onChange={e => handleChange(e)}
            type="text"
            placeholder="Enter Email or Phone Number"
            value={form.contactinput.value}
          />
          {inputValid ? null : (
            <ValidationError>
              Invalid email address or phone number.
            </ValidationError>
          )}
          <StyledButton
            disabled={!isValid || submitting}
            loading={submitting}
            type="submit"
          >
            Request Link
          </StyledButton>
          {submitError && <SubmitError>{submitError}</SubmitError>}
        </form>
      </StyledPanel>
    </ColumnWrapper>
  );
};

export default PFGContent;
