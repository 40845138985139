import * as React from "react";
import {
  Container,
  Header,
  PageContent,
  LogoContainer
} from "./CommonWrapper.styled";
import Footer from "./Footer";
import BillfireValetLogo from "../../assets/Logo_Billfire_Valet_APP.svg";
import Navbar from "./Navbar";
import { useNavbarDisplay } from "../../hooks/navigation";
import { navigationFunctions } from "./utils";
import VendorLocation from "../VendorLocation";
import VendorTitle from "../VendorTitle";
import { useRouteContext } from "../../routeContext";
import AnnouncementBanner from "../AnnouncementBanner";
import { Announcement } from "../LetMeIn/types";
import { usePrivateAnnouncements } from "../PrivateRouteWrapper";

interface CommonWrapperProps {
  announcements?: Announcement[];
}

const CommonWrapper: React.FC<CommonWrapperProps> = ({
  announcements,
  children
}) => {
  const { shouldDisplayNavbar, hookInfo, routeInfo } = useNavbarDisplay();
  const { announcements: privateAnnouncements } = usePrivateAnnouncements();
  const [allAnnouncements, setAllAnnouncements] = React.useState<
    Announcement[]
  >([]);
  const routeContext = useRouteContext();
  const { billfireLogo, showVendorTitleComponent } = routeInfo;

  React.useEffect(() => {
    if (announcements) {
      setAllAnnouncements([...announcements, ...privateAnnouncements]);
    } else {
      setAllAnnouncements(privateAnnouncements);
    }
  }, [announcements, privateAnnouncements, setAllAnnouncements]);

  return (
    <>
      {(shouldDisplayNavbar || billfireLogo) && (
        <Header
          clickable={shouldDisplayNavbar}
          onClick={() => {
            navigationFunctions[routeInfo.navFn] &&
              navigationFunctions[routeInfo.navFn](
                hookInfo,
                routeContext
              ).navigate();
          }}
        >
          <Navbar />

          {billfireLogo && (
            <LogoContainer>
              <BillfireValetLogo data-testid="billfire-valet-logo" />
            </LogoContainer>
          )}
        </Header>
      )}
      {allAnnouncements.map((announcement: Announcement) => (
        <AnnouncementBanner key={announcement.id} announcement={announcement} />
      ))}
      <Container>
        <PageContent>
          {showVendorTitleComponent ? <VendorTitle /> : <VendorLocation />}
          {children}
        </PageContent>
        <Footer />
      </Container>
    </>
  );
};

export default CommonWrapper;
