export const isLoading = (
  loadingRequest: boolean,
  locationsLoading: boolean,
  loadingInvoices: boolean,
  paymentMethodsLoading: boolean,
  loadedVendors: boolean,
  loadedConfiguration: boolean,
  loadingAutopay?: boolean
) =>
  loadingAutopay ||
  loadingRequest ||
  locationsLoading ||
  loadingInvoices ||
  paymentMethodsLoading ||
  !loadedVendors ||
  !loadedConfiguration;
