// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useModal, modalTypes } from "./index";
import BasicGrayModal from "./modals/BasicGrayModal";
import BasicWhiteModal from "./modals/BasicWhiteModal";
import EditInvoiceModal from "./modals/EditInvoiceModal";
import UndoDisputedInvoiceModal from "./modals/UndoDisputedInvoiceModal";
import PaymentMethodDeleteModal from "../../app/components/PaymentMethodSelect/PaymentMethodDeleteModal";
import ConfirmFeeModal from "./modals/ConfirmFeeModal";
import ChangePaymentMethodConfirmFeeModal from "./modals/ChangePaymentMethodConfirmFeeModal";
import ConfirmAutopayDeleteModal from "./modals/ConfirmAutopayDeleteModal";
import AddInvoiceCommentModal from "../components/InvoiceJourney/AddInvoiceCommentModal";
import ConfirmPaymentPlanUpdateModal from "./modals/ConfirmPaymentPlanUpdateModal";
import SetReminderModal from "../components/SetupReminders/SetReminderModal";
import DeleteReminderModal from "../components/SetupReminders/DeleteReminderModal";
import BasicConfirmModal from "./modals/BasicConfirmModal";
import PdfOrHistoryModal from "./modals/PdfOrHistoryModal";

export const ModalRoot: React.FC = () => {
  const { modalComponent } = useModal();

  switch (modalComponent.type) {
    case modalTypes.BASIC_GRAY:
      return <BasicGrayModal {...modalComponent.props} />;
    case modalTypes.BASIC_WHITE:
      return <BasicWhiteModal {...modalComponent.props} />;
    case modalTypes.EDIT_INVOICE:
      return <EditInvoiceModal {...modalComponent.props} />;
    case modalTypes.PAYMENT_METHOD_CONFIRM:
      return <ConfirmFeeModal />;
    case modalTypes.UNDO_DISPUTED_INVOICE_MODAL:
      return <UndoDisputedInvoiceModal {...modalComponent.props} />;
    case modalTypes.PAYMENT_METHOD_DELETE:
      return <PaymentMethodDeleteModal {...modalComponent.props} />;
    case modalTypes.CHANGE_PAYMENT_METHOD_CONFIRM_MODAL:
      return <ChangePaymentMethodConfirmFeeModal {...modalComponent.props} />;
    case modalTypes.CONFIRM_AUTOPAY_DELETE_MODAL:
      return <ConfirmAutopayDeleteModal {...modalComponent.props} />;
    case modalTypes.ADD_INVOICE_COMMENT_MODAL:
      return <AddInvoiceCommentModal {...modalComponent.props} />;
    case modalTypes.CONFIRM_PAYMENT_PLAN_UPDATE_MODAL:
      return <ConfirmPaymentPlanUpdateModal {...modalComponent.props} />;
    case modalTypes.SET_REMINDER_MODAL:
      return <SetReminderModal {...modalComponent.props} />;
    case modalTypes.DELETE_REMINDER_MODAL:
      return <DeleteReminderModal {...modalComponent.props} />;
    case modalTypes.BASIC_CONFIRM_MODAL:
      return <BasicConfirmModal {...modalComponent.props} />;
    case modalTypes.PDF_OR_HISTORY_MODAL:
      return <PdfOrHistoryModal {...modalComponent.props} />;
    default:
      return <></>;
  }
};

export default ModalRoot;
