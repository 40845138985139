import * as React from "react";
import CommonWrapper from "../CommonWrapper";
import styles, { Accordion } from "@billfire/toybox";
import { ButtonContainer, StyledButton, StyledCancelButton } from "./styled";
import TermsAndConditions from "../TermsAndConditions";
import DateSelect from "../DateSelect";
import Amount from "./Amount";
import { DateTime } from "luxon";
import { getISODate } from "./utils";
import { useClick2PayForm } from "./hooks";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import {
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../state/entities/paymentMethods/selectors";
import { useSetDate } from "../PaymentDatePicker/hooks";
import { useParamHook } from "../../hooks/useParams";
import { LocationPageWrapper } from "../bits/styled";
import LocationHeaderText from "../LocationHeaderText";
import C2PPaymentMethodSelect from "./C2PPaymentMethodSelect";

const {
  colors: { baseGray7 }
} = styles;

const Click2Pay: React.FC = () => {
  const {
    form,
    validDates,
    checked,
    setChecked,
    isFormValid,
    submitForm,
    updateForm,
    submitting,
    confirmed,
    pathname
  } = useClick2PayForm();
  const { token, vendorId, locationId } = useParamHook();

  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state)
  );

  const _handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid) {
      submitForm();
      localStorage.setItem(
        "lastPaymentMethodId",
        String(selectedPaymentMethod && selectedPaymentMethod.id)
      );
    }
  };

  const selectedDate = form.date;
  const setDate = useSetDate();

  const handleDateChanged = (date: DateTime) => {
    setDate(date);
    updateForm("date", date);
  };

  const provider = useSelector(getSelectedPaymentMethodProvider);

  return confirmed && pathname ? (
    <Redirect
      to={{
        pathname
      }}
    />
  ) : (
    <CommonWrapper>
      <form noValidate>
        <LocationPageWrapper>
          <LocationHeaderText headerText={"Make a Payment for"} />
          <C2PPaymentMethodSelect />
          <Accordion
            title="Date"
            secondaryTitle={
              <span style={{ fontSize: "15px", color: baseGray7 }}>
                {`${selectedDate.month}/${selectedDate.day}/${selectedDate.year}`}
              </span>
            }
            fused
            content={
              <DateSelect
                selectedDate={form.date.toJSDate()}
                onChange={(jsDate: Date) =>
                  handleDateChanged(DateTime.fromJSDate(jsDate))
                }
                filterDate={(jsDate: Date) => {
                  const ISODate = getISODate(jsDate);
                  // eslint-disable-next-line security/detect-object-injection
                  return !!validDates[ISODate];
                }}
              />
            }
            fontSize="15px"
          />
          <Amount />
          <ButtonContainer>
            <TermsAndConditions
              checked={checked}
              setChecked={setChecked}
              provider={provider}
            />
            <StyledButton
              loading={submitting}
              disabled={!isFormValid}
              handleClick={_handleSubmit}
            >
              Confirm and Pay
            </StyledButton>
            <StyledCancelButton
              data-testid="cancel-button"
              href={`/token/${token}/vendors/${vendorId}/locations/${locationId}`}
              rel="noopener noreferrer"
            >
              Cancel
            </StyledCancelButton>
          </ButtonContainer>
        </LocationPageWrapper>
      </form>
    </CommonWrapper>
  );
};

export default Click2Pay;
