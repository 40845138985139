import { DataTable } from "@billfire/toybox";
import styled from "styled-components";
import colors from "@billfire/toybox";

export const ResolutionCenterDataTable = styled(DataTable)`
  width: 100%;
  th {
    color: ${colors.colors.baseGray3};
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
  }
  td {
    color: ${colors.colors.baseGray2};
    padding: 15px;
    font-size: 14px;
  }
`;
export interface ResolutionCenterContainerProps {
  mobile: boolean;
}
export const ResolutionCenterContainer = styled.div.attrs({
  "data-testid": "resolution-center"
})<ResolutionCenterContainerProps>`
  padding-left: ${props => (props.mobile ? "0px" : "40px")};
  padding-right: ${props => (props.mobile ? "0px" : "40px")};
  background-color: white;
`;

export const ErrorContainer = styled.div`
  padding: 40px 25px 20px 25px;
  font-size: 18px;
  text-align: center;
  color: #999999;
`;

export const ErrorText = styled.div`
  padding-bottom: 20px;
`;

export const InvoiceLink = styled.a`
  color: ${colors.colors.baseBlue0};
  text-decoration: underline;
  white-space: nowrap;
  text-align: left;
  max-width: 90%;
  &:hover {
    cursor: pointer;
  }

  @media print {
    color: #4a4a4a;
  }
`;
