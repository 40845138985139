import React from "react";

import { usePublicAnnouncements } from "../LetMeIn/hooks";
import ImageBanner from "./ImageBanner";
import PFGContent from "./PFGContent";
import PFGWrapper from "./PFGWrapper";
import { ContentWrapper } from "./styled";

const PerformanceFoodService: React.FC = () => {
  const { loadAnnouncements, announcements } = usePublicAnnouncements();

  React.useEffect(() => {
    loadAnnouncements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PFGWrapper announcements={announcements}>
      <ContentWrapper>
        <PFGContent />
        <ImageBanner />
      </ContentWrapper>
    </PFGWrapper>
  );
};

export default PerformanceFoodService;
