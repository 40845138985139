import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { paymentGroupTypes as types } from "./index";
import { PaymentGroup } from "./types";

export const initialState: types.PaymentGroupsState = {
  byId: {},
  loadingPaymentGroups: false,
  loadedPaymentGroups: false,
  loadPaymentGroupsFailed: false
};

export const paymentGroupsSlice = createSlice({
  name: "paymentGroups",
  initialState,
  reducers: {
    loadPaymentGroupsSubmit: (state: types.PaymentGroupsState) => {
      state.loadingPaymentGroups = true;
      state.loadedPaymentGroups = false;
      state.loadPaymentGroupsFailed = false;
    },
    loadPaymentGroupsSuccess: (
      state: types.PaymentGroupsState,
      action: PayloadAction<Record<string, types.PaymentGroup>>
    ) => {
      state.loadingPaymentGroups = false;
      state.loadedPaymentGroups = true;
      state.loadPaymentGroupsFailed = false;
      state.byId = action.payload;
    },
    loadPaymentGroupsFailed: (state: types.PaymentGroupsState) => {
      state.loadingPaymentGroups = false;
      state.loadedPaymentGroups = false;
      state.loadPaymentGroupsFailed = true;
    },
    resetStore: () => initialState
  }
});

export const {
  loadPaymentGroupsSubmit,
  loadPaymentGroupsSuccess,
  loadPaymentGroupsFailed,
  resetStore
} = paymentGroupsSlice.actions;

export default paymentGroupsSlice.reducer;
