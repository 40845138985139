enum InvoiceColumnName {
  displayInvNum = "displayInvNum",
  externalInvoiceDate = "externalInvoiceDate",
  dueDate = "dueDate",
  openAmount = "openAmount"
}

export const isInvoiceColumnName = (
  colName?: string | null
): colName is InvoiceColumnName => {
  return Object.values(InvoiceColumnName).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    colName as unknown as InvoiceColumnName
  );
};
