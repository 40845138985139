import * as React from "react";
import { useParamHook } from "../../hooks/useParams";
import { ButtonContainer, StyledButton, StyledCancelButton } from "./styled";

export interface FormButtonsProps {
  updateInvoice: Function;
  disabled: boolean;
}

export const DisputedInvoicesFormButtons: React.FC<FormButtonsProps> = ({
  updateInvoice,
  disabled
}) => {
  const { token, vendorId, locationId } = useParamHook();
  return (
    <div>
      <ButtonContainer>
        <StyledButton
          handleClick={() => updateInvoice()}
          disabled={disabled}
          loading={false}
          type="submit"
        >
          Apply
        </StyledButton>
      </ButtonContainer>
      <ButtonContainer>
        <span>
          <StyledCancelButton
            data-testid="cancel-button"
            href={`/token/${token}/vendors/${vendorId}/locations/${locationId}/click2pay`}
            rel="noopener noreferrer"
          >
            Cancel
          </StyledCancelButton>
        </span>
      </ButtonContainer>
    </div>
  );
};

export default DisputedInvoicesFormButtons;
