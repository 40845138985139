import * as React from "react";
import { useTextChop } from "./hooks";

interface PrefixEllipsisProps {
  text: string;
  minEllipsesWidth?: number;
}

const PrefixEllipsis: React.FC<PrefixEllipsisProps> = ({
  text,
  minEllipsesWidth
}) => {
  const { chop, ref } = useTextChop(text, minEllipsesWidth);
  const newText = chop === 0 ? text : `...${text.substring(chop)}`;
  return (
    <span
      style={{
        width: "90%",
        overflow: "scroll",
        whiteSpace: "nowrap"
      }}
      ref={ref}
    >
      {newText}
    </span>
  );
};

export default PrefixEllipsis;
