import { formatCurrency } from "@billfire/toybox";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { PaymentPlanConfirmationData } from "../../state/entities/paymentPlans/types";
import { formatFrequency } from "../../state/entities/paymentPlans/utils";
import DetailRow from "./DetailRow";
import PaymentDetailRowServiceFee from "./PaymentDetailRowServiceFee";

const PaymentDetailRow: React.FC = () => {
  const { state } = useLocation<PaymentPlanConfirmationData>();
  const { paymentPlan, paymentMethod } = state;

  return paymentMethod.displayExtra.serviceFee ? (
    <PaymentDetailRowServiceFee />
  ) : (
    <DetailRow
      titleText={`${formatFrequency(paymentPlan.frequency)} Payment`}
      detailText={formatCurrency(paymentPlan.eachAmount)}
    />
  );
};

export default PaymentDetailRow;
