import * as React from "react";
import { FooterContainer, FooterText, StyledSup } from "./CommonWrapper.styled";

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterText>
        Powered by Billfire<StyledSup>&reg;</StyledSup> VALET
      </FooterText>
      <FooterText data-testid="footer-text">
        &copy; {new Date().getFullYear()} Billfire, LLC. All rights reserved.
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
