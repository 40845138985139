import * as React from "react";
import { CheckboxStates, MultiStateCheckbox } from "@billfire/toybox";
import { useRemitForm } from ".";

const SelectAllCheckBox: React.FC = () => {
  const { sortedInvoices, toggleAllInvoicesSelected } = useRemitForm();

  const checked = sortedInvoices.filter(inv => inv.selected).length;

  const state =
    checked === 0
      ? CheckboxStates.none
      : checked === sortedInvoices.length
      ? CheckboxStates.checked
      : CheckboxStates.minus;

  const clickHandler = () => {
    toggleAllInvoicesSelected();
  };

  return (
    <MultiStateCheckbox
      data-testid="select-all-invoices"
      id="selectAll"
      onClick={clickHandler}
      state={state}
    />
  );
};

export default SelectAllCheckBox;
