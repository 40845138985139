import styled from "styled-components";
import styles, { Button } from "@billfire/toybox";
import { device } from "../../../breakpoints";
const {
  colors: { baseGray7 }
} = styles;

export const Divider = styled.div`
  width: 100%;
  height: 1.8px;
  margin: 0 0px 21.8px 0;
  opacity: 0.3;
  background-color: #e4e4e4;
`;

export const MoreText = styled.div`
  width: 285px;
  height: 36px;
  margin: auto auto auto 0px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.09px;
  text-align: left;
  color: ${baseGray7};
`;

export const MoreContainer = styled.div`
  display: flex;
  border-top: 1px solid rgba(204, 204, 204, 0.3);
  margin-top: 21px;
  padding-top: 21px;
  padding-bottom: 21px;
  @media only screen and (${device.tablet}) {
    flex-direction: column;
  }
`;

export const StyledMoreButton = styled(Button)`
  width: 100%;
  max-width: 218px;
  margin: auto 0px auto auto;

  @media only screen and (${device.tablet}) {
    max-width: inherit;
    margin-top: 20px;
  }
`;
