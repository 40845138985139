import React from "react";
import { HiddenInput } from "./styled";

export interface DayProps {
  id: string;
  name: string;
  value: number;
  htmlFor: string;
  checked: boolean;
  handleChange: (e: { target: { checked: boolean; value: string } }) => void;
  children: React.ReactChild;
  className?: string;
}

export const Day: React.FC<DayProps> = ({
  id,
  name,
  value,
  htmlFor,
  checked,
  handleChange,
  children,
  className
}) => {
  const classNames = className ? className : "";

  return (
    <>
      <label htmlFor={htmlFor} className={`day ${classNames}`}>
        <HiddenInput
          value={value}
          type="radio"
          name={name}
          id={id}
          checked={checked}
          onChange={handleChange}
        />
        {children}
      </label>
    </>
  );
};

export default Day;
