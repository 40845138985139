import * as React from "react";
import { loadPaymentGroupDetails } from "./utils";
import { PaymentGroupDetail } from "./types";
import { useParamHook } from "../../hooks/useParams";

export const usePaymentDetailsLoader = () => {
  const { token, vendorId, locationId, paymentGroupId } = useParamHook();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadFailed, setLoadFailed] = React.useState<boolean>(false);
  const [paymentGroupDetail, setPaymentGroupDetail] = React.useState<
    PaymentGroupDetail | undefined
  >(undefined);

  React.useEffect(() => {
    const local = async (): Promise<void> => {
      await loadPaymentGroupDetails(token, vendorId, locationId, paymentGroupId)
        .then(res => {
          setPaymentGroupDetail(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setLoadFailed(true);
        });
    };
    local();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading, loadFailed, paymentGroupDetail };
};

export const useLocationDashboardPath = () => {
  const { token, vendorId, locationId } = useParamHook();
  const pathname = `/token/${token}/vendors/${vendorId}/locations/${locationId}`;
  return { pathname };
};
