export const moreInvoicesSentence = (moreInvoices: number): string => {
  return `You have ${moreInvoices} more ${
    moreInvoices === 1 ? "invoice" : "invoices"
  } that you can pay.`;
};

export const morePastDueSentence = (
  moreInvoices: number,
  morePastDue: number
): string => {
  if (morePastDue === 0) {
    return "";
  }

  if (morePastDue === 1 && moreInvoices === 1) {
    return "That invoice is past due.";
  }

  const verb = morePastDue === 1 ? "is" : "are";

  return `${
    moreInvoices === morePastDue ? "All " : ""
  }${morePastDue} of those invoices ${verb} past due.`;
};
