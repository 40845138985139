import * as React from "react";
import { isPastDue } from "../../../state/entities/invoices/utils";
import { useVendor } from "../../Locations/hooks";
import { CustomTableColumn } from "./styled";
import { isRemitInvoice, RenderComponentProps } from "../types";

const RemitInvoiceDueDate: React.FC<RenderComponentProps> = ({
  value,
  item
}) => {
  const vendor = useVendor();

  if (!isRemitInvoice(item)) {
    return null;
  }

  return (
    <CustomTableColumn isPastDue={isPastDue(item, vendor)}>
      {value}
    </CustomTableColumn>
  );
};

export default RemitInvoiceDueDate;
