import { useSelector } from "react-redux";
import { getInvoiceById } from "../state/entities/invoices/selectors";
import { Invoice } from "../state/entities/invoices/types";
import { StoreType } from "../state/types";
import { useParamHook } from "./useParams";

export const useInvoiceFromPath = (): Invoice => {
  const { invoiceId } = useParamHook();
  return useSelector((state: StoreType) => getInvoiceById(state, invoiceId));
};
