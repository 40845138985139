// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { sortCurrency, sortString, formatCurrency } from "@billfire/toybox";
import { PaymentPlan } from "../../state/entities/paymentPlans/types";
import { formatFrequency } from "../../state/entities/paymentPlans/utils";
import { StyledLink } from "./styled";
import { SortBys } from "./types";

export const initalPaymentPlanSortBysData: SortBys = {
  active: {
    sortBy: "paymentPlanName",
    sortDirection: "ASC"
  },
  complete: {
    sortBy: "paymentPlanName",
    sortDirection: "ASC"
  },
  canceled: {
    sortBy: "paymentPlanName",
    sortDirection: "ASC"
  }
};

export interface PaymentPlanRow extends PaymentPlan {
  rowId: string;
  paidSoFar: number;
}

export const columns = (
  token: string,
  vendorId: string,
  locationId: string
) => {
  const path = `/token/${token}/vendors/${vendorId}/locations/${locationId}/plans`;
  return [
    {
      name: "name",
      label: "Plan Name",
      width: "20%",
      sortFn: sortString,
      render: (name: string, item: PaymentPlanRow) => (
        <StyledLink to={() => `${path}/${item.id}`} title={item.name}>
          {item.name}
        </StyledLink>
      )
    },
    {
      name: "totalAmount",
      label: "Total",
      width: "20%",
      formatFn: formatCurrency,
      sortFn: sortCurrency
    },
    {
      name: "eachAmount",
      label: "Payment",
      width: "20%",
      formatFn: formatCurrency,
      sortFn: sortCurrency
    },
    {
      name: "paidSoFar",
      label: "Paid So Far",
      width: "20%",
      formatFn: formatCurrency,
      sortFn: sortCurrency,
      mobile: false
    },
    {
      name: "frequency",
      label: "Schedule",
      formatFn: formatFrequency,
      sortFn: sortString,
      width: "20%"
    }
  ];
};

export const consolidatePaymentPlansByStatus = (
  paymentPlans: PaymentPlanRow[]
) => {
  return paymentPlans.reduce(
    (
      acc: {
        [key: string]: PaymentPlanRow[];
        active: PaymentPlanRow[];
        complete: PaymentPlanRow[];
        canceled: PaymentPlanRow[];
      },
      curr
    ) => {
      if (acc[curr.status]) {
        acc[curr.status].push(curr);
      }
      return acc;
    },
    {
      active: [],
      complete: [],
      canceled: []
    }
  );
};

const formatCSVRow = (planRow: PaymentPlanRow) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rowId, ...rest } = planRow;
  return {
    ...rest,
    totalAmount: formatCurrency(rest.totalAmount),
    paidSoFar: formatCurrency(rest.paidSoFar),
    eachAmount: formatCurrency(rest.eachAmount),
    frequency:
      rest.frequency.substring(0, 1).toUpperCase() +
      rest.frequency.substring(1),
    status: rest.status.substring(0, 1).toUpperCase() + rest.status.substring(1)
  };
};

export const generateCSVExport = (plans: {
  active: PaymentPlanRow[];
  complete: PaymentPlanRow[];
  canceled: PaymentPlanRow[];
}) => {
  return [
    ...plans.active.map(formatCSVRow),
    ...plans.complete.map(formatCSVRow),
    ...plans.canceled.map(formatCSVRow)
  ];
};
