import * as React from "react";
import { useRemitForm } from "..";
import {
  FormCommentPrintArea,
  FormCommentPrintBox,
  FormCommentsLabel
} from "./styled";
import FormCommentsTextArea from "./FormCommentsTextArea";

const FormComments: React.FC = () => {
  const { formComment } = useRemitForm();

  return (
    <>
      <FormCommentsTextArea />
      <FormCommentPrintArea>
        <FormCommentsLabel>Comments:</FormCommentsLabel>
        <FormCommentPrintBox data-testid="form-comment-print">
          {formComment}
        </FormCommentPrintBox>
      </FormCommentPrintArea>
    </>
  );
};

export default FormComments;
