import * as React from "react";
import PrintExportComponent from "../PrintExportComponent";
import { PrintComponentContainer } from "./styled";

interface ConfirmationPagePrintExportProps {
  printRef: React.RefObject<HTMLDivElement>;
  documentTitle: string;
}
const ConfirmationPagePrintExport: React.FC<
  ConfirmationPagePrintExportProps
> = ({ printRef, documentTitle }) => {
  return (
    <PrintComponentContainer>
      <PrintExportComponent printRef={printRef} documentTitle={documentTitle} />
    </PrintComponentContainer>
  );
};

export default ConfirmationPagePrintExport;
