// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import validator from "validator";
import { DateTime } from "luxon";
import {
  DisputedInvoice,
  Invoice,
  SetDispute
} from "../state/entities/invoices/types";
import { Dispatch } from "redux";
import { setDispute } from "../state/entities/invoices/slice";
import { StyledA } from "./styled.components";
import PrefixEllipsis from "../components/PrefixEllipsis";
import { invoiceTypes } from "../state/entities/invoices";
import { formatShortDate } from "@billfire/toybox";
import { PaymentDetail } from "../pages/PaymentDetailPage/types";

export const isValidSmsOrEmail = (val: string) => {
  return (
    (validator.isMobilePhone(val) && val.length >= 10) || validator.isEmail(val)
  );
};

export const getSimpleTime = (journeyUpdatedAt: string) => {
  const date = DateTime.fromISO(journeyUpdatedAt);
  return date.toLocaleString(DateTime.TIME_SIMPLE);
};

export const getShortTime = (journeyUpdatedAt: string) => {
  const date = DateTime.fromISO(journeyUpdatedAt);
  return date.toLocaleString(DateTime.DATE_SHORT);
};

export class Debouncer {
  timeout?: NodeJS.Timeout;

  debounce(func: () => void, wait: number, dontWait?: boolean) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (dontWait) {
      func();
    } else {
      this.timeout = setTimeout(func, wait);
    }
  }
}

export const getVendorIdFromPath = (pathName: string): string => {
  const pathArray = pathName.split("/");
  const vendorIndex = pathArray.findIndex(path => path === "vendors");
  return vendorIndex !== -1 ? pathArray[vendorIndex + 1] : "";
};

export const updateInvoice = (
  reason: string | undefined,
  notes: string,
  invoice: Invoice,
  newAmount: number,
  dispatch: Dispatch,
  redirect: Function,
  path: string
) => {
  if (reason) {
    const disputedInvoice: DisputedInvoice = {
      reason,
      notes,
      newAmount,
      ...invoice
    };
    const setDisputeEntity: SetDispute = { disputedInvoice };
    dispatch(setDispute(setDisputeEntity));
    redirect(path);
  }
};

export const translatePowerPayType = (paymentType: string): string => {
  switch (paymentType) {
    case "manual":
      return "Portal";
    case "click2pay":
      return "Click2Pay";
    case "paymentplan":
      return "Payment Plan";
    case "autopay":
      return "Autopay Plus";
    default:
      return paymentType;
  }
};

export const invoiceExternalUrlRender = (
  displayInvNum: string,
  invoice: Invoice
) => {
  const { externalUrl } = invoice;

  return externalUrl ? (
    <StyledA target="_blank" href={externalUrl}>
      <PrefixEllipsis text={displayInvNum} />
    </StyledA>
  ) : (
    <PrefixEllipsis text={displayInvNum} />
  );
};

export const getISODate = (date: Date): string => {
  const ISODate = date.toISOString().split("T")[0];
  return ISODate;
};

export const locationPath = (oldPath: string, newPath: string): string => {
  const path = oldPath.split("/");

  const parentPath = path.slice(0, path.indexOf("locations") + 2).join("/");
  return `${parentPath}/${newPath}`;
};

export const getExternalInvoiceDateDisplay = (
  invoice: invoiceTypes.Invoice | PaymentDetail
) =>
  invoice.externalInvoiceDate
    ? formatShortDate(invoice.externalInvoiceDate)
    : "-";

export const replacePath = (oldPath: string, newPath: string): string => {
  const parentPath = oldPath.split("/").slice(0, -1).join("/");

  return `${parentPath}/${newPath}`;
};
