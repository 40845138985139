import * as React from "react";
import { useRemitForm } from "../";
import { getEmptyRemitInvoice, isRemitInvoice } from "../types";
import {
  CommentsLabel,
  CommentsTextAreaContainer,
  CommentsText,
  InvoiceCommentContainer,
  PrintedCommentsContainer
} from "./styled";
import { TextArea } from "@billfire/toybox";
import { showComments } from "../utils";
import { useMobile } from "../../../hooks/useMobile";

interface CommentsProps {
  rowId: string;
}

const Comments: React.FC<CommentsProps> = ({ rowId }) => {
  const { sortedInvoices, setInvoiceComment } = useRemitForm();
  const invoice =
    sortedInvoices.find(inv => inv.rowId === rowId) || getEmptyRemitInvoice();

  const renderComment = showComments(invoice);

  const isBlank = !isRemitInvoice(invoice);

  const { mobile } = useMobile();

  return (
    <InvoiceCommentContainer {...{ isBlank, mobile }}>
      <PrintedCommentsContainer isBlank={isBlank}>
        <CommentsLabel className={isBlank ? "blank-label" : ""}>
          Comments:
        </CommentsLabel>
        <CommentsText data-testid={`comment-print-text-${rowId}`}>
          {invoice.comment}
        </CommentsText>
      </PrintedCommentsContainer>
      {renderComment && (
        <CommentsTextAreaContainer>
          <TextArea
            id={`comment-${rowId}`}
            name={`comment-${rowId}`}
            data-testid={`comment-${rowId}`}
            className="invoiceComment"
            initialValue={invoice.comment}
            label="Description"
            autoFocus={false}
            maxLength={250}
            rows={2}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInvoiceComment(invoice.id, e.target.value);
            }}
            allowNewLines={false}
          />
        </CommentsTextAreaContainer>
      )}
    </InvoiceCommentContainer>
  );
};

export default Comments;
