// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import CommonWrapper from "../CommonWrapper";
import { LocationPageWrapper } from "../bits/styled";
import LocationHeaderText from "../LocationHeaderText";
import { Header, ThankYouHeader } from "./styled.components";
import CheckIcon from "../../assets/checked.svg";
import DisputeConfirmationBody from "./DisputeConfirmationBody";

const AllDisputedConfirmationView = () => {
  return (
    <CommonWrapper>
      <LocationPageWrapper>
        <LocationHeaderText headerText={"Payment Scheduled for"} />
        <Header>
          <CheckIcon />
          <ThankYouHeader>Thank you!</ThankYouHeader>
          <DisputeConfirmationBody />
        </Header>
      </LocationPageWrapper>
    </CommonWrapper>
  );
};

export default AllDisputedConfirmationView;
