import React from "react";
import { useModal } from "../index";
import { Modal, ModalSize, ModalType } from "@billfire/toybox";
import { StyledMessage } from "../modals.styled";

interface BasicGrayModalProps {
  message: string;
  onClose?: () => void;
  size?: ModalSize;
}

const BasicGrayModal: React.FC<BasicGrayModalProps> = ({
  message,
  onClose,
  size = ModalSize.small
}) => {
  const { hideModal } = useModal();
  return (
    <Modal
      type={ModalType.gray}
      size={size}
      onClose={onClose || hideModal}
      loading={false}
      errored={false}
    >
      <StyledMessage>{message}</StyledMessage>
    </Modal>
  );
};

export default BasicGrayModal;
