import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedPaymentMethod } from "../../../state/entities/paymentMethods/selectors";
import { setSelectedPaymentMethod } from "../../../state/entities/paymentMethods/slice";
import { PaymentMethod } from "../../../state/entities/paymentMethods/types";
import { StoreType } from "../../../state/types";
import PaymentMethodDelete from "../PaymentMethodDelete/index";
import IconContainer from "./IconContainer";
import { PaymentMethodText, StyledRow } from "./styled.components";
import PendingVerification from "../PaymentMethodTitle/PendingVerification";

export interface PaymentMethodRowProps {
  paymentMethod: PaymentMethod;
  onlyUseSelectedPmFromRedux: boolean;
}

const PaymentMethodRow: React.FC<PaymentMethodRowProps> = ({
  paymentMethod,
  onlyUseSelectedPmFromRedux
}) => {
  const dispatch = useDispatch();

  const selectedPaymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, onlyUseSelectedPmFromRedux)
  );
  const [checkPaymentsLoading, setCheckPaymentsLoading] = useState(false);

  const { id, displayExtra } = paymentMethod;
  const { name, lastFour, verificationRequired } = displayExtra;

  return (
    <StyledRow
      key={id}
      selected={id === selectedPaymentMethod?.id}
      onClick={() =>
        dispatch(setSelectedPaymentMethod({ paymentMethodId: id }))
      }
    >
      <IconContainer
        paymentMethod={paymentMethod}
        onlyUseSelectedPmFromRedux={onlyUseSelectedPmFromRedux}
      />
      <PaymentMethodText verificationRequired={verificationRequired}>
        {`${name} ${lastFour}`}
        {verificationRequired && <PendingVerification />}
      </PaymentMethodText>
      <PaymentMethodDelete
        name={name}
        lastFour={lastFour}
        checkPaymentsLoading={checkPaymentsLoading}
        setCheckPaymentsLoading={setCheckPaymentsLoading}
      />
    </StyledRow>
  );
};

export default PaymentMethodRow;
