import React, { useEffect, useState, createContext } from "react";
import { useLocation, useRouteMatch } from "react-router";
import { RouteContextType } from "./types";

const context: RouteContextType = { to: "", from: "", history: [] };

export const RouteContext = createContext(context);
export const useRouteContext = () => React.useContext(RouteContext);
export const RouteContextProvider: React.FC<{}> = ({ children }) => {
  const location = useLocation();
  const [route, setRoute] = useState<RouteContextType>(
    (window.sessionStorage["valet-route-context"] &&
      JSON.parse(window.sessionStorage["valet-route-context"])) || {
      to: location.pathname,
      from: "",
      history: [location.pathname]
    }
  );

  const dashboardMatch = useRouteMatch(
    "/token/:token/vendors/:vendorId/locations/:locationId"
  );

  useEffect(() => {
    if (location.pathname !== route.to) {
      if (dashboardMatch?.isExact) {
        route.history = [];
      }

      while (route.history.includes(location.pathname)) {
        route.history.pop();
      }

      route.history.push(location.pathname);
      setRoute({
        to: location.pathname,
        from: route.to,
        history: route.history
      });
      window.sessionStorage.setItem(
        "valet-route-context",
        JSON.stringify({
          to: location.pathname,
          from: route.to,
          history: route.history
        })
      );
    }
  }, [dashboardMatch, location, route]);

  return (
    <RouteContext.Provider value={route}>{children}</RouteContext.Provider>
  );
};
