/* eslint-disable @typescript-eslint/no-explicit-any */
import { getReplayActions, isUserAction } from "./selectors";
import { StoreType } from "../../types";
import { startReplay, stopReplay, resetPersistedTokenState } from "./slice";
import { EMPTY, from, Observable, of } from "rxjs";
import {
  map,
  concatAll,
  filter,
  exhaustMap,
  first,
  mergeMap
} from "rxjs/operators";
import { PayloadAction } from "@reduxjs/toolkit";
import { ofType, StateObservable } from "redux-observable";
import { undoInvoiceReplayedActions } from "../invoices/slice";
import { getAuthToken, getShared } from "../shared/selectors";
import { setToken } from "../shared/slice";
import { selectVendor } from "../vendors/slice";
import { selectLocation } from "../locations/slice";
import { getSelectedVendorId, getVendorsLoadState } from "../vendors/selectors";
import {
  getLocationsLoadState,
  getSelectedLocationId
} from "../locations/selectors";
import {
  getHasEverDispatchedSavedActions,
  hasAllValuesNeeded
} from "./selectors";
import { ReplayType, ResetCurrentTokenAction } from "./types";
import {
  isOnAutopayManagePage,
  isOnAutopaySetupPage,
  isOnReplayablePage
} from "./utils";
import { getRequestLoadingFlags } from "../requests/selectors";
import { getInvoicesLoadState } from "../invoices/selectors";
import { getPaymentMethodsLoading } from "../paymentMethods/selectors";
import { isLoading } from "../../../pages/AutopaySetupPage/utils";
import { isLoading as isLoadingAutopayManage } from "../../../pages/AutopayManagePage/utils";
import { getAutopayLoadingState } from "../autopay/selectors";

const runReplayActions = (
  state$: StateObservable<StoreType>,
  type = ReplayType.none
) => {
  const token = getAuthToken(state$.value);
  const selectedLocationId = getSelectedLocationId(state$.value);
  const selectedVendorId = getSelectedVendorId(state$.value);

  const existingActions = getReplayActions(
    state$.value.persisted,
    token,
    selectedVendorId,
    selectedLocationId,
    type
  );
  const replayActions = [startReplay(), ...existingActions, stopReplay()];

  return from(replayActions);
};

export const replayEpic = (
  action$: Observable<PayloadAction<{ [key: string]: string }>>,
  state$: StateObservable<StoreType>
) =>
  action$.pipe(
    ofType(selectVendor.type, selectLocation.type, setToken.type),
    filter(
      () =>
        isUserAction(state$.value) &&
        hasAllValuesNeeded(state$.value) &&
        !getHasEverDispatchedSavedActions(state$.value) &&
        isOnReplayablePage()
    ),
    map(() => {
      return runReplayActions(state$);
    }),
    concatAll()
  );

const _isAutopayLoading = (state: StoreType) => {
  const { loadingRequest } = getRequestLoadingFlags(state);
  const { loadingInvoices } = getInvoicesLoadState(state);
  const paymentMethodsLoading = getPaymentMethodsLoading(state);
  const { loadedVendors } = getVendorsLoadState(state);
  const { locationsLoading } = getLocationsLoadState(state);
  const { loadedConfiguration } = getShared(state);

  return isLoading(
    loadingRequest,
    locationsLoading,
    loadingInvoices,
    paymentMethodsLoading,
    loadedVendors,
    loadedConfiguration
  );
};

const _isAutopayManageLoading = (state: StoreType) => {
  const { loadingInvoices } = getInvoicesLoadState(state);
  const paymentMethodsLoading = getPaymentMethodsLoading(state);
  const { loadedVendors } = getVendorsLoadState(state);
  const { locationsLoading } = getLocationsLoadState(state);
  const { loadingAutopay } = getAutopayLoadingState(state);

  return isLoadingAutopayManage(
    loadingAutopay,
    locationsLoading,
    loadingInvoices,
    paymentMethodsLoading,
    loadedVendors
  );
};

export const replayAutopaySetupActions = (
  action$: Observable<PayloadAction<{ [key: string]: string }>>,
  state$: StateObservable<StoreType>
) =>
  action$.pipe(
    filter(
      () =>
        isUserAction(state$.value) &&
        hasAllValuesNeeded(state$.value) &&
        _isAutopayLoading(state$.value) &&
        isOnAutopaySetupPage()
    ),
    exhaustMap(action => {
      const state = state$.value;
      const autopayLoading = _isAutopayLoading(state);

      return !autopayLoading
        ? of(action)
        : state$.pipe(
            mergeMap((state: any): any => {
              const autopayLoading = _isAutopayLoading(state);
              return !autopayLoading ? of(action) : EMPTY;
            }),
            first()
          );
    }),
    map(() => {
      return runReplayActions(state$, ReplayType.request);
    }),
    concatAll()
  );

export const replayAutopayManageActions = (
  action$: Observable<PayloadAction<{ [key: string]: string }>>,
  state$: StateObservable<StoreType>
) =>
  action$.pipe(
    filter(
      () =>
        isUserAction(state$.value) &&
        hasAllValuesNeeded(state$.value) &&
        _isAutopayManageLoading(state$.value) &&
        isOnAutopayManagePage()
    ),
    exhaustMap(action => {
      const state = state$.value;
      const autopayLoading = _isAutopayManageLoading(state);

      return !autopayLoading
        ? of(action)
        : state$.pipe(
            mergeMap((state: any): any => {
              const autopayLoading = _isAutopayManageLoading(state);
              return !autopayLoading ? of(action) : EMPTY;
            }),
            first()
          );
    }),
    map(() => {
      return runReplayActions(state$, ReplayType.autopay);
    }),
    concatAll()
  );

export const clearUserSelectionsFromStore = (
  action$: Observable<PayloadAction<ResetCurrentTokenAction>>
) =>
  action$.pipe(
    ofType(resetPersistedTokenState.type),
    map(() => undoInvoiceReplayedActions())
  );
