import { useDispatch } from "react-redux";
import { setSelectedPaymentDate } from "../../state/entities/requests/slice";
import { DateTime } from "luxon";
import { getISODate } from "../../utils";

export const useSetDate = () => {
  const dispatch = useDispatch();
  return (date: DateTime) => {
    const formattedDate = getISODate(date.toJSDate());
    dispatch(setSelectedPaymentDate(formattedDate));
  };
};
