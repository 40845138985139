import React from "react";

import { Accordion } from "@billfire/toybox";
import AddPaymentMethodLink from "../AddPaymentMethodLink/index";
import PaymentMethodSelect from "../PaymentMethodSelect";
import PaymentMethodTitle from "../PaymentMethodSelect/PaymentMethodTitle";
import { getPaymentMethods } from "../../state/entities/paymentMethods/selectors";
import { useSelector } from "react-redux";
import { StyledAccordionTitle } from "./styled";
import { useMobile } from "../../hooks/useMobile";
import { PaymentPlan } from "../../state/entities/paymentPlans/types";

export interface PPUPaymentMethodSelectProps {
  paymentPlan: PaymentPlan;
}

const PPUPaymentMethodSelect: React.FC<PPUPaymentMethodSelectProps> = ({
  paymentPlan
}) => {
  const hasPaymentMethods = !!useSelector(getPaymentMethods).length;
  const { mobile } = useMobile();

  return (
    <>
      {hasPaymentMethods ? (
        <Accordion
          title={<StyledAccordionTitle>Payment Method</StyledAccordionTitle>}
          secondaryTitle={
            <PaymentMethodTitle
              onlyUseSelectedPmFromRedux={true}
              paymentMethodOverride={paymentPlan.paymentMethod}
            />
          }
          headingPadding={mobile ? "15px 0px" : "15px 17px 15px 14px"}
          content={<PaymentMethodSelect onlyUseSelectedPmFromRedux={true} />}
          fontSize="15px"
          titleWeight="500"
          fused
          accordionWidth="100%"
        />
      ) : (
        <AddPaymentMethodLink {...{ hasPaymentMethods }} />
      )}
    </>
  );
};

export default PPUPaymentMethodSelect;
