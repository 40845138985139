import * as React from "react";
import PaymentPlanUpdate from "../../components/PaymentPlanUpdate";
import SkeletonLoader from "../../components/SkeletonLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";
import { usePaymentPlansLoader } from "../../hooks/usePaymentPlansLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { usePaymentMethodsLoading } from "../../hooks/usePaymentMethodsLoading";

const PaymentPlanUpdatePage: React.FC = () => {
  const { paymentPlansLoadingState } = usePaymentPlansLoader();
  const { loadingVendors } = useVendorsLoader();
  const { locationsLoading } = useLocationsLoader();
  const paymentMethodsLoading = usePaymentMethodsLoading();

  return paymentPlansLoadingState.loadingPaymentPlans ||
    loadingVendors ||
    locationsLoading ||
    paymentMethodsLoading ? (
    <SkeletonLoader />
  ) : (
    <PaymentPlanUpdate />
  );
};

export default PaymentPlanUpdatePage;
