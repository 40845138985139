import { formatCurrency } from "@billfire/toybox";
import currency from "currency.js";
import { Invoice } from "../../state/entities/invoices/types";

export const validateNewAmount = (
  e: React.ChangeEvent<HTMLInputElement>,
  invoice: Invoice,
  setNewAmount: Function,
  setNewAmountDirtyBit: Function,
  setNewAmountError: Function
) => {
  const amount = e.target.value;

  if (!/[0-9]+\.?[0-9]*$/.test(amount)) {
    setNewAmountError("New amount must be formatted in dollars and cents.");
  } else if (Number(amount) <= 0) {
    setNewAmountError("New amount cannot be $0 or negative.");
  } else if (Number(amount) >= Number(invoice.openAmount)) {
    setNewAmountError(
      "New amount cannot be greater than or equal to than the original amount."
    );
  }

  const curr = new currency(amount);
  setNewAmount(curr.value);
  setNewAmountDirtyBit(false);
};

export const isNewAmountValid = (
  newAmount: number,
  invoice: Invoice
): boolean => {
  const newAmountNumber = Number(newAmount);
  return newAmountNumber > 0.0 && newAmountNumber < Number(invoice.openAmount);
};

export const isNewAmountInputValid = (
  newAmountIsDirty: boolean,
  newAmount: number,
  invoice: Invoice
): boolean => {
  return newAmountIsDirty ? true : isNewAmountValid(newAmount, invoice);
};

export const calcBalance = (invoice: Invoice, newAmount: number) =>
  formatCurrency(currency(invoice.openAmount).subtract(newAmount).value);
