import React, { useEffect, useState } from "react";
import { useModal } from "../index";
import { Modal, ModalSize, ModalType } from "@billfire/toybox";
import { WhiteStyledMessage } from "../modals.styled";
import { useSelector } from "react-redux";
import { getAutopayDeletingState } from "../../state/entities/autopay/selectors";

export interface ConfirmAutopayDeleteModalProps {
  title: string;
  message: string | JSX.Element;
  onClose?: () => void;
  actions?: {
    submitButtonText?: string;
    cancelButtonText?: string;
    onSubmit: () => void;
  };
}

const ConfirmAutopayDeleteModal: React.FC<ConfirmAutopayDeleteModalProps> = ({
  title,
  message,
  onClose,
  actions
}) => {
  const { hideModal } = useModal();
  const showActions = !!actions;
  const { deleting, deleted } = useSelector(getAutopayDeletingState);
  const [startedDeleting, setStartedDeleting] = useState(deleting);
  const [error, setError] = useState(false);

  useEffect(() => {
    deleting && setStartedDeleting(true);
    if (deleted && startedDeleting) {
      hideModal();
      setStartedDeleting(false);
    } else if (startedDeleting && !deleting) setError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted, deleting]);

  return (
    <Modal
      title={title}
      type={ModalType.form}
      size={ModalSize.small}
      onClose={onClose || hideModal}
      loading={deleting}
      errored={error}
      errorMessage="Could not turn off AutoPay Plus."
      hideActions={!showActions}
      submitButtonText={actions?.submitButtonText}
      cancelButtonText={actions?.cancelButtonText}
      onSubmit={actions?.onSubmit}
    >
      <WhiteStyledMessage>{message}</WhiteStyledMessage>
    </Modal>
  );
};

export default ConfirmAutopayDeleteModal;
