import * as React from "react";
import CommonWrapper from "../../components/CommonWrapper";
import {
  useGetPaymentPlan,
  useInitSelectedPaymentMethod,
  useCancelNavigation,
  usePaymentPlanSubmit,
  useDecidedChangedValidPaymentMethod
} from "./hooks";
import {
  LeftColumn,
  RightColumn,
  Row,
  Divider
} from "../PaymentPlanDetail/styled";
import TermsAndConditions from "../TermsAndConditions";
import {
  StyledButton,
  ButtonHeaderText,
  ErrorText,
  DisplayContainer,
  SubmitContainer,
  TermsConditionsContainer,
  ButtonContainer
} from "./styled";
import { buttonIsDisabled } from "./utils";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import {
  getSelectedPaymentMethod,
  getSelectedPaymentMethodProvider
} from "../../state/entities/paymentMethods/selectors";
import {
  getPaymentPlansUpdating,
  getPaymentPlansUpdateFailed
} from "../../state/entities/paymentPlans/selectors";
import LocationHeaderText from "../LocationHeaderText";
import PPUPaymentMethodSelect from "./PPUPaymentMethodSelect";

export const PaymentPlanUpdate: React.FC = () => {
  const paymentPlan = useGetPaymentPlan();
  useInitSelectedPaymentMethod(paymentPlan);
  const cancelNav = useCancelNavigation();
  const provider = useSelector(getSelectedPaymentMethodProvider);
  const paymentMethodHasChangedAndValid =
    useDecidedChangedValidPaymentMethod(paymentPlan);
  const { updating, failed } = useSelector((state: StoreType) => ({
    selectedPaymentMethod: getSelectedPaymentMethod(state),
    updating: getPaymentPlansUpdating(state),
    failed: getPaymentPlansUpdateFailed(state)
  }));
  const {
    checked,
    setChecked,
    confirmationData,
    handleSubmit,
    confirmed,
    pathname
  } = usePaymentPlanSubmit();

  return confirmed ? (
    <Redirect
      to={{
        pathname,
        state: { ...confirmationData }
      }}
    />
  ) : (
    <CommonWrapper>
      <DisplayContainer>
        <LocationHeaderText headerText={"Update Payment Plan for"} />

        <Row>
          <LeftColumn>Plan Nickname</LeftColumn>
          <RightColumn>{paymentPlan.name}</RightColumn>
        </Row>
        <Divider />
        <PPUPaymentMethodSelect {...{ paymentPlan }} />
        <SubmitContainer>
          <TermsConditionsContainer>
            <TermsAndConditions
              checked={checked}
              setChecked={setChecked}
              provider={provider}
            />
          </TermsConditionsContainer>

          <ButtonHeaderText>
            The payment method that you select will be used for all future
            payments. If you already received notification of a scheduled
            payment, it will be processed using the previously-selected payment
            method.
          </ButtonHeaderText>
          <ButtonContainer>
            <StyledButton
              loading={updating}
              disabled={buttonIsDisabled(
                paymentMethodHasChangedAndValid,
                checked
              )}
              handleClick={handleSubmit}
            >
              Update Payment Plan
            </StyledButton>
            <ErrorText>
              {failed ? "Could not update payment plan." : ""}
            </ErrorText>
            <StyledButton
              loading={false}
              color={"secondary"}
              handleClick={cancelNav}
            >
              Cancel
            </StyledButton>
          </ButtonContainer>
        </SubmitContainer>
      </DisplayContainer>
    </CommonWrapper>
  );
};

export default PaymentPlanUpdate;
