import { AjaxResponse } from "rxjs/ajax";

export type Nacha = "nacha";
export type Priority = "priority";
export type Plastiq = "plastiq";
export type PaymentMethodProvider = Nacha | Priority | Plastiq;
import * as SharedTypes from "@billfire/va-types";
export enum ALaCartePaymentMethodProvider {
  Plastiq = "plastiq-cc",
  PriorityACH = "priority-ach",
  PriorityCC = "priority-cc",
  DirectACH = "direct-ach"
}

type CreditCard = "CC";
type ACH = "ACH";
export type PaymentMethodType = CreditCard | ACH;

export interface StripeClientFlowInfo {
  clientSecret: string;
  vendorAccount: string;
}

export type ClientFlowInfo = StripeClientFlowInfo;

export interface DisplayedPaymentMethod {
  type: string; // TODO remove type when done with the cruds
  provider?: SharedTypes.PaymentProvider; // TODO make provider not optional when done with the cruds
  displayExtra: {
    name: string;
    lastFour: string;
  };
}

export enum PaymentMethodTypeEnum {
  CreditCard = "CC",
  ACH = "ACH"
}

export interface APICodedErrorResponse extends AjaxResponse {
  extra: {
    processorErrors: [{ code: string | undefined }];
  };
}

export const isAPICodedErrorResponse = (
  res: AjaxResponse
): res is APICodedErrorResponse => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const apiRes = res as APICodedErrorResponse;
  if (Array.isArray(apiRes?.extra?.processorErrors)) {
    if (apiRes?.extra?.processorErrors[0]) {
      return true;
    }
  }

  return false;
};

export type PlastiqExtra = { plastiqServiceFee: string };

export interface PaymentMethod<T = PaymentMethodProvider> {
  id: string;
  locationId: string;
  contactId: string;
  provider: SharedTypes.PaymentProvider;
  displayExtra: {
    name: string;
    lastFour: string;
    serviceFee?: string;
    verificationRequired?: boolean;
  };
  type: T extends Nacha
    ? ACH
    : T extends Plastiq
    ? CreditCard
    : PaymentMethodType;
  extra: T extends Plastiq ? PlastiqExtra : {};
}

interface SharedPaymentMethod {
  type: PaymentMethodType;
  id: string;
  displayExtra: {
    name: string;
    lastFour: string;
  };
  locationId: string;
  contactId: string;
  provider: PaymentMethodProvider;
}

export interface ConstructingPaymentMethod {
  name: string;
  type: PaymentMethodTypeEnum;
  provider: string;
}
export interface ConstructingPaymentMethodACH
  extends ConstructingPaymentMethod {
  bankRoutingNumber: string;
  checkingAccountNumber: string;
}

export const isConstructingPaymentMethodACH = (
  testMe: ConstructingPaymentMethodACH | ConstructingPaymentMethodCC
): testMe is ConstructingPaymentMethodACH =>
  testMe.type === PaymentMethodTypeEnum.ACH &&
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  !!(testMe as ConstructingPaymentMethodACH).checkingAccountNumber;

export const getEmptyConstructingPaymentMethodACH = () => ({
  name: "",
  type: PaymentMethodTypeEnum.ACH,
  bankRoutingNumber: "",
  checkingAccountNumber: "",
  provider: ""
});
export interface ConstructingPaymentMethodCC extends ConstructingPaymentMethod {
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  addressLine1: string;
  addressLine2: string;
  zipcode: string;
  city: string;
  state: string;
  nickname: string;
  tin: string;
}

export const getEmptyConstructingPaymentMethodCC =
  (): ConstructingPaymentMethodCC => ({
    name: "",
    number: "",
    expiration: "",
    cvc: "",
    addressLine1: "",
    addressLine2: "",
    zipcode: "",
    city: "",
    state: "",
    nickname: "",
    tin: "",
    type: PaymentMethodTypeEnum.CreditCard,
    provider: ""
  });

export interface PaymentMethodTokenResult {
  key: string;
  providerResponse: Record<string, string>;
}

export interface PaymentMethodCreateState {
  creating: boolean;
  creatingTokenized: boolean;
  createSuccess: boolean;
  createFailed: boolean;
  creatingPaymentMethod:
    | ConstructingPaymentMethodACH
    | ConstructingPaymentMethodCC;
  creatingPaymentMethodToken: PaymentMethodTokenResult;
  createFailedCode: string;
}

export interface PaymentMethodsState extends PaymentMethodCreateState {
  byId: Record<string, PaymentMethod>;
  jwt: string;
  clientFlowInfoMap: Record<string, ClientFlowInfo>;
  loading: boolean;
  loaded: boolean;
  loadFailed: boolean;
  selectedPaymentMethodId: string;
  deleting: boolean;
  deleteSuccess: boolean;
  deleteFailed: boolean;
}

export interface SetCreatingPaymentMethodTokenPayload {
  token: PaymentMethodTokenResult;
}

export interface ApiResponseLoadPaymentMethods {
  paymentMethods: Array<PaymentMethod>;
  paymentMethodsJwt: string;
  clientFlowInfo: Record<string, ClientFlowInfo>;
}

export interface MappedLoadPaymentMethodsResponse {
  byId: Record<string, PaymentMethod>;
  jwt: string;
  clientFlowInfoMap: Record<string, ClientFlowInfo>;
}

export interface MappedDeletePaymentMethodsResponse {
  paymentMethodId: string;
}

export interface PriorityPaymentMethod extends SharedPaymentMethod {
  provider: "priority";
  type: PaymentMethodType;
  extra?: {};
}

export interface SetPaymentMethod {
  paymentMethodId: string;
}

export const getEmptyPriorityPaymentMethod = (
  type: PaymentMethodType = "ACH"
): PriorityPaymentMethod => ({
  displayExtra: {
    lastFour: "",
    name: ""
  },
  type: type,
  id: "",
  locationId: "",
  contactId: "",
  provider: "priority",
  extra: {}
});

export const getEmptyPlastiquePaymentMethod = (): PaymentMethod => ({
  type: "CC",
  id: "",
  displayExtra: {
    name: "",
    lastFour: "",
    serviceFee: ""
  },
  locationId: "",
  contactId: "",
  provider: SharedTypes.PaymentProvider.PLASTIQ_CC,
  extra: {}
});

export const getEmptyPaymentMethod = (): PaymentMethod => ({
  displayExtra: {
    name: "",
    lastFour: ""
  },
  type: "ACH",
  id: "",
  locationId: "",
  contactId: "",
  provider: SharedTypes.PaymentProvider.NONE,
  extra: {}
});

export const isPlastiqPaymentMethod = (
  pm: PaymentMethod
): pm is PaymentMethod<Plastiq> => {
  return pm.provider === SharedTypes.PaymentProvider.PLASTIQ_CC;
};

export interface LoadPaymentMethodsSubmit {
  forCreate: boolean;
}
