import {
  formatCurrency,
  formatShortDate,
  sortDate,
  SortDirection,
  sortNumber,
  sortString
} from "@billfire/toybox";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { PaymentPlan } from "../../state/entities/paymentPlans/types";
import { PaymentScheduleDisplayItem, RowData } from "./types";
import { DateTime } from "luxon";
import { Column } from "@billfire/toybox/dist/components/DataTable/types";

const headers = [
  { label: "Plan Nickname", key: "name" },
  { label: "Start Date", key: "startDate" },
  { label: "Total Payment", key: "totalAmount" },
  { label: "Paid By", key: "paidBy" },
  { label: "Payment Method", key: "paymentMethodDisplay" },
  { label: "Payment Number", key: "paymentNum" },
  { label: "Payment Date", key: "paymentDate" },
  { label: "Payment Amount", key: "paymentAmount" },
  { label: "Status", key: "status" }
];

export const mapPPDetailsCSVData = (
  paymentPlan: PaymentPlan,
  schedule: PaymentScheduleDisplayItem[]
) => {
  const {
    paymentMethod: {
      displayExtra: { name, lastFour }
    }
  } = paymentPlan;

  const csvData = schedule.map(pmt => ({
    name: paymentPlan.name,
    startDate: formatShortDate(paymentPlan.startDate),
    totalAmount: formatCurrency(paymentPlan.totalAmount),
    paidBy: `${paymentPlan.paymentMethod.contact.firstName} ${paymentPlan.paymentMethod.contact.lastName}`,
    paymentMethodDisplay: `${name} ${lastFour}`,
    paymentNum: pmt.paymentNum,
    paymentDate: pmt.paymentDate,
    paymentAmount: pmt.paymentAmount,
    status: pmt.status
  }));

  return { csvData, headers };
};

export const getRowData = (
  paymentPlan: PaymentPlan,
  startDate: string,
  paidTotalAmount: number
): RowData => [
  { left: "Plan Nickname", right: paymentPlan.name },
  {
    left: "Start Date",
    right: DateTime.fromFormat(startDate, "M/d/yyyy").toFormat("M/d/yy")
  },
  { left: "Total", right: formatCurrency(paymentPlan.totalAmount) },
  { left: "Payment", right: formatCurrency(paymentPlan.eachAmount) },
  {
    left: "Paid By",
    right: `${paymentPlan.paymentMethod.contact.firstName} ${paymentPlan.paymentMethod.contact.lastName}`
  },
  {
    left: "Payment Method",
    right: ""
  },
  { left: "Paid So Far", right: formatCurrency(paidTotalAmount) }
];

export const scheduleColumns: Column[] = [
  {
    name: "paymentNum",
    label: "Number",
    sortFn: (a: number, b: number, sortDirection: SortDirection) =>
      sortNumber(a, b, sortDirection)
  },
  {
    name: "paymentDate",
    label: "Scheduled",
    sortFn: (a: string, b: string, sortDirection: SortDirection) =>
      sortDate(a, b, sortDirection)
  },
  {
    name: "paymentAmount",
    label: "Payment",
    sortFn: (a: number, b: number, sortDirection: SortDirection) =>
      sortNumber(a, b, sortDirection),
    mobile: false
  },
  {
    name: "status",
    label: "Status",
    sortFn: (a: string, b: string, sortDirection: SortDirection) =>
      sortString(a, b, sortDirection),
    mobile: false
  }
];
