import { PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { ajax, AjaxError } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  loadPaymentPlans,
  loadPaymentPlansSuccess,
  loadPaymentPlansFailed
} from "./slice";
import { StoreType } from "../../types";
import api from "../../api";
import { getAuthToken } from "../shared/selectors";
import { getSelectedVendorId } from "../vendors/selectors";
import { getSelectedLocationId } from "../locations/selectors";
import { mapById } from "./utils";

export const fetchPaymentPlansEpic = (
  action$: Observable<PayloadAction>,
  state$: { value: StoreType }
): Observable<AnyAction> =>
  action$.pipe(
    ofType(loadPaymentPlans.type),
    switchMap(() => {
      const selectedLocationId = getSelectedLocationId(state$.value);
      const selectedVendorId = getSelectedVendorId(state$.value);

      const token = getAuthToken(state$.value);
      return ajax
        .get(
          api.API_URL(
            `/vendors/${selectedVendorId}/locations/${selectedLocationId}/paymentplans`
          ),
          api.getHeaders({ token })
        )
        .pipe(
          map(response => {
            const resp = api.handleAJAXResponse(response, token);
            const plans = mapById(resp);

            return loadPaymentPlansSuccess(plans);
          }),
          catchError(error => {
            if (error instanceof AjaxError)
              api.handleAJAXResponse(error, token);
            return of(loadPaymentPlansFailed());
          })
        );
    })
  );
