import styled from "styled-components";

export const Title = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.1px;
  text-align: left;
  color: #777;
`;
