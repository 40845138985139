import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as reducer from "./entities";
import { createEpicMiddleware, combineEpics, Epic } from "redux-observable";
import {
  fetchRequestDetailsEpic,
  fetchRequestTypeDetailsEpic,
  fetchTokenInfoEpic,
  persistAutopayRequestInvoiceSelectionEpic,
  setRequestInvoiceIdsEpic,
  persistPaymentDateSelectedEpic
} from "./entities/requests/epics";
import { fetchVendorsEpic } from "./entities/vendors/epics";
import {
  replayEpic,
  clearUserSelectionsFromStore,
  replayAutopaySetupActions,
  replayAutopayManageActions
} from "./entities/persisted/epics";
import {
  fetchInvoicesEpic,
  persistDisputesEpic,
  persistInvoiceSelectedEpic,
  fetchFilteredShipTosEpic
} from "./entities/invoices/epics";
import {
  deleteAutopayEpic,
  fetchAutopayEpic,
  persistAutopayInvoiceSelectionEpic
} from "./entities/autopay/epics";
import {
  fetchPaymentMethodsEpic,
  deletePaymentMethodsEpic,
  createPaymentMethodEpic,
  tokenizePlastiqPaymentMethod,
  createTokenizedPaymentMethodEpic,
  persistPaymentMethodSelectedEpic,
  paymentMethodSuccessListener
} from "./entities/paymentMethods/epics";
import {
  fetchEpaEpic,
  fetchGlobalHolidaysEpic,
  fetchGlobalConfigEpic,
  fetchGlobalFeatureFlags
} from "./entities/shared/epics";
import { fetchLocationsEpic } from "./entities/locations/epics";
import { fetchPaymentPlansEpic } from "./entities/paymentPlans/epics";
import { fetchPaymentGroupsEpic } from "./entities/paymentGroups/epics";
import { fetchMessagesEpic } from "./entities/messages/epics";
import {
  fetchStatementDeliveryEpic,
  createStatementDeliveryEpic,
  deleteStatementDeliveryEpic
} from "./entities/statementDelivery/epics";
import {
  fetchCampaignsEpic,
  createCampaignsEpic,
  deleteCampaignsEpic
} from "./entities/campaigns/epics";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { fetchAdsEpic } from "./entities/ads/epics";

const persistConfig = {
  key: "persisted",
  storage,
  version: 1,
  blacklist: ["hasEverDispatchedSavedActions"]
};

export const createReducer = () =>
  combineReducers({
    shared: reducer.sharedSlice,
    requests: reducer.requestSlice,
    vendors: reducer.vendorsSlice,
    invoices: reducer.invoicesSlice,
    paymentMethods: reducer.paymentMethodsSlice,
    locations: reducer.locationsSlice,
    autopay: reducer.autopaySlice,
    paymentPlans: reducer.paymentPlansSlice,
    paymentGroups: reducer.paymentGroupsSlice,
    messages: reducer.messagesSlice,
    statementDelivery: reducer.statementDeliverySlice,
    campaigns: reducer.campaignsSlice,
    persisted: persistReducer(persistConfig, reducer.persistedSlice),
    ads: reducer.ads
  });

export const epics = [
  fetchTokenInfoEpic,
  fetchRequestDetailsEpic,
  fetchRequestTypeDetailsEpic,
  fetchVendorsEpic,
  fetchLocationsEpic,
  fetchInvoicesEpic,
  fetchFilteredShipTosEpic,
  fetchPaymentMethodsEpic,
  deletePaymentMethodsEpic,
  fetchEpaEpic,
  setRequestInvoiceIdsEpic,
  fetchGlobalHolidaysEpic,
  fetchGlobalConfigEpic,
  fetchPaymentGroupsEpic,
  fetchAutopayEpic,
  deleteAutopayEpic,
  createPaymentMethodEpic,
  createTokenizedPaymentMethodEpic,
  tokenizePlastiqPaymentMethod,
  fetchPaymentPlansEpic,
  fetchPaymentGroupsEpic,
  fetchMessagesEpic,
  persistDisputesEpic,
  persistInvoiceSelectedEpic,
  persistPaymentMethodSelectedEpic,
  persistPaymentDateSelectedEpic,
  replayEpic,
  clearUserSelectionsFromStore,
  persistAutopayRequestInvoiceSelectionEpic,
  persistAutopayInvoiceSelectionEpic,
  replayAutopaySetupActions,
  replayAutopayManageActions,
  paymentMethodSuccessListener,
  fetchGlobalFeatureFlags,
  fetchStatementDeliveryEpic,
  createStatementDeliveryEpic,
  deleteStatementDeliveryEpic,
  fetchCampaignsEpic,
  createCampaignsEpic,
  deleteCampaignsEpic,
  fetchAdsEpic
];

const epicMiddleware = createEpicMiddleware();
const rootEpic = combineEpics(...epics);

const store = configureStore({
  reducer: createReducer(),
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(epicMiddleware)
});

const persistor = () => persistStore(store);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
epicMiddleware.run(rootEpic as Epic);

export type AppDispatch = typeof store.dispatch;

export default { persistor, store };
