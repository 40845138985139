import * as React from "react";
import SkeletonLoader from "../../components/SkeletonLoader";
import Click2Pay from "../../components/Click2Pay";
import { useGlobalHolidaysLoader } from "../../hooks/useGlobalHolidaysLoader";
import { useGlobalConfigurationLoader } from "../../hooks/useGlobalConfigurationLoader";
import { useVendorsLoader } from "../../hooks/useVendorsLoader";
import { usePaymentMethodsLoading } from "../../hooks/usePaymentMethodsLoading";
import { useInvoicesLoader } from "../../hooks/useInvoicesLoader";
import { useLocationsLoader } from "../../hooks/useLocationsLoader";

const Click2PayPage: React.FC = () => {
  const { loadingInvoices } = useInvoicesLoader({
    type: "click2pay"
  });
  const paymentMethodsLoading = usePaymentMethodsLoading();

  const { loadedVendors } = useVendorsLoader();
  const { loadedGlobalHolidays } = useGlobalHolidaysLoader();
  const { loadedConfiguration } = useGlobalConfigurationLoader();
  const { locationsLoading } = useLocationsLoader();

  return loadingInvoices ||
    paymentMethodsLoading ||
    !loadedVendors ||
    !loadedGlobalHolidays ||
    !loadedConfiguration ||
    locationsLoading ? (
    <SkeletonLoader />
  ) : (
    <Click2Pay />
  );
};

export default Click2PayPage;
