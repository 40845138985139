// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import ReportPrintView, {
  ReportPrintViewProps
} from "../PrintExportComponent/ReportPrintView";
import { InvoiceReportDataTable } from "./styled";
import { InvoiceRow } from "./types";
import { getColumns } from "./utils";
import { SortDirection } from "@billfire/toybox";
import { useSelector } from "react-redux";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";
import { getGlobalFeatureFlags } from "../../state/entities/shared/selectors";

interface InvoiceReportPrintViewProps extends ReportPrintViewProps {
  rows: InvoiceRow[];
  sortDirection: SortDirection;
  externalSortBy: string;
}

export const InvoiceReportPrintView: React.FC<InvoiceReportPrintViewProps> = ({
  printRef,
  headerText,
  rows,
  sortDirection,
  externalSortBy
}) => {
  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);
  return (
    <>
      <ReportPrintView printRef={printRef} headerText={headerText}>
        <InvoiceReportDataTable
          columns={getColumns(false, vendor, globalFeatureFlags)}
          initialData={rows}
          externalSortDirection={sortDirection}
          externalSortBy={externalSortBy}
        />
      </ReportPrintView>
    </>
  );
};

export default InvoiceReportPrintView;
