import styled from "styled-components";
import { XIcon } from "../../Icon";

interface StyledRowProps {
  selected?: boolean;
}

export const StyledRow = styled.div<StyledRowProps>`
  cursor: pointer;
  display: flex;
  background-color: ${props => (props.selected ? "#e6eef8" : "#ffffff")};
  height: 70px;
`;

interface PaymentMethodTextProps {
  verificationRequired?: boolean;
}

export const PaymentMethodText = styled.div<PaymentMethodTextProps>`
  width: 100%;
  padding-left: 15px;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  gap: 8px;
  ${props => (props.verificationRequired ? "color: #888" : "")}
`;

export const StyledXIcon = styled(XIcon)`
  padding-right: 12px;
`;
