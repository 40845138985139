import * as React from "react";

import { MobileCellProps } from ".";
import PrefixEllipsis from "../../../../PrefixEllipsis";
import { HeaderLabel, Value, ContentRow, EllipseContainer } from "./styled";
import { useColumnFlags } from "../hooks";

const PORef: React.FC<MobileCellProps> = ({ invoice }) => {
  const { isInvoicePoRefIdEnabled } = useColumnFlags();

  if (!isInvoicePoRefIdEnabled) {
    return null;
  }

  return (
    <ContentRow>
      <HeaderLabel>PO/Ref #</HeaderLabel>
      <Value>
        <EllipseContainer>
          <PrefixEllipsis text={invoice.poRefId || "-"} />
        </EllipseContainer>
      </Value>
    </ContentRow>
  );
};

export default PORef;
