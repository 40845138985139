import styled from "styled-components";
import { device } from "../../breakpoints";

export const ListButton = styled.div.attrs({
  "data-testid": "list-button"
})`
  align-items: center;
  margin: 0 7px 0 7px;
  background-color: #ffffff;
  border-radius: 4px;
  border-bottom: solid 1px #eaf0f7;
  padding: 0px 45px 0px 45px;
  &:last-child {
    border-bottom: none;
  }
  @media only screen and (${device.tablet}) {
    padding: 0px 5px 0px 20px;
    margin: 0px;
  }
`;

export const Hover = styled.div.attrs({
  "data-testid": "hover"
})`
  display: grid;
  line-height: 1;
  grid-template-columns: 1fr 9px;
  gap: 4px 0px;
  grid-template-areas: "leftcol rightcol";
  margin: 7px 3px 7px 3px;
  &:hover {
    background-color: #e6eef8;
    cursor: pointer;
  }
  padding: 15px 7px 15px 7px;
`;

export const LeftCol = styled.div`
  grid-area: leftcol;
  display: flex;
  @media only screen and (${device.tablet}) {
    display: inherit;
  }
`;

export const RightCol = styled.div`
  grid-area: rightcol;
  align-self: center;
`;

export const LocationName = styled.div.attrs({
  ["data-testid"]: "location-name"
})`
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 300;
  color: #4a4a4a;
`;

export const LocationAddress = styled.div`
  font-size: 14px;
  color: rgba(100, 102, 120, 0.75);
  margin: 1.5px 0px 0px 10px;

  @media only screen and (${device.tablet}) {
    margin: inherit;
  }
`;
