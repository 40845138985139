export interface LocationPaymentConfig {
  id: string;
  provider: string;
}

export interface Location {
  id: string;
  displayName: string;
  address: {
    postalCode: string;
    addressLine1?: string;
    addressLine2?: string;
    city: string;
    state: string;
  };
  createType: string;
  hasUnreadMessages?: boolean;
  paymentConfigs: LocationPaymentConfig[];
}

export const getEmptyLocation = (): Location => ({
  id: "",
  displayName: "",
  address: {
    postalCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: ""
  },
  createType: "",
  paymentConfigs: []
});

export interface LocationsState {
  byId: Record<string, Location>;
  selectedLocationId: string;
  locationsLoading: boolean;
  locationsLoaded: boolean;
  locationsLoadFailed: boolean;
  hasEverLoadedLocations: boolean;
}

export interface ResponseLocations {
  locations: Array<Location>;
}

export interface LocationsLoadStart {
  refresh?: boolean;
}
