import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedLocationId } from "../../../state/entities/locations/selectors";
import { getSelectedPaymentMethod } from "../../../state/entities/paymentMethods/selectors";
import { PaymentMethod } from "../../../state/entities/paymentMethods/types";
import { getAuthToken } from "../../../state/entities/shared/selectors";
import { getSelectedVendorId } from "../../../state/entities/vendors/selectors";
import { StoreType } from "../../../state/types";
import { getPaymentMethodPaymentDetails } from "../PaymentMethodDeleteModal/utils";
import { InvoiceForSelected, InvoiceForSelectedDataRow } from "../types";

export interface PaymentDetailsParams {
  authToken: string;
  selectedPaymentMethod: PaymentMethod | undefined;
  selectedVendorId: string | null;
  selectedLocationId: string | null;
}

export interface PaymentPayload {
  payments: InvoiceForSelected[];
  hasPaymentPlan: boolean;
  hasAutopay: boolean;
}

export const usePaymentDetailsParams = (): PaymentDetailsParams => {
  return useSelector((state: StoreType) => ({
    authToken: getAuthToken(state),
    selectedPaymentMethod: getSelectedPaymentMethod(state),
    selectedVendorId: getSelectedVendorId(state),
    selectedLocationId: getSelectedLocationId(state)
  }));
};

export const useFetchPaymentDetails = (
  paymentDetailsParams: PaymentDetailsParams,
  checkPaymentsLoading: boolean,
  setCheckPaymentsLoading: (val: boolean) => void,
  setPaymentsLoaded: (val: boolean) => void
) => {
  const [hasPaymentPlan, setHasPaymentPlan] = useState(false);
  const [hasAutopay, setHasAutopay] = useState(false);
  const [invoicesForSelected, setInvoicesForSelected] = useState<
    InvoiceForSelectedDataRow[]
  >([]);
  useEffect(() => {
    setPaymentsLoaded(false);
    setCheckPaymentsLoading(true);
    getPaymentMethodPaymentDetails(
      paymentDetailsParams,
      setInvoicesForSelected,
      setHasPaymentPlan,
      setHasAutopay,
      setCheckPaymentsLoading,
      setPaymentsLoaded
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    invoicesForSelected,
    checkPaymentsLoading,
    hasPaymentPlan,
    hasAutopay
  };
};
