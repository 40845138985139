import { SortDirection } from "@billfire/toybox";

export interface SortBy {
  sortBy: string;
  sortDirection: SortDirection;
}

enum PaymentGroupColumnName {
  confirmationNumber = "confirmationNumber",
  paymentDate = "paymentDate",
  paymentTotal = "paymentTotal"
}

export const isPaymentGroupColumnName = (
  colName?: string | null
): colName is PaymentGroupColumnName => {
  return Object.values(PaymentGroupColumnName).includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    colName as unknown as PaymentGroupColumnName
  );
};
