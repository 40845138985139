import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { loadTokenInfoSubmit } from "../state/entities/requests/slice";
import { useHistory } from "react-router-dom";
import { getAuthToken } from "../state/entities/shared/selectors";
import { TokenInfo } from "../state/entities/requests/types";
import { resetPersistedTokenState } from "../state/entities/persisted/slice";

interface DeepLinker {
  redirect: () => void;
  redirectReady: boolean;
}

interface LocalState {
  loaded: boolean;
  error: boolean;
  tokenInfo: TokenInfo;
  authToken: string;
}

const useLocalState = (): LocalState => {
  return useSelector((state: StoreType) => ({
    loaded: state.requests.loadedTokenInfo,
    error: state.requests.loadTokenInfoFailed,
    tokenInfo: state.requests.tokenInfo,
    authToken: getAuthToken(state)
  }));
};

const routePaymentPlanRequest = (
  pathBase: string,
  requestId: string,
  status: string
) => {
  if (status === "complete") {
    return pathBase;
  } else {
    return `${pathBase}/planrequests/${requestId}`;
  }
};

const routeAutopayRequest = (pathBase: string, requestId: string) => {
  if (requestId) {
    return `${pathBase}/autopay/${requestId}`;
  } else {
    return `${pathBase}/autopay`;
  }
};

const calculateLink = (authToken: string, tokenInfo: TokenInfo): string => {
  const { id: requestId, locationId, vendorId, type, status } = tokenInfo;
  const pathBase = `/token/${authToken}/vendors/${vendorId}/locations/${locationId}`;

  switch (type) {
    case "paymentplan":
      return routePaymentPlanRequest(pathBase, requestId, status);
    case "autopay":
      return routeAutopayRequest(pathBase, requestId);
    default:
      return `${pathBase}/${type}`;
  }
};

export function useDeepLinker(): DeepLinker {
  const dispatch = useDispatch();
  const history = useHistory();

  const [redirectPath, setRedirectPath] = React.useState<string>("");

  const { loaded, tokenInfo, authToken, error } = useLocalState();

  React.useEffect(() => {
    dispatch(loadTokenInfoSubmit());
  }, [dispatch]);

  React.useEffect(() => {
    if (loaded) {
      const path = calculateLink(authToken, tokenInfo);
      setRedirectPath(path);
      redirectPath && dispatch(resetPersistedTokenState({ token: authToken }));
    } else if (error) {
      const path = `/token/${authToken}/error`;
      setRedirectPath(path);
      redirectPath && dispatch(resetPersistedTokenState({ token: authToken }));
    }
  }, [loaded, tokenInfo, authToken, redirectPath, error, dispatch]);

  const redirect = () => history.push(redirectPath);
  const redirectReady = redirectPath !== "";

  return { redirect, redirectReady };
}
