import React from "react";
import { DropDownContainer, StyledDropDown } from "./styled";

interface ShipToDropDownProps {
  selectedVendorShipToConfig: boolean;
  mobile: boolean;
  dropDownMaxHeight?: string | undefined;
  shipToOptions: Record<string, string>[];
  selectedOption: { [key: string]: string } | undefined;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}

const ShipToDropDown: React.FC<ShipToDropDownProps> = ({
  selectedVendorShipToConfig,
  mobile,
  dropDownMaxHeight,
  shipToOptions,
  selectedOption,
  setSelectedOption
}) => {
  if (!selectedVendorShipToConfig) return null;

  return (
    <DropDownContainer mobile={mobile}>
      <StyledDropDown
        mobile={mobile}
        maxHeight={dropDownMaxHeight || "400px"}
        addNew={false}
        optionRowOrder={["filterName", "filterValue"]}
        placeholder="All"
        options={[...shipToOptions, { filterName: "All", filterValue: "" }]}
        columnOptions={[
          { maxWidth: mobile ? "180px" : "222px" },
          { maxWidth: mobile ? "60px" : "110px" }
        ]}
        selected={selectedOption}
        setSelected={setSelectedOption}
        label="Select Ship-To"
      />
    </DropDownContainer>
  );
};

export default ShipToDropDown;
