import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import {
  loadInvoicesSubmit,
  loadShipTosSubmit
} from "../state/entities/invoices/slice";
import { getInvoicesLoadState } from "../state/entities/invoices/selectors";
import { useNavigation } from "./route";
import { selectSelectedVendorShipToConfig } from "../state/entities/vendors/selectors";

export const useInvoicesLoader = (
  {
    loadAll = false,
    type = "",
    invoiceId = ""
  }: { loadAll?: boolean; type?: string; invoiceId?: string } = {
    loadAll: false,
    type: ""
  }
) => {
  const dispatch = useDispatch();
  const { loadingInvoices, loadInvoicesFailed, loadedInvoices } = useSelector(
    (state: StoreType) => getInvoicesLoadState(state)
  );
  const { vendorId, locationId } = useNavigation();

  const selectedVendorShipToConfig = useSelector(
    selectSelectedVendorShipToConfig
  );

  React.useEffect(() => {
    if (vendorId && locationId) {
      dispatch(loadInvoicesSubmit({ loadAll, type, invoiceId }));
      if (selectedVendorShipToConfig) {
        dispatch(loadShipTosSubmit());
      }
    }
  }, [
    dispatch,
    vendorId,
    locationId,
    loadAll,
    type,
    invoiceId,
    selectedVendorShipToConfig
  ]);

  return {
    loadingInvoices,
    loadInvoicesFailed,
    loadedInvoices
  };
};
