export const getPaymentType = (
  hasPaymentPlan: boolean,
  hasAutopay: boolean
) => {
  return hasPaymentPlan && hasAutopay
    ? "AutoPay Plus and a payment plan"
    : hasPaymentPlan
    ? "a payment plan"
    : "AutoPay Plus";
};

export const isBeingUsed = (
  hasPaymentPlan: boolean,
  hasAutopay: boolean,
  selectedVendorName: string
) => {
  return (hasPaymentPlan || hasAutopay) && !!selectedVendorName;
};
