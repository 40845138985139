import * as React from "react";
import { useSelector } from "react-redux";
import { getPaymentGroupsLoadFailed } from "../../state/entities/paymentGroups/selectors";
import { StoreType } from "../../state/types";
import { ErrorContainer, ErrorText } from "./styled";
import { getSelectedVendor } from "../../state/entities/vendors/selectors";

const PaymentsReportError: React.FC = () => {
  const loadError = useSelector((state: StoreType) =>
    getPaymentGroupsLoadFailed(state)
  );

  const vendor = useSelector(getSelectedVendor);

  return (
    <>
      {loadError ? (
        <ErrorContainer>
          <ErrorText>
            We were unable to load the data for this report. Please refresh your
            browser window to try again.
          </ErrorText>
          <ErrorText>
            If you continue to have difficulties viewing this page, please
            contact {vendor.name}.
          </ErrorText>
        </ErrorContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentsReportError;
