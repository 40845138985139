import * as React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../../state/types";
import { getAuthToken } from "../../state/entities/shared/selectors";
import { setToken } from "../../state/entities/shared/slice";
import { useLoadPrivateAnnouncements } from "./hooks";
import { Announcement } from "../LetMeIn/types";

const context: {
  announcements: Announcement[];
} = {
  announcements: []
};
export const PrivateAnnouncementContext = React.createContext(context);
export const usePrivateAnnouncements = () =>
  React.useContext(PrivateAnnouncementContext);

const PrivateRouteWrapper: React.FC = ({ children }) => {
  const { authToken } = useSelector((state: StoreType) => ({
    authToken: getAuthToken(state)
  }));
  const dispatch = useDispatch();
  const { globalAnnouncements, vendorAnnouncements } =
    useLoadPrivateAnnouncements(authToken);

  const { token: pathToken } = useParams<{ token: string }>();

  React.useEffect(() => {
    if (pathToken && !authToken) {
      (async () => {
        dispatch(setToken({ authToken: pathToken }));
      })();
    }
  }, [pathToken, authToken, dispatch]);

  return (
    <PrivateAnnouncementContext.Provider
      value={{
        announcements: [...globalAnnouncements, ...vendorAnnouncements]
      }}
    >
      {authToken && children}
    </PrivateAnnouncementContext.Provider>
  );
};

export default PrivateRouteWrapper;
