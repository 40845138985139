// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";

import {
  sortDate,
  sortString,
  sortCurrency,
  formatCurrency,
  formatShortDate,
  SortDirection
} from "@billfire/toybox";
import {
  PaymentDetail,
  PaymentGroupDetail,
  PaymentGroupWithMethod
} from "../../pages/PaymentDetailPage/types";
import {
  getExternalInvoiceDateDisplay,
  translatePowerPayType
} from "../../utils";
import {
  calculateServiceFee,
  paymentMethodHasAFee
} from "../TotalsAndFees/utils";
import currency from "currency.js";
import { paidBy } from "../../state/entities/paymentGroups/utils";
import { isPaymentColumnName } from "./types";
import { formatFrequency } from "../../state/entities/paymentPlans/utils";
import { Vendor } from "../../state/entities/vendors/types";
import { GlobalFeatureFlags } from "../../state/entities/shared/types";
import { featureEnabled } from "../../state/entities/shared/utils";
import InvoiceLink from "../InvoiceLink";
import Truncate from "../Truncate";

const baseColumnWidths = {
  invoiceDisplayInvNum: 19,
  invoiceDueDate: 19,
  paidAmount: 19,
  shipTo: 0,
  externalInvoiceDate: 0
};

export const dynamicColumnWidths = (
  selectedVendorShipToConfig: boolean,
  displayExternalInvoiceDate: boolean
) => {
  let remainingWidth =
    100 -
    baseColumnWidths.invoiceDisplayInvNum -
    baseColumnWidths.invoiceDueDate -
    baseColumnWidths.paidAmount;

  const widths = { ...baseColumnWidths };

  if (displayExternalInvoiceDate) {
    const externalInvoiceDateWidth = Math.min(19, remainingWidth);
    widths.externalInvoiceDate = externalInvoiceDateWidth;
    remainingWidth -= externalInvoiceDateWidth;
  }

  if (selectedVendorShipToConfig) {
    widths.shipTo = remainingWidth;
  }

  return widths;
};

export const paymentsTableColumns = (
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags,
  selectedVendorShipToConfig?: boolean,
  mobile?: boolean
) => {
  const displayExternalInvoiceDate = featureEnabled(
    vendor,
    globalFeatureFlags,
    "displayExternalInvoiceDate"
  );
  const columnWidths = dynamicColumnWidths(
    !!selectedVendorShipToConfig,
    displayExternalInvoiceDate
  );

  return [
    {
      name: "invoiceDisplayInvNum",
      label: "Invoice",
      width: `${columnWidths.invoiceDisplayInvNum}%`,
      render: (value: string, item: PaymentDetail) => {
        return (
          <InvoiceLink
            invoiceId={item.invoiceId}
            invoiceDisplayInvNum={item.invoiceDisplayInvNum}
          />
        );
      },
      sortFn: sortString
    },
    ...(selectedVendorShipToConfig
      ? [
          {
            name: "shipTo",
            label: "Ship-To",
            width: `${columnWidths.shipTo}%`,
            sortFn: sortString,
            render: (value: string) => (
              <Truncate value={value} maxHeight={"40px"} lineClamp={2} />
            )
          }
        ]
      : []),
    ...(displayExternalInvoiceDate
      ? [
          {
            name: "externalInvoiceDate",
            mobile: false,
            label: "Invoice Date",
            width: `${columnWidths.externalInvoiceDate}%`,
            render: (value: string, item: PaymentDetail) => {
              return <>{getExternalInvoiceDateDisplay(item)}</>;
            }
          }
        ]
      : []),
    {
      name: "invoiceDueDate",
      label: mobile ? "Due" : "Due Date",
      width: `${columnWidths.invoiceDueDate}%`,
      sortFn: sortDate,
      formatFn: formatShortDate
    },
    {
      name: "paidAmount",
      label: mobile ? "Amt Paid" : "Amount Paid",
      width: `${columnWidths.paidAmount}%`,
      sortFn: sortCurrency,
      formatFn: formatCurrency
    }
  ];
};

const getC2PPaymentHeaders = (hasExternalInvoiceDate: boolean) => [
  { label: "Confirmation Number", key: "confirmationNumber" },
  { label: "Payment Date", key: "paymentDate" },
  { label: "Payer Name", key: "payerName" },
  { label: "Payment Type", key: "paymentType" },
  { label: "Payment Method", key: "paymentMethodDisplay" },
  { label: "Method Type", key: "paymentMethodType" },
  { label: "Group Subtotal", key: "subtotal" },
  { label: "Group Service Fee", key: "fee" },
  { label: "Group Total", key: "total" },
  { label: "Invoice", key: "invoiceDisplayInvNum" },
  ...(hasExternalInvoiceDate
    ? [{ label: "Invoice Date", key: "externalInvoiceDate" }]
    : []),
  { label: "Due Date", key: "invoiceDueDate" },
  { label: "Open Amount", key: "invoiceOriginalAmount" },
  { label: "Amount Paid", key: "paidAmount" },
  { label: "Reason Code", key: "subject" },
  { label: "Notes", key: "message" }
];

const ppPaymentHeaders = [
  { label: "Confirmation Number", key: "confirmationNumber" },
  { label: "Payment Date", key: "paymentDate" },
  { label: "Payer Name", key: "payerName" },
  { label: "Payment Type", key: "paymentType" },
  { label: "Payment Method", key: "paymentMethodDisplay" },
  { label: "Method Type", key: "paymentMethodType" },
  { label: "Plan ID", key: "paymentPlanName" },
  { label: "Payment", key: "subtotal" },
  { label: "Service Fee", key: "fee" },
  { label: "Payment Total", key: "total" }
];

const calculateTotals = (paymentGroupDetail: PaymentGroupDetail) => {
  const { paymentGroup } = paymentGroupDetail;
  const { paymentMethod, paymentTotal } = paymentGroup;

  const hasFee = paymentMethodHasAFee(paymentMethod);

  let serviceFee = currency(0);

  if (hasFee) {
    serviceFee = calculateServiceFee(paymentMethod, paymentTotal);
  }

  return {
    subtotal: formatCurrency(paymentTotal),
    fee: formatCurrency(serviceFee.value),
    total: formatCurrency(serviceFee.add(paymentTotal).value)
  };
};

const mapCommonPaymentFields = (paymentGroupDetail: PaymentGroupDetail) => {
  const { paymentGroup } = paymentGroupDetail;
  const { paymentMethod } = paymentGroup;
  return {
    confirmationNumber: paymentGroup.confirmationNumber,
    paymentDate: formatShortDate(paymentGroup.paymentDate),
    payerName: paidBy(paymentGroup),
    paymentType: translatePowerPayType(paymentGroup.paymentType),
    paymentMethodDisplay: `${paymentMethod.displayExtra.name} ${paymentMethod.displayExtra.lastFour}`,
    paymentMethodType: paymentMethod.type,
    ...calculateTotals(paymentGroupDetail)
  };
};

const mapPaymentFields = (
  payment: PaymentDetail,
  hasExternalInvoiceDate: boolean
) => {
  return {
    invoiceDisplayInvNum: payment.invoiceDisplayInvNum,
    ...(hasExternalInvoiceDate && {
      externalInvoiceDate: formatShortDate(payment.externalInvoiceDate)
    }),
    invoiceDueDate: formatShortDate(payment.invoiceDueDate),
    invoiceOriginalAmount: formatCurrency(payment.invoiceOriginalAmount),
    paidAmount: formatCurrency(payment.paidAmount),
    subject: payment.subject,
    message: payment.message
  };
};

const mapPPPaymentDetailsCSV = (paymentGroupDetail: PaymentGroupDetail) => {
  const { paymentGroup } = paymentGroupDetail;
  const csvData = [
    {
      ...mapCommonPaymentFields(paymentGroupDetail),
      paymentPlanName: paymentGroup.paymentPlanName
    }
  ];

  return {
    csvData,
    headers: ppPaymentHeaders,
    documentTitle: "Payment Plan Payment Detail"
  };
};

export const mapAndSortPaymentCSVData = (
  confirmation: boolean,
  paymentGroupDetail: PaymentGroupDetail,
  vendor: Vendor,
  globalFeatureFlags: GlobalFeatureFlags,
  sortBy?: string,
  sortDirection?: SortDirection
) => {
  const { paymentGroup, payments } = paymentGroupDetail;

  if (paymentGroup.paymentPlanName)
    return mapPPPaymentDetailsCSV(paymentGroupDetail);

  const columns = paymentsTableColumns(vendor, globalFeatureFlags);
  const hasExternalInvoiceDate = !!columns.find(
    col => col.name === "externalInvoiceDate"
  );

  const foundColumn = columns.find(({ name }) => name === sortBy);
  const sortFn = foundColumn && foundColumn.sortFn;

  const csvData = payments
    .sort(
      (a, b) =>
        sortFn &&
        isPaymentColumnName(sortBy) &&
        // eslint-disable-next-line security/detect-object-injection
        sortFn(a[sortBy], b[sortBy], sortDirection || "ASC")
    )
    .map(pmt => ({
      ...mapCommonPaymentFields(paymentGroupDetail),
      ...mapPaymentFields(pmt, hasExternalInvoiceDate)
    }));

  return {
    csvData,
    headers: getC2PPaymentHeaders(hasExternalInvoiceDate),
    documentTitle: confirmation ? "Payment Confirmation" : "Payment Detail"
  };
};

export const getSubtitleText = (paymentGroup: PaymentGroupWithMethod) => {
  const { paymentMethod, paymentPlanFrequency } = paymentGroup;
  const hasFee = paymentMethodHasAFee(paymentMethod);

  if (paymentPlanFrequency) {
    return `${formatFrequency(paymentPlanFrequency)} Payment`;
  } else {
    return hasFee ? "Payment Subtotal" : "Payment Total";
  }
};
