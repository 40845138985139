import React from "react";

import { Modal, ModalSize, ModalType } from "@billfire/toybox";

import { useCreateReminderSubmit, useSetReminderModal } from "./hooks";
import { ModalContent, ModalTitle, StyledRadioGroup } from "./styled";
import { CREATE_OPTIONS, getEventDay, setAndValidateRadioValue } from "./utils";

export interface SetReminderModalProps {
  setCreateReminder: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetReminderModal: React.FC<SetReminderModalProps> = ({
  setCreateReminder
}) => {
  const {
    submitCreateCampaign,
    creatingCampaign,
    createdCampaign,
    createCampaignFailed
  } = useCreateReminderSubmit();
  const {
    closeModal,
    contactId,
    optionValue,
    setOptionValue,
    value,
    setValue,
    createFailedMsg,
    createFailed,
    setCreateFailed,
    isValid,
    formIsValid,
    validationErrMsg
  } = useSetReminderModal(setCreateReminder, createdCampaign);

  return (
    <Modal
      type={ModalType.form}
      size={ModalSize.small}
      onClose={() => closeModal()}
      loading={creatingCampaign}
      errored={createFailed || createCampaignFailed || !isValid}
      submitButtonText={"Add Reminder"}
      cancelButtonText={"Cancel"}
      onSubmit={() => {
        const eventDay = getEventDay(optionValue, value);
        submitCreateCampaign(eventDay, contactId);
      }}
      errorMessage={validationErrMsg ? validationErrMsg : createFailedMsg}
      disableSubmit={creatingCampaign || !isValid || !formIsValid}
    >
      <ModalContent>
        <ModalTitle>Set a Reminder</ModalTitle>
        <StyledRadioGroup
          className="create-reminders-group"
          onChange={(newOptionValue, newValue) => {
            setAndValidateRadioValue(
              optionValue,
              newOptionValue,
              value,
              newValue,
              setCreateFailed,
              setOptionValue,
              setValue
            );
          }}
          options={CREATE_OPTIONS}
          margin={"0 0 29px 0"}
          labelMargin={"0 0 0 16px"}
        />
      </ModalContent>
    </Modal>
  );
};

export default SetReminderModal;
