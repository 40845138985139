import styled from "styled-components";
import styles, { Panel, Button } from "@billfire/toybox";

const { colors } = styles;
const { baseGray2, baseGray3 } = colors;

export const StyledPanel = styled(Panel)`
  width: 600px;
  margin: 50px auto;
  padding: 30px;

  display: flex;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 700px) {
    width: 82%;
  }
`;

export const StyledButton = styled(Button)`
  width: 229px;
  margin-top: 30px;
  font-size: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Header = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${baseGray3};
`;

export const Message = styled.p`
  font-size: 16px;
  color: ${baseGray2};
  width: 90%;
  margin: auto;
`;

export const FooterContainer = styled.div`
  text-align: center;
`;

export const FooterText = styled.p.attrs({ "data-testid": "footer" })`
  font-size: 14px;
  line-height: 1.14;
  color: ${baseGray2};
`;

export const StyledSup = styled.sup`
  font-size: 65%;
`;
