import {
  AutopayBaseConfirmState,
  AutopayConfirmState,
  ConfirmationLoadingState
} from "./types";

const isFailed = (apConfirmState: AutopayConfirmState): boolean => {
  return (
    apConfirmState.invoiceLoadingState.loadInvoicesFailed ||
    apConfirmState.vendorLoadingState.loadVendorsFailed ||
    apConfirmState.autopayLoadingState.loadAutopayFailed ||
    apConfirmState.locationLoadingState.locationsLoadFailed
  );
};

const isInactive = (apConfirmState: AutopayConfirmState): boolean => {
  return (
    !apConfirmState.apActive &&
    apConfirmState.pmAndDateLoadingState.loading &&
    apConfirmState.autopayLoadingState.loadedAutopay
  );
};

const isLoading = (apConfirmState: AutopayConfirmState): boolean => {
  return (
    apConfirmState.invoiceLoadingState.loadingInvoices ||
    apConfirmState.vendorLoadingState.loadingVendors ||
    apConfirmState.autopayLoadingState.loadingAutopay ||
    apConfirmState.locationLoadingState.locationsLoading ||
    apConfirmState.pmAndDateLoadingState.loading ||
    apConfirmState.paymentMethodsLoading
  );
};

export const getLoadingState = (
  apConfirmState: AutopayConfirmState
): ConfirmationLoadingState => {
  if (isFailed(apConfirmState)) {
    return ConfirmationLoadingState.failed;
  } else if (isInactive(apConfirmState)) {
    return ConfirmationLoadingState.inactive;
  } else if (isLoading(apConfirmState)) {
    return ConfirmationLoadingState.loading;
  }

  return ConfirmationLoadingState.loaded;
};

export const getDeleteLoadingState = (
  apConfirmState: AutopayBaseConfirmState
): ConfirmationLoadingState => {
  if (
    apConfirmState.apActive &&
    apConfirmState.autopayLoadingState.loadedAutopay
  ) {
    return ConfirmationLoadingState.active;
  } else if (
    apConfirmState.locationLoadingState.locationsLoadFailed ||
    apConfirmState.vendorLoadingState.loadVendorsFailed ||
    apConfirmState.autopayLoadingState.loadAutopayFailed
  ) {
    return ConfirmationLoadingState.failed;
  } else if (
    apConfirmState.locationLoadingState.locationsLoading ||
    apConfirmState.vendorLoadingState.loadingVendors ||
    apConfirmState.autopayLoadingState.loadingAutopay
  ) {
    return ConfirmationLoadingState.loading;
  }

  return ConfirmationLoadingState.loaded;
};
