import * as React from "react";
import { GroupPanel, DisplayContainer } from "./styled";

import InvoicesPanel from "./InvoicesPanel";
import PaymentsPanel from "./PaymentsPanel";
import PaymentPlanPanel from "./PaymentPlanPanel";
import { useLocationHasPaymentPlans } from "./hooks";
import GroupHeader from "./GroupHeader";

const ReportsPanels: React.FC = () => {
  const hasPaymentPlans = useLocationHasPaymentPlans();
  return (
    <DisplayContainer>
      <GroupHeader title="REPORTS" color="#2d70aa" />
      <GroupPanel>
        <InvoicesPanel />
        <PaymentsPanel />
        {hasPaymentPlans && <PaymentPlanPanel />}
      </GroupPanel>
    </DisplayContainer>
  );
};

export default ReportsPanels;
