import styled from "styled-components";
import styles, { DataTable } from "@billfire/toybox";
const { colors } = styles;

export const Header = styled.div`
  text-align: center;

  h2 {
    margin-top: 20px;
    margin-bottom: 50px;
    color: rgb(80, 80, 80);
    font-weight: 400;
  }
`;

export const UpdatedHeader = styled.div`
  font-size: 24px;
  padding: 25px 0px;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;

export const StyledInvoiceTable = styled(DataTable)`
  width: 100%;
  margin-top: 15px;
  th {
    color: ${colors.baseGray7};
    padding: 15px 0 15px 0;
    font-size: 16px;
  }
  td {
    color: ${colors.baseGray3};
    padding: 15px 0 15px 0;
    font-size: 15px;
  }

  thead > tr > th:nth-child(2) {
    text-align: center;
  }

  thead > tr > th:nth-child(3) {
    text-align: right;
  }

  tr > td:nth-child(1) > div {
    text-align: left;
  }

  tr > td:nth-child(2) > div {
    text-align: center;
  }

  tr > td:nth-child(3) > div {
    text-align: right;
  }
`;

export const StyledCaption = styled.caption`
  color: ${colors.baseGray7};
`;
