import * as React from "react";
import LSPrintView from "./LocationStatementPrintView";
import PrintExportComponent from "../../PrintExportComponent";
import { BodyRow, InvoiceTable, TBody } from "./styled";
import { SortDirection } from "@billfire/toybox";
import { InvoiceRow, getColumns } from "./utils";
import { getGlobalFeatureFlags } from "../../../state/entities/shared/selectors";
import {
  getSelectedVendor,
  selectSelectedVendorShipToConfig
} from "../../../state/entities/vendors/selectors";
import { selectShipTos } from "../../../state/entities/invoices/selectors";
import { useStatementExport } from "./hooks";
import { useMobile } from "../../../hooks/useMobile";
import { useSelector } from "react-redux";
import ShipToDropDown from "../../ShipToDropDown/index";

interface InvoiceListProps {
  headerText: string;
  filteredInvoices: {
    pastDue: InvoiceRow[];
    current: InvoiceRow[];
    credits: InvoiceRow[];
  };
  selectedOption: Record<string, string>;
  setSelectedOption: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
}

const InvoiceList: React.FC<InvoiceListProps> = ({
  headerText,
  filteredInvoices,
  selectedOption,
  setSelectedOption
}) => {
  const [sortBy, setSortBy] = React.useState<string>("dueDate");
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>("ASC");
  const vendor = useSelector(getSelectedVendor);
  const globalFeatureFlags = useSelector(getGlobalFeatureFlags);
  const selectedVendorShipToConfig = useSelector(
    selectSelectedVendorShipToConfig
  );

  const { csvData, headers } = useStatementExport(sortBy, sortDirection);
  const printRef = React.useRef(null);
  const { mobile } = useMobile();
  const shipToOptions = useSelector(selectShipTos);
  const { pastDue, current, credits } = filteredInvoices;
  const invoicesArray = [...pastDue, ...current, ...credits];
  const columns = getColumns(
    vendor,
    globalFeatureFlags,
    selectedVendorShipToConfig,
    mobile
  );

  return (
    <>
      <PrintExportComponent
        printRef={printRef}
        documentTitle={"Statement"}
        headers={headers}
        csvData={csvData}
      />

      <LSPrintView
        printRef={printRef}
        headerText={headerText}
        columns={columns}
        invoices={filteredInvoices}
        invoicesArray={invoicesArray}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />

      <ShipToDropDown
        {...{
          selectedVendorShipToConfig,
          mobile,
          shipToOptions,
          selectedOption,
          setSelectedOption
        }}
      />
      <InvoiceTable
        TableBodyComponent={TBody}
        RowComponent={BodyRow}
        initialData={invoicesArray}
        columns={columns}
        sortable
        externalSetSortBy={setSortBy}
        externalSortBy={sortBy}
        externalSortDirection={sortDirection}
        setExternalSortDirection={setSortDirection}
        mobileMQ="550px"
      />
    </>
  );
};

export default InvoiceList;
