export interface Ad {
  fileId: string;
  fileUrl: string;
  clickUrl?: string;
  duration: number;
  order: number;
}

export interface AdResponse {
  ads: Ad[];
}

export const getEmptyAd = (): Ad => ({
  fileId: "",
  fileUrl: "",
  duration: 0,
  order: 0
});

export interface LoadingAdsFlags {
  loadingAds: boolean;
  loadedAds: boolean;
  loadAdsFailed: boolean;
}

export interface AdsState extends LoadingAdsFlags {
  byId: Record<string, Ad>;
}
