// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useModal } from "../index";
import { Modal, ModalSize, ModalType } from "@billfire/toybox";
import { WhiteStyledMessage } from "../modals.styled";
import { useSelector, useDispatch } from "react-redux";
import {
  getPaymentPlansUpdating,
  getPaymentPlansUpdateFailed,
  getPaymentPlansUpdated
} from "../../state/entities/paymentPlans/selectors";
import { getSelectedPaymentMethod } from "../../state/entities/paymentMethods/selectors";
import { StoreType } from "../../state/types";
import { resetUpdateState } from "../../state/entities/paymentPlans/slice";

export interface ConfirmPaymentPlanUpdateModalProps {
  submit: () => void;
}

const ConfirmAutopayDeleteModal: React.FC<
  ConfirmPaymentPlanUpdateModalProps
> = ({ submit }) => {
  const dispatch = useDispatch();
  const { hideModal } = useModal();
  const { selectedPaymentMethod, updating, updated, failed } = useSelector(
    (state: StoreType) => ({
      selectedPaymentMethod: getSelectedPaymentMethod(state),
      updating: getPaymentPlansUpdating(state),
      updated: getPaymentPlansUpdated(state),
      failed: getPaymentPlansUpdateFailed(state)
    })
  );

  const closeModal = () => {
    dispatch(resetUpdateState());
    hideModal();
  };

  React.useEffect(() => {
    if (updated) closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  return (
    <Modal
      title="Update payment plan?"
      type={ModalType.form}
      size={ModalSize.small}
      onClose={closeModal}
      loading={updating}
      errored={failed}
      errorMessage="Could not update payment method."
      hideActions={false}
      submitButtonText="Update"
      cancelButtonText="Cancel"
      onSubmit={submit}
    >
      <WhiteStyledMessage>{`A ${selectedPaymentMethod?.displayExtra?.serviceFee} service fee will be added to all payments made when you use the card ${selectedPaymentMethod?.displayExtra.name} ${selectedPaymentMethod?.displayExtra.lastFour}.`}</WhiteStyledMessage>
    </Modal>
  );
};

export default ConfirmAutopayDeleteModal;
