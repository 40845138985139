import * as React from "react";
import { loadInvoiceJourney, mapRowIdToJourneyRecord } from "./utils";
import { useParams } from "react-router-dom";
import { getEmptyUpdatedInvoiceJourney } from "../../components/InvoiceJourney/types";
import { useAbortController } from "../../hooks/useAbortController";
import { getEmptyInvoice } from "../../state/entities/invoices/types";

export const useInvoiceJourneyLoader = () => {
  const { token, vendorId, locationId, invoiceId } = useParams<{
    token: string;
    vendorId: string;
    locationId: string;
    invoiceId: string;
  }>();

  const [journeyLoading, setJourneyLoading] = React.useState<boolean>(true);
  const [journeyLoadFailed, setJourneyLoadFailed] =
    React.useState<boolean>(false);
  const [{ invoiceJourney, invoice }, setInvoiceJourneyResponse] =
    React.useState({
      invoiceJourney: getEmptyUpdatedInvoiceJourney(),
      invoice: getEmptyInvoice()
    });

  const signal = useAbortController();

  React.useEffect(() => {
    const local = async (): Promise<void> => {
      await loadInvoiceJourney(token, vendorId, locationId, invoiceId, signal)
        .then(res => {
          if (!signal.aborted) {
            setInvoiceJourneyResponse({
              invoiceJourney: mapRowIdToJourneyRecord(res),
              invoice: res.invoice
            });

            setJourneyLoading(false);
          }
        })
        .catch(() => {
          if (!signal.aborted) {
            setJourneyLoading(false);
            setJourneyLoadFailed(true);
          }
        });
    };

    local();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    journeyLoading,
    journeyLoadFailed,
    invoiceJourney: invoiceJourney,
    invoice: invoice
  };
};
