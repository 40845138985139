export const isLoading = (
  loadingAutopay: boolean,
  locationsLoading: boolean,
  loadingInvoices: boolean,
  paymentMethodsLoading: boolean,
  loadedVendors: boolean
) =>
  loadingAutopay ||
  locationsLoading ||
  loadingInvoices ||
  paymentMethodsLoading ||
  !loadedVendors;
