import * as React from "react";
import { getColumns } from "../utils";
import { useRemitForm } from "..";
import InvoiceCheckBox from "./InvoiceCheckBox";
import InvoiceTableCell from "./InvoiceTableCell";
import { InvoiceRowProps } from "./InvoiceRow";
import { getEmptyRemitInvoice, isRemitInvoice } from "../types";
import { DataRow } from "./styled";

const InvoiceDataRow: React.FC<InvoiceRowProps> = ({ mobile, rowId }) => {
  const [columnsMobile, setColumnsMobile] = React.useState(false);
  const { sortedInvoices } = useRemitForm();

  React.useEffect(() => {
    if (mobile) {
      setColumnsMobile(true);
    } else {
      setColumnsMobile(false);
    }
  }, [mobile]);

  const columns = getColumns(columnsMobile);

  const invoice =
    sortedInvoices.find(inv => inv.rowId === rowId) || getEmptyRemitInvoice();

  if (!isRemitInvoice(invoice)) {
    return null;
  }

  const rowClassName = invoice.selected ? "tr-selected" : "";

  return (
    <DataRow className={rowClassName}>
      <td className="select-box" key={`select-${invoice.rowId}`} width={"8%"}>
        <InvoiceCheckBox
          ariaLabel={`${invoice.selected ? "De-select" : "Select"} invoice ${
            invoice.displayInvNum
          }`}
          id={invoice.id}
        />
      </td>
      {columns.map(column => {
        return (
          <InvoiceTableCell
            key={`row-${invoice.rowId}-${column.name}`}
            column={column}
            item={invoice}
          />
        );
      })}
    </DataRow>
  );
};

export default InvoiceDataRow;
