import { EpaInterfaces } from "@billfire/va-types";

export interface Configuration {
  paymentRequest: {
    c2pPaymentDays: number;
  };
  autopayConfig: {
    startDateTimeFrame: number;
  };
}

export const getEmptyConfiguration = () => ({
  paymentRequest: {
    c2pPaymentDays: 0
  },
  autopayConfig: {
    startDateTimeFrame: 0
  }
});

export const getEmptyEPA = () => ({
  default: {
    version: "",
    filename: ""
  },
  ["plastiq-cc"]: {
    version: "",
    filename: ""
  }
});

export type GlobalHolidays = string[];

export interface GlobalFeatureFlags {
  click2PayCampaign: { default: boolean; enabled: boolean };
  adoptionAndTraining: { default: boolean; enabled: boolean };
  vendorManage: { default: boolean; enabled: boolean };
  broadCastQuick: { default: boolean; enabled: boolean };
  accountingQbo: { default: boolean; enabled: boolean };
  pro: {
    features: string[];
    default: boolean;
    enabled: boolean;
  };
  accountSummary: { default: boolean; enabled: boolean };
  qboConnect: { default: boolean; enabled: boolean };
  autopay: { default: boolean; enabled: boolean };
  customerLocationReport: { default: boolean; enabled: boolean };
  click2Pay: { default: boolean; enabled: boolean };
  vendorDashboardHelp: { default: boolean; enabled: boolean };
  oneTimeStatements: { default: boolean; enabled: boolean };
  broadCastCustom: { default: boolean; enabled: boolean };
  statements: { default: boolean; enabled: false };
  power: {
    features: string[];
    default: boolean;
    enabled: boolean;
  };
  recurringStatements: { default: boolean; enabled: boolean };
  sendCustomerWelcomeCommunications: { default: boolean; enabled: boolean };
  category: "featureFlags";
  sendPaymentMethodUpdate: { default: boolean; enabled: boolean };
  displayExternalInvoiceDate: { default: boolean; enabled: boolean };
  displayInvoicePoRefId: { default: boolean; enabled: boolean };
  customerManageAutopay: { default: boolean; enabled: boolean };
}

export const getEmptyGlobalFeatureFlags = (): GlobalFeatureFlags => ({
  click2PayCampaign: { default: false, enabled: false },
  adoptionAndTraining: { default: false, enabled: false },
  vendorManage: { default: false, enabled: false },
  broadCastQuick: { default: false, enabled: false },
  accountingQbo: { default: false, enabled: false },
  pro: {
    features: [],
    default: false,
    enabled: false
  },
  accountSummary: { default: false, enabled: false },
  qboConnect: { default: false, enabled: false },
  autopay: { default: false, enabled: false },
  customerLocationReport: { default: false, enabled: false },
  click2Pay: { default: false, enabled: false },
  vendorDashboardHelp: { default: false, enabled: false },
  oneTimeStatements: { default: false, enabled: false },
  broadCastCustom: { default: false, enabled: false },
  statements: { default: false, enabled: false },
  power: {
    features: [],
    default: false,
    enabled: false
  },
  recurringStatements: { default: false, enabled: false },
  sendCustomerWelcomeCommunications: { default: false, enabled: false },
  category: "featureFlags",
  sendPaymentMethodUpdate: { default: false, enabled: false },
  displayExternalInvoiceDate: { default: false, enabled: true },
  displayInvoicePoRefId: { default: false, enabled: true },
  customerManageAutopay: { default: true, enabled: true }
});

export type FeatureFlag = keyof GlobalFeatureFlags;
export interface SharedState {
  unAuthError: boolean;
  authToken: string;
  epa: EpaInterfaces.Epa;
  loadingEpa: boolean;
  loadedEpa: boolean;
  loadEpaFailed: boolean;
  loadingGlobalHolidays: boolean;
  loadedGlobalHolidays: boolean;
  loadGlobalHolidaysFailed: boolean;
  globalHolidays: GlobalHolidays;
  configuration: Configuration;
  loadingConfiguration: boolean;
  loadedConfiguration: boolean;
  loadConfigurationFailed: boolean;
  globalFeatureFlags: GlobalFeatureFlags;
}

export enum Frequency {
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  MONTHLY = "monthly"
}
