import * as React from "react";
import { StyledPanel, PanelName, RefDiv, MessagesIcon } from "./styled";
import { useHover } from "./hooks";
import { getBackgroundFillColor } from "./utils";
import { usePageNav } from "../../hooks/navigation";

const ResolutionCenterPanel: React.FC = () => {
  const [hoverRef, isHovered] = useHover();

  const { appendPath } = usePageNav();

  return (
    <RefDiv
      ref={hoverRef}
      data-testid="messages-hover"
      onClick={() => appendPath("messages")}
    >
      <StyledPanel background={getBackgroundFillColor(isHovered)}>
        <MessagesIcon fill={"#000"} data-testid="MessagesIcon" />
        <PanelName>MESSAGES</PanelName>
      </StyledPanel>
    </RefDiv>
  );
};

export default ResolutionCenterPanel;
