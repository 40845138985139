import * as React from "react";
import { StyledLink, PrintedText } from "./styled";
import PrefixEllipsis from "../PrefixEllipsis";
import { Invoice } from "../../state/entities/invoices/types";
import { openPdf, printViewTruncate } from "./utils";
import { useInvoiceLink } from "./hooks";
import { useParamHook } from "../../hooks/useParams";

interface InvoiceLinkProps {
  invoiceId: string;
  invoiceDisplayInvNum?: string;
  className?: string;
  minEllipsesWidth?: number;
  invoice?: Invoice;
  linkToPdf?: boolean;
}

const InvoiceLink: React.FC<InvoiceLinkProps> = ({
  invoiceId,
  invoiceDisplayInvNum,
  className,
  minEllipsesWidth,
  invoice,
  linkToPdf
}) => {
  const { openModal, displayInvNum } = useInvoiceLink(
    invoiceId,
    invoice,
    invoiceDisplayInvNum
  );

  const { token, vendorId, locationId } = useParamHook();

  return (
    <>
      <StyledLink
        data-testid="invoice-styled-link"
        className={className}
        onClick={e => {
          e.preventDefault();
          linkToPdf
            ? openPdf(token, vendorId, locationId, invoiceId)
            : openModal();
        }}
        title={displayInvNum}
      >
        <PrefixEllipsis
          text={displayInvNum}
          minEllipsesWidth={minEllipsesWidth}
        />
      </StyledLink>
      <PrintedText>{printViewTruncate(displayInvNum)}</PrintedText>
    </>
  );
};

export default InvoiceLink;
