import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreType } from "../../state/types";
import { getRequestDetails } from "../../state/entities/requests/selectors";
import {
  getAuthToken,
  getGlobalHolidays
} from "../../state/entities/shared/selectors";
import React from "react";
import { getEpa } from "../../state/entities/shared/selectors";
import { getSelectedVendorId } from "../../state/entities/vendors/selectors";
import {
  getSelectedPaymentMethodProvider,
  getSelectedPaymentMethod
} from "../../state/entities/paymentMethods/selectors";

import { useVendorFromPath } from "../../hooks/useVendorFromPath";
import { PaymentScheduleItem, DataTableRow } from "./types";
import { PaymentPlanHelper } from "@billfire/payment-lib-base-v2";
import { formatCurrency } from "@billfire/toybox";
import {
  formatDate,
  getLastPaymentDate,
  formatDataForTable,
  submitDisabled,
  createPaymentPlan
} from "./utils";
import { PaymentPlanConfirmationApiResponse } from "../../state/entities/paymentPlans/types";
import { getSelectedLocationId } from "../../state/entities/locations/selectors";

interface UsePaymentPlanRequestSummary {
  totalAmount: string;
  paidSoFar: string;
  startDate: string;
  endDate: string;
  tableData: Array<DataTableRow>;
}

export const usePaymentPlanRequestSummary =
  (): UsePaymentPlanRequestSummary => {
    const { requestId } = useParams<{ requestId: string }>();
    const request = useSelector((state: StoreType) => {
      return getRequestDetails(state, requestId);
    });
    const holidays = useSelector((state: StoreType) => {
      return getGlobalHolidays(state, requestId);
    });
    const vendor = useVendorFromPath();
    const paymentPlanHelper = new PaymentPlanHelper(vendor, holidays);

    const paymentPlanSchedule: Array<PaymentScheduleItem> =
      paymentPlanHelper.getPaymentPlanSchedule({
        startDate: request.startDate,
        totalAmount: request.totalAmount,
        frequency: request.frequency,
        eachAmount: request.eachAmount
      });

    const endDate = getLastPaymentDate(paymentPlanSchedule);

    return {
      totalAmount: formatCurrency(request.totalAmount),
      paidSoFar: formatCurrency(0),
      startDate: formatDate(request.startDate),
      endDate: formatDate(endDate),
      tableData: formatDataForTable(paymentPlanSchedule)
    };
  };

export const usePaymentPlanSubmit = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [confirmationData, setConfirmationData] = React.useState<
    PaymentPlanConfirmationApiResponse | {}
  >({});
  const [confirmed, setConfirmed] = React.useState(false);
  const provider = useSelector(getSelectedPaymentMethodProvider);
  const { version: epaVersion } = useSelector((state: StoreType) =>
    getEpa(state, provider)
  );
  const paymentMethod = useSelector((state: StoreType) =>
    getSelectedPaymentMethod(state, true)
  );
  const vendorId = useSelector(getSelectedVendorId);
  const locationId = useSelector(getSelectedLocationId);
  const token = useSelector(getAuthToken);
  const { requestId } = useParams<{ requestId: string }>();

  const pathname = `/token/${token}/vendors/${vendorId}/locations/${locationId}/planrequests/${requestId}/confirmation`;

  const submit = async () => {
    setSubmitting(true);

    const paymentMethodId = paymentMethod?.id || "";

    await createPaymentPlan(
      {
        vendorId,
        paymentMethodId,
        epaVersion
      },
      token
    )
      .then(
        ({
          paymentMethod,
          paymentPlan
        }: PaymentPlanConfirmationApiResponse) => {
          setSubmitting(false);
          setConfirmationData({ paymentMethod, paymentPlan });
          setConfirmed(true);
          localStorage.setItem("lastPaymentMethodId", paymentMethodId);
        }
      )
      .catch(() => {
        setSubmitting(false);
        setShowError(true);
      });
  };

  return {
    submitDisabled: submitDisabled(paymentMethod, vendorId),
    submitting,
    showError,
    confirmationData,
    submit,
    confirmed,
    pathname
  };
};
