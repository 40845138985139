import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreType } from "../state/types";
import { LoadingRequestFlags } from "../state/entities/requests/types";
import { useNavigation } from "./route";
import { getRequestLoadingFlags } from "../state/entities/requests/selectors";
import { useParams } from "react-router-dom";
import {
  loadRequestDetailsSubmit,
  loadRequestTypeDetailsSubmit
} from "../state/entities/requests/slice";

export const useRequestDetailsLoader = (): LoadingRequestFlags => {
  const dispatch = useDispatch();
  const flags = useSelector((state: StoreType) =>
    getRequestLoadingFlags(state)
  );
  const { vendorId, locationId } = useNavigation();
  const { requestId } = useParams<{ requestId: string }>();

  React.useEffect(() => {
    if (vendorId && locationId && requestId) {
      dispatch(loadRequestDetailsSubmit({ requestId }));
    }
  }, [dispatch, vendorId, locationId, requestId]);

  return flags;
};

export const useAutopayRequestDetailsLoader = (): LoadingRequestFlags => {
  const dispatch = useDispatch();
  const flags = useSelector((state: StoreType) =>
    getRequestLoadingFlags(state)
  );
  const { vendorId, locationId } = useNavigation();

  React.useEffect(() => {
    if (vendorId && locationId) {
      dispatch(loadRequestTypeDetailsSubmit({ type: "autopay" }));
    }
  }, [dispatch, vendorId, locationId]);

  return flags;
};
