import { StoreType } from "../../types";
import { PaymentGroup } from "./types";

export const getPaymentGroups = (state: StoreType): PaymentGroup[] => {
  return Object.values(state.paymentGroups.byId);
};

export const getPaymentGroup = (state: StoreType, id: string): PaymentGroup => {
  // eslint-disable-next-line security/detect-object-injection
  return state.paymentGroups.byId[id];
};

export const getPaymentGroupsLoading = (state: StoreType): boolean => {
  return state.paymentGroups.loadingPaymentGroups;
};

export const getPaymentGroupsLoadFailed = (state: StoreType): boolean => {
  return state.paymentGroups.loadPaymentGroupsFailed;
};
