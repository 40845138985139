import * as React from "react";
import { StyledH2, ListContainer } from "./Locations.styled";
import CommonWrapper from "../CommonWrapper";

import LocationsListItem from "../LocationsListItem";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useCallback } from "react";

import { useSortedLocations } from "./hooks";

export const Locations: React.FC = () => {
  const locations = useSortedLocations();

  const history = useHistory();
  const navigateToLocation = useCallback(
    (locationId: string) => {
      history.push(`locations/${locationId}`);
    },
    [history]
  );

  useEffect(() => {
    if (locations.length === 1) {
      navigateToLocation(locations[0].id);
    }
  }, [locations, navigateToLocation]);

  return (
    <CommonWrapper>
      <ListContainer>
        <StyledH2>Choose a Location</StyledH2>
        {locations.map(location => (
          <LocationsListItem
            key={location.id}
            location={location}
            handleClick={() => navigateToLocation(location.id)}
          />
        ))}
      </ListContainer>
    </CommonWrapper>
  );
};

export default Locations;
