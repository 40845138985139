import * as History from "history";
import { RouteContextType } from "../../routeContext";

export enum NavbarSelection {
  vendor = "Vendor",
  location = "Location"
}

const navigateBack = (
  selectionType: NavbarSelection,
  history: History.History<unknown>
): void => {
  const [target, offset] =
    selectionType === NavbarSelection.vendor
      ? ["vendors", 0]
      : ["locations", 1];
  const path = history.location.pathname.split("/");

  history.push(path.slice(0, path.indexOf(target) + offset).join("/"));
};

export interface NavData {
  history: History.History<unknown>;
  vendorCount: number;
  locationCount: number;
  locationSelected: boolean;
  selectedLocationId: string | null;
  selectedVendorId: string | null;
  token: string;
  push: (path: string) => void;
}

export type DashboardNav = (
  navData: NavData,
  routeContext: RouteContextType
) => {
  navigate: () => void;
  text: string;
};
export type BackNav = (
  navData: NavData,
  routeContext: RouteContextType
) => {
  navigate: () => void;
  text: string;
};
export type ChangeLocationOrVendorNav = (
  navData: NavData,
  routeContext: RouteContextType
) => {
  navigate: () => void;
  text: string;
};

export const navigationFunctions: {
  [key: string]: DashboardNav | BackNav | ChangeLocationOrVendorNav;
} = {
  changeLocationOrVendorNav: ({
    vendorCount,
    locationCount,
    locationSelected,
    history
  }) => {
    const selectionType =
      vendorCount > 1 && !(locationCount > 1 && locationSelected)
        ? NavbarSelection.vendor
        : NavbarSelection.location;

    return {
      navigate: () => navigateBack(selectionType, history),
      text: `Change ${selectionType}`
    };
  },
  dashboardNav: ({ selectedVendorId, selectedLocationId, push, token }) => {
    return {
      navigate: () =>
        push(
          `/token/${token}/vendors/${selectedVendorId}/locations/${selectedLocationId}`
        ),
      text: "Dashboard"
    };
  },
  backNav: (
    { selectedVendorId, selectedLocationId, push, token },
    routeContext: RouteContextType
  ) => {
    if (routeContext.history.length > 1) {
      return {
        navigate: () => {
          push(routeContext.history[routeContext.history.length - 2]);
        },
        text: "Back"
      };
    } else {
      return {
        navigate: () =>
          push(
            `/token/${token}/vendors/${selectedVendorId}/locations/${selectedLocationId}`
          ),
        text: "Dashboard"
      };
    }
  }
};
